import {TextField} from '@material-ui/core'
import styled from 'styled-components'

export const TextFieldSearchStyle = styled(TextField)`
  & > .MuiInputBase-formControl {
    font-size: 14px;
    height: 38.7px;
    margin: 20px 30px 0px 30px;

    & > input {
      height: 38.7px;
      padding: 0px 18.5px;
    }
  }
`

export const SearcableSelectField = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.borderColor || '#a9a8a8'};
  border-radius: 5px;
  padding: 10px 14px;

  .icon {
    height: 15px;
  }

  .value {
    user-select: none;
    font-size: 12px;
    margin: 0px;
  }

  .placeholder {
    user-select: none;
    font-size: 12px;
    color: #a9a8a8;
    margin: 0px;
  }
`