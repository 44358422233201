import React from 'react'
import {CircularProgress} from '@material-ui/core'

export default function LoadingComponent() {
  return (
    <div className="w-full h-full flex flex-col pt-32 pb-40 items-center">
      <CircularProgress />
      <h4 className="text-sm mt-6">Loading Data, please wait..</h4>
    </div>
  )
}
