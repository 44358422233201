import React, {useEffect} from 'react'
import {
  MenuItemStyled,
  TextFieldStyledNoLine,
} from '../../../../components/form/FormStyles'
import {
  GET_LIST_DD_COMPANY,
  GET_LIST_DD_PEOPLE,
} from '../../../../graphql/queries'

import {MUTATION_DELETE_DOCUMENT} from '../../../../graphql/mutations/document/document.mutation'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import IconFilterActive from '../../../shared-component/Filter/IconFilterActive'
import ImageIcon from '@material-ui/icons/Image'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import TextfieldSearch from '../../../shared-component/form/TextfieldSearch'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import {useMutation, useQuery} from '@apollo/react-hooks'
import Divider from '../../../../components/divider/DividerStyles'
import {TypographyStyled} from '../../../../components/typography/TypographyStyles'
import moment from 'moment'
import {Button, MenuItem, Menu} from '@material-ui/core'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'
import ModalShareButton from '../../form-component/ModalShareButton'
import ModalEditName from '../../form-component/ModalEditName'
import ConfirmationDialog from '../../../shared-component/dialog/ConfirmationDialog'
import {useSnackbar} from 'notistack'

function ListDigitalDocument(props) {
  const {tabsValue, setRefetch} = props
  const {enqueueSnackbar} = useSnackbar()
  const [digitalToggle, setDigitalToggle] = React.useState({
    openShare: false,
    openDelete: false,
    openEdit: false,
    data: null,
  })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filter, setFilter] = React.useState({
    orderBy: 'desc',
    search: '',
  })

  const [pagination, setPagination] = React.useState({
    rowsPerPage: 10,
    page: 0,
  })
  const [editApply] = useMutation(MUTATION_DELETE_DOCUMENT)

  let query = GET_LIST_DD_COMPANY
  let variable = {}
  let returnQuery = 'company_files'
  if (tabsValue === 0) {
    returnQuery = 'people_files'
    query = GET_LIST_DD_PEOPLE
    variable = {
      user: USER_ID,
      search: filter.search !== '' ? filter.search : null,
      sorting: filter.orderBy,
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
    }
  } else {
    variable = {
      company: COMPANY_ID,
      search: filter.search !== '' ? filter.search : null,
      sorting: filter.orderBy,
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
    }
  }

  const {data, error, loading, refetch} = useQuery(query, {
    variables: variable,
    context: {
      headers: {
        'X-Hasura-Role': tabsValue === 0 ? 'user' : 'organization-staff',
      },
    },
  })

  const deleteDD = data => {
    editApply({
      variables: {id: data.id},
      context: {
        headers: {
          'X-Hasura-Role': 'user',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Delete for Digital Document Success', {
          variant: 'success',
        })
        refetch()
        setDigitalToggle({
          openDelete: false,
        })
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  useEffect(() => {
    if (data) {
      setRefetch(refetch)
    }
  }, [data])

  const IconFile = nameFile => {
    const ext = nameFile.split('.')
    if (
      ext[ext.length - 1] === 'jpg' ||
      ext[ext.length - 1] === 'jpeg' ||
      ext[ext.length - 1] === 'png' ||
      ext[ext.length - 1] === ''
    ) {
      return (
        <ImageIcon style={{fontSize: 70, color: '#ef4d5e'}} className="pr-8" />
      )
    } else {
      return (
        <InsertDriveFileIcon
          style={{fontSize: 70, color: '#007fb2'}}
          className="pr-8"
        />
      )
    }
  }

  const handleClick = (event, data) => {
    setDigitalToggle({
      ...digitalToggle,
      data: data,
    })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (e, target) => {
    setFilter({
      ...filter,
      [target]: e.target.value,
    })
  }

  const MenuDropDown = () => {
    if (tabsValue === 0) {
      return (
        <>
          <MenuItem
            onClick={() => {
              handleRename(data)
            }}
          >
            Rename
          </MenuItem>
          <MenuItem onClick={() => handleShare()}>Share</MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                digitalToggle.data.file,
                '_blank',
                'noopener,noreferrer'
              )
            }}
          >
            Download
          </MenuItem>
          <MenuItem onClick={() => handleDelete()} style={{color: '#ef4d5e'}}>
            Delete
          </MenuItem>
        </>
      )
    } else {
      return (
        <>
          <MenuItem onClick={() => handleShare()}>Share</MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                digitalToggle.data.file,
                '_blank',
                'noopener,noreferrer'
              )
            }}
          >
            Download
          </MenuItem>
        </>
      )
    }
  }
  const handleShare = () => {
    setDigitalToggle({
      ...digitalToggle,
      openShare: !digitalToggle.openShare,
    })
  }
  const handleDelete = () => {
    setDigitalToggle({
      ...digitalToggle,
      openDelete: !digitalToggle.openDelete,
    })
  }

  const handleRename = () => {
    setDigitalToggle({
      ...digitalToggle,
      openEdit: true,
    })
  }
  return (
    <div className="flex flex-col px-0 min-h-screen">
      <div
        className="py-4 px-6 flex flex-row justify-between items-center"
        style={{borderBottom: '1px solid #f7f8f9'}}
      >
        <TextFieldStyledNoLine
          value={filter.orderBy}
          onChange={e => handleChange(e, 'orderBy')}
          select
          style={{width: 'auto', marginTop: '20px'}}
          size="small"
        >
          <MenuItemStyled value="desc">Latest</MenuItemStyled>
          <MenuItemStyled value="asc">Oldest</MenuItemStyled>
        </TextFieldStyledNoLine>
        <TextfieldSearch
          value={filter.search}
          onChange={e => handleChange(e, 'search')}
        />

        <div
        // className="cursor-pointer text-sm items-center flex flex-row"
        // onClick={e => setAnchorEl(e.currentTarget)}
        // style={{marginTop: '20px'}}
        // aria-describedby={idFilter}
        >
          <IconFilterActive
          // isFiltered={
          //   policy[0] || dateStart || dateEnd || status[0] || requester[0]
          // }
          />
          {/* Filter */}
        </div>
      </div>
      <Divider />
      {!loading || !error ? (
        data &&
        data[returnQuery].map((row, key) => {
          const fileName = row.file.split('/')
          const names = fileName[fileName.length - 1].split('.')
          const name = `${row.name}.${names[names.length - 1]}`
          return (
            <div
              key={key}
              style={{
                marginTop: 20,
                marginBottom: 6,
                padding: '0px 20px',
              }}
              className="flex justify-between items-center"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {IconFile(fileName[fileName.length - 1])}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '10px',
                  }}
                  className="flex justify-between items-center"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '90%',
                    }}
                  >
                    <TypographyStyled
                      className="font-semibold"
                      text="#000000"
                      style={{
                        fontSize: '14px',
                        marginBottom: 4,
                        lineHeight: '24px',
                      }}
                    >
                      {name}
                    </TypographyStyled>
                    <TypographyStyled
                      className="font-semibold"
                      text="#a9a8a8"
                      style={{
                        fontSize: '14px',
                        marginBottom: 4,
                        lineHeight: '24px',
                      }}
                    >
                      {row.global_file_type.name} -{' '}
                      {moment(row.date_added).format('MMMM D, YYYY')}
                    </TypographyStyled>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={e => handleClick(e, row)}
                    >
                      <MoreHorizIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {MenuDropDown()}
                    </Menu>
                  </div>
                </div>
                <Divider />
              </div>
            </div>
          )
        })
      ) : (
        <LoadingComponent />
      )}

      <TablePaginationStyled
        className="mt-auto"
        rowsPerPageOptions={[10, 25, 50]}
        onChangePage={(e, newPage) => {
          e.preventDefault()
          setPagination({...pagination, page: newPage})
        }}
        onChangeRowsPerPage={e => {
          setPagination({
            ...pagination,
            page: 0,
            rowsPerPage: parseInt(e.target.value, 10),
          })
        }}
        component="div"
        count={data && data.total.aggregate.count}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
      />
      <ModalShareButton
        open={digitalToggle.openShare}
        handleOpenCekin={handleShare}
        data={digitalToggle.data}
      />
      <ConfirmationDialog
        title="Apply for Digital Document"
        content="Are you sure you want to delete for this Digital Document?"
        onClickPrimary={() => deleteDD(digitalToggle.data)}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={digitalToggle.openDelete}
        onClose={() =>
          setDigitalToggle({
            ...digitalToggle,
            openDelete: false,
          })
        }
        disable={false}
      />

      <ModalEditName
        open={digitalToggle.openEdit}
        handleClose={() => {
          setDigitalToggle({
            ...digitalToggle,
            openEdit: false,
          })
          refetch()
        }}
        data={digitalToggle.data}
      />
    </div>
  )
}

export default ListDigitalDocument
