import styled from 'styled-components'

export const WrapperFilter = styled.div`
  width: auto;
  min-height: 216px;
  display: flex;
  flex-direction: row;
`
export const WrapperLeftFilter = styled.div`
  width: 218px;
  min-height: 216px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 1;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;

  .scroll {
    max-height: 100px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.16);
      border-radius: 5px;
    }
  }

  .action {
    .reset-all {
    }

    .cancel-apply {
      .cancel {
      }

      .apply {
      }
    }
  }
`
export const WrapperRightFilter = styled.div`
  width: 274px;
  min-height: 216px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f8f9;
  padding: 10px 0px;

  .textfield {
    padding: 0px 17px;
  }

  .title {
    padding: 0px 17px 10px 17px;
  }
  .content-checkbox {
    padding: 0px 17px 10px 17px;
    max-height: 120px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.16);
      border-radius: 5px;
    }
  }
`
