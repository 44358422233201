import React from "react";
import { FormHelperTextStyled } from "../../../components/form/FormStyles";
import { SelectStyled } from "../../../components/select/SelectStyled";
import { IconButtonStyled } from "../../../components/Icons/IconComponent";
import { TypographyStyled } from "../../../components/typography/TypographyStyles";
import FormWrapperInline from "../../shared-component/form/FormWrapperInline";
import ClearIcon from "@material-ui/icons/Clear";
import {
  DEFAULT_BUDGET_COMPONENT,
  DEFAULT_DESTINATION_NAME,
} from "./ApplyForTripForm";
import { useQuery } from "@apollo/react-hooks";
import { GET_LIST_TRIP_DESTINATION } from "../../../graphql/queries";

const SelectDestination = ({
  onClickOption,
  value,
  error,
  placeholder,
  onSearch,
  option,
  isLoading,
}) => {
  const isError = [null, "", undefined].includes(value);
  return (
    <FormWrapperInline style={{ width: "40%", minWidth: 197 }} className="mr-4">
      <SelectStyled
        filterOption={false}
        onChange={(option) => onClickOption(option)}
        onInputChange={onSearch}
        value={value}
        options={option}
        isSearchable
        isLoading={isLoading}
        autoload={false}
        placeholder={placeholder}
        getOptionLabel={(option) => (
          <div className="wrap-option">
            <h3 className="text-sm mb-1">{option.label}</h3>
            <p className="hide-when-selected text-xs m-0 text-disable">
              {option.country_name}
            </p>
          </div>
        )}
        className="mr-4"
        minWidth={181}
        singleValueStyle={singleValueStyle}
      />
      {error && isError && (
        <FormHelperTextStyled className="text-danger">
          This field is required
        </FormHelperTextStyled>
      )}
    </FormWrapperInline>
  );
};

const TripDestination = ({
  onClickDelete,
  onChangeDestination,
  error,
  onSearch,
  option,
  isLoading,
  selected,
}) => {
  return (
    <div className="flex flex-row w-full items-center mb-4">
      <SelectDestination
        onClickOption={(data) => onChangeDestination(data, "from")}
        value={selected?.["from"]}
        error={error}
        onSearch={onSearch}
        option={option}
        isLoading={isLoading}
        placeholder="Choose Origin"
      />
      <SelectDestination
        onClickOption={(data) => onChangeDestination(data, "to")}
        value={selected?.["to"]}
        error={error}
        onSearch={onSearch}
        option={option}
        isLoading={isLoading}
        placeholder="Choose Destination"
      />
      {onClickDelete && (
        <IconButtonStyled
          onClick={onClickDelete}
          size="small"
          className="h-full"
        >
          <ClearIcon className="text-md" color="error" />
        </IconButtonStyled>
      )}
    </div>
  );
};

export default function FormDestination({
  smallSize,
  values,
  setValues,
  error,
  destination_type,
}) {
  const [search, setSearch] = React.useState("");

  const { data: dataDestination, loading: loadingDestination } = useQuery(
    GET_LIST_TRIP_DESTINATION,
    {
      variables: {
        policy: values?.policy?.business_trip_policy?.id,
        isStrictPolicy: destination_type === 2,
        search: `%${search}%`,
      },
    }
  );

  const handleDeleteDestination = (selected) => {
    setValues(() => {
      const _destination = [...values.destination];
      const index = _destination.indexOf(selected);
      if (index > -1) {
        _destination.splice(index, 1);
      }
      return {
        ...values,
        destination: _destination,
      };
    });
  };

  const handleAddDestination = () => {
    setValues((prev) => {
      const _destination = values.destination;
      const _destinationLength = prev.destination.length;
      _destination.push({
        id: prev.destination[_destinationLength - 1].id + 1,
        from: null,
        to: null,
        duration: null,
        name: DEFAULT_DESTINATION_NAME,
        budget: DEFAULT_BUDGET_COMPONENT,
      });
      return {
        ...values,
        destination: _destination,
      };
    });
  };

  const handleChangeDestination = (data, target, selected) => {
    setValues((prev) => {
      const _prevDestination = [...prev.destination];
      const _prevSelectedDestination = [...prev.destination].filter(
        (res) => res.id === selected.id
      );

      const _obj = {
        ..._prevSelectedDestination[0],
        [target]: data.value,
        selected: {
          ..._prevSelectedDestination[0].selected,
          [target]: data,
        },
        name: {
          ..._prevSelectedDestination[0].name,
          [target]: data.label,
        },
      };

      const destination = _prevDestination.map(
        (obj) => [_obj].find((o) => o.id === obj.id) || obj
      );

      return {
        ...prev,
        destination,
      };
    });
  };

  return (
    <div className="flex sm:flex-row flex-row w-full mb-8">
      <div
        style={!smallSize ? { marginRight: 30 } : { marginRight: 0 }}
        className="font-semibold text-xs"
      >
        {!smallSize && "5."}
      </div>
      <div className="w-full">
        <TypographyStyled className="text-xs font-semibold mb-3">
          Destination*
        </TypographyStyled>

        {values.destination.map((res, i) => {
          return (
            <TripDestination
              key={res.id}
              error={error}
              selected={res.selected}
              onSearch={(search) => setSearch(search)}
              option={dataDestination?.global_destination.map((res) => ({
                label: res.name,
                value: res.id,
                country_name: res.global_country.country_name,
              }))}
              isLoading={loadingDestination}
              onChangeDestination={(data, target) => {
                handleChangeDestination(data, target, res);
                setSearch("");
              }}
              onClickDelete={
                i !== 0 ? () => handleDeleteDestination(res) : null
              }
            />
          );
        })}
        <span
          onClick={handleAddDestination}
          className="text-xs text-secondary cursor-pointer select-none"
        >
          Add destination
        </span>
      </div>
    </div>
  );
}

const singleValueStyle = {
  ".wrap-option": {
    ".hide-when-selected": {
      display: "none",
    },
  },
};
