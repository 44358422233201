import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {ListItemAtt} from '../SharedCompoenentAttendance/ListItemAtt'
import {GET_DETAIL_STATUS_ATTENDANCE} from '../../../graphql/queries'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import {generateDay} from '../../../utils/helpers'
import {AVATAR} from '../../../utils/globals'
import {getInOutDateDetail, getGMT} from '../helperAttendance'

export default function DetailList({id}) {
  const {data, error} = useQuery(GET_DETAIL_STATUS_ATTENDANCE, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
    },
  })

  if (error) {
    return <div>Something went wrong</div>
  }

  if (!data) {
    return <LoadingComponent />
  }

  const details = data.people_work_attendances_by_pk
  const policy = details.time_attendance_policy

  const day = generateDay(details.clock_in)
  const dayLowercase = day.toLowerCase()

  const isFlexTime = policy?.flexi === 1
  const isFlexLocation = policy?.location_option === 2

  const {checkin, checkout} = getInOutDateDetail(details)

  return (
    <div className="flex flex-col">
      {policy?.mobile_setting.isIncludePhoto && (
        <div className="p-6 flex flex-row justify-around">
          <div>
            <img
              alt="photo-checkin"
              src={details.photo}
              className="w-32 h-32 bg-wlbgrey"
            />
            <div style={{marginTop: 7, fontWeight: '600', fontSize: 14}}>
              Clock - In
            </div>
          </div>
          <div>
            {AVATAR ? (
              <img alt="photo" src={AVATAR} className="w-33 h-32 bg-wlbgrey" />
            ) : (
              <img
                alt="photo"
                src={require('../../../assets/slicing/default-avatar.png')}
                className="w-33 h-32 bg-wlbgrey"
              />
            )}
            <div style={{marginTop: 7, fontWeight: '600', fontSize: 14}}>
              Photo Profile
            </div>
          </div>
        </div>
      )}

      {policy?.mobile_setting.isIncludePhoto && (
        <div className="h-1 bg-wlbgrey" />
      )}

      <div className="px-6 pt-6">
        <ListItemAtt
          label="Name"
          value={details.people_work_placement.global_user.name}
        />
        <ListItemAtt
          label="Position"
          value={details.people_work_placement.company_job_profile.title}
        />
      </div>

      {policy && <div className="h-1 bg-wlbgrey" />}

      {policy && (
        <div className="px-6 pt-6">
          <h6 className="text-sm text-primary mb-5 font-semibold">
            Work Schedule
          </h6>

          <ListItemAtt label="Type" value={policy.name || '-'} />
          <ListItemAtt
            label=""
            value={getGMT(policy.mobile_setting.timezone)}
          />
          <ListItemAtt
            label="Office Hour"
            value={
              isFlexTime
                ? 'Flexible'
                : `${day}, ${policy.schedule[dayLowercase].clockIn} - ${policy.schedule[dayLowercase].clockOut}`
            }
          />
          <ListItemAtt
            label="Grace Time"
            value={isFlexTime ? '-' : `${policy.late_tolerance || 0} Minutes`}
          />
          <ListItemAtt
            label="Location"
            value={
              isFlexLocation
                ? 'Flexible'
                : policy.time_attendance_policy_locations?.[0]
                    ?.time_attendance_location.location.address || '-'
            }
            secondary
            location
          />
        </div>
      )}

      <div className="h-1 bg-wlbgrey" />

      <div className="px-6 pt-6">
        <div className="mb-5">
          <h6 className="text-sm text-secondary mb-2 font-semibold">
            Check - In
          </h6>

          <ListItemAtt label="Time" value={checkin} />

          {policy && (
            <ListItemAtt
              label="Location"
              value={details.location?.address || '-'}
              secondary
              location
            />
          )}

          {policy && (
            <ListItemAtt
              label="Late"
              value={(details.late_minutes || 0) + ' Minutes'}
            />
          )}
        </div>

        {details.clock_out && (
          <div className="mb-5">
            <h6 className="text-sm text-warning mb-2 font-semibold">
              Check - Out
            </h6>

            <ListItemAtt label="Time" value={checkout} />

            {policy && (
              <ListItemAtt
                label="Location"
                value={details.attendance_fields?.checkout?.address || '-'}
                secondary
                location
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
