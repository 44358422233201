import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import {capitalize} from '../../../utils/helpers'
import {useMediaQuery, useTheme} from '@material-ui/core'

export default function ModalDetailWeb({
  status,
  title,
  open,
  onClose,
  confirm_type,
  data,
  from,
  ...child
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  let policy_type = 'time_leave_policy_child'
  if (from === 'overtime') {
    policy_type = 'time_overtime_policy'
  }
  const generateColor = status => {
    switch (status) {
      case 'Approved':
        return 'bg-success'
      case 'Pending':
        return 'bg-warning'
      case 'Waiting':
        return 'bg-warning'
      case 'Waiting for second approval':
        return 'bg-warning'
      case 'Rejected':
        return 'bg-danger'
      case 'Cancelled':
        return 'bg-disable'
      default:
        // return null
        return 'bg-warning'
    }
  }

  const generateStatus = (status, type, first, second) => {
    if (status === 'approved') {
      return 'Approved'
    } else if (status === 'rejected') {
      return 'Rejected'
    } else if (status === 'cancelled') {
      return 'Cancelled'
    } else if (type === '1') {
      if (first === null && second === null) {
        if (status === 'pending') {
          return 'Waiting'
        }
      } else if (first === null && second !== null) {
        if (status === 'pending' || status === 'confirm1') {
          return 'Waiting'
        }
      }
      if (status === 'pending') {
        return 'Waiting'
      }
    } else if (type === '2') {
      if (first !== null && second === null) {
        if (status === 'pending') {
          return 'Waiting'
        }
      }
      if (status === 'pending' || status === 'confirm1') {
        return 'Waiting'
      }
    } else {
      return capitalize(status)
    }
  }

  return (
    <StyledDialog
      classes={{paper: 'w-693-px'}}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="body"
      fullScreen={fullScreen}
    >
      <div className="flex flex-col">
        <div
          className={`flex flex-row justify-between ${
            fullScreen ? 'p-4' : 'p-6'
          } bg-wlbgrey items-center`}
        >
          <div className="flex flex-row items-center">
            <div
              className={`${status &&
                generateColor(
                  generateStatus(
                    status,
                    confirm_type,
                    data[policy_type] && data[policy_type].first_job_profile,
                    data[policy_type] && data[policy_type].second_job_profile
                    // data.globalUserByModifiedBy,
                  )
                )}  ${
                fullScreen ? 'py-4 px-4' : 'py-2 px-6'
              } text-white rounded-wlb mr-4 text-xs`}
            >
              {status &&
                !fullScreen &&
                generateStatus(
                  status,
                  confirm_type,
                  data[policy_type] && data[policy_type].first_job_profile,
                  data[policy_type] && data[policy_type].second_job_profile
                  // data.globalUserByModifiedBy,
                )}
            </div>
            <h2 className={fullScreen ? null : 'font-semibold'}>{title}</h2>
          </div>
          <CloseIcon className="cursor-pointer" onClick={onClose} />
        </div>

        <div {...child} />
      </div>
    </StyledDialog>
  )
}
