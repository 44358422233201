import {
  GET_LIST_POLICY_OVERTIME_FILTER,
  GET_LIST_REQUESTER_OVERTIME_REQUEST_FILTER,
} from '../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {generateDateSubmit} from '../../utils/helpers'

export const listFilter = [
  {
    name: 'Type',
    fieldName: 'type',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_LIST_POLICY_OVERTIME_FILTER,
        getVariables: ({search, limit, _nin}) => {
          return {
            limit,
            user: USER_ID,
            company: COMPANY_ID,
            search: search || '%%',
            nin: _nin,
          }
        },
        setData: ({time_overtime_policies, total}) => {
          if (time_overtime_policies) {
            const _data = time_overtime_policies.map(res => {
              return {
                value: res?.id,
                label: res?.name,
              }
            })
            return [_data, total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {value: 'waiting', label: 'Waiting'},
        {value: 'approved', label: 'Approved'},
        {value: 'rejected', label: 'Rejected'},
        {value: 'cancelled', label: 'Cancelled'},
      ],
    },
  },
  {
    name: 'Requester',
    fieldName: 'requester',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_LIST_REQUESTER_OVERTIME_REQUEST_FILTER,
        getVariables: ({search, limit, _nin}) => {
          return {
            limit,
            user: USER_ID,
            company: COMPANY_ID,
            search: search || '%%',
            nin: _nin,
          }
        },
        setData: ({people_work_placements, total}) => {
          if (people_work_placements) {
            const _data = people_work_placements.map(({global_user}) => {
              return {
                value: global_user?.id,
                label: global_user?.name,
              }
            })
            return [_data, total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Submission Date',
    fieldName: 'date',
    type: 'date',
  },
]

const _formated = date => generateDateSubmit(date)

export const generateFilter = data => {
  const _data = {}
  for (const property in data) {
    if (Array.isArray(data[property])) {
      _data[property] = data[property][0]
        ? data[property].map(itm => itm.value)
        : undefined
    } else if (property === 'date') {
      _data[property] = {
        ...(data[property].from && {
          from: _formated(data[property].from),
        }),
        ...(data[property].to && {to: _formated(data[property].to)}),
      }
    }
  }
  return _data
}
