import React from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
// import {
//   ModalHeaderWhite,
//   ModalContent,
// } from '../../../components/modal/ModalStyles'
// import {IconStyled} from '../../../components/Icons/IconComponent'
// import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import CloseIcon from '@material-ui/icons/Close'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'

function ModalShareButton({handleOpenCekin, open, data}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      onClose={() => handleOpenCekin()}
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      fullScreen={fullScreen}
    >
      <DialogTitle style={{backgroundColor: '#eff2f4'}}>
        <div className="flex justify-between items-center">
          <TypographyStyled variant="h6" style={{fontSize: 16}} weight="600">
            Share
          </TypographyStyled>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleOpenCekin()}
            aria-label="close"
            // className="md:hidden lg:hidden"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: 'p-0'}}>
        {data && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
            }}
          >
            <EmailShareButton
              url={data.file}
              subject={data.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <EmailIcon size={42} round={true}></EmailIcon>
              Email
            </EmailShareButton>
            <FacebookShareButton
              url={data.file}
              quote={data.name}
              hashtag={'#WLB'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FacebookIcon size={42} round={true}></FacebookIcon>
              Facebook
            </FacebookShareButton>
            <TwitterShareButton
              url={data.file}
              via={data.file}
              title={data.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TwitterIcon size={42} round={true}></TwitterIcon>
              Twitter
            </TwitterShareButton>
            <LinkedinShareButton
              url={data.file}
              title={data.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LinkedinIcon size={42} round={true}></LinkedinIcon>
              LinkedIn
            </LinkedinShareButton>
            <WhatsappShareButton
              url={data.file}
              title={data.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <WhatsappIcon size={42} round={true}></WhatsappIcon>
              Whatsapp
            </WhatsappShareButton>
            <TelegramShareButton
              url={data.file}
              title={data.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TelegramIcon size={42} round={true}></TelegramIcon>
              Telegram
            </TelegramShareButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalShareButton
