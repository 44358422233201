/** @type {Map<string, string>} */
const currencySignCache = new Map()

/**
 * @param {string} currency
 * @returns {string}
 */
export function getCurrencySign(currency) {
  let sign = currencySignCache.get(currency)

  if (!sign) {
    const formatOptions = {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
    }

    sign = (123).toLocaleString('id-ID', formatOptions).replace(/\s*123.+/, '')
    currencySignCache.set(currency, sign)
  }

  return sign
}

/**
 * @param {{ value: string, label: string }} option
 * @returns {string}
 */
export function getCurrencyOptionLabel(option) {
  const currency = option.value.toUpperCase()

  return `${option.label} (${currency}) - ${getCurrencySign(option.value)}`
}

/**
 * @param {string} currency
 * @param {number} nominal
 * @returns {string}
 */
export function formatCurrency(currency, nominal) {
  const formatted = nominal.toLocaleString('id-ID', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  })

  // Remove spacing between currency sign and nominal
  return formatted.replace(/\s+/g, '')
}
