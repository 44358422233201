import gql from 'graphql-tag'

export const GET_DETAIL_SLIP = gql`
  query GetDetailSlip($id: bigint!) {
    people_work_payroll_by_pk(id: $id) {
      year
      month
      employee_placement {
        id
        regno
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        people_work_salaries(where: {active: {_eq: true}}) {
          npwp
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
      }
      approver
      finance_personnel
      date_sent
      date_printed
      salary
      allowances
      non_fixed_components
      payroll_fields
      final_salary
      postponed_items
    }
  }
`
