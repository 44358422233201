import {
  addDay,
  differenceDate,
  FormattedDate,
  generateDate,
} from '../../../utils/helpers'

export const getDestination = destination => {
  return destination ? fillDestination(destination) : '-'
}

const fillDestination = items => {
  let destination = ''
  for (const [idx, item] of items.entries()) {
    destination += generateDestination(idx, item, items)
  }
  return destination
}

const generateDestination = (idx, item, items) => {
  return `${item.globalDestinationByFrom.name} - ${
    item.globalDestinationByTo.name
  }${idx + 1 !== items.length ? ',' : ''} `
}

export const getComponentBudget = (row, type = 'TRIP') => {
  const budget_component = [0]

  for (const component of row.budget) {
    const DETAIL_TYPE = {
      TRIP: calculateBudget(row, component),
      TRIP_REPORT: component.realization,
    }

    budget_component.push(DETAIL_TYPE[type])
  }

  return budget_component.reduce((acc, cur) => acc + cur)
}

export const getTotalBudget = (destination, type = 'TRIP') => {
  const totalBudget = [0]
  const _destination = [...destination]
  for (const row of _destination) {
    totalBudget.push(getComponentBudget(row, type))
  }
  return totalBudget.reduce((acc, cur) => acc + cur)
}

const componentDurationHandler = ({data, arrayDuration, i}) => {
  const _arr_start = arrayDuration.slice(0, i)
  const count_start_date_added =
    (_arr_start.length && _arr_start.reduce((acc, cur) => acc + cur)) || 0
  const start_date = addDay(data?.date_start, count_start_date_added)
  const end_date = addDay(start_date, arrayDuration[i])

  return {start_date, end_date}
}

export const getComponentDuration = ({data, arrayDuration, i, duration}) => {
  const {start_date, end_date} = componentDurationHandler({
    data,
    arrayDuration,
    i,
  })

  return `${FormattedDate(start_date, 'MMMM dd')} - ${FormattedDate(
    end_date,
    'MMMM dd, yyyy'
  )} (${duration} Days)`
}

const _is_error = value => ['', null].includes(value)
const mandatoryFields = [
  'participant',
  'policy',
  'destination',
  'start_date',
  'end_date',
  'description',
]

export const isErrorSubmit = values => {
  let isError = false

  // TODO: Validate mandatory fields
  for (const field of mandatoryFields) {
    if (_is_error(values[field])) {
      isError = true
    }
  }

  for (const destination of values.destination || []) {
    if (
      !destination.from ||
      !destination.to ||
      !destination.duration ||
      !destination.start_date ||
      !destination.end_date
    ) {
      isError = true
    }

    for (const budget of destination.budget) {
      if (!budget.type) {
        isError = true
      }
    }
  }

  return isError
}

const diffDestinationDuration = (dateLeft, dateRight, startFrom = 0) =>
  (differenceDate(dateLeft, dateRight) || 0) + startFrom

export const getDurationInDestination = ({
  date,
  start_date,
  end_date,
  target,
}) => {
  return target === 'start_date'
    ? diffDestinationDuration(end_date, date, 1)
    : diffDestinationDuration(date, start_date, 1)
}

export const getTripDate = (start, end, isShortDate) => {
  const month = isShortDate ? 'MMM' : 'MMMM'
  const totalDay = differenceDate(end, start)
  return `${FormattedDate(
    start,
    totalDay === 0 ? `${month} dd, yyyy` : `${month} dd`
  )} - ${FormattedDate(end, `${month} dd, yyyy`)}`
}

export const getDestinationDate = (startDate, endDate) =>
  `${FormattedDate(startDate, 'MMMM dd')} - ${FormattedDate(
    endDate,
    'MMMM dd, yyyy'
  )}`

const update_record_status = {
  pending: 'first_',
  confirm1: 'second_',
  confirm2: 'third_',
}

export const getRecordField = (status, action) =>
  update_record_status[status] + action

export const getListDate = (startdate, enddate) => {
  return startdate === enddate
    ? `For: ${generateDate(startdate)}`
    : `For: ${generateDate(startdate)} - ${generateDate(enddate)}`
}

export const getUserRejectorTrip = business_trip_fields => {
  const {first_reject, second_reject, third_reject} = business_trip_fields || {}

  if (first_reject) {
    return first_reject?.user || {}
  } else if (second_reject) {
    return second_reject?.user || {}
  } else if (third_reject) {
    return third_reject?.user || {}
  }
}

export const getUserApproval = aproval_origin => {
  const job_profile_supervisor_1 = aproval_origin
  const job_profile_supervisor_2 = job_profile_supervisor_1?.spv_2
  const job_profile_supervisor_3 = job_profile_supervisor_2?.spv_3

  const is_spv_1 = job_profile_supervisor_1?.people_work_placements?.[0]
  const is_spv_2 = job_profile_supervisor_2?.people_work_placements?.[0]
  const is_spv_3 = job_profile_supervisor_3?.people_work_placements?.[0]

  const name = (is_spv_1 || is_spv_2 || is_spv_3)?.global_user?.name
  const title = is_spv_1
    ? job_profile_supervisor_1?.title
    : is_spv_2
    ? job_profile_supervisor_2?.title
    : is_spv_3
    ? job_profile_supervisor_3?.title
    : ''

  return {name, title}
}

export const getUserPlacement = placement => {
  const {name} = placement?.global_user || {}
  const {title} = placement?.company_job_profile || {}

  return {name, title}
}

export const isUploadingFiles = attachments => {
  return attachments?.some(attachment => attachment.url === '')
}

export const getStatusApproval = data => {
  const {status, business_trip_policy} = data || {}
  const {
    companyJobProfileBySecondConfirmProfile,
    companyJobProfileByThirdConfirmProfile,
  } = business_trip_policy || {}

  let statusResult = 'processing'

  switch (status) {
    case 'pending':
      if (companyJobProfileBySecondConfirmProfile) {
        statusResult = 'confirm1'
      }
      break
    case 'confirm1':
      if (companyJobProfileByThirdConfirmProfile) {
        statusResult = 'confirm2'
      }
      break
  }

  return statusResult
}

export const getBudgetCalculationLabel = type => {
  switch (type) {
    case 'flat':
      return 'Flat'
    case 'multiply_by_days':
    default:
      return 'Multiply by day(s)'
  }
}

export const calculateBudget = (trip, budget) => {
  switch (budget.budget_calculation) {
    case 'flat':
      return budget.nominal
    case 'multiply_by_days':
    default:
      return trip.duration * budget.nominal
  }
}
