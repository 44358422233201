import React from 'react'

import UploadAttachmentBudget from './UploadAttachmentBudget'
import {
  InputAdornmentStyled,
  StartAdornment,
  TextFieldStyled,
} from '../../../components/form/FormStyles'
import {convertNominal} from '../../../utils/helpers'

import {makeStyles} from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {updateBudgetComponent} from '../trip-report-helper'
import {calculateBudget} from '../../trip/trip-shared-component/trip-helper'
import {getCurrencySign} from '../../trip/currencyExchanges'
import {Typography} from '@material-ui/core'
import {InputConversionDisplay} from '../../trip/TripStyles'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&::before': {
      content: '',
      display: 'none',
    },
  },
  summary: {
    padding: 0,
    minHeight: 'unset !important',
  },
}))

export default function Budget(props) {
  const {data, setValues, values, dataDestination, isEditBudget} = props
  const classes = useStyles()

  const handleChangeBudgetUsage = ({selectedDestination, component, value}) => {
    const numberOnly = /^\d+$/
    const nominal = Number(value.replace(/[^0-9,-]+/g, ''))

    if (numberOnly.test(nominal)) {
      setValues(prev => {
        const destination = updateBudgetComponent({
          prev,
          selectedDestination,
          value: nominal,
          component,
          target: 'realization',
        })
        return {...prev, destination}
      })
    }
  }

  const givenBudget = calculateBudget(dataDestination, data)

  return (
    <Accordion defaultExpanded classes={{root: classes.root, expanded: 'm-0'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: classes.summary,
          content: 'm-0',
          expandIcon: 'py-0',
        }}
      >
        <Typography className="text-xs font-semibold my-3">
          {data?.business_trip_component?.name || '-'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className="p-0 flex flex-col"
        style={{maxWidth: isEditBudget ? 637 : 'unset'}}
      >
        <Typography className="text-xs mb-2">
          {data?.business_trip_component?.description || '-'}
        </Typography>

        <div className="flex flex-row gap-3 my-3">
          <div>
            <p className="text-xs mb-2 font-semibold">Budget</p>
            <TextFieldStyled
              disabled
              fullWidth
              variant="outlined"
              value={convertNominal(givenBudget)}
              InputProps={{
                classes: {
                  input: 'text-xs text-right py-3',
                },
                startAdornment: (
                  <InputAdornmentStyled position="start" className="text-xs">
                    <StartAdornment className="text-xs">
                      {getCurrencySign(values.currency)}
                    </StartAdornment>
                  </InputAdornmentStyled>
                ),
              }}
            />

            {values.currency !== values.policy?.currency_from && (
              <InputConversionDisplay
                // NOTE(intrnl): Yes, this is intentionally flipped
                from={values.currency}
                to={values.policy.currency_from}
                value={givenBudget}
                date={values.approved_date}
                approximate
              />
            )}
          </div>
          <div>
            <p className="text-xs mb-2 font-semibold">Budget Used*</p>
            <TextFieldStyled
              fullWidth
              placeholder="Add Budget"
              variant="outlined"
              value={convertNominal(data?.realization || '0')}
              onFocus={e => e.target.select()}
              onChange={e => {
                handleChangeBudgetUsage({
                  selectedDestination: dataDestination,
                  component: data,
                  value: e.target.value,
                })
              }}
              InputProps={{
                inputProps: {className: 'text-xs text-right py-3', min: 0},
                startAdornment: (
                  <InputAdornmentStyled position="start">
                    <StartAdornment className="text-xs">
                      {getCurrencySign(values.currency)}
                    </StartAdornment>
                  </InputAdornmentStyled>
                ),
              }}
            />

            {values.currency !== values.policy?.currency_from && (
              <InputConversionDisplay
                // NOTE(intrnl): Yes, this is intentionally flipped
                from={values.currency}
                to={values.policy.currency_from}
                value={data?.realization || 0}
                date={values.approved_date}
                approximate
              />
            )}
          </div>
        </div>

        <UploadAttachmentBudget
          selectedDestination={dataDestination}
          {...props}
        />
      </AccordionDetails>
    </Accordion>
  )
}
