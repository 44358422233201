import React from 'react'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import {
  InputAdornmentStyled,
  StartAdornment,
  TextFieldStyled,
} from '../../../components/form/FormStyles'

import FormWrapper from '../../shared-component/form/FormWrapper'
import FormEditBudgetWrapper from '../trip-report-shared-component/FormEditBudgetWrapper'

import {PAYMENT_METHOD} from '../trip-report-helper'
import {convertNominal} from '../../../utils/helpers'
import {getCurrencySign} from '../../trip/currencyExchanges'
import {InputConversionDisplay} from '../../trip/TripStyles'

export default function FormTotalRemainingMoney({
  smallSize,
  remainingState,
  remainingMoney,
  values,
  setValues,
  error,
  isEditBudget,
}) {
  const handleChange = (e, value) => {
    e.preventDefault()
    setValues(prev => ({...prev, budget_action: value}))
  }

  const Container = isEditBudget ? FormEditBudgetWrapper : FormWrapper

  return (
    <Container number={!smallSize && 6} title="Total Remaining Money">
      <div className="flex flex-col">
        {!isEditBudget && (
          <p className="mt-2 mb-3 text-xs">
            All the money that not spent or overspent during the trip. This
            money should be returned to the company or given back to the
            employee
          </p>
        )}

        <div className="w-1/2" style={isEditBudget ? {maxWidth: 255} : null}>
          <TextFieldStyled
            fullWidth
            variant="outlined"
            value={convertNominal(remainingMoney)}
            InputProps={{
              readOnly: true,
              classes: {
                input: 'text-xs text-right py-3',
              },
              startAdornment: (
                <InputAdornmentStyled position="start" className="text-xs">
                  <StartAdornment className="text-xs">
                    {getCurrencySign(values.currency || 'idr')}
                  </StartAdornment>
                </InputAdornmentStyled>
              ),
            }}
          />

          {values.currency !== values.policy?.currency_from && (
            <InputConversionDisplay
              // NOTE(intrnl): Yes, this is intentionally flipped
              from={values.currency}
              to={values.policy.currency_from}
              value={remainingMoney}
              date={values.approved_date}
              approximate
            />
          )}
        </div>

        <FormControl className="mt-4" component="fieldset">
          <FormLabel
            className="text-xs mb-2 text-black font-semibold"
            component="legend"
          >
            {PAYMENT_METHOD?.[remainingState]?.message}
          </FormLabel>
          <RadioGroup
            aria-label="return-options"
            name="options"
            onChange={handleChange}
            value={values?.budget_action}
          >
            {PAYMENT_METHOD?.[remainingState]?.options?.map((option, idx) => (
              <FormControlLabel
                key={idx}
                checked={option.value === values?.budget_action}
                classes={{label: 'text-xs select-none'}}
                value={option.value}
                control={<Radio size="small" color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {error && !values.budget_action && (
            <FormHelperText error>This field is required</FormHelperText>
          )}
        </FormControl>
      </div>
    </Container>
  )
}
