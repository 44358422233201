import React from 'react'
import {useApolloClient, useQuery, useMutation} from '@apollo/react-hooks'

import {Button, CircularProgress, TextField} from '@material-ui/core'

import {AttachmentCard} from '@smartasn/wlb-utils-components'
import {useSnackbar} from 'notistack'

import DetailsTable from '../../components/utils-components/DetailsTable'
import ModalDetail from '../shared-component/dialog/ModalDetail'
import LoadingComponent from '../shared-component/loading/LoadingComponent'
import ConfirmationDialog from '../shared-component/dialog/ConfirmationDialog'

import {getClaimType, getStatusColor} from './ClaimStyles'

import {
  capitalize,
  convertToRupiah,
  generateDateMonth,
} from '../../utils/helpers'
import usePrevious from '../../hooks/usePrevious'
import {POSITION_ID, USER_ID} from '../../utils/globals'
import {
  GET_CLAIM_DETAILS,
  GET_CLAIM_INVOICE_EXPORTS,
  GET_CLAIM_USER_DETAILS,
} from '../../graphql/queries'
import {
  APPROVE_CLAIM,
  REQUEST_CLAIM_INVOICE_EXPORTS,
  SET_CLAIM_REJECTED,
  SET_CLAIM_STATUS,
} from '../../graphql/mutations'

const SUBMISSION_CONFIG = [
  {title: 'Employee Name', render: x => x.employee.user.name},
  {title: 'Position', render: x => x.employee.profile.title},
  {title: 'Submission Date', render: x => generateDateMonth(x.date_added)},
  {title: 'Submission Status', render: renderSubmissionStatus},
]

const REQUEST_CONFIG = [
  {title: 'Request ID', render: x => x.formatted_id},
  {
    title: 'Invoice ID',
    hide: x => !x.invoice,
    render: x => x.invoice.code,
  },
  {
    title: 'Claim Nominal',
    render: x => convertToRupiah(x.invoice ? x.invoice.final_price : x.nominal),
  },
  {
    title: 'Issue Date',
    hide: x => x.invoice,
    render: x => generateDateMonth(x.date_issued),
  },
  {
    title: 'Description',
    hide: x => x.invoice,
    render: x => x.description,
  },
  {
    title: 'Rejected Statement',
    hide: x => x.status !== 'rejected',
    render: x => x.claim_fields?.reason,
  },
  {
    title: 'Transaction Invoice',
    render: x =>
      x.invoice ? (
        <TransactionInvoiceExports invoiceId={x.invoice.id} />
      ) : (
        <TransactionInvoices files={x.attachments || []} />
      ),
  },
]

const TRANSFER_CONFIG = [
  {title: 'Bank Account', render: x => x.bank?.provider.name},
  {title: 'Account Number', render: x => x.bank?.account_number},
  {title: 'Account Name', render: x => x.bank?.account_name},
  {
    title: 'Transfer Evidence',
    render: x => <TransactionInvoices files={x.proofs} />,
  },
]

function ClaimDetailsModal(props) {
  const {
    open = false,
    claimId,
    currentTab,
    isCurrentApprover,
    onClose,
    refetchList,
  } = props

  const {data, loading, error} = useQuery(GET_CLAIM_DETAILS, {
    skip: !claimId,
    fetchPolicy: 'cache-and-network',
    variables: {
      claimId: claimId,
    },
    context: {
      headers: {
        'X-Hasura-Role': refetchList ? 'organization-staff' : 'user',
      },
    },
  })

  const prevOpen = usePrevious(open)
  const isNeverOpen = prevOpen === false && prevOpen === open

  if (isNeverOpen) {
    return null
  }

  const details = data?.details

  return (
    <ModalDetail
      open={open}
      onClose={onClose}
      title={
        details ? (
          <>
            <span
              className="mr-2 px-2 py-1 font-semibold text-sm rounded text-white"
              style={{backgroundColor: getStatusColor(details.status)}}
            >
              {capitalize(details.status)}
            </span>

            {details.policy
              ? details.policy.name
              : `${getClaimType(details)} Claim`}
          </>
        ) : (
          <>Claim Submission Details</>
        )
      }
    >
      {!claimId || loading ? (
        <LoadingComponent />
      ) : error || !data.details ? (
        <div className="p-6">
          Something went wrong when requesting claim data
        </div>
      ) : (
        <>
          <div className="p-6">
            <DetailsTable fields={SUBMISSION_CONFIG} data={details} />
          </div>

          <div className="pt-1 bg-wlbgrey" />

          <div className="p-6">
            <DetailsTable fields={REQUEST_CONFIG} data={details} />
          </div>

          <div className="pt-1 bg-wlbgrey" />

          <div className="p-6">
            <DetailsTable fields={TRANSFER_CONFIG} data={details} />
          </div>

          {details.status === 'waiting' &&
            (currentTab === 0 ? (
              <ClaimWaiting claimId={claimId} />
            ) : isCurrentApprover ? (
              <ClaimApproval
                claimId={claimId}
                refetchList={refetchList}
                onClose={onClose}
              />
            ) : null)}
        </>
      )}
    </ModalDetail>
  )
}

export default ClaimDetailsModal

const STATE_NORMAL = 0
// const STATE_ERROR = 1
// const STATE_CONFIRM = 2
const STATE_DISPATCHING = 3

function ClaimApproval(props) {
  const {claimId, refetchList, onClose} = props

  const [state, setState] = React.useState(STATE_NORMAL)
  const [approvalState, setApprovalState] = React.useState(null)
  const [rejectMessage, setRejectMessage] = React.useState(null)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const handleRejectClick = () => {
    setRejectMessage('')
  }

  const handleRejectSubmit = () => {
    setApprovalState({
      open: true,
      action: 'reject',
      title: 'Reject Claim Request?',
      content: 'Are you sure you want to reject this claim request?',
    })
  }

  const handleRejectCancel = () => {
    setRejectMessage(null)
  }

  const handleApproveSubmit = () => {
    setRejectMessage(null)
    setApprovalState({
      open: true,
      action: 'approve',
      title: 'Approve Claim Request?',
      content: 'Are you sure you want to approve this claim request?',
    })
  }

  const handleApprovalConfirm = () => {
    const action = approvalState.action
    const isReject = action === 'reject'

    const actionMsg = capitalize(action)

    setRejectMessage(null)

    let variables

    if (isReject) {
      variables = {
        requestId: claimId,
        fields: {
          reason: rejectMessage,
          finalizer: {
            user: USER_ID,
            position: POSITION_ID,
          },
        },
      }
    } else {
      variables = {
        requestId: claimId,
      }
    }

    const promise = client.mutate({
      mutation: isReject ? SET_CLAIM_REJECTED : APPROVE_CLAIM,
      variables: variables,
      context: {headers: {'X-Hasura-Role': 'organization-staff'}},
    })

    setState(STATE_DISPATCHING)

    promise.then(
      result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        onClose()
        refetchList()

        enqueueSnackbar(`${actionMsg} claim successful`, {variant: 'success'})
      },
      error => {
        console.error(error)
        enqueueSnackbar(`${actionMsg} claim failed`, {variant: 'error'})
      }
    )
  }

  const handleApprovalClose = () => {
    setApprovalState({...approvalState, open: false})
  }

  const handleRejectMessageChange = ev => {
    setRejectMessage(ev.target.value)
  }

  return (
    <>
      <div className="flex justify-center p-6">
        <Button
          disabled={state === STATE_DISPATCHING}
          onClick={handleRejectClick}
          variant="outlined"
          className="text-danger"
        >
          Reject
        </Button>
        <Button
          disabled={state === STATE_DISPATCHING}
          onClick={handleApproveSubmit}
          variant="contained"
          color="primary"
          className="ml-4"
        >
          Approve
        </Button>
      </div>

      {rejectMessage !== null && (
        <div className="p-6">
          <TextField
            disabled={state === STATE_DISPATCHING}
            value={rejectMessage}
            onChange={handleRejectMessageChange}
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            autoFocus
            placeholder="Add rejection reasoning here"
          />

          <div className="flex justify-center mt-2">
            <Button
              disabled={state === STATE_DISPATCHING}
              onClick={handleRejectCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={state === STATE_DISPATCHING}
              onClick={handleRejectSubmit}
              variant="contained"
              color="primary"
              className="ml-4"
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      <ConfirmationDialog
        {...approvalState}
        textPrimary="Confirm"
        textSecondary="Cancel"
        onClickPrimary={handleApprovalConfirm}
        onClose={handleApprovalClose}
      />
    </>
  )
}

function ClaimWaiting(props) {
  const {claimId} = props

  const [isOpen, setIsOpen] = React.useState(false)
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const handleCancelOpen = () => {
    setIsOpen(true)
  }

  const handleCancelConfirm = () => {
    setIsOpen(false)

    const promise = client.mutate({
      mutation: SET_CLAIM_STATUS,
      variables: {
        requestId: claimId,
        status: 'cancelled',
      },
    })

    promise.then(
      () => {
        enqueueSnackbar('Claim request cancelled', {variant: 'success'})
      },
      () => {
        enqueueSnackbar('Failed to cancel claim request', {variant: 'error'})
      }
    )
  }

  const handleCancelClose = () => {
    setIsOpen(false)
  }

  return (
    <div className="flex justify-center p-6">
      <Button
        onClick={handleCancelOpen}
        variant="outlined"
        className="text-danger"
      >
        Cancel
      </Button>

      <ConfirmationDialog
        open={isOpen}
        type="danger"
        title="Cancel Claim Request?"
        content="Are you sure you want to cancel this request?"
        textPrimary="Confirm"
        textSecondary="Cancel"
        onClickPrimary={handleCancelConfirm}
        onClose={handleCancelClose}
      />
    </div>
  )
}

function renderSubmissionStatus(x) {
  const status = x.status

  const el = <b style={{color: getStatusColor(status)}}>{capitalize(status)}</b>

  if (status === 'waiting') {
    const policy = x.policy
    const confirm_type = policy?.confirm_type

    if (confirm_type === 'supervisor') {
      const supervisor = retrieveDirectSupervisor(x.employee.profile)

      if (!supervisor) {
        return <>Your application will be approved by administrator</>
      }

      const user = supervisor.placements[0].user

      return (
        <>
          {el} for <b>{user.name}</b>, <b>{supervisor.title}</b> approval
        </>
      )
    } else if (confirm_type === 'position') {
      const approved_by = x.claim_fields.approved_by

      const approvals = [policy.first_job_profile, policy.second_job_profile]
      const current_approval = approvals[approved_by.length]

      return (
        <>
          {el} for <b>{current_approval.title}</b> approval
        </>
      )
    } else {
      return (
        <>
          {el} for <b>Finance Admin</b> approval
        </>
      )
    }
  } else if (status === 'approved' || status === 'rejected') {
    const finalizer = x.claim_fields.finalizer || {}
    const userId = finalizer.user
    const positionId = finalizer.position

    return (
      <>
        {el} by <StatusUser userId={userId} positionId={positionId} />
      </>
    )
  }

  return el
}

function retrieveDirectSupervisor(prof) {
  while (prof) {
    if (prof.placements?.length > 0) {
      return prof
    }

    prof = prof.supervisor
  }
}

function StatusUser(props) {
  const {userId, positionId} = props

  const {data, error} = useQuery(GET_CLAIM_USER_DETAILS, {
    skip: !userId && !positionId,
    variables: {
      userId,
      positionId,
    },
  })

  if (!data) {
    return <>- {error && '(failed to retrieve user)'}</>
  }

  return (
    <>
      <b>{data.user.name}</b>, <b>{data.position.title}</b>
    </>
  )
}

function TransactionInvoiceExports(props) {
  const {invoiceId} = props

  const {data, error, refetch} = useQuery(GET_CLAIM_INVOICE_EXPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceId: invoiceId,
    },
  })

  const [dispatchInvoiceRequest, {error: requestError}] = useMutation(
    REQUEST_CLAIM_INVOICE_EXPORTS,
    {
      variables: {
        invoiceId: invoiceId,
      },
    }
  )

  // We use `data?.invoice_exports?.length` as dependency than `data` here,
  // because it seems that the effect is getting rerun twice despite being the
  // "exact" same.
  React.useEffect(() => {
    if (data && data.invoice_exports.length < 1) {
      dispatchInvoiceRequest().then(() => refetch())
    }
  }, [data?.invoice_exports?.length])

  if (requestError) {
    return <div>{'' + requestError}</div>
  }

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <CircularProgress />
  }

  if (data.invoice_exports.length < 1) {
    return <div>Invoice is still being generated, please wait.</div>
  }

  const files = data.invoice_exports.map((invoice, idx) => ({
    name: `invoice${idx > 0 ? `-${idx + 1}` : ''}.pdf`,
    url: invoice.url,
  }))

  return <TransactionInvoices files={files} />
}

function TransactionInvoices(props) {
  const {files} = props

  const [showMore, setShowMore] = React.useState(false)
  const MAX_PREVIEW = 3

  const nodes = React.useMemo(() => {
    const arr = showMore ? files : files.slice(0, MAX_PREVIEW)

    return arr.map((item, idx) => (
      <AttachmentCard key={idx} name={item.name || ''} url={item.url} />
    ))
  }, [files, showMore])

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    <>
      <div>{nodes}</div>

      {files.length > 3 && (
        <a
          onClick={toggleShowMore}
          className="text-secondary cursor-pointer hover:underline"
        >
          {!showMore ? 'Show More' : 'Show Less'}
        </a>
      )}
    </>
  )
}
