import {useQuery} from '@apollo/react-hooks'
import {useState} from 'react'
import {GET_PEOPLE_PROFILE} from '../graphql/queries'
import {
  AVATAR,
  COMPANY_NAME,
  POSITION_NAME,
  USER_ID,
  USER_NAME,
} from '../utils/globals'

const useGetProfile = () => {
  const [data, setData] = useState({
    id: USER_ID,
    avatar: AVATAR,
    name: USER_NAME,
    position: POSITION_NAME,
    company: COMPANY_NAME,
    user_work_id: null,
  })

  useQuery(GET_PEOPLE_PROFILE, {
    context: {headers: {role: 'user'}},
    fetchPolicy: 'cache-and-network',
    variables: {
      id: USER_ID,
    },
    onCompleted: ({global_users}) => {
      setData(e => ({
        ...e,
        id: global_users[0] && global_users[0].id,
        avatar: global_users?.[0]?.avatar,
        name: global_users?.[0]?.name,
        position: global_users?.[0]?.working_status?.[0]?.company_job_profile?.title,
        company: global_users?.[0]?.working_status?.[0]?.company_profile?.brand_name,
        user_work_id: global_users?.[0]?.working_status?.[0]?.id,
      }))
    },
  })

  return data
}

export default useGetProfile
