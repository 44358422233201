import React from 'react'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import Divider from '../../../components/divider/DividerStyles'
import {ButtonStyled} from '../../../components/button/ButtonStyles'

export default function NotificationWLB({
  open,
  onClick,
  message,
  title,
  type = 'primary',
  textButton = 'Back',
}) {
  return (
    <StyledDialog open={open} onClose={onClick} fullWidth maxWidth="xs">
      <div className="flex flex-col">
        <div className="p-4">
          <span className={`text-${type} text-sm font-semibold `}>{title}</span>
        </div>
        <Divider />
        <div className="p-4 flex flex-col">
          <span className="text-xs mb-12">{message}</span>
          <ButtonStyled
            classes={{
              root: 'ml-auto px-6 text-xs',
            }}
            size="small"
            state={type}
            onClick={onClick}
          >
            {textButton}
          </ButtonStyled>
        </div>
      </div>
    </StyledDialog>
  )
}
