import React, {useState} from 'react'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import {
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {convertToRoundedRupiah} from '../../../utils/helpers'

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  tableHeader: {
    width: '50%',
    fontSize: '.75rem',
    fontWeight: '600',
    backgroundColor: 'rgb(239, 242, 244)',
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'rgb(224, 224, 224)',
  },
})

export default function TableFinance({from, data, setTakeHomePay}) {
  const classes = useStyles()

  const [showCompanyComponentTable, setShowCompanyComponentTable] = useState(
    false
  )
  const allowances = []
  const nonFixedComponents = []
  const companyComponentList = []
  const companyDeductionList = []

  // Earning Deduction calculation process
  allowances.push({
    name: from === 'thr' ? 'THR' : 'Basic Salary',
    value: from === 'thr' ? data.thr : data.salary,
  })

  if (data.allowances?.length > 0) {
    data.allowances.forEach(item => {
      if (!item.is_postponed) allowances.push(item)
    })
  }

  if (data.non_fixed_components?.length > 0) {
    data.non_fixed_components.forEach(item => {
      if (!item.is_postponed) nonFixedComponents.push(item)
    })
  }

  const totalEarning =
    allowances?.length > 0
      ? allowances
          ?.map(item => Math.round(item?.value))
          ?.reduce((prev, next) => prev + next) || 0
      : 0

  const totalDeduction =
    nonFixedComponents?.length > 0
      ? nonFixedComponents
          ?.map(item => Math.round(item?.value))
          ?.reduce((prev, next) => prev + next) || 0
      : 0

  const longestArr =
    allowances?.length > nonFixedComponents?.length
      ? allowances
      : nonFixedComponents

  // Company component calculation process
  if (data?.payroll_fields?.CompanyComponents) {
    companyComponentList.push(...data?.payroll_fields?.CompanyComponents)
  }

  if (data?.payroll_fields?.CompanyDeductions) {
    companyDeductionList.push(...data?.payroll_fields?.CompanyDeductions)
  }

  const companyComponentTotal =
    companyComponentList?.length > 0
      ? companyComponentList
          .map(item => Math.round(item?.value))
          .reduce((prev, next) => prev + next) || 0
      : 0

  const companyDeductionTotal =
    companyDeductionList?.length > 0
      ? companyDeductionList
          .map(item => Math.round(item?.value))
          .reduce((prev, next) => prev + next) || 0
      : 0

  const longestCompanyCemponentArr =
    companyComponentList?.length > companyDeductionList?.length
      ? companyComponentList
      : companyDeductionList

  React.useEffect(() => {
    if (totalEarning?.toString() && totalDeduction?.toString())
      setTakeHomePay(totalEarning - totalDeduction)
  }, [])

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} colSpan={2}>
                Earning
              </TableCell>

              <TableCell className={classes.tableHeader} colSpan={2}>
                Deduction
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data && longestArr
              ? // eslint-disable-next-line no-unused-vars
                longestArr.map((_, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="text-xs">
                        {allowances?.[index]?.name}
                      </TableCell>

                      <TableCell
                        className={`${classes.borderRight} text-xs`}
                        align="right"
                      >
                        {allowances?.[index]?.value &&
                          convertToRoundedRupiah(
                            allowances?.[index]?.value,
                            false
                          )}
                      </TableCell>

                      <TableCell className="text-xs">
                        {nonFixedComponents?.[index]?.name}
                      </TableCell>

                      <TableCell className="text-xs" align="right">
                        {nonFixedComponents?.[index]?.value &&
                          convertToRoundedRupiah(
                            nonFixedComponents?.[index]?.value,
                            false
                          )}
                      </TableCell>
                    </TableRow>
                  )
                })
              : null}

            <TableRow>
              <TableCell
                colSpan={2}
                align="right"
                className={`${classes.borderRight} font-bold text-xs`}
              >
                {totalEarning ? convertToRoundedRupiah(totalEarning) : '-'}
              </TableCell>

              <TableCell
                colSpan={2}
                align="right"
                className="font-bold text-xs"
              >
                {totalDeduction ? convertToRoundedRupiah(totalDeduction) : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ButtonContainer>
        <FormGroup
          className="hide-on-print"
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={showCompanyComponentTable}
                onChange={() =>
                  setShowCompanyComponentTable(!showCompanyComponentTable)
                }
                inputProps={{
                  'aria-label': 'controlled',
                }}
              />
            }
            label={<p className="text-xs">Show Company Components</p>}
          />
        </FormGroup>

        {showCompanyComponentTable && (
          <div className="show-on-print mt-10 mb-3" style={{display: 'none'}}>
            <span className="text-xs font-semibold">Company Components</span>
          </div>
        )}
      </ButtonContainer>

      {/* COMPANY COMPONENT TABLE */}
      {showCompanyComponentTable && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} colSpan={2}>
                  Added by Company
                </TableCell>

                <TableCell className={classes.tableHeader} colSpan={2}>
                  Deducted by Company
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data && longestCompanyCemponentArr
                ? // eslint-disable-next-line no-unused-vars
                  longestCompanyCemponentArr?.map((_, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="text-xs">
                          {companyComponentList &&
                            companyComponentList?.[index] &&
                            companyComponentList?.[index]?.name}
                        </TableCell>

                        <TableCell
                          className={`${classes.borderRight} text-xs`}
                          align="right"
                        >
                          {companyComponentList &&
                            companyComponentList?.[index] &&
                            convertToRoundedRupiah(
                              companyComponentList?.[index]?.value,
                              false
                            )}
                        </TableCell>

                        <TableCell className="text-xs">
                          {companyDeductionList &&
                            companyDeductionList?.[index] &&
                            companyDeductionList?.[index]?.name}
                        </TableCell>

                        <TableCell className="text-xs" align="right">
                          {companyDeductionList &&
                            companyDeductionList?.[index] &&
                            convertToRoundedRupiah(
                              companyDeductionList?.[index]?.value,
                              false
                            )}
                        </TableCell>
                      </TableRow>
                    )
                  })
                : null}

              <TableRow>
                <TableCell
                  colSpan={2}
                  align="right"
                  className={`${classes.borderRight} font-bold text-xs`}
                >
                  {companyComponentTotal
                    ? convertToRoundedRupiah(companyComponentTotal)
                    : '-'}
                </TableCell>

                <TableCell
                  colSpan={2}
                  align="right"
                  className="font-bold text-xs"
                >
                  {companyDeductionTotal
                    ? convertToRoundedRupiah(companyDeductionTotal)
                    : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export const ButtonContainer = styled.div`
  width: 100%;

  @media print {
    .hide-on-print,
    .hide-on-print * {
      display: none !important;
    }

    .show-on-print,
    .show-on-print * {
      display: block !important;
    }
  }
`
