import React from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import {
  getComponentBudget,
  getTotalBudget,
} from '../../trip/trip-shared-component/trip-helper'
import {getRemainingState, PAYMENT_METHOD} from '../trip-report-helper'
import {Typography} from '@material-ui/core'
import {ConversionDisplay} from '../../trip/TripStyles'

export default function TableDetailRemainingMoney({data}) {
  const destination = [...(data?.destination || [])]

  const totalActual = getTotalBudget(destination, 'TRIP')
  const totalRealized = getTotalBudget(destination, 'TRIP_REPORT')
  const totalRemaining = totalActual - totalRealized

  const remainingState = getRemainingState(totalRemaining)
  const remainingResult =
    PAYMENT_METHOD[remainingState]?.type?.[data?.budget_diff_action]

  return (
    <>
      <Typography className="font-semibold text-sm mb-4">
        Total Remaining Money
      </Typography>

      <TableContainer>
        <Table>
          <TableHead className="bg-wlbgrey">
            <TableRow>
              <TableCell className="font-semibold">Trip</TableCell>
              <TableCell className="font-semibold text-right">
                Total Budget
              </TableCell>
              <TableCell className="font-semibold text-right">
                Total Budget Used
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.destination.map(row => {
              const actual = getComponentBudget(row, 'TRIP')
              const realized = getComponentBudget(row, 'TRIP_REPORT')

              return (
                <TableRow key={row.id}>
                  <TableCell className="text-xs">
                    {row.globalDestinationByFrom.name} -{' '}
                    {row.globalDestinationByTo.name}
                  </TableCell>
                  <TableCell className="text-xs text-right">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={data.currency}
                      to={data.business_trip_policy.currency_from}
                      value={actual}
                      date={data.approved_date}
                    />
                  </TableCell>
                  <TableCell className="text-xs text-right">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={data.currency}
                      to={data.business_trip_policy.currency_from}
                      value={realized}
                      date={data.approved_date}
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell className="text-sm font-semibold">Total</TableCell>
              <TableCell className="text-sm text-right font-semibold">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={data.currency}
                  to={data.business_trip_policy.currency_from}
                  value={totalActual}
                  date={data.approved_date}
                  embolden
                />
              </TableCell>
              <TableCell className="text-sm text-right font-semibold">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={data.currency}
                  to={data.business_trip_policy.currency_from}
                  value={totalRealized}
                  date={data.approved_date}
                  embolden
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="text-sm font-semibold">
                <div>Total Remaining Budget</div>
                {remainingResult && (
                  <Typography color="textSecondary" className="text-xs">
                    Total Remaining Money will be{' '}
                    <Typography
                      display="inline"
                      className="text-xs"
                      color="secondary"
                    >
                      {remainingResult}
                    </Typography>
                  </Typography>
                )}
              </TableCell>
              <TableCell
                colSpan={2}
                className="text-sm text-right font-semibold"
              >
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={data.currency}
                  to={data.business_trip_policy.currency_from}
                  value={totalRemaining}
                  date={data.approved_date}
                  embolden
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
