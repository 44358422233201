import {useLazyQuery} from '@apollo/react-hooks'
import {useEffect, useState} from 'react'
import {GET_LIST_HOLIDAY, GET_TODAY_HOLIDAY} from '../graphql/queries'
import {COMPANY_ID} from '../utils/globals'
import {generateDateSubmit, generateFullTime, isBetween} from '../utils/helpers'

export default function useDayHoliday() {
  const [holiday, setHoliday] = useState(undefined)
  const [getListLeave, {data}] = useLazyQuery(GET_LIST_HOLIDAY, {
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
  const [getListCompanyHoliday, {data: companyHoliday}] = useLazyQuery(
    GET_TODAY_HOLIDAY,
    {
      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  const dateToday = generateDateSubmit(new Date())
  const getData = user_work_id => {
    getListLeave({
      variables: {
        user_work_id,
        date: dateToday,
      },
    })
    getListCompanyHoliday({
      variables: {
        company: COMPANY_ID,
        date: dateToday,
      },
    })
  }

  useEffect(() => {
    const status = getStatusCheckin({
      leave: data?.people_work_leaves,
      leave_half: data?.leave_half,
      holiday: companyHoliday?.time_holidays,
      trip: data?.trip,
    })

    setHoliday(status)
  }, [data, companyHoliday])

  return [getData, holiday]
}

const getStatusCheckin = ({
  leave = [],
  leave_half = [],
  holiday = [],
  trip = [],
}) => {
  const isBetweenTime = getBetween(leave_half)

  const isLeave = leave?.length > 0
  const isLeaveHalf = leave_half?.length > 0 && isBetweenTime
  const isHoliday = holiday?.length > 0
  const isOnTrip = trip?.length > 0

  if (isLeave) {
    return 'On Leave'
  }

  if (isLeaveHalf) {
    return 'On Leave'
  }

  if (isOnTrip) {
    return 'On Trip'
  }

  if (isHoliday) {
    return 'Holiday'
  }
}

const getBetween = leave_half => {
  const time = generateFullTime(new Date())
  const {startDate, endDate} = leave_half?.[0]?.leave_fields || {}
  return isBetween(time, startDate, endDate)
}
