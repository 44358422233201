import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import Chip from '@material-ui/core/Chip'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

export const FormControlLabelStyled = styled(FormControlLabel)``

export const FormControlStyled = styled(FormControl)``

export const FormLabelStyled = styled(FormLabel)``

export const TextFieldStyled = styled(TextField)`
  & > div {
    background-color: ${props => props.backgroundcolor};
  }
  .Mui-disabled {
    background-color: #fafafa;
  }
`
export const TextFieldStyledNoLine = styled(TextField)`
  & > div {
    &:before {
      content: none;
    }
  }
`
export const TextFieldStyledNoLine2 = styled(TextField)`
  & > div {
    &:before {
      content: none;
    }
  }

  div {
    input {
      display: none;
    }
  }
`

export const InputAdornmentStyled = styled(InputAdornment)``

export const RadioStyled = styled(Radio)``

export const RadioGroupStyled = styled(RadioGroup)``

export const InputLabelStyled = styled(InputLabel)``

export const MenuItemStyled = styled(MenuItem)``

export const MenuStyled = styled(Menu)``

export const FormHelperTextStyled = styled(FormHelperText)``

export const SelectStyled = styled(Select)``

export const SwitchStyled = styled(Switch)``

export const ChipStyled = styled(Chip)`
  border-radius: 5px !important;
`

export const MuiPickersUtilsProviderStyled = styled(MuiPickersUtilsProvider)``

export const KeyboardTimePickerStyled = styled(KeyboardTimePicker)`
  & > div {
    padding-right: ${props => props.space};
  }
`

export const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  & > div {
    padding-right: ${props => props.space};
  }
  .Mui-disabled {
    background-color: #fafafa;
  }
  * {
    outline: none !important;
  }
`

export const StartAdornment = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.27);
  padding-right: 13px;
  display: flex;
  align-items: center;
  height: 37px;
`
