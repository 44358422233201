import Financial from './FinancialComponent'

export const FinancialConfig = {
  routes: [
    {
      path: '/bank-and-tax',
      component: Financial,
      state: 'financial',
      exact: true,
    },
  ],
}
