import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {Button} from '@material-ui/core'

import {Filter} from '@smartasn/wlb-utils-components'

import {
  MenuItemStyled,
  TextFieldStyledNoLine,
} from '../../../components/form/FormStyles'
import {TablePaginationStyled} from '../../../components/pagination/PaginationStyles'

import TextfieldSearch from '../../shared-component/form/TextfieldSearch'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import EmptyState from '../../shared-component/empty-state/EmptyStateComponent'

import ClaimItem from './ClaimItem'
import ClaimDetailsModal from '../ClaimDetailsModal'

import NoListImage from '../../../assets/images/nodatalist.png'

import {
  ACTIVITY_FILTER_CONFIG,
  getActivityFilterVariables,
} from '../activityFilterConfig'

import {USER_ID} from '../../../utils/globals'
import {PAGINATION_RANGE} from '../../../utils/constants'
import {GET_CLAIM_ACTIVITY_LIST} from '../../../graphql/queries'

const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

const INITIAL_PAGINATION = {
  page: 0,
  limit: 10,
}

function ClaimActivityList() {
  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)
  const [search, setSearch] = React.useState('')
  const [filter, setFilter] = React.useState(null)
  const [sort, setSort] = React.useState(SORT_DESC)

  const [detailsState, setDetailsState] = React.useState(null)

  const filterVariables = React.useMemo(
    () => getActivityFilterVariables(filter),
    [filter]
  )

  const {data, loading, error} = useQuery(GET_CLAIM_ACTIVITY_LIST, {
    variables: {
      ...filterVariables,
      search: `%${search}%`,
      userId: USER_ID,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
      orderBy: sort,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSortChange = ev => {
    setSort(ev.target.value)
  }

  const handleSearchChange = ev => {
    setSearch(ev.target.value)
  }

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (ev, page) => {
    setPagination({...pagination, page})
  }

  const handleLimitChange = ev => {
    setPagination({page: 0, limit: ev.target.value})
  }

  // eslint-disable-next-line no-unused-vars
  const handleItemClick = (ev, data) => {
    setDetailsState({open: true, claimId: data.id})
  }

  const handleDetailsClose = () => {
    setDetailsState({...detailsState, open: false})
  }

  const handleApplyFilter = next => {
    setPagination({...pagination, page: 0})
    setFilter(next)
  }

  return (
    <div className="grid">
      <div className="flex items-center justify-between px-6 pt-4">
        <TextFieldStyledNoLine
          select
          value={sort}
          onChange={handleSortChange}
          size="small"
        >
          <MenuItemStyled value={SORT_DESC}>Latest</MenuItemStyled>
          <MenuItemStyled value={SORT_ASC}>Oldest</MenuItemStyled>
        </TextFieldStyledNoLine>

        <Filter
          onApply={handleApplyFilter}
          listFilter={ACTIVITY_FILTER_CONFIG}
          includeExclude={false}
        >
          <Button>Filter</Button>
        </Filter>
      </div>

      <TextfieldSearch value={search} onChange={handleSearchChange} />

      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : !data.total.aggregate.count ? (
        <EmptyState
          image={NoListImage}
          message1="Sorry, No List"
          message2="No claims found"
        />
      ) : (
        data.claims.map(item => (
          <ClaimItem key={item.id} data={item} onClick={handleItemClick} />
        ))
      )}

      <TablePaginationStyled
        rowsPerPageOptions={PAGINATION_RANGE}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        count={data?.total.aggregate.count || 0}
        page={pagination.page}
        rowsPerPage={pagination.limit}
      />

      <ClaimDetailsModal
        {...detailsState}
        currentTab={0}
        onClose={handleDetailsClose}
      />
    </div>
  )
}

export default ClaimActivityList
