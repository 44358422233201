export function getStatusColor(status) {
  switch (status) {
    case 'approved':
      return '#4caf50'
    case 'processing':
      return '#a35a31'
    case 'waiting':
      return '#ffa000'
    case 'cancelled':
      return '#a9a8a8'
    case 'rejected':
      return '#ef4d5e'
  }
}

export function getClaimType(data) {
  if (!data.invoice) {
    return 'Financial'
  }

  switch (data.invoice.module_name) {
    case 'marketplace':
      return 'Vendor'
    case 'academy':
    case 'book':
    case 'classroom':
    case 'microlearning':
      return 'Learning'
    default:
      return 'Unknown'
  }
}
