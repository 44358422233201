import styled from 'styled-components'

export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.font};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 4px;
`
