import React from 'react'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import {Button} from '@material-ui/core'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import Divider from '../../../components/divider/DividerStyles'

export default function ConfirmationDialog({
  title,
  content,
  onClickPrimary,
  textPrimary,
  textSecondary,
  open = false,
  onClose,
  type = 'primary',
  disable,
  loading,
}) {
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <div className="flex flex-col">
        <div className="p-6">
          <span className={`text-${type} text-sm font-semibold`}>{title}</span>
        </div>
        <Divider />
        <div className="p-6 flex flex-col">
          <span className="text-xs">{content}</span>
          <div className="mt-8 ml-auto">
            <Button
              className="text-xs text-disable font-semibold mr-2"
              onClick={onClose}
              style={{padding: '9px 18px', outline: 'none'}}
            >
              {textSecondary}
            </Button>
            <ButtonStyled
              classes={{
                root: 'ml-auto text-xs font-semibold',
              }}
              style={{padding: '9px 18px', outline: 'none'}}
              disabled={disable}
              size="small"
              state={type}
              onClick={onClickPrimary}
            >
              {loading ? 'loading...' : textPrimary}
            </ButtonStyled>
          </div>
        </div>
      </div>
    </StyledDialog>
  )
}
