import React from 'react'
import {Paper} from '../../components/paper/PaperStyles'
import {IconStyled} from '../../components/Icons/IconComponent'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import Divider from '../../components/divider/DividerStyles'
import SalaryList from './SalaryList'

const Salary = () => {
  return (
    <div className="flex flex-col m-0 rounded">
      <Paper>
        <div className="py-8 px-8 flex flex-row justify-between">
          <div className="flex flex-row">
            <div className="self-center flex flex-center ">
              <IconStyled text="#014a62" className="mr-3 align-middle">
                attach_money
              </IconStyled>
              <TypographyStyled
                component="span"
                className="text-primary font-semibold text-lg"
                text="#014a62"
              >
                Salary
              </TypographyStyled>
            </div>
          </div>
        </div>
        <Divider />
        <SalaryList />
      </Paper>
    </div>
  )
}

export default Salary
