import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import {ConversionDisplay} from '../TripStyles'

import {getComponentBudget} from './trip-helper'

export default function TableDetailTotalTrip({
  data,
  currencyTo,
  currencyFrom,
  approvedDate,
  fullScreen = false,
}) {
  let totalBudgetSpent = 0

  const cellLeftStyle = {paddingLeft: fullScreen ? 16 : 100}
  const cellRightStyle = {paddingRight: fullScreen ? 16 : 100}

  return (
    <>
      <Typography className="text-xs mb-4" color="textSecondary">
        Total Budget
      </Typography>

      <TableContainer>
        <Table>
          <TableHead className="bg-wlbgrey">
            <TableRow>
              <TableCell style={cellLeftStyle} className="font-semibold">
                Trip
              </TableCell>
              <TableCell
                style={cellRightStyle}
                className="font-semibold text-right"
              >
                Total Budget
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.destination.map(row => {
              const budget = getComponentBudget(row)
              totalBudgetSpent += budget

              return (
                <TableRow key={row.id}>
                  <TableCell style={cellLeftStyle} className="text-xs">
                    {row.globalDestinationByFrom.name} -{' '}
                    {row.globalDestinationByTo.name}
                  </TableCell>
                  <TableCell
                    style={cellRightStyle}
                    className="text-xs text-right"
                  >
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={budget}
                      date={approvedDate}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                style={cellLeftStyle}
                className="text-black text-sm font-bold"
              >
                Total Budget
              </TableCell>

              <TableCell style={cellRightStyle} className="text-black">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalBudgetSpent}
                  date={approvedDate}
                  embolden
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}
