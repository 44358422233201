import React from 'react'
import {withRouter, Redirect, Route, Switch, Link} from 'react-router-dom'

import {Paper} from '../../components/paper/PaperStyles'
import {IconStyled} from '../../components/Icons/IconComponent'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import {ButtonStyled} from '../../components/button/ButtonStyles'
import {TabsStyled, TabStyled} from '../../components/tabs/TabsComponents'

import ClaimActivityList from './list/ClaimActivity'
import ClaimRequestList from './list/ClaimRequest'

function Claim(props) {
  const {location, history} = props

  // eslint-disable-next-line no-unused-vars
  const handleChangeTabs = (event, next) => {
    history.push(next)
  }

  return (
    <Paper>
      <div className="flex items-center px-8 py-6 text-xl font-bold">
        <IconStyled text="#014a62" className="mr-3">
          attach_money
        </IconStyled>
        <TypographyStyled
          component="span"
          className="flex-grow"
          size="16px"
          weight="600"
          text="#014a62"
        >
          Claim
        </TypographyStyled>
        <ButtonStyled
          component={Link}
          to="/claim/apply"
          className="ml-3 text-sm"
          size="large"
          state="primary"
        >
          Apply for Claim
        </ButtonStyled>
      </div>

      <TabsStyled value={location.pathname} onChange={handleChangeTabs}>
        <TabStyled value="/claim/activity" label="Activity" />
        <TabStyled value="/claim/request" label="Request" />
      </TabsStyled>

      <Switch>
        <Redirect exact from="/claim" to="/claim/activity" />
        <Route
          exact
          path="/claim/activity"
          render={() => <ClaimActivityList />}
        />
        <Route
          exact
          path="/claim/request"
          render={() => <ClaimRequestList />}
        />
      </Switch>
    </Paper>
  )
}

export default withRouter(Claim)
