import React, {useState} from 'react'
import {DateRangePicker} from 'r-date-picker'
import 'r-date-picker/src/styles/index.scss'
import './styleddate.scss'
import {Button} from '@material-ui/core'
import {generateDateSubmit} from '../../../utils/helpers'
import moment from 'moment'

export default function DateWLB({
  setTitleDate,
  titleDate,
  onSubmit,
  onClose,
  setReset,
}) {
  const [dateSelected, setDateSelected] = useState(
    titleDate.start ? [moment(titleDate.start), moment(titleDate.end)] : null
  )

  const removeClass = () => {
    const el = document.getElementsByClassName('rdp__days-item')
    if (el) {
      for (let index = 0; index <= el.length; index++) {
        const element = el[index]
        if (element) {
          if (
            element.classList.contains('rdp__days-item-active--range-start')
          ) {
            element.classList.remove('rdp__days-item-active--range-start')
          }
          if (element.classList.contains('rdp__days-item-active--range-end')) {
            element.classList.remove('rdp__days-item-active--range-end')
          }
          if (
            element.classList.contains('rdp__days-item-active--range-connect')
          ) {
            element.classList.remove('rdp__days-item-active--range-connect')
          }
          if (
            element.classList.contains(
              'rdp__days-item rdp__days-item-active--range-end'
            )
          ) {
            element.classList.remove(
              'rdp__days-item rdp__days-item-active--range-end'
            )
          }
          if (
            element.classList.contains(
              'rdp__days-item rdp__days-item-active--range-start'
            )
          ) {
            element.classList.remove(
              'rdp__days-item rdp__days-item-active--range-start'
            )
          }
        }
      }
    }
  }

  const resetClass = () => {
    removeClass()
    const el = document.getElementsByClassName('rdp__days-item')
    if (el) {
      for (let index = 0; index <= el.length; index++) {
        const element = el[index]
        if (element) {
          if (element.classList.contains('rdb__days-item-active--start')) {
            element.classList.remove('rdb__days-item-active--start')
          }
          if (element.classList.contains('rdb__days-item-active--end')) {
            element.classList.remove('rdb__days-item-active--end')
          }
          if (element.classList.contains('rdb__days-item-active--connect')) {
            element.classList.remove('rdb__days-item-active--connect')
          }
          if (element.classList.contains('rdp__days-item-active--end')) {
            element.classList.remove('rdp__days-item-active--end')
          }
          if (element.classList.contains('rdp__days-item-active--start')) {
            element.classList.remove('rdp__days-item-active--start')
          }
        }
      }
    }
  }

  const handleClickDate = e => {
    setDateSelected(null)
    // removeClass()
    const [start, end] = e
    setTitleDate(e => ({
      ...e,
      _start: generateDateSubmit(start),
      _end: generateDateSubmit(end),
    }))
    setDateSelected(e)
    setReset(false)
  }

  const handleResetDate = () => {
    setDateSelected(null)
    resetClass()
    setReset(true)
    setTitleDate(e => ({
      ...e,
      _start: e.start,
      _end: e.end,
    }))
  }

  const handleClose = () => {
    setDateSelected([moment(titleDate.start), moment(titleDate.end)])
    setReset(false)
    onClose()
  }

  const handleDateClick = () => {
    removeClass()
  }

  return (
    <section className="px-8 pt-2 pb-10 flex flex-col">
      <Button
        onClick={handleResetDate}
        className="ml-auto mt-2 mb-2 font-semibold text-secondary text-xs"
      >
        Reset
      </Button>
      <div>
        <DateRangePicker
          onDateRangeChange={e => handleClickDate(e)}
          language="en"
          ranges={[dateSelected]}
          onDateClick={handleDateClick}
        />
      </div>
      <div className="flex justify-end flex-row mt-8">
        <Button
          className="text-xs font-semibold py-2 px-6"
          onClick={handleClose}
          style={{color: '#a9a8a8 !important'}}
        >
          Cancel
        </Button>
        <Button
          className="text-xs ml-4 font-semibold py-2 px-6"
          disabled={!dateSelected}
          onClick={onSubmit}
          color="primary"
          disableElevation
          variant="contained"
        >
          Submit
        </Button>
      </div>
    </section>
  )
}
