import gql from "graphql-tag";

export const GET_LIST_TRIP_GROUP_ACTIVITY = gql`
  query getListGroupActivity(
    $userId: uuid
    $participant: [uuid!]
    $search: String
    $status: [String!]
    $filter_date_start: timestamp
    $filter_date_end: timestamp
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        deletedAt: { _is_null: true }
        added_by: { _eq: $userId }
        user_id: { _in: $participant, _neq: $userId }
        status: { _in: $status }
        date_added: { _gte: $filter_date_start, _lte: $filter_date_end }
        _or: [
          { id_display: { _ilike: $search } }
          { business_trip_policy: { name: { _ilike: $search } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_business_trips(
      where: {
        deletedAt: { _is_null: true }
        added_by: { _eq: $userId }
        user_id: { _in: $participant, _neq: $userId }
        status: { _in: $status }
        date_added: { _gte: $filter_date_start, _lte: $filter_date_end }
        _or: [
          { id_display: { _ilike: $search } }
          { business_trip_policy: { name: { _ilike: $search } } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{ id: $sorting }]
    ) {
      id
      id_display
      date_start
      date_end
      status
      date_added
      business_trip_policy {
        id
        name
      }
      global_user {
        id
        name
      }
    }
  }
`;

export const GET_LIST_PARTICIPANT_GROUP_ACTIVITY = gql`
  query getParticpant(
    $userId: uuid
    $limit: Int
    $nin: [uuid!]
    $search: String
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        user_id: { _neq: $userId }
        added_by: { _eq: $userId }
        global_user: {
          _and: [
            { name: { _ilike: $search } }
            { id: { _nin: $nin } }
          ]
        }
      }
      distinct_on: user_id
    ) {
      aggregate {
        count
      }
    }

    people_work_business_trips(
      where: {
        user_id: { _neq: $userId }
        added_by: { _eq: $userId }
        global_user: {
          _and: [
            { name: { _ilike: $search } }
            { id: { _nin: $nin } }
          ]
        }
      }
      distinct_on: user_id
      limit: $limit
    ) {
      id
      global_user {
        id
        name
      }
    }
  }
`;
