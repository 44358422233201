import gql from 'graphql-tag'

export const CANCEL_APPLY_OVERTIME = gql`
  mutation($id: bigint!) {
    update_people_work_overtimes(
      _set: {status: "cancelled"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const APPROVE_APPLY_OVERTIME = gql`
  mutation($id: bigint!, $status: String!, $overtime_fields: jsonb) {
    update_people_work_overtimes(
      _set: {status: $status, overtime_fields: $overtime_fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const REJECT_APPLY_OVERTIME = gql`
  mutation update_people_work_overtimes(
    $id: bigint!
    $changes: people_work_overtimes_set_input
  ) {
    update_people_work_overtimes(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
