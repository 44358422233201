import React from 'react'
import GoogleMapReact from 'google-map-react'
import CloseIcon from '@material-ui/icons/Close'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import LocationOnIcon from '@material-ui/icons/LocationOn'

const Location = () => {
  return (
    <div className="wrapper" style={{position: 'relative'}}>
      <div
        className="location"
        style={{
          padding: 20,
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 160, 0, 0.2)',
          border: '1px solid #ffa000',
          position: 'absolute',
          top: '-42px',
          left: '-33px',
        }}
      >
        <LocationOnIcon
          style={{
            color: '#ef4d5e',
          }}
        />
      </div>
    </div>
  )
}

export default function ModalDetailMap({open, onClose, coord}) {
  return (
    <StyledDialog
      classes={{paper: 'w-693-px'}}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="body"
    >
      <div className="flex flex-col ">
        <div className="flex flex-row justify-between p-6 bg-wlbgrey items-center">
          <div className="flex flex-row items-center">
            <h2 className="font-semibold ">Location</h2>
          </div>
          <CloseIcon className="cursor-pointer" onClick={onClose} />
        </div>

        <div style={{height: 'auto', width: '100%'}}>
          {coord && (
            <GoogleMapReact
              style={{width: '100%', height: '50vh', position: 'relative'}}
              bootstrapURLKeys={{
                key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
              }}
              zoom={16}
              center={{
                lat: coord.lat,
                lng: coord.lng,
              }}
            >
              <Location lat={coord.lat} lng={coord.lng} />
            </GoogleMapReact>
          )}
        </div>
      </div>
    </StyledDialog>
  )
}
