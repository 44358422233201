import {GetModularSettings, GlobalSidebar} from '@smartasn/wlb-utils-components'
import React, {useEffect} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {
  AppWrapper,
  LeftSideWrapper,
  MainWrapper,
  RightSideWrapper,
  VacancyListsMainArea,
} from './components/header-menu/HeaderMenuTimeStyles'
import {Header} from './components/header/HeaderComponent'
import Main from './components/Main'
import {AnnualHoliday} from './components/right-side-menu/AnnualHoliday'
import UserLoggedIn from './components/user-logged-in/UserLoggedInComponent'
// import AccessToAnotherModule from './components/access-to-another-module/AccessToAnotherModuleComponent'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from './components/utils-components/TimeStyles'
// import Interest from './components/right-side-menu/Interest'
// import Learning from './components/right-side-menu/Learning'
// import Projects from './components/right-side-menu/Projects'
// import QuickAccess from './components/right-side-menu/QuickAccess'
// import FinanceDetailPrint from './pages/shared-component/print/FinanceDetailPrint'
import UnderDevelopment from './pages/error/UnderDevelopment'
import {COMPANY_ID} from './utils/globals'
import {hasModule, listenCookieChange, renderTabs} from './utils/helpers'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
  },
}

const AppContainer = props => {
  let regexUrl = ''
  if (props.location.pathname) {
    regexUrl = props.location.pathname.match('[a-z0-9-]+')
  }
  const activeTab = regexUrl ? `/${regexUrl[0]}` : '/'
  const handleChange = (event, newValue) => {
    event.preventDefault()
    props.history.push({
      pathname: newValue,
    })
  }

  const {data, loading} = GetModularSettings(COMPANY_ID)

  const tipeTabs = () => {
    const {pathname} = props.location
    const page = renderTabs(pathname)
    if (pathname === '/') {
      return (
        <StyledTabs value="/" style={customStyle.tabs} centered>
          <StyledTab label="HC Admin" value="/" />
        </StyledTabs>
      )
    } else if (page === 'time') {
      document.title = 'SmartASN - Kehadiran'
      return (
        hasModule(data, 'time') && (
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            style={customStyle.tabs}
            centered
          >
            <StyledTab label="Attendance" value="/attendance" />
            <StyledTab label="Leave and Permission" value="/leave" />
            <StyledTab label="Overtime" value="/overtime" />
          </StyledTabs>
        )
      )
    } else if (page === 'financial') {
      document.title = 'SmartASN - Keuangan'
      return (
        hasModule(data, 'financial') && (
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            style={customStyle.tabs}
            centered
          >
            <StyledTab label="Claim" value="/claim" />
            <StyledTab label="Salary" value="/salary" />
            <StyledTab label="THR" value="/thr" />
            <StyledTab label="Bank & Tax" value="/bank-and-tax" />
          </StyledTabs>
        )
      )
    } else if (page === 'document') {
      document.title = 'SmartASN - Dokumen Digital'
      return (
        <StyledTabs
          value={activeTab}
          onChange={handleChange}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Digital Document" value="/digital-document" />
        </StyledTabs>
      )
    } else if (page === 'business-trip') {
      document.title = 'SmartASN - Perjalanan Bisnis'
      return (
        <StyledTabs
          value={activeTab}
          onChange={handleChange}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Trip" value="/trip" />
          <StyledTab label="Trip Report" value="/trip-report" />
        </StyledTabs>
      )
    }
  }

  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <AppWrapper>
      {/* 
            // Route untuk print pdf user finance thr dan salary
            {props.location.pathname === '/print-user-finance' ? (
              <Switch>
                <Route
                  exact
                  path="/print-user-finance"
                  component={FinanceDetailPrint}
                />{' '}
              </Switch>
            ) : (
            ) : null} 
            */}

      {props.location.pathname === '/page-under-development' ? (
        <Switch>
          <Route
            exact
            path="/page-under-development"
            render={() => <UnderDevelopment />}
          />
        </Switch>
      ) : (
        <>
          <Header />
          <TabsWrapper>{tipeTabs()}</TabsWrapper>
          <MainWrapper>
            <LeftSideWrapper>
              <React.Fragment>
                <UserLoggedIn />
                <GlobalSidebar
                  language="indonesian"
                  env={{
                    SOSMED_URL: process.env.REACT_APP_HC_SOSMED,
                    USER_URL: process.env.REACT_APP_HC_TIME,
                    PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
                    LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
                    TALENT_URL: process.env.REACT_APP_HC_TALENT,
                    VENDOR_URL: process.env.REACT_APP_HC_VENDOR,
                  }}
                />
              </React.Fragment>
            </LeftSideWrapper>
            <VacancyListsMainArea>
              <Main loading={loading} modular={data} />
            </VacancyListsMainArea>
            <RightSideWrapper>
              <AnnualHoliday />
              {/* <Interest />
                    <Learning />
                    <Projects />
                    <QuickAccess /> */}
            </RightSideWrapper>
          </MainWrapper>
        </>
      )}
    </AppWrapper>
  )
}

export default withRouter(AppContainer)
