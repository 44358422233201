import gql from 'graphql-tag'

export const GET_WORK_BALANCE_LEAVE = gql`
  query($user: uuid!, $company: uuid) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
      global_user {
        id
        employment_date: people_work_placements_aggregate(
          where: {company: {_eq: $company}}
        ) {
          aggregate {
            min {
              start_date
              join_date
            }
          }
        }
      }
    }
  }
`
export const GET_LIST_BALANCE = gql`
  query($user_id: uuid, $company_id: uuid, $period_year: [Int!]) {
    total: time_leave_policy_children_aggregate(
      where: {
        company_job_profile_r_leaves: {
          company_job_profile: {
            people_work_placements: {
              user: {_eq: $user_id}
              company: {_eq: $company_id}
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    time_leave_policy_children(
      where: {
        company_job_profile_r_leaves: {
          company_job_profile: {
            people_work_placements: {
              user: {_eq: $user_id}
              company: {_eq: $company_id}
            }
          }
        }
      }
      order_by: {name: asc}
    ) {
      id
      name
      infinite_quota
      quota
      deletedAt
      time_leave_policy {
        id
        name
        deletedAt
        leftover_valid
        leftover_valid_until_month
        calc_method
        quota_prorate_option
        quota_reset_option
        quota_reset_option_date
        quota_reset_option_month
        quota_effective_after_month
      }
      people_work_leave_quotas(
        where: {
          period_year: {_in: $period_year}
          people_work_placement: {user: {_eq: $user_id}}
        }
        limit: 1
        order_by: {id: desc}
      ) {
        id
        quota
        period_year
        carry_over
        taken_current
        taken_carry_over
      }
    }
  }
`

export const GET_JOIN_DATE = gql`
  query getJoinDate($user: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}}
      limit: 1
      order_by: {id: desc}
    ) {
      id
      join_date
    }
  }
`
