import React from 'react'
import {numberToMonth} from '../../../utils/helpers'
import Divider from '../../../components/divider/DividerStyles'

export default function ListItem({
  month,
  year,
  from,
  // schedule,
  onClick = null,
}) {
  return (
    <div onClick={onClick} style={{padding: '0px 30px 0px 30px'}}>
      <div className="py-5 flex flex-row justify-between items-start cursor-pointer">
        <div className="flex flex-col">
          <h4 className="text-primary text-sm ">
            {`${from === 'thr' ? 'Hari Raya' : ''} ${numberToMonth(
              month
            )} ${year}`}
          </h4>
          {/* /** Religion holiday date is temporary hide */}
          {/* <p className="text-disable text-md pt-3 pb-2 text-xs md:text-xs">
            {schedule || '-'}
          </p> */}
        </div>
      </div>
      <Divider />
    </div>
  )
}
