import React, {useState} from 'react'

import {AttachmentItem, UploadButton} from '@smartasn/wlb-utils-components'
import {FormHelperTextStyled} from '../../../components/form/FormStyles'
import {checkFilesType} from '../../overtime/helperForOvertime'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import ContentTooltipFile from '../../shared-component/tooltip/ContentTooltipFile'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {getPrevAttachment, updateBudgetComponent} from '../trip-report-helper'
import {getFilesType} from '../../../utils/helpers'

export default function UploadAttachmentBudget({
  setValues,
  error,
  data,
  selectedDestination,
  setErrorFile,
  setErrorSize,
  errorFile,
  errorSize,
  isEditBudget,
}) {
  const [fileType, setFileType] = useState('')

  const uploadFile = (tempFile, budgetData) => {
    tempFile.forEach(val => {
      const formData = new FormData()
      formData.append('file', val)
      const url = URL.createObjectURL(val)
      const type = getFilesType(val.name)
      const _initialUpload = {
        id: val.name,
        name: val.name,
        size: val.size,
        loaded: 0,
        isUploaded: true,
        data: val,
        type,
        url,
      }
      setValues(prev => {
        const prevAttachments = getPrevAttachment({
          prevState: prev,
          selectedDestination,
          budgetData,
        })

        const destination = updateBudgetComponent({
          prev,
          selectedDestination,
          value: [...(prevAttachments || []), _initialUpload],
          component: budgetData,
          target: 'attachment',
        })
        return {...prev, destination}
      })
    })
  }

  const handleChange = (data, budgetData) => {
    setErrorFile(false)
    setErrorSize(false)
    const tempFile = Array.from(data)
    const [isErrorType, isMaxsize] = checkFilesType(tempFile, setFileType)
    if (isErrorType) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else if (isMaxsize) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
    } else {
      uploadFile(tempFile, budgetData)
    }
  }

  const handleChangeNameFile = ({e, i, budgetData, selectedDestination}) => {
    const newName = e?.target?.value

    setValues(prev => {
      const prevAttachments = getPrevAttachment({
        prevState: prev,
        selectedDestination,
        budgetData,
      })

      const newState = [...prevAttachments]
      newState[i].name = newName

      const destination = updateBudgetComponent({
        prev,
        selectedDestination,
        value: newState,
        component: budgetData,
        target: 'attachment',
      })
      return {...prev, destination}
    })
  }

  const handleDeleteAttachement = ({i, budgetData, selectedDestination}) => {
    setValues(prev => {
      const prevAttachments = getPrevAttachment({
        prevState: prev,
        selectedDestination,
        budgetData,
      })

      const newState = [...prevAttachments]
      if (i > -1) {
        newState.splice(i, 1)
      }

      const destination = updateBudgetComponent({
        prev,
        selectedDestination,
        value: newState,
        component: budgetData,
        target: 'attachment',
      })
      return {...prev, destination}
    })
  }

  return (
    <>
      <TypographyStyled className="mb-2 font-semibold" size="12px">
        Attachment*
        <InfoFileTooltip
          className="ml-2"
          title={<ContentTooltipFile />}
          placement="right"
        >
          <InfoOutlinedIcon className="text-xs text-primary" />
        </InfoFileTooltip>
      </TypographyStyled>
      <UploadButton
        multiple
        onChange={ev => handleChange(ev.target.files, data)}
      />

      <div
        className={
          'grid gap-3 mb-4 mt-3' +
          (data.attachment?.length > 0 ? '' : ' hidden')
        }
        style={isEditBudget ? {maxWidth: 450} : null}
      >
        {data?.attachment?.map((file, i) => {
          return (
            <AttachmentItem
              key={i}
              name={file.name}
              size={file.size}
              url={file.url}
              type={file.type}
              isUploading={!file.isUploaded}
              progress={file.loaded}
              onNameChange={e =>
                handleChangeNameFile({
                  e,
                  i,
                  budgetData: data,
                  selectedDestination,
                })
              }
              onRemove={() =>
                handleDeleteAttachement({
                  i,
                  budgetData: data,
                  selectedDestination,
                })
              }
            />
          )
        })}
      </div>

      <div className="mb-4">
        {errorFile && (
          <FormHelperTextStyled fileType={fileType} className="text-error mx-3">
            There are file types that are not allowed
          </FormHelperTextStyled>
        )}
        {errorSize && (
          <FormHelperTextStyled className="text-error mx-3">
            Uploaded file size cannot exceed 25MB
          </FormHelperTextStyled>
        )}
        {error && !data?.attachment?.[0] && (
          <FormHelperTextStyled className="text-error mx-3">
            This field is required
          </FormHelperTextStyled>
        )}
      </div>
    </>
  )
}
