import React from 'react'

import FormWrapper from '../../shared-component/form/FormWrapper'

import {getTripDate} from '../../trip/trip-shared-component/trip-helper'
import {
  ExchangeRateDisplay,
  TableInfo,
} from '../../trip/trip-shared-component/TableDetailTrip'
import FormEditBudgetWrapper from '../trip-report-shared-component/FormEditBudgetWrapper'

import Budget from './Budget'

export default function FormBudgetUsage(props) {
  const {smallSize, values, isEditBudget = false} = props

  const Container = isEditBudget ? FormEditBudgetWrapper : FormWrapper

  return (
    <Container
      number={!smallSize && 5}
      title={isEditBudget ? 'Budget Usage' : 'Budget Usage*'}
    >
      {values?.destination?.map((destination, idx) => (
        <Destination
          key={destination.id}
          destination={destination}
          isEditBudget={isEditBudget}
          destinationOrder={idx + 1}
          {...props}
        />
      ))}
    </Container>
  )
}

const Destination = props => {
  const {values, destination, isEditBudget, destinationOrder} = props
  const {globalDestinationByFrom, globalDestinationByTo} = destination

  const tripDestination = `${globalDestinationByFrom.name} - ${globalDestinationByTo.name}`
  const tripDate = getTripDate(
    destination?.destination_start_date,
    destination?.destination_end_date
  )

  return (
    <div className="mt-4">
      {isEditBudget ? (
        <>
          <h4 className="mb-2 font-semibold text-xs">
            Trip {destinationOrder}
          </h4>
          <TableInfo
            data={[
              {
                label: 'Destination',
                value: tripDestination,
              },
              {
                label: 'Date',
                value: tripDate,
              },
              values.currency !== values.policy?.currency_from && {
                label: 'Exchange Rate',
                value: (
                  <ExchangeRateDisplay
                    // Yes, this is intentionally flipped
                    from={values.currency}
                    to={values.policy?.currency_from}
                    date={values.approved_date}
                  />
                ),
              },
            ]}
          />
        </>
      ) : (
        <div className="mb-2 text-xs">
          <p className="text-primary font-semibold">{tripDestination}</p>
          <p>{tripDate}</p>

          {values.currency !== values.policy?.currency_from && (
            <p className="text-gray-600">
              <ExchangeRateDisplay
                // Yes, this is intentionally flipped
                from={values.currency}
                to={values.policy?.currency_from}
                date={values.approved_date}
              />
            </p>
          )}
        </div>
      )}

      <div>
        {destination?.budget?.map((res, idx) => (
          <Budget
            key={idx}
            data={res}
            dataDestination={destination}
            {...props}
          />
        ))}
      </div>
    </div>
  )
}
