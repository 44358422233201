import React from "react";
import { ButtonStyled } from "../../../components/button/ButtonStyles";
import { FormControlLabelStyled } from "../../../components/form/FormStyles";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

export default function FormActionButton({
  onCancel,
  onSubmit,
  onCheckAnotherTrip,
  isSubmitAnotherTrip,
  isDisableSubmit,
}) {
  return (
    <div className="flex flex-row justify-between p-8">
      <FormControlLabelStyled
        classes={{ label: "text-xs select-none" }}
        label="Submit and Apply Another Trip"
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            checked={isSubmitAnotherTrip}
            onChange={onCheckAnotherTrip}
          />
        }
      />
      <div className={`flex flex-row ml-auto justify-end`}>
        <ButtonStyled text="#000" className="px-6 py-2 mr-2" onClick={onCancel}>
          Cancel
        </ButtonStyled>
        <Button
          variant="contained"
          color="primary"
          className="px-6 py-2"
          onClick={onSubmit}
          disabled={isDisableSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
