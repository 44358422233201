import React, {useState, useEffect} from 'react'
import {Paper} from '../../components/paper/PaperStyles'
import LeaveTab from './tab/LeaveTab'
import ApplyForLeave from './apply-for-leave/ApplyForLeave'
import {useLazyQuery, useQuery} from '@apollo/react-hooks'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {
  GET_WORK_PLACEMENT,
  GET_LIST_NEW_POLICY_FORM,
} from '../../graphql/queries'
import useScheduleHoliday from '../../hooks/useScheduleHoliday'
import {withRouter} from 'react-router-dom'

const Leave = props => {
  const [open, setOpen] = useState({
    form: false,
    dialogCancel: false,
    notificationNoPolicy: false,
  })
  const [form, setForm] = useState(false)
  const [dataSchedule, getSchedule] = useScheduleHoliday()

  const {data} = useQuery(GET_WORK_PLACEMENT, {
    variables: {
      user: USER_ID,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  const [
    getListPolicy,
    {data: dataPolicyForm, error: errorPolicyForm},
  ] = useLazyQuery(GET_LIST_NEW_POLICY_FORM, {
    fetchPolicy: 'cache-and-network',
  })

  const renderComponent = activeUrl => {
    switch (activeUrl) {
      case '/leave':
        return (
          <LeaveTab
            form={form}
            dataId={data}
            setForm={setForm}
            open={open}
            setOpen={setOpen}
            {...props}
          />
        )
      case '/leave/apply':
        return (
          <ApplyForLeave
            dataPolicy={dataPolicyForm}
            setForm={setForm}
            dataScheduleHoliday={dataSchedule}
            openNopol={open}
            setOpenNopol={setOpen}
            {...props}
          />
        )
      default:
        return (
          <LeaveTab
            form={form}
            dataId={data}
            setForm={setForm}
            open={open}
            setOpen={setOpen}
            {...props}
          />
        )
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (data) {
      const year = new Date().getFullYear()
      getSchedule(data.people_work_placements[0].id)
      getListPolicy({
        variables: {
          user_id: USER_ID,
          company_id: COMPANY_ID,
          year: [year, year + 1],
          user_work_id: data.people_work_placements[0].id,
        },
      })
    }
  }, [form, data])

  if (errorPolicyForm) {
    return <span>{errorPolicyForm}</span>
  }

  return (
    <div className="flex flex-col m-0 rounded">
      <Paper>{renderComponent(props.match.url)}</Paper>
    </div>
  )
}

export default withRouter(Leave)
