import {format, differenceInMinutes, differenceInCalendarDays} from 'date-fns'
import {TIMEZONES, FILE_TYPES} from './constants'

import moment from 'moment'
import {COOKIE_URL, SSO_URL} from './globals'

export const differenceDateFrom = (start, to, diff = 'years') =>
  moment(start).diff(to, diff)

export const generateDate = date => {
  if (date) {
    return format(new Date(date), 'MMM dd, yyyy')
  } else {
    return null
  }
}
export const generateDateMonth = date => {
  if (date) {
    return format(new Date(date), 'MMMM dd, yyyy')
  } else {
    return null
  }
}

export const durationDay = (startDate, endDate) => {
  if (startDate && endDate) {
    return differenceInCalendarDays(new Date(startDate), new Date(endDate))
  } else {
    return null
  }
}

export const differenceDate = (dateLeft, dateRight, unitOfTime = 'days') =>
  moment(dateLeft).diff(dateRight, unitOfTime)

export const generateFullTime = date => {
  if (date) {
    return format(new Date(date), 'kk:mm')
  } else {
    return null
  }
}

export const generateFullDate = date => {
  if (date) {
    return format(new Date(date), 'eeee, dd MMM yyyy')
  }
}

export const generateDateSubmit = date => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd')
  } else {
    return null
  }
}
export const getDifferenceDateInMinutes = (dateLeft, dateRight) => {
  if (dateLeft && dateRight) {
    return differenceInMinutes(new Date(dateLeft), new Date(dateRight))
  } else {
    return null
  }
}

export const generateDay = date => {
  if (!date) {
    return ''
  }

  const instance = new Date(date)

  if (Number.isNaN(instance.getTime())) {
    return ''
  }

  return format(instance, 'cccc')
}

export const FormattedDate = (date, formatCode) => {
  if (date) {
    return format(new Date(date), formatCode)
  } else {
    return ''
  }
}

export const numberToMonth = num => {
  if (num) return moment(num, 'MM').format('MMMM')
  else return ''
}

export const isSameOrBefore = (start, end, type) => {
  return moment(start).isSameOrBefore(end, type)
}

export const isBetween = (time, start, end, format = 'hh:mm') => {
  if (time) {
    const _date = (time, _format) => {
      return moment(time, _format)
    }
    return _date(time, format).isBetween(
      _date(start, format),
      _date(end, format)
    )
  } else return false
}
export const generateTimeSubmit = date => {
  if (date) {
    return format(new Date(date), 'kk:mm')
  } else {
    return null
  }
}
export const getFullFormatDateNow = () => {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSSxxx')
}

export const addDay = (date, count = 0) => {
  if (date) {
    return moment(date)
      .add(count, 'day')
      .format('yyyy-MM-DD')
  } else {
    return date
  }
}

// TODO: tambahkan tanggal sebanyak {amount} berdasarkan {unit}
export const dateAdd = ({date, amount, unit, format}) => {
  if (date) {
    return moment(date)
      .add(amount, unit)
      .format(format)
  } else {
    return date
  }
}

// TODO: ubah {unit} tanggal berdasarkan {amount}
export const dateSet = ({date, amount, unit, format}) => {
  if (date) {
    return moment(date)
      .set(unit, amount)
      .format(format)
  } else {
    return date
  }
}

export const isPolicyCanUse = (start, amount) => {
  const futureMonth = moment(moment(start)).add(amount, 'M')
  const canUse = moment(new Date()).isAfter(futureMonth)
  const formattedDate = moment(futureMonth).format('LL')
  return [canUse, formattedDate]
}
export const convertNominal = (nominal, options = {}) =>
  new Intl.NumberFormat('id-ID', {...options}).format(nominal)

export function convertToRupiah(angka, isDecimalIncluded = true) {
  let rupiah = ''

  if (!angka) {
    return 'Rp -'
  }

  const angkarev = angka
    .toString()
    .split('')
    .reverse()
    .join('')
  for (let i = 0; i < angkarev.length; i++)
    if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.'

  return (
    'Rp' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('') +
    (isDecimalIncluded ? ',00' : '')
  )
}

export function convertToRoundedRupiah(angka, isDecimalIncluded = true) {
  return convertToRupiah(Math.round(angka || 0), isDecimalIncluded)
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}
export function substract(x, y) {
  const dataX = x
  const dataY = y
  const nilai = dataX - dataY
  return nilai
}
export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

// export function getDate(date) {
//   const initDate = new Date(date)
//   return Moment(initDate).format('DD MMMM YYYY')
//   // let dd = initDate.getDate()
//   // let mm = initDate.getMonth() + 1
//   // let yyyy = initDate.getFullYear()
//   // if (dd < 10) {
//   //   dd = `0${dd}`
//   // }

//   // if (mm < 10) {
//   //   mm = `0${mm}`
//   // }

//   // const dateFull = `${dd}/${mm}/${yyyy}`
//   // return dateFull
// }
export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + COOKIE_URL
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function getWithin(start_date) {
  if (start_date !== '') {
    // var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // var firstDate = new Date(start_date);
    // var secondDate = new Date();
    // var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));

    // return diffDays
    // var date2 = new Date(start_date);
    // var date1 = new Date();
    // var diff = Math.floor(date1.getTime() - date2.getTime());
    // var day = 1000 * 60 * 60 * 24;

    // var days = Math.floor(diff / day);
    // // var weeks = Math.floor(diff / 7);
    // var months = Math.floor(days / 31);
    // var years = Math.floor(months / 12);

    // var message = '';
    // message += " was "
    // message += days + " days "
    // // message += weeks + " weeks "
    // message += months + " months "
    // message += years + " years ago \n"

    // return message
    // Calculate time between two dates:
    const date1 = new Date(start_date) // the date you already commented/ posted
    const date2 = new Date() // today

    let message = ''

    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor((diffInSeconds / 60 / 60) % 24)
    const minutes = Math.floor((diffInSeconds / 60) % 60)

    const months = Math.floor(days / 31)
    const years = Math.floor(months / 12)

    // the below object is just optional
    // if you want to return an object instead of a message

    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in days if no years / months
      if (days > 0) {
        if (days === 1) {
          message = days + ' day'
        } else {
          message = days + ' days'
        }
      } else if (hours > 0) {
        if (hours === 1) {
          message = hours + ' hour'
        } else {
          message = hours + ' hours'
        }
      } else {
        // show in minutes if no years / months / days
        if (minutes === 1) {
          message = minutes + ' minute'
        } else {
          message = minutes + ' minutes'
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = months + ' month'
      } else {
        message = months + ' months'
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = years + ' year'
      } else {
        message = years + ' years'
      }
    }

    return 'Within ' + message
  }
}

export function setRoutes(config) {
  let routes = [...config.routes]

  if (config.settings || config.auth) {
    routes = routes.map(route => {
      let auth = config.auth ? [...config.auth] : []
      auth = route.auth ? [...auth, ...route.auth] : auth
      return {
        ...route,
        settings: {...config.settings, ...route.settings},
        auth,
      }
    })
  }

  return [...routes]
}

export function generateRoutesFromConfigs(configs) {
  let allRoutes = []
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)]
  })
  return allRoutes
}

export const generateIDApplied = (selectedItem, spacer) => {
  function convertDateForID(date) {
    const initDate = new Date(date)
    let dd = initDate.getDate()
    let mm = initDate.getMonth() + 1
    const yyyy = initDate.getFullYear()
    if (dd < 10) {
      dd = `0${dd}`
    }

    if (mm < 10) {
      mm = `0${mm}`
    }

    const dateFull = `${dd}${mm}${yyyy}`
    return dateFull
  }

  const date = convertDateForID(selectedItem && selectedItem.date_added)
  const id = String(selectedItem && selectedItem.id)
  const customId = id.padStart(5, '0')

  return `${spacer}-${date}${customId}`
}

export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

export const MathBoxes = (dataList, dataBoxes) => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let uid = null
  let Xnpf = []
  let Xgoal = []
  let Xmultirater = []
  let Xogf = []
  let Yattedance = []
  let Ycompetency = []
  let dataProfile = []
  const setBoxes = []

  dataList &&
    dataList.people_work_placements.forEach(data => {
      data.talent_assignments.forEach(resx => {
        Xnpf.push(resx.indicator_perf_nps)
        Xgoal.push(resx.indicator_perf_goal)
        Xmultirater.push(resx.indicator_perf_multirater)
        Xogf.push(resx.indicator_perf_ogf)
        Yattedance.push(resx.indicator_pot_attendance)
        Ycompetency.push(resx.indicator_pot_competence)
      })
      dataProfile = data
      uid = data.global_user.id
      const dataIndicator = {
        npf: Xnpf.reduce((a, b) => a + b, 0),
        ogf: Xogf.reduce((a, b) => a + b, 0),
        goal: Xgoal.reduce((a, b) => a + b, 0),
        multirater: Xmultirater.reduce((a, b) => a + b, 0),
        attendance: Yattedance.reduce((a, b) => a + b, 0),
        competency: Ycompetency.reduce((a, b) => a + b, 0),
      }

      dataBoxes &&
        dataBoxes.talent_versions.forEach(res => {
          start = res.start_date
          end = res.end_date

          x =
            (res.indicator_perf_nps
              ? (Xnpf.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_nps_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_ogf
              ? (Xogf.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_ogf_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_goal
              ? (Xgoal.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_goal_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_multirater
              ? (Xmultirater.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_multirater_fields.weight) /
                100
              : 0)
          y =
            (res.indicator_pot_attendance
              ? (Yattedance.reduce((a, b) => a + b, 0) *
                  res.indicator_pot_attendance_fields.weight) /
                100
              : 0) +
            (res.indicator_pot_competence
              ? (Ycompetency.reduce((a, b) => a + b, 0) *
                  res.indicator_pot_competence_fields.weight) /
                100
              : 0)
          const nilai = x + y

          if (x > res.min_perf && y > res.min_pot) {
            persentaseX = (res.max_perf - res.min_perf) / 2
            persentaseY = (res.max_pot - res.min_pot) / 2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            data.talent_assignments.forEach(resx => {
              res.talent_boxes.forEach(resd => {
                if (resx.badge_classification !== null) {
                  if (resx.badge_classification === resd.id) {
                    setBoxes.push({
                      resd,
                      nilai,
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                      res,
                    })
                  }
                } else {
                  if (akhirX === resd.index_x && akhirY === resd.index_y) {
                    setBoxes.push({
                      resd,
                      nilai,
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                      res,
                    })
                  }
                }
              })
            })
          }
        })
      Xnpf = []
      Xgoal = []
      Xmultirater = []
      Xogf = []
      Yattedance = []
      Ycompetency = []
    })
  return setBoxes
}
export const PersonalBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      data.talent_assignments.forEach(resx => {
        const dataIndicator = {
          npf: resx.indicator_perf_nps,
          ogf: resx.indicator_perf_ogf,
          goal: resx.indicator_perf_goal,
          multirater: resx.indicator_perf_multirater,
          attendance: resx.indicator_pot_attendance,
          competency: resx.indicator_pot_competence,
        }

        x =
          (resx.talentVersionByTalentVersion.indicator_perf_nps
            ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion.indicator_perf_ogf
            ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion.indicator_perf_goal
            ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion.indicator_perf_multirater
            ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
            : 0)
        y =
          (resx.talentVersionByTalentVersion.indicator_pot_attendance
            ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion.indicator_pot_competence
            ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
            : 0)
        const nilai = x + y

        if (
          x > resx.talentVersionByTalentVersion.min_perf &&
          y > resx.talentVersionByTalentVersion.min_pot
        ) {
          persentaseX =
            (resx.talentVersionByTalentVersion.max_perf -
              resx.talentVersionByTalentVersion.min_perf) /
            2
          persentaseY =
            (resx.talentVersionByTalentVersion.max_pot -
              resx.talentVersionByTalentVersion.min_pot) /
            2
          akhirX = x / persentaseX
          akhirY = y / persentaseY
          const modX = x % persentaseX
          const modY = y % persentaseY
          if (modX > 0) {
            akhirX = Math.floor(akhirX)
          } else {
            akhirX = Math.floor(akhirX - 1)
          }

          if (modY > 0) {
            akhirY = Math.floor(akhirY)
          } else {
            akhirY = Math.floor(akhirY - 1)
          }

          dataProfile = data
          start = resx.talentVersionByTalentVersion.start_date
          end = resx.talentVersionByTalentVersion.end_date
          resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
            if (resx.badge_classification !== null) {
              if (resx.badge_classification === resd.id) {
                setBoxes.push({
                  resd,
                  nilai,
                  start,
                  end,
                  dataProfile,
                  dataIndicator,
                  dataP: resx.talentVersionByTalentVersion,
                })
              }
            } else {
              if (akhirX === resd.index_x && akhirY === resd.index_y) {
                setBoxes.push({
                  resd,
                  nilai,
                  start,
                  end,
                  dataProfile,
                  dataIndicator,
                  dataP: resx.talentVersionByTalentVersion,
                })
              }
            }
          })
        }
      })
    })

  return setBoxes
}
export const MyTeamBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      if (data.talent_assignments.length === 0) {
        setBoxes.push({
          resd: null,
          dataProfile: data,
        })
      } else {
        data.talent_assignments.forEach(resx => {
          const dataIndicator = {
            npf:
              data.talent_assignments.length > 0 ? resx.indicator_perf_nps : 0,
            ogf:
              data.talent_assignments.length > 0 ? resx.indicator_perf_ogf : 0,
            goal:
              data.talent_assignments.length > 0 ? resx.indicator_perf_goal : 0,
            multirater:
              data.talent_assignments.length > 0
                ? resx.indicator_perf_multirater
                : 0,
            attendance:
              data.talent_assignments.length > 0
                ? resx.indicator_pot_attendance
                : 0,
            competency:
              data.talent_assignments.length > 0
                ? resx.indicator_pot_competence
                : 0,
          }
          const nilai =
            data.talent_assignments.length > 0
              ? (resx.indicator_perf_nps *
                  resx.talent_version.indicator_perf_nps_fields.weight) /
                  100 +
                (resx.indicator_perf_ogf *
                  resx.talent_version.indicator_perf_ogf_fields.weight) /
                  100 +
                (resx.indicator_perf_goal *
                  resx.talent_version.indicator_perf_goal_fields.weight) /
                  100 +
                (resx.indicator_perf_multirater *
                  resx.talent_version.indicator_perf_multirater_fields.weight) /
                  100 +
                (resx.indicator_pot_attendance *
                  resx.talent_version.indicator_pot_attendance_fields.weight) /
                  100 +
                (resx.indicator_pot_competence *
                  resx.talent_version.indicator_pot_competence_fields.weight) /
                  100
              : 0

          x =
            (resx.indicator_perf_nps *
              resx.talent_version.indicator_perf_nps_fields.weight) /
              100 +
            (resx.indicator_perf_ogf *
              resx.talent_version.indicator_perf_ogf_fields.weight) /
              100 +
            (resx.indicator_perf_goal *
              resx.talent_version.indicator_perf_goal_fields.weight) /
              100 +
            (resx.indicator_perf_multirater *
              resx.talent_version.indicator_perf_multirater_fields.weight) /
              100
          y =
            (resx.indicator_pot_attendance *
              resx.talent_version.indicator_pot_attendance_fields.weight) /
              100 +
            (resx.indicator_pot_competence *
              resx.talent_version.indicator_pot_competence_fields.weight) /
              100

          if (
            x > resx.talent_version.min_perf &&
            y > resx.talent_version.min_pot
          ) {
            persentaseX =
              (resx.talent_version.max_perf - resx.talent_version.min_perf) / 2
            persentaseY =
              (resx.talent_version.max_pot - resx.talent_version.min_pot) / 2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talent_version.start_date
            end = resx.talent_version.end_date
            resx.talent_version.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai,
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai,
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              }
            })
          }
        })
      }
    })

  return setBoxes
}
export const MathDetailBoxes = (dataBoxes, dataList) => {
  const setBoxes = []
  dataBoxes &&
    dataBoxes.talent_versions.forEach(res => {
      const boxRangeY = (res.max_pot - res.min_pot) / res.box_height
      const boxRangeX = (res.max_perf - res.min_perf) / res.box_width
      res.talent_boxes.forEach(resd => {
        const minBoxY = res.min_pot + resd.index_y * boxRangeY
        const minBoxX = res.min_perf + resd.index_x * boxRangeX
        const nps = res.indicator_perf_nps
          ? (res.indicator_perf_nps_fields.weight * minBoxX) / 100
          : 0
        const ogf = res.indicator_perf_ogf
          ? (res.indicator_perf_ogf_fields.weight * minBoxX) / 100
          : 0
        const goal = res.indicator_perf_goal
          ? (res.indicator_perf_goal_fields.weight * minBoxX) / 100
          : 0
        const multirater = res.indicator_perf_multirater
          ? (res.indicator_perf_multirater_fields.weight * minBoxX) / 100
          : 0
        const competency = res.indicator_pot_competence
          ? (res.indicator_pot_competence_fields.weight * minBoxY) / 100
          : 0
        const attendance = res.indicator_pot_attendance
          ? (res.indicator_pot_attendance_fields.weight * minBoxY) / 100
          : 0
        const data = {
          nps: nps,
          ogf: ogf,
          goal: goal,
          multirater: multirater,
          competency: competency,
          attendance: attendance,
          resd,
          res,
          idList: dataList
            ? dataList &&
              dataList.people_work_placements[0].talent_assignments[0].id
            : null,

          current: dataList
            ? dataList &&
              dataList.people_work_placements[0].talent_assignments[0]
                .badge_classification
            : null,
        }
        setBoxes.push(data)
      })
    })

  return setBoxes
}

export const renderTabs = pathname => {
  const path = pathname.split('/')[1]
  switch (path) {
    case 'attendance':
    case 'leave':
    case 'overtime':
      return 'time'
    case 'claim':
    case 'salary':
    case 'thr':
    case 'bank-and-tax':
      return 'financial'
    case 'trip':
    case 'trip-report':
      return 'business-trip'
    case 'digital-document':
      return 'document'
    default:
      return ''
  }
}

export const convertTimezone = ({
  date,
  timezone = null,
  timezoneName = null,
}) => {
  const convertTZ = (date, tzString) => {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        {timeZone: tzString}
      )
    )
  }
  if (date) {
    if (timezone) {
      return convertTZ(date, timezone)
    } else if (!timezone && timezoneName) {
      const selectedTimezone = TIMEZONES.filter(
        zone => zone.value === timezoneName
      )
      const _timezone =
        (selectedTimezone &&
          selectedTimezone[0] &&
          selectedTimezone[0].utc[0]) ||
        ''
      return convertTZ(date, _timezone)
    } else return date
  } else return date
}

export const getApproalType = (data, actionStatus, bridge) => {
  let approvalType = ''

  const _getApprove = (data, type) => {
    let _type = `first_${type}`

    if (data && data[bridge]) {
      const {confirm_type} = data[bridge]
      if (confirm_type === '1' && data.status === 'confirm1')
        _type = `second_${type}`
      else if (confirm_type === '2') {
        if (data.status === 'confirm1') {
          _type = `second_${type}`
        } else {
          _type = `first_${type}`
        }
      }
    }
    return _type
  }

  if (actionStatus === 'approved') approvalType = _getApprove(data, 'approve')
  else if (actionStatus === 'rejected')
    approvalType = _getApprove(data, 'reject')

  return approvalType
}

export const hasModule = (data, moduleName) =>
  data?.company_module_settings?.length === 0 ||
  data?.company_module_settings?.some(o => o.global_module.name === moduleName)

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

const getExtensionFile = name => {
  const nameSplited = name.split('.')
  const idx = nameSplited.length - 1
  return nameSplited[idx]
}

export const getFilesType = name => {
  const extension = getExtensionFile(name)
  return FILE_TYPES[extension]
}

export const isValidURL = url => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  )
}

export const limitNumberWithinRange = num => {
  const parsed = parseInt(num)
  return Math.min(Math.max(parsed, 0), 100)
}
