import React from 'react'
import {Paper} from '@material-ui/core'
import DigitalComponent from './DigitalComponent'
import FormUploadFile from './form-component/FormUploadFile'

export default function Document() {
  const [page, setPage] = React.useState('list')
  const [refetch, setRefetch] = React.useState()

  const pageComponent = page => {
    if (page === 'list') {
      return <DigitalComponent setPage={setPage} setRefetch={setRefetch} />
    } else if (page === 'upload') {
      return <FormUploadFile setPage={setPage} refetch={refetch} />
    }
  }

  return (
    <div className="flex flex-col w-full">
      <Paper className="">{pageComponent(page)}</Paper>
    </div>
  )
}
