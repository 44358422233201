import React from 'react'
import {
  ButtonStyled,
  IconButtonStyled,
} from '../../../components/button/ButtonStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {useMutation} from '@apollo/react-hooks'
import Divider from '../../../components/divider/DividerStyles'
import Select from 'react-select'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {useMediaQuery, useTheme} from '@material-ui/core'
import {
  TextFieldStyled,
  InputAdornmentStyled,
  FormControlStyled,
  FormHelperTextStyled,
} from '../../../components/form/FormStyles'
import CancelDialog from '../../shared-component/dialog/CancelDialog'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {MUTATION_INSERT_DELETE_TAX} from '../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {USER_ID} from '../../../utils/globals'

function AddTax(props) {
  const {dataEdit, setPage, refetch} = props
  const [values, setValues] = React.useState({
    npwp: '',
    bpjs_kes: '',
    bpjs_ket: '',
  })

  const [npwp, setNpwp] = React.useState({})
  const [bpjsKes, setBpjsKes] = React.useState({})
  const [bpjsKet, setBpjsKet] = React.useState({})

  const {enqueueSnackbar} = useSnackbar()

  const [error, setError] = React.useState(false)

  const [insertApply] = useMutation(MUTATION_INSERT_DELETE_TAX)

  const [openSubmit, setOpenSubmit] = React.useState(false)
  const [open, setOpen] = React.useState({
    dialogCancel: false,
  })

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    dataEdit.map(row => {
      if (row.type === 'NPWP') {
        setNpwp(row)
      } else if (row.type === 'BPJSKes') {
        setBpjsKes(row)
      } else if (row.type === 'BPJSTK') {
        setBpjsKet(row)
      }
    })
  }, [dataEdit, props])

  React.useEffect(() => {
    setValues({
      npwp: npwp.id,
      bpjs_kes: bpjsKes.id,
      bpjs_ket: bpjsKet.id,
    })
  }, [npwp, bpjsKes, bpjsKet])

  const handleChange = (e, target, child) => {
    if (child) {
      setValues({
        ...values,
        [target]: e,
      })
    } else {
      setValues({
        ...values,
        [target]: e.target.value,
      })
    }
  }

  const renderComponent = res => {
    const {type, value, options, inputProps, textArea, field, placeholder} = res
    if (type === 'text' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          fullWidth
          disabled={field === 'name'}
          value={inputProps ? parseInt(value) : value}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e, field)}
          multiline={textArea}
          rows={textArea && 8}
          placeholder={placeholder}
          InputProps={
            inputProps
              ? {
                  inputProps: {className: 'text-xs', min: 0},
                  endAdornment: (
                    <InputAdornmentStyled position="end" disableTypography>
                      <div
                        style={{
                          borderLeft: '1px solid rgba(0, 0, 0, 0.27)',
                          paddingLeft: 13,
                          display: 'flex',
                          alignItems: 'center',
                          height: 34,
                          marginleftt: 6,
                        }}
                      >
                        <p style={{fontSize: 14, color: '#a9a8a8'}}>Hours</p>
                      </div>
                    </InputAdornmentStyled>
                  ),
                }
              : {
                  inputProps: {className: 'text-xs'},
                }
          }
          error={field !== 'name' && values[field] === '' && error}
          helperText={
            field !== 'name' &&
            values[field] === '' &&
            error &&
            'This field is required'
          }
          classes={
            placeholder && {
              root: 'text-xs',
            }
          }
        />
      )
    } else if (type === 'select') {
      return (
        <>
          <FormControlStyled style={{width: '100%'}} size="small">
            <Select
              inputProps={{className: 'text-xs'}}
              style={{width: '100%'}}
              value={value}
              options={options}
              displayEmpty
              fullWidth
              onChange={(e, f) => handleChange(e, field, f)}
              variant="outlined"
              error={values[field] === '' && error}
            />
            {values[field] === '' && error && (
              <FormHelperTextStyled className="text-error mx-3">
                This field is required
              </FormHelperTextStyled>
            )}
          </FormControlStyled>
        </>
      )
    }
  }

  const fieldList = [
    {
      number: !smallSize && '1',
      fieldName: 'NPWP*',
      field: 'npwp',
      type: 'number',
      value: values.npwp,
    },
    {
      number: !smallSize && '2',
      fieldName: 'BPJS Kesehatan*',
      field: 'bpjs_kes',
      type: 'number',
      value: values.bpjs_kes,
    },
    {
      number: !smallSize && '3',
      fieldName: 'BPJS Ketenagakerjaan*',
      field: 'bpjs_ket',
      type: 'number',
      value: values.bpjs_ket,
    },
  ]

  const handleSubmit = () => {
    const {npwp, bpks_kes, bpjs_ket} = values
    if (
      // name === '' ||
      npwp === '' ||
      bpks_kes === '' ||
      bpjs_ket === ''
    ) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      setOpenSubmit(true)
    }
  }

  const handleClickCancel = () => {
    setOpen({
      ...open,
      dialogCancel: false,
    })
    setPage('bank-tax')
  }

  const submitApplied = () => {
    const variable = {
      user: USER_ID,
      bpjstk: values.bpjs_ket,
      bpjskes: values.bpjs_kes,
      npwp: values.npwp,
    }
    insertApply({
      variables: variable,
      context: {
        headers: {
          'X-Hasura-Role': 'user',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Apply for Tax & BPJS Success', {variant: 'success'})
        // setForm(false)
        refetch()
        setPage('bank-tax')
        setOpenSubmit(false)
      })
      .catch(e => {
        const a = JSON.parse(JSON.stringify(e))
        let msg = ''
        a.graphQLErrors &&
          a.graphQLErrors.length >= 1 &&
          a.graphQLErrors.map(row => {
            msg = `${msg} ${row.message}`
          })
        enqueueSnackbar(msg, {variant: 'error'})
      })
  }

  return (
    <>
      <div className="sm:p-8 p-6 flex flex-row">
        <div className="flex flex-row items-center">
          <IconButtonStyled
            className="p-0"
            // onClick={() => props.history.goBack()}
            onClick={() => setPage('bank-tax')}
            // onClick={() => setOpen({...open, dialogCancel: true})}
          >
            <ArrowBackIcon />
          </IconButtonStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TypographyStyled
              className="font-semibold"
              text="#014a62"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '10px',
              }}
            >
              Bank & Tax /
            </TypographyStyled>
            <TypographyStyled
              className="font-semibold"
              text="#a9a8a8"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '5px',
              }}
            >
              Edit Tax & BPJS
            </TypographyStyled>
          </div>
        </div>
      </div>

      <Divider />

      <div className={`pt-8 sm:px-8 px-6 flex flex-col`}>
        {fieldList.map((res, i) => (
          <FormWrapper
            forMobile={smallSize}
            key={i}
            number={res.number}
            title={res.fieldName}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
      </div>

      <Divider />
      <div
        className="py-8 sm:px-8 px-6 flex flex-row ml-auto"
        style={{justifyContent: 'flex-end'}}
      >
        <ButtonStyled
          onClick={() =>
            setOpen({
              ...open,
              dialogCancel: true,
            })
          }
          text="#000"
          className="px-6 py-2"
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
          state="primary"
          className="px-6 py-2 ml-2"
        >
          Submit
        </ButtonStyled>
      </div>
      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        onClickPrimary={handleClickCancel}
      />

      <ConfirmationDialog
        title="Apply for Tax & BPJS?"
        content="Are you sure you want to apply for this Tax & BPJS?"
        onClickPrimary={submitApplied}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={false}
      />
    </>
  )
}

export default AddTax
