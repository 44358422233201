import gql from 'graphql-tag'

export const GET_LIST_DD_COMPANY = gql`
  query(
    $company: uuid
    $search: String
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: company_files_aggregate(
      where: {
        company: {_eq: $company}
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    company_files(
      where: {
        company: {_eq: $company}
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
      order_by: [{id: $sorting}]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      file
      date_added
      last_modified
      global_file_type {
        name
      }
    }
  }
`

export const GET_LIST_DD_PEOPLE = gql`
  query(
    $user: uuid
    $search: String
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: people_files_aggregate(
      where: {
        user: {_eq: $user}
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_files(
      where: {
        user: {_eq: $user}
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
      order_by: [{id: $sorting}]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      file
      date_added
      last_modified
      global_file_type {
        name
      }
    }
  }
`

export const GET_LIST_TYPE = gql`
  query GetGlobalTypeDoc {
    global_file_types {
      id
      name
    }
  }
`
