import React, {useState, useEffect} from 'react'
import {
  TextFieldStyledNoLine,
  MenuItemStyled,
} from '../../components/form/FormStyles'
import ModalDetailWebFinance from '../shared-component/dialog/ModalDetailWebFinance'
import {generateDate, numberToMonth} from '../../utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import LoadingComponent from '../shared-component/loading/LoadingComponent'
import DetailListFinance from '../shared-component/dialog/DetailListFinance'
import ListItemFinance from '../shared-component/list/ListItemFinance'
import {GET_LIST_SALARY} from '../../graphql/queries'
import {TablePaginationStyled} from '../../components/pagination/PaginationStyles'
import Filter from '../shared-component/advance-filter/Filter'
import {Button} from '@material-ui/core'
import {PAGINATION_RANGE_HUNDRED} from '../../utils/constants'
import EmptyState from '../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../assets/images/nodatalist.png'
import {COMPANY_ID, USER_ID} from '../../utils/globals'

const SalaryList = () => {
  const [sort, setSort] = useState('desc')
  const [open, setOpen] = useState({
    web: false,
    mobile: false,
    data: null,
    cancel: false,
    idCancel: null,
  })
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE_HUNDRED[0],
    page: 0,
  })
  const [filter, setFilter] = useState({
    year: [],
    month: [],
  })
  const [listFilter, setListFilter] = useState([
    {
      name: 'Year',
      fieldName: 'year',
      type: 'checkbox',
      options: {
        list: null,
      },
    },
    {
      name: 'Month',
      fieldName: 'month',
      type: 'checkbox',
      options: {
        list: null,
      },
    },
  ])

  const {data: dataSalary, loading, error: errorSalary, refetch} = useQuery(
    GET_LIST_SALARY,
    {
      variables: {
        company: COMPANY_ID,
        user: USER_ID,
        year: filter.year.length === 0 ? null : filter.year,
        month: filter.month.length === 0 ? null : filter.month,
        order: sort,
        limit: pagination.rowsPerPage,
        offset: pagination.page * pagination.rowsPerPage,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    }
  )
  // console.log('dataSalary : ', dataSalary)

  const valueLabelFilterMap = (data, key) => {
    const uniqueOptions = {}

    data.forEach(data => {
      if (!uniqueOptions[data[key]]) uniqueOptions[data[key]] = data[key]
    })

    if (key === 'month') {
      return Object.keys(uniqueOptions).map(key => ({
        label: numberToMonth(key),
        value: key,
      }))
    }

    return Object.keys(uniqueOptions).map(key => ({
      label: key,
      value: key,
    }))
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (dataSalary) {
      setListFilter([
        {
          ...listFilter[0],
          options: {
            list: valueLabelFilterMap(dataSalary.people_work_payroll, 'year'),
          },
        },
        {
          ...listFilter[1],
          options: {
            list: valueLabelFilterMap(dataSalary.people_work_payroll, 'month'),
          },
        },
      ])
    }
  }, [dataSalary])

  if (errorSalary) {
    return <span>{errorSalary}</span>
  }

  const handleClick = res => {
    setOpen({mobile: false, web: true, data: res})
  }

  const handleApply = result => {
    // console.log('result : ', result)
    const {year, month} = result
    const yearFilter = []
    const monthFilter = []
    if (year) {
      year.forEach(year => {
        yearFilter.push(year.value)
      })
    }
    if (month) {
      month.forEach(month => {
        monthFilter.push(month.value)
      })
    }
    setFilter({
      ...filter,
      year: yearFilter,
      month: monthFilter,
    })
  }

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  }

  const transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  }

  return (
    <div className="flex flex-col px-0 min-h-screen">
      <div
        className="py-4 px-6 flex flex-row justify-between items-center"
        style={{borderBottom: '1px solid #f7f8f9'}}
      >
        <TextFieldStyledNoLine
          value={sort}
          onChange={e => setSort(e.target.value)}
          select
          style={{width: 'auto'}}
          size="small"
        >
          <MenuItemStyled value="desc">Latest</MenuItemStyled>
          <MenuItemStyled value="asc">Oldest</MenuItemStyled>
        </TextFieldStyledNoLine>

        {dataSalary && listFilter[0].options.list ? (
          <Filter
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id="filter-wlb"
            onApply={handleApply}
            listFilter={listFilter}
          >
            <Button>Filter</Button>
          </Filter>
        ) : null}
      </div>
      {loading ? (
        <LoadingComponent />
      ) : !loading &&
        dataSalary &&
        dataSalary.payroll_count.aggregate.count > 0 ? (
        dataSalary.people_work_payroll.map((res, i) => {
          return (
            <ListItemFinance
              key={i}
              onClick={() => handleClick(res)}
              from={'salary'}
              month={res.month}
              year={res.year}
              schedule={generateDate(res.date_printed)}
            />
          )
        })
      ) : (
        <EmptyState image={NoListImage} message1="Sorry, No List" />
      )}

      <TablePaginationStyled
        className="mt-auto"
        rowsPerPageOptions={PAGINATION_RANGE_HUNDRED}
        onChangePage={(e, newPage) => {
          e.preventDefault()
          setPagination({...pagination, page: newPage})
        }}
        onChangeRowsPerPage={e => {
          setPagination({
            ...pagination,
            page: 0,
            rowsPerPage: parseInt(e.target.value),
          })
        }}
        component="div"
        count={(dataSalary && dataSalary.payroll_count.aggregate.count) || 0}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
      />
      <ModalDetailWebFinance
        open={open.web}
        onClose={() => setOpen({...open, web: false, data: null})}
      >
        <DetailListFinance from="salary" id={open.data && open.data.id} />
      </ModalDetailWebFinance>
    </div>
  )
}

export default SalaryList
