import React from 'react'
import styled from 'styled-components'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFile,
  faFilePowerpoint,
} from '@fortawesome/free-solid-svg-icons'

const getIcon = url => {
  let type = null
  let icon = faFile
  if (url) {
    const fileType = url.split('.').pop()
    switch (fileType) {
      case 'pdf':
        type = 'pdf'
        icon = faFilePdf
        break
      case 'xls':
      case 'xlsx':
        type = 'xls'
        icon = faFileExcel
        break
      case 'ppt':
        type = 'ppt'
        icon = faFilePowerpoint
        break
      case 'doc':
      case 'docx':
        type = 'doc'
        icon = faFileWord
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'PNG':
      case 'gif':
      case 'tiff':
      case 'pjp':
      case 'pjpeg':
        type = 'img'
        icon = null
        break
      default:
        type = null
        icon = faFile
        break
    }
  }
  return [type, icon]
}

export default function AttachmentItemList({name, url, onClick}) {
  const [type, icon] = getIcon(url)

  return (
    <AttchWrap
      onClick={onClick}
      className="shadow-wlb mb-2 flex flex-row items-center rounded-wlb cursor-pointer"
      style={{padding: '8px 12px'}}
    >
      <div className="mr-1">
        {icon ? (
          <FontAwesomeIcon className={`icon ${type}`} icon={icon} />
        ) : (
          <img
            alt="icon"
            className={`icon max-width-itm-atch ${type}`}
            src={url}
          />
        )}
      </div>
      <span className="file-name">{name}</span>
    </AttchWrap>
  )
}

export const AttchWrap = styled.div`
  padding: 8px 12px;
  width: 120px;

  .icon {
    margin-right: 9px;
  }
  .max-width-itm-atch {
    max-width: 22px;
  }
  .pdf {
    color: #ef4d5e;
  }
  .xls {
    color: #2c5a1a;
  }
  .ppt {
    color: #ef4d5e;
  }
  .doc {
    color: #039be5;
  }
  .file-name {
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`
