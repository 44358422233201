import React from 'react'
import {Typography} from '@material-ui/core'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'

const EmptyState = ({message1, image, message2}) => {
  return (
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      }}
      className="min-h-screen"
    >
      <div style={{ maxWidth: "351px" }}>
        <img
          src={image}
          alt="no-data"
          height="200px"
          width="auto"
          style={{margin: '24px 0', padding: 16}}
        ></img>
        <Typography className="text-md mb-2" variant="h6">{message1}</Typography>
        <TypographyStyled className="text-xm" text="#a9a8a8" variant="body1">
          {message2}
        </TypographyStyled>
      </div>
    </div>
  )
}

export default EmptyState
