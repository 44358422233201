import {useMediaQuery, useTheme} from '@material-ui/core'
import React from 'react'

import {AttachmentCard} from '@smartasn/wlb-utils-components'
import ModalDetail from '../../shared-component/dialog/ModalDetail'

export default function DetailAttachment({data, open, onClose}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const PADDING_SECTION = 'p-6'

  const type = {
    '1': 'Transfer',
    '2': 'Ticket / Voucher',
  }

  const {bank_name, account_number, account_name} =
    data?.people_profile_bank || {}

  return (
    <ModalDetail
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      maxWidth="md"
      title="Attachment"
    >
      <div className={PADDING_SECTION}>
        {data?.payment_type && (
          <ItemDetail fullScreen={fullScreen} label="Payment Type">
            {type[data?.payment_type]}
          </ItemDetail>
        )}
        {data?.people_profile_bank && (
          <ItemDetail fullScreen={fullScreen} label="Bank Account">
            {`${account_number} (${bank_name} - ${account_name})`}
          </ItemDetail>
        )}
        <ItemDetail
          className={fullScreen && 'font-semibold'}
          fullScreen={true}
          label="Receipt"
        >
          <div className="flex flex-row flex-wrap mt-2">
            {data?.attachment?.map((file, idx) => (
              <AttachmentCard
                key={idx}
                name={file.name || ''}
                url={file.url || ''}
              />
            ))}
          </div>
        </ItemDetail>
      </div>
    </ModalDetail>
  )
}

const ItemDetail = ({
  label,
  fullScreen,
  semibold = true,
  className = '',
  ...value
}) => {
  return (
    <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-3`}>
      <span
        className={`${
          fullScreen ? 'text-sm' : 'font-semibold text-sm'
        } text-disable ${className}`}
        style={{width: 200, paddingRight: 28}}
      >
        {label}
      </span>
      <div
        className={`${fullScreen ? 'mb-4 text-sm' : 'text-sm'} ${
          semibold ? ' font-semibold' : ''
        }`}
        {...value}
      />
    </div>
  )
}
