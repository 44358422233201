import Salary from './SalaryPageComponent'

export const SalaryConfig = {
  routes: [
    {
      path: '/salary',
      component: Salary,
      state: 'salary',
      exact: true,
    },
  ],
}
