import gql from 'graphql-tag'

export const GET_LIST_TRIP_REQUEST = gql`
  query getListBusinessTripRequest(
    $user_id: uuid!
    $search: String
    $status: [String!]
    $policy_id: [Int!]
    $participant: [uuid!]
    $filter_date_start: timestamp
    $filter_date_end: timestamp
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        people_work_placement: {user: {_in: $participant}}
        business_trip_policy: {id: {_in: $policy_id}, confirm_type: {_neq: 3}}
        deletedAt: {_is_null: true}
        status: {_in: $status}
        _or: [
          {id_display: {_ilike: $search}}
          {business_trip_policy: {name: {_ilike: $search}}}
        ]
        _and: {
          _or: [
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user_id}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                    job_profile_supervisor: {
                      people_work_placements: {
                        user: {_eq: $user_id}
                        status: {_eq: "ACTIVE"}
                      }
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                    job_profile_supervisor: {
                      _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user_id}
                          status: {_eq: "ACTIVE"}
                        }
                      }
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {
                confirm_type: {_eq: 2}
                companyJobProfileByFirstConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
            {
              business_trip_policy: {
                companyJobProfileBySecondConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
            {
              business_trip_policy: {
                companyJobProfileByThirdConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_business_trips(
      where: {
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        people_work_placement: {user: {_in: $participant}}
        business_trip_policy: {id: {_in: $policy_id}, confirm_type: {_neq: 3}}
        deletedAt: {_is_null: true}
        status: {_in: $status}
        _or: [
          {id_display: {_ilike: $search}}
          {business_trip_policy: {name: {_ilike: $search}}}
        ]
        _and: {
          _or: [
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user_id}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                    job_profile_supervisor: {
                      people_work_placements: {
                        user: {_eq: $user_id}
                        status: {_eq: "ACTIVE"}
                      }
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {confirm_type: {_eq: 1}}
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                    job_profile_supervisor: {
                      _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user_id}
                          status: {_eq: "ACTIVE"}
                        }
                      }
                    }
                  }
                }
              }
            }
            {
              business_trip_policy: {
                confirm_type: {_eq: 2}
                companyJobProfileByFirstConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
            {
              business_trip_policy: {
                companyJobProfileBySecondConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
            {
              business_trip_policy: {
                companyJobProfileByThirdConfirmProfile: {
                  people_work_placements: {
                    user: {_eq: $user_id}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          ]
        }
      }
      limit: $limit
      offset: $offset
      order_by: [{id: $sorting}]
    ) {
      id
      id_display
      date_start
      date_end
      status
      date_added
      business_trip_policy {
        id
        name
      }
      global_user {
        id
        name
      }
    }
  }
`

export const GET_LIST_TRIP_REQUESTER = gql`
  query listRequester(
    $userId: uuid
    $company: uuid
    $limit: Int
    $nin: [uuid!]
    $search: String
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        _or: [
          {
            business_trip_policy: {
              companyJobProfileByFirstConfirmProfile: {
                people_work_placements: {
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
          {
            business_trip_policy: {
              companyJobProfileBySecondConfirmProfile: {
                people_work_placements: {
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
          {
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $userId}
                    company: {_eq: $company}
                  }
                }
              }
            }
            business_trip_policy: {confirm_type: {_eq: 1}}
          }
          {
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $userId}
                    company: {_eq: $company}
                  }
                }
              }
            }
            business_trip_policy: {confirm_type: {_eq: 3}}
          }
        ]
        global_user: {_and: [{name: {_ilike: $search}}, {id: {_nin: $nin}}]}
      }
      distinct_on: user_id
    ) {
      aggregate {
        count
      }
    }

    people_work_business_trips(
      where: {
        _or: [
          {
            business_trip_policy: {
              companyJobProfileByFirstConfirmProfile: {
                people_work_placements: {
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
          {
            business_trip_policy: {
              companyJobProfileBySecondConfirmProfile: {
                people_work_placements: {
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
          {
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $userId}
                    company: {_eq: $company}
                  }
                }
              }
            }
            business_trip_policy: {confirm_type: {_eq: 1}}
          }
          {
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $userId}
                    company: {_eq: $company}
                  }
                }
              }
            }
            business_trip_policy: {confirm_type: {_eq: 3}}
          }
        ]
        global_user: {_and: [{name: {_ilike: $search}}, {id: {_nin: $nin}}]}
      }
      distinct_on: user_id
      limit: $limit
    ) {
      id
      global_user {
        id
        name
      }
    }
  }
`

export const CHECKER_ACTION_BUTTON_REQUEST_TRIP = gql`
  query CheckerButtonAction($userId: uuid!, $company: uuid, $id: Int!) {
    checker_1st_approval: people_work_business_trips(
      where: {
        id: {_eq: $id}
        status: {_eq: "pending"}
        _or: [
          {
            business_trip_policy: {
              confirm_type: {_eq: 2}
              companyJobProfileByFirstConfirmProfile: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
          {
            business_trip_policy: {confirm_type: {_eq: 1}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $userId}
                  }
                }
              }
            }
          }
          {
            business_trip_policy: {confirm_type: {_eq: 1}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                  job_profile_supervisor: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $userId}
                    }
                  }
                }
              }
            }
          }
          {
            business_trip_policy: {confirm_type: {_eq: 1}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                  job_profile_supervisor: {
                    _not: {people_work_placements: {status: {_eq: "ACTIVE"}}}
                    job_profile_supervisor: {
                      people_work_placements: {
                        status: {_eq: "ACTIVE"}
                        user: {_eq: $userId}
                      }
                    }
                  }
                }
              }
            }
          }
        ]
      }
    ) {
      id
    }

    checker_2nd_approval: people_work_business_trips(
      where: {
        id: {_eq: $id}
        status: {_eq: "confirm1"}
        _or: [
          {
            business_trip_policy: {
              companyJobProfileBySecondConfirmProfile: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
        ]
      }
    ) {
      id
    }

    checker_3rd_approval: people_work_business_trips(
      where: {
        id: {_eq: $id}
        status: {_eq: "confirm2"}
        _or: [
          {
            business_trip_policy: {
              companyJobProfileByThirdConfirmProfile: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $userId}
                  company: {_eq: $company}
                }
              }
            }
          }
        ]
      }
    ) {
      id
    }
  }
`
