import {ApolloProvider} from '@apollo/react-hooks'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core'
import React from 'react'
// import {StylesProvider} from '@material-ui/styles'
import {ThemeProvider} from 'styled-components'
import AppContainer from './AppContainer'
import client from './graphql/connector'
import * as Sentry from '@sentry/browser'
import {SENTRY_ENV, SENTRY_URL, userData} from './utils/globals'

const theme = createMuiTheme({
  font: 'muli',
  borderColor: 'rgb(204, 204, 204)',
  palette: {
    primary: {
      main: '#055469',
    },
    secondary: {
      main: '#039be5',
    },
    danger: {
      main: '#ef4d5e',
    },
  },
  typography: {
    fontFamily: ['muli'],
    button: {
      textTransform: 'capitalize',
    },
    h6: {
      fontWeight: 700,
    },
  },
})

Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENV,
})

Sentry.configureScope(scope => scope.setExtra('userData', userData))

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <AppContainer />
        </MuiThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
