import React, {useState} from 'react'
import Webcam from 'react-webcam'
import {
  Slide,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Icon,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FormCheckin from './FormSubmitCheckin'
const useStyles = makeStyles(theme => ({
  toolbar: {
    marginRight: '3vw',
    background: '#fff',

    display: 'flex',
  },
  webcam: {
    height: '92.2vh',
  },
  toolbarRoot: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
  },
  rootPaper: {
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DetailCheckin({
  open,
  handleOpenCekin,
  dataAtt,
  refetch,
  idWork,
  // refetchAttendance,
  setOpenCekin,
  setopenNotif,
  getTime,
}) {
  const classes = useStyles()
  const [imgsrc, setImgsrc] = useState(null)
  const webcamRef = React.useRef(null)
  const videoConstraints = {
    width: 720,
    height: 720,
    aspectRatio: 1,
    facingMode: 'user',
  }

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImgsrc(imageSrc)
  }, [webcamRef])

  const close = () => {
    handleOpenCekin()
    setImgsrc(null)
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      classes={{paper: classes.rootPaper}}
    >
      <div className={classes.toolbarRoot}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="primary"
            className="w-full text-center"
          >
            Check - In
          </Typography>
        </Toolbar>
      </div>

      {(dataAtt && !dataAtt.dataPolicy.mobile_setting.isIncludePhoto) ||
      imgsrc ? (
        <FormCheckin
          getTime={getTime}
          setopenNotif={setopenNotif}
          setOpenCekin={setOpenCekin}
          // refetchAttendance={refetchAttendance}
          imgsrc={imgsrc}
          classes={classes}
          dataAtt={dataAtt}
          close={close}
          refetch={refetch}
          idWork={idWork}
          setImgsrc={setImgsrc}
        />
      ) : (
        <div className="text-center">
          <Webcam
            className={classes.webcam}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={'100%'}
            screenshotQuality={0.8}
            videoConstraints={videoConstraints}
          />
          <IconButton
            edge="start"
            color="primary"
            onClick={capture}
            aria-label="close"
            className="my-4 absolute"
            style={{top: '85vh', background: '#fff'}}
          >
            <Icon>camera_alt</Icon>
          </IconButton>
        </div>
      )}
    </Dialog>
  )
}

export default DetailCheckin
