import gql from 'graphql-tag'

export const UPLOAD_TRIP_REPORT = gql`
  mutation(
    $id_bustrip: Int!
    $budget_action: String
    $budget_realizations: [people_work_business_trip_destination_budgets_insert_input!]!
  ) {
    update_people_work_business_trips_by_pk(
      pk_columns: {id: $id_bustrip}
      _set: {report_status: "reported", budget_diff_action: $budget_action}
    ) {
      id
      report_status
      budget_diff_action
    }

    insert_people_work_business_trip_destination_budgets(
      on_conflict: {
        constraint: people_work_business_trip_destination_budgets_pkey
        update_columns: [report_attachments, realization]
      }
      objects: $budget_realizations
    ) {
      affected_rows
      returning {
        id
        report_attachments
        realization
      }
    }
  }
`

export const UPDATE_TRIP_REPORT = gql`
  mutation(
    $id_bustrip: Int!
    $budget_action: String
    $budget_realizations: [people_work_business_trip_destination_budgets_insert_input!]!
  ) {
    update_people_work_business_trips_by_pk(
      pk_columns: {id: $id_bustrip}
      _set: {report_status: "reported", budget_diff_action: $budget_action}
    ) {
      id
      report_status
      budget_diff_action
    }

    insert_people_work_business_trip_destination_budgets(
      on_conflict: {
        constraint: people_work_business_trip_destination_budgets_pkey
        update_columns: [attachment, realization]
      }
      objects: $budget_realizations
    ) {
      affected_rows
    }
  }
`
