import React from 'react'
import {DrawerStyles} from '../../../components/drawer/DrawerStyles'
import ClearIcon from '@material-ui/icons/Clear'

const generateStyle = style => {
  if (style) {
    return style
  } else {
    return {backgroundColor: '#014a62'}
  }
}

export default function DrawerDetail(props) {
  const {open, onClose, title, styleHeader, ...child} = props
  return (
    <DrawerStyles anchor="right" open={open} onClose={onClose}>
      <div className="w-screen flex flex-col">
        <div
          style={generateStyle(styleHeader)}
          className={`flex justify-between px-5 py-3 ${
            styleHeader ? null : 'text-white'
          }`}
        >
          <span className="text-lg">{title}</span>
          <span onClick={onClose}>
            <ClearIcon />
          </span>
        </div>
        <div className="flex flex-col h-full" {...child} />
      </div>
    </DrawerStyles>
  )
}
