import ClaimComponent from './ClaimComponent'
import ClaimApplyComponent from './ClaimApply'

export const ClaimConfig = {
  routes: [
    {
      path: '/claim/apply',
      component: ClaimApplyComponent,
      exact: true,
    },
    {
      path: '/claim',
      component: ClaimComponent,
    },
  ],
}
