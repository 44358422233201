import styled from 'styled-components'
import {
  Card,
  Tabs,
  Tab,
  Icon,
  withStyles,
  LinearProgress,
  Avatar,
  Button,
  Grid,
  ExpansionPanelSummary,
  MenuItem,
  ExpansionPanel,
} from '@material-ui/core'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const TitleCreate = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  font-size: 18px;
`
export const TaskCard = styled.div`
  width: 100%;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FlexColumn = styled.div`
  margin: 10px 35px;
  flex-direction: column;
  width: 90%;
`

export const Center = styled.div`
  text-align: center;
`

export const SummaryContainer = styled.div``

export const ListSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const CardSummary = styled(Card)`
  padding: 18px;
  width: 27%;
  cursor: pointer;
  margin-top: 1rem;
`
export const OverallContainer = styled.div`
  margin: 1rem 0;
  color: #039be5;
`
export const SummaryScore = styled.div`
  color: #039be5;
`
export const Rate = styled.div`
  color: #039be5;
`

export const DateContainer = styled.div`
  color: #707070;
  height: 2rem;
  display: grid;
`
export const SummaryListContainer = styled.div``

export const AchievementContainer = styled.div`
  padding-top: 2rem;
`

export const MultiraterContainer = styled.div`
  padding-top: 2rem;
`

export const NPSContainer = styled.div`
  padding-top: 2rem;
`

export const FeedbackContainer = styled.div`
  padding-top: 2rem;
`

export const SelectContainer = styled.div``

export const SeeMore = styled.div`
  font-size: 14px;
  border-top: 3px solid #f7f8f9;
  margin-top: 1.5rem;
  padding-top: 1rem;
  text-align: center;
  color: #039be5;
  text-transform: none !important;
  width: 100%;
`

export const CardFeedback = styled(Card)`
  padding: 15px;
  width: 15%;
  display: flex;
  justify-content: center;
`

export const IconFeedback = styled(Icon)`
  font-size: 2.3rem;
`

export const CardFeedbackTabs = styled(Card)`
  padding: 8px 18px;
  margin-bottom: 10px;
  cursor: pointer;
`
export const FeedbackTabsContainer = styled(Card)`
  width: 100%;
  margin-top: 20px;
`
export const FeedbackTabsContent = styled.div`
  padding: 20px;
  padding-top: 0;
  background-color: #f7f8f9;
`
export const FeedbackTabs = styled(Tabs)`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
`
export const FeedbackTab = styled(Tab)`
  text-transform: none;
`
export const StyledTabs = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'rgb(246, 248, 249)',
  },
  indicator: {
    backgroundColor: '#039be5',
    height: '3px',
  },
})(Tabs)

export const TabsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: sticky;
  top: 58px;
`

export const StyledTabsSurvey = withStyles({
  indicator: {backgroundColor: '#f6f8f9', height: '3px'},
})(Tabs)

export const StyledTab = withStyles({
  root: {
    boxShadow: 'none',
    textTtransform: 'capitalize',
    padding: '8px 20px',
    minWidth: '120px',
    fontSize: '13px',
    fontWeight: '500',
    outline: 'none !important',
  },
})(Tab)

// export const StyledTab = styled(Tab)`
//   text-transform: capitalize;
//   font-family: ${props => props.theme.font};
// `

export const ArchiveContainer = styled.div`
  min-height: 75vh;
`
export const TitlePerforment = styled.div`
  width: 66px;
  height: 20px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #055469;
`

export const ListArchiveContainer = styled.div`
  margin: 0 1.5rem;
  justify-content: space-between;
  padding-top: 0.5rem;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
`
export const DetailArchiveContainer = styled.div``

export const TitleArchive = styled.div``

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const InformationContainer = styled.div`
  padding-bottom: 24px;
`

// export const StyledLinear = styled(LinearProgress)`
//   border-radius: 22px;
//   margin: 5px 1rem 10px 0;
//   background: white;
//   border: 1px solid lightgrey;
//   height: 10px;
//   width: 80%;
// `
export const StyledLinear = withStyles({
  root: {
    borderRadius: 22,
    margin: '5px 1rem 10px 0',
    background: 'white',
    border: '1px solid lightgrey',
    height: 10,
    width: '80%',
  },
  // bar1Determinate: {
  //   background: '#4caf50',
  // },
})(LinearProgress)

export const TabContent = styled.div`
  padding-top: 2rem;
`
export const DescriptionTabContainer = styled.div``

export const DescriptionWrapper = styled.div``

export const AttachmentWrapper = styled.div`
  padding-top: 2rem;
`

export const AttachmentImg = styled.img`
  width: 30%;
`
export const ActivityTabContainer = styled.div``

export const CommentAvatar = styled(Avatar)`
  width: 77px;
  height: 77px;
`
export const CommentWrapper = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  align-items: center;
`

export const CommentWrapperAct = styled.div`
  display: flex;
  width: 80%;
`

export const CommentDetailWrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
`
export const CommentChildrenAvatar = styled(Avatar)`
  width: 58px;
  height: 58px;
`
export const BtnAttachment = styled(Button)`
  background: #3b86ff;
  text-transform: capitalize;
  margin: 1rem 1rem 0 0;
`
export const MainMultiraterContainer = styled.div``

export const ListMultiraterContainer = styled.div`
  margin-top: 1rem;
`
export const GoalContainer = styled(Grid)`
  background-color: #f7f8f9;
  margin: 0;
  max-height: 42rem;
  overflow-y: auto;
`
export const FormElement = styled.div`
  width: 50%;
`

export const FilterAssignWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
`

export const StyledExpansionSummaryAssign = styled(ExpansionPanelSummary).attrs(
  {
    classes: {content: 'content'},
  }
)`
  .content {
    align-items: center;
    justify-content: space-between;
  }
`
export const FormChildButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
  padding-right: 1.6rem;
  padding-bottom: 1rem;
`
export const FeedbackListContainer = styled.div`
  height: 260px;
  overflow-y: auto;
  padding-top: 10px;
`
export const CardSummaryPlain = styled(Card)`
  padding: 18px;
  width: 27%;
  margin-top: 1rem;
`

export const TitleHoverable = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  width: max-content;
  cursor: pointer;
  padding: 8px 0 16px;
`

export const IconTitle = styled(Icon)`
  font-size: 2.3rem;
  padding-right: 12px;
`

export const BlueMenuItem = styled(MenuItem)`
  color: #014a62;
`

export const StyledBorderedExpansion = styled(ExpansionPanel)`
  margin-top: 12px;
  border: 1px solid #a9a8a8;
  border-radius: 5px;
`

export const QuickFilter = styled.p`
  width: 63px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  margin-right: 20px;
`
export const MyGoalsBtn = styled(Button)`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: #014a62;
  margin-right: 20px;
`
export const SpanMyGoals = styled.span`
  width: 100px;
  height: 15px;
  text-transform: initial;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`

export const DepartementGoalsBtn = styled(Button)`
  width: 150px;
  height: 35px;
  text-transform: initial;
  border-radius: 5px;
  background-color: #ffffff;
`

export const SpanDepartementGoals = styled.span`
  width: 150px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #014a62;
`
export const TitleContent = styled.p`
  width: 100%;
  margin-left: 15px;
  height: 15px;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #014a62;
`
export const TitleCardSummary = styled.p`
  width: 100%;
  height: 15px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
`

export const TitleCardSummaryContent = styled.p`
  width: 100%;
  height: 15px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #055469;
`

export const GoalsListBtn = styled(Button)`
  width: 70px;
  height: 30px;
  border-radius: 5px;
`

export const GoalProgressIndicator = styled.p`
  margin: 0px;
  text-align: right;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #707070;
`

export const CarouselCard = styled('div')`
  display: flex;
  overflow-x: scroll;
`

export const TextDeepTeal = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  float: right;
  color: #014a62;
`
