import gql from 'graphql-tag'

export const GET_LIST_STATUS_OVERTIME = gql`
  query($user: uuid) {
    people_work_overtimes(
      where: {
        status: {_eq: "approved"}
        people_work_placement: {user: {_eq: $user}}
      }
    ) {
      id
      people_work_placement {
        id
        user
        regno
      }
      datestart
      dateend
      convert_to
      time_overtime_policy {
        id
        name
        convert_to_leave
        convert_to_money
      }
    }
  }
`
