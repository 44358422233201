import React, {useState} from 'react'
import ListItem from '../../../shared-component/list/ListItem'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_LIST_REQUEST_OVERTIME} from '../../../../graphql/queries/overtime/getListActivity'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {generateDate, getApproalType} from '../../../../utils/helpers'
import ModalDetailWeb from '../../../shared-component/dialog/ModalDetailWeb'
import DetailList from '../../../shared-component/dialog/DetailList'
import {
  APPROVE_APPLY_OVERTIME,
  REJECT_APPLY_OVERTIME,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import EmptyState from '../../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../../assets/images/nodatalist.png'
import {PAGINATION_RANGE} from '../../../../utils/constants'
import {
  COMPANY_ID,
  POSITION_NAME,
  USER_ID,
  USER_NAME,
} from '../../../../utils/globals'
import ListSearcher from '../../../shared-component/list/ListSearcher'
import {generateFilter, listFilter} from '../../FIlterConfig'

export default function Request({salary}) {
  const [search, setSearch] = useState('')
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE[0],
    page: 0,
  })
  const [sort, setSort] = useState('desc')
  const [detail, setDetail] = useState({
    open: false,
    openWeb: false,
    data: null,
  })
  const [filter, setFilter] = useState({
    type: null,
    status: null,
    requester: null,
    filter_date_start: null,
    filter_date_end: null,
  })

  const [approveApplyOvertime] = useMutation(APPROVE_APPLY_OVERTIME)
  const [rejectApplyOvertime] = useMutation(REJECT_APPLY_OVERTIME)

  const {data: dataQuery, error, loading, refetch} = useQuery(
    GET_LIST_REQUEST_OVERTIME,
    {
      variables: {
        search: `%${search}%`,
        user: USER_ID,
        company: COMPANY_ID,
        sorting: sort,
        idPolicy: filter?.type,
        idRequester: filter?.requester,
        dateStart: filter?.filter_date_start,
        dateEnd: filter?.filter_date_end,
        status: filter?.status,
        limit: pagination.rowsPerPage,
        offset: pagination.page * pagination.rowsPerPage,
      },
    }
  )

  if (error) {
    console.error(error)
  }

  const handleClick = res => {
    setDetail({open: false, openWeb: true, data: res})
  }

  const handleApprove = data => {
    const approvalType = getApproalType(
      data,
      'approved',
      'time_overtime_policy'
    )
    const second = data.time_overtime_policy.second_job_profile
    const send = {
      id: detail.data.id,
      status:
        second === null || data.status === 'confirm1' ? 'approved' : 'confirm1',
      overtime_fields: {
        ...detail.data.overtime_fields,
        [approvalType]: {
          type: 'user',
          user: {
            id: USER_ID,
            name: USER_NAME,
            title: POSITION_NAME,
          },
        },
      },
    }
    approveApplyOvertime({
      variables: send,
    })
      .then(() => {
        enqueueSnackbar('Applied successfully Approved', {
          variant: 'success',
        })
        setDetail({...detail, openWeb: false, data: null})
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleReject = (text, id, data) => {
    const approvalType = getApproalType(
      data,
      'rejected',
      'time_overtime_policy'
    )
    const send = {
      id: id,
      changes: {
        status: 'rejected',
        overtime_fields: {
          ...detail.data.overtime_fields,
          reason: text,
          [approvalType]: {
            type: 'user',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      },
    }
    rejectApplyOvertime({
      variables: send,
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Applied successfully rejected', {
          variant: 'success',
        })
        setDetail({...detail, open: false, openWeb: false, data: null})
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleApplyFilter = dataFilter => {
    const filter = generateFilter(dataFilter[0])
    const _status = []

    for (const status of filter?.status || []) {
      if (status === 'waiting') _status.push('pending', 'confirm1')
      else _status.push(status)
    }

    setFilter({
      type: filter?.type,
      status: _status.length ? _status : null,
      requester: filter.requester,
      filter_date_start: filter?.date?.from
        ? filter?.date?.from + ' 00:00:00'
        : null,
      filter_date_end: filter?.date?.to ? filter?.date?.to + ' 23:59:59' : null,
    })
  }

  return (
    <>
      <div className="flex flex-col px-0 min-h-screen">
        <ListSearcher
          setSearch={setSearch}
          search={search}
          setSort={setSort}
          sort={sort}
          onApplyFilter={handleApplyFilter}
          listFilter={listFilter}
        />

        <div>
          {loading ? (
            <LoadingComponent />
          ) : !loading && dataQuery?.total.aggregate.count > 0 ? (
            dataQuery?.people_work_overtimes?.map((res, i) => {
              const startdate = generateDate(res.datestart)
              const enddate = generateDate(res.dateend)
              return (
                <ListItem
                  idDisplay={res.id_display}
                  onClick={() => handleClick(res)}
                  key={i}
                  number={res.id}
                  time={
                    startdate === enddate
                      ? startdate
                      : `${startdate} - ${enddate}`
                  }
                  name={res.people_work_placement.global_user.name}
                  submited={generateDate(res.date_added)}
                  status={res.status}
                  title={
                    res.time_overtime_policy && res.time_overtime_policy.name
                  }
                  confirm_type={
                    res.time_overtime_policy &&
                    res.time_overtime_policy.confirm_type
                  }
                  data={res}
                  from="overtime"
                />
              )
            })
          ) : (
            <EmptyState
              image={NoListImage}
              message1="Sorry, No List"
              message2="No overtime found"
            />
          )}
        </div>

        <TablePaginationStyled
          className="mt-auto"
          onChangePage={(e, newPage) => {
            e.preventDefault()
            setPagination({...pagination, page: newPage})
          }}
          onChangeRowsPerPage={e => {
            setPagination({
              ...pagination,
              page: 0,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }}
          rowsPerPageOptions={PAGINATION_RANGE}
          component="div"
          count={dataQuery && dataQuery.total.aggregate.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
        />

        <ModalDetailWeb
          title={detail?.data?.time_overtime_policy?.name}
          open={detail.openWeb}
          onClose={() =>
            setDetail({...detail, open: false, openWeb: false, data: null})
          }
          status={detail.data && detail.data.status}
          confirm_type={detail?.data?.time_overtime_policy?.confirm_type}
          data={detail.data}
          from="overtime"
        >
          <DetailList
            from="overtime"
            type="request"
            salary={salary}
            id={detail.data && detail.data.id}
            onClickApprove={data => handleApprove(data)}
            onCancel={() =>
              setDetail({...detail, open: false, openWeb: false, data: null})
            }
            onClickSubmitReject={(text, id, data) =>
              handleReject(text, id, data)
            }
          />
        </ModalDetailWeb>
      </div>
    </>
  )
}
