import gql from 'graphql-tag'

export const MUTATION_ADD_DOCUMENT = gql`
  mutation($objects: [people_files_insert_input!]!) {
    insert_people_files(objects: $objects) {
      returning {
        id
      }
    }
  }
`
export const MUTATION_DELETE_DOCUMENT = gql`
  mutation deleteFile($id: bigint) {
    update_people_files(where: {id: {_eq: $id}}, _set: {deleted_at: "now()"}) {
      affected_rows
    }
  }
`

export const MUTATION_EDIT_DOCUMENT = gql`
  mutation updateFileName($id: bigint, $name: String) {
    update_people_files(where: {id: {_eq: $id}}, _set: {name: $name}) {
      affected_rows
    }
  }
`
