import {
  dateAdd,
  dateSet,
  generateDate,
  isSameOrBefore,
  limitNumberWithinRange,
} from '../../../../utils/helpers'
import moment from 'moment'

const thisYear = new Date().getFullYear()

const getValidUntilForCarryOver = ({
  quota_reset_option,
  leftover_valid_until_month,
  quota_reset_option_date,
  quota_reset_option_month,
  period_year,
  start_date,
}) => {
  if (quota_reset_option === 1) {
    return dateAdd({
      date: `01-01-${period_year}`,
      amount: leftover_valid_until_month,
      unit: 'M',
      format: 'LL',
    })
  } else if (quota_reset_option === 2) {
    const date = dateSet({
      date: start_date,
      amount: period_year,
      unit: 'year',
      format: 'LL',
    })
    return dateAdd({
      date: date,
      amount: leftover_valid_until_month,
      unit: 'M',
      format: 'LL',
    })
  } else if (quota_reset_option === 3) {
    return dateAdd({
      date: `${quota_reset_option_month}-${quota_reset_option_date}-${period_year}`,
      amount: leftover_valid_until_month,
      unit: 'M',
      format: 'LL',
    })
  }
}

const getValidUntilForQuota = ({
  quota_reset_option,
  quota_reset_option_date,
  quota_reset_option_month,
  period_year,
  start_date,
}) => {
  if (quota_reset_option === 1) {
    const _date = `01-01-${period_year}`

    return {
      start_date: dateAdd({
        date: _date,
        amount: 0,
        unit: 'Y',
        format: 'LL',
      }),
      end_date: dateAdd({
        date: _date,
        amount: 1,
        unit: 'Y',
        format: 'LL',
      }),
    }
  } else if (quota_reset_option === 2) {
    let startDateAmount = ''
    let endDateAmount = ''

    const reducePeriod = () => {
      startDateAmount = period_year - 1
      endDateAmount = period_year
    }
    const addPeriod = () => {
      startDateAmount = period_year
      endDateAmount = period_year + 1
    }

    const periodLessThanYear = period_year < moment().year()
    const periodGreaterThanYear = period_year > moment().year()
    const periodSameWithYear = period_year === moment().year()

    const isDateAfterJoin = () => {
      const dayJoin = moment(start_date).day()
      const monthJoin = moment(start_date).month()
      const currentDay = moment().day()
      const currentMonth = moment().month()

      return dayJoin < currentDay && monthJoin <= currentMonth
    }

    /**
     * condition range quotas
     * p = period
     * y = year
     * c = current date
     * j = join date
     * c > j && p > y || c > j && p < j || c > j && p == y : add year period
     * c < j && p > y || c < j && p < j || c < j && p == y : reduce year period
     *
     * add period: startAmout = period_year, endAmount = period_year - 1
     * reduce period: startAmout = period_year - 1, endAmount = period_year
     */
    if (!periodSameWithYear && periodGreaterThanYear) {
      reducePeriod()
    }

    if (!periodSameWithYear && periodLessThanYear) {
      addPeriod()
    }

    if (isDateAfterJoin() && periodSameWithYear) {
      addPeriod()
    }

    if (!isDateAfterJoin() && periodSameWithYear) {
      reducePeriod()
    }

    return {
      start_date: dateSet({
        date: start_date,
        amount: startDateAmount,
        unit: 'year',
        format: 'LL',
      }),
      end_date: dateSet({
        date: start_date,
        amount: endDateAmount,
        unit: 'year',
        format: 'LL',
      }),
    }
  } else if (quota_reset_option === 3) {
    const _date = new Date(
      period_year,
      quota_reset_option_month - 1,
      quota_reset_option_date
    )

    const offset = _date > Date.now() ? -1 : 0

    return {
      start_date: dateAdd({
        date: _date,
        amount: offset,
        unit: 'Y',
        format: 'LL',
      }),
      end_date: dateAdd({
        date: _date,
        amount: offset + 1,
        unit: 'Y',
        format: 'LL',
      }),
    }
  }
}

export const getDataBalance = ({data, startDate}) => {
  const row = {
    ...data,
    quota: data.quota,
  }

  const carryOver = row?.people_work_leave_quotas?.[0]?.carry_over || 0
  const carryOverTaken =
    row?.people_work_leave_quotas?.[0]?.taken_carry_over || 0
  const periodYear = row?.people_work_leave_quotas?.[0]?.period_year || thisYear
  const takenCurrent = row?.people_work_leave_quotas?.[0]?.taken_current || 0
  const isAccrual = row?.time_leave_policy?.calc_method === 'accrual'
  const baseQuota = row.people_work_leave_quotas?.[0]?.quota
  const isProrate = row.time_leave_policy.quota_prorate_option
  const isUnlimited = row.infinite_quota
  const quotaResetOption = row.time_leave_policy.quota_reset_option
  const quotaResetMonth = row.time_leave_policy.quota_reset_option_month
  const quotaResetDate = row.time_leave_policy.quota_reset_option_date
  const leftOverValidUntil = row.time_leave_policy.leftover_valid_until_month

  const accrualQuota = getMonthlyAccuralQuota(row.quota, startDate)

  const initialQuota = isAccrual
    ? accrualQuota || 0
    : Math.floor(isProrate ? row.quota : baseQuota || row.quota)

  let quotas = initialQuota

  let remainingQuota = initialQuota - takenCurrent

  if (isUnlimited) {
    quotas = 'Unlimited'
    remainingQuota = 'Unlimited'
  } else {
    if (isProrate) {
      let yearReset = parseInt(moment(startDate).format('YYYY'))

      if (
        (quotaResetOption === 1 || quotaResetOption === 3) &&
        yearReset === thisYear
      ) {
        const joinMonth = moment(startDate).month() + 1
        if (joinMonth >= quotaResetMonth) {
          yearReset++
        }

        const nextReset = moment([
          yearReset,
          quotaResetMonth - 1,
          quotaResetDate,
        ])
        const monthDiff = Math.ceil(
          nextReset.diff(moment(startDate), 'months', true)
        )

        const _prorate = monthDiff / 12
        const totalProrate = _prorate * initialQuota

        quotas = Math.floor(totalProrate)
        remainingQuota = Math.floor(totalProrate - takenCurrent)
      }
    }
  }

  const valueBar = getValueBar({
    isInfinite: row.infinite_quota,
    takenCurrent: takenCurrent,
    totalQuota: isProrate || isAccrual ? quotas : row.quota,
  })

  const dateQuotaActive = getValidUntilForQuota({
    quota_reset_option: quotaResetOption,
    quota_reset_option_date: quotaResetDate,
    quota_reset_option_month: quotaResetMonth,
    period_year: periodYear,
    start_date: startDate,
  })

  const quotasCarryOver = carryOver
  const takenCurrentCarryOver = carryOverTaken
  const remainingQuotaCarryOver = quotasCarryOver - takenCurrentCarryOver
  const caryOverBar = (takenCurrentCarryOver / quotasCarryOver) * 100 || 0
  const valueBarCarryOver = caryOverBar > 100 ? 100 : caryOverBar
  const validUntilCarryOver = getValidUntilForCarryOver({
    quota_reset_option: quotaResetOption,
    leftover_valid_until_month: leftOverValidUntil,
    quota_reset_option_date: quotaResetDate,
    quota_reset_option_month: quotaResetMonth,
    period_year: periodYear,
    start_date: startDate,
  })

  const {isDeleted, deletedDate} = getDeletedByAdmin(data)

  return {
    isDeleted,
    deletedDate,

    quotas,
    remainingQuota,
    takenCurrent,
    valueBar,
    dateQuotaActive,

    quotasCarryOver,
    remainingQuotaCarryOver,
    takenCurrentCarryOver,
    valueBarCarryOver,
    validUntilCarryOver,
  }
}

const getDeletedDate = (date1, date2) => {
  if (date1 && date2)
    return generateDate(isSameOrBefore(date1, date2) ? date1 : date2)
  else return generateDate(date1 || date2)
}

const getDeletedByAdmin = child_policy => {
  const deletedChild = child_policy?.deletedAt
  const deletedParent = child_policy?.time_leave_policy?.deletedAt

  const isDeleted = deletedChild || deletedParent
  const deletedDate = isDeleted
    ? getDeletedDate(deletedChild, deletedParent)
    : null

  return {isDeleted, deletedDate}
}

/**
 * * getMonthlyAccuralQuota - Monthly Accural Quota Functions
 *
 * ex:
 * maksimal kuota pertahun ada 5, berarti kalo monthly accural itu 5/12=0.4
 * 0.4 kuota di tambah perbulannya, jadi di bulan ketiga totalnya menjadi 1.2
 *
 * * quota is reset every year in the month the user joins
 * * see: https://docs.google.com/spreadsheets/d/1yWPKwLOKks-AQkl4peRpu6MZgiIVm2J-DdpNGZ7419U/edit#gid=796791265
 *
 * @param {number} totalQuota - maximum policy quota
 * @param {string} peopleJoinDate - people joined date
 * @returns {number} - the quota obtained this month
 */
const getMonthlyAccuralQuota = (totalQuota, peopleJoinDate) => {
  const quotaPerMonth = totalQuota / 12
  const firstDayJoindate = moment(peopleJoinDate)
    .startOf('month')
    .format('YYYY-MM-DD')

  const now = moment()
  const year = now.format('Y')
  const month = now.format('M')
  const day = now.format('D')

  const monthDiff = Math.ceil(
    moment([year, month - 1, day]).diff(firstDayJoindate, 'months', true)
  )

  const multiplier = getMultiplierAccual(monthDiff - 1)

  return Math.floor(quotaPerMonth * multiplier * 10) / 10
}

/**
 * function used to get quota multiplier
 * @param {number} monthDiff - month difference between join date and current month
 * if join month August and current month August => monthDiff: 0
 * if join month July and current month August => monthDiff: 1
 * if join month June and current month August => monthDiff: 2
 * @returns {number} - quota multiplier every month
 */
const getMultiplierAccual = monthDiff => {
  const enhancer = monthDiff >= 12 ? 1 : 0
  return (monthDiff % 12) + enhancer
}

const getValueBar = ({isInfinite, takenCurrent, totalQuota}) => {
  if (isInfinite) {
    return 100
  }

  if (totalQuota === 0) {
    return 0
  }

  const result = (takenCurrent / totalQuota) * 100
  return limitNumberWithinRange(result)
}
