import React from 'react'
import {FormControlStyled} from '../../../components/form/FormStyles'
import DatePicker from '../../shared-component/date/DatePicker'
import FormWrapper from '../../shared-component/form/FormWrapper'
import FormWrapperInline from '../../shared-component/form/FormWrapperInline'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import {getDurationInDestination} from '../trip-shared-component/trip-helper'

import BudgetStrictComponent from './BudgetStrictComponent'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {ExchangeRateDisplay} from '../trip-shared-component/TableDetailTrip'

const isError = value => [null, '', undefined].includes(value)

export const LINE_STYLE = {
  borderRight: '1px solid rgba(0, 0, 0, 0.27)',
  paddingRight: 10,
  display: 'flex',
  alignItems: 'center',
  height: 42,
}

const BudgetItem = ({
  from,
  to,
  error,
  budget,
  policy,
  destination,
  budget_component_option,
  onChangeNominal,
  onChangeDestinationDate,
  values,
  setValues,
  smallSize,
}) => {
  const handleChangeDate = (e, target) => {
    if (target === 'start_date') {
      e && e.setHours(0, 0, 0, 0)
    } else if (target === 'end_date') {
      e && e.setHours(23, 59, 59, 999)
    }
    onChangeDestinationDate(e, target)
  }

  const currencyFrom = policy.business_trip_policy.currency_from
  const currencyTo = policy.business_trip_policy.currency_to

  return (
    <FormControlStyled fullWidth className="w-full mb-4" size="small">
      <div className="mt-2 text-xs">
        <p className="text-primary font-semibold">
          {from} - {to}
        </p>

        {currencyFrom !== currencyTo && (
          <p className="text-gray-600">
            <ExchangeRateDisplay from={currencyTo} to={currencyFrom} />
          </p>
        )}
      </div>

      <div className="flex flex-row w-full justify-between">
        <FormWrapperInline
          className={smallSize ? '' : 'mr-4'}
          style={{width: smallSize ? '48%' : '50%'}}
        >
          <DatePicker
            placeholder="Add Start Date"
            className="mt-2"
            inputProps={{className: 'text-xs'}}
            value={destination.start_date}
            onChange={e => handleChangeDate(e, 'start_date')}
            error={error && isError(destination.start_date)}
            minDate={values.start_date}
            maxDate={destination.end_date || values.end_date}
            helperText={
              error &&
              isError(destination.start_date) &&
              'This field is required'
            }
          />
        </FormWrapperInline>
        <FormWrapperInline style={{width: smallSize ? '48%' : '50%'}}>
          <DatePicker
            wrapperClassName="mb-auto"
            placeholder="Add End Date"
            inputProps={{className: 'text-xs'}}
            value={destination.end_date}
            onChange={e => handleChangeDate(e, 'end_date')}
            error={error && isError(destination.end_date)}
            minDate={destination.start_date || values.start_date}
            maxDate={values.end_date}
            helperText={
              error && isError(destination.end_date) && 'This field is required'
            }
          />
        </FormWrapperInline>
      </div>
      {budget?.map(res => {
        return (
          <BudgetStrictComponent
            key={res.id}
            policy={policy}
            destination={destination}
            nominal={res.nominal}
            name={res?.type?.label}
            description={res?.type?.description}
            values={values}
            setValues={setValues}
            budget_component_option={budget_component_option}
            budget_component_type={res.budget_component_type}
            budgetCalculation={res.budget_calculation}
            onChangeNominal={e => onChangeNominal(res, e.target.value)}
          />
        )
      })}
    </FormControlStyled>
  )
}

export default function FormBudget({
  smallSize,
  error,
  values,
  setValues,
  budget_component_option,
}) {
  const handleAddBudgetComponent = selectedDestination => {
    setValues(prev => {
      const _prevDestination = [...prev.destination]
      const budget = [...selectedDestination.budget]
      budget.push({id: Date.now(), type: null, nominal: 0})
      const _obj = {...selectedDestination, budget}

      const destination = _prevDestination.map(
        obj => [_obj].find(o => o.id === obj.id) || obj
      )

      return {
        ...prev,
        destination,
      }
    })
  }

  const updateBudgetComponent = ({
    prev,
    selectedDestination,
    value,
    component,
    target,
  }) => {
    const _prevDestination = [...prev.destination]
    const _prevBudget = [...selectedDestination.budget]
    const _selectedBudget = _prevBudget.filter(
      budget => budget.id === component.id
    )

    const _obj = {..._selectedBudget[0], [target]: value}
    const _budget = _prevBudget.map(
      obj => [_obj].find(o => o.id === obj.id) || obj
    )

    const _objDestination = {
      ...selectedDestination,
      budget: _budget,
    }
    const destination = _prevDestination.map(
      obj => [_objDestination].find(o => o.id === obj.id) || obj
    )

    return destination
  }

  const handleChangeNominal = (selectedDestination, component, value) => {
    const numberOnly = /^\d+$/
    const nominal = Number(value.replace(/[^0-9,-]+/g, ''))
    if (numberOnly.test(nominal)) {
      setValues(prev => {
        const destination = updateBudgetComponent({
          prev,
          selectedDestination,
          value: nominal,
          component,
          target: 'nominal',
        })
        return {...prev, destination}
      })
    }
  }

  const handleChangeDestinationDate = (selectedDestination, date, target) => {
    setValues(prev => {
      const _prevDestination = [...prev.destination]
      const _prevSelectedDestination = [...prev.destination].filter(
        res => res.id === selectedDestination.id
      )

      const {start_date, end_date} = _prevSelectedDestination[0]
      const duration = getDurationInDestination({
        date,
        start_date,
        end_date,
        target,
      })

      const _obj = {
        ..._prevSelectedDestination[0],
        [target]: date,
        duration,
      }

      const destination = _prevDestination.map(
        obj => [_obj].find(o => o.id === obj.id) || obj
      )

      return {
        ...prev,
        destination,
      }
    })
  }

  return (
    <FormWrapper number={!smallSize && 7} title={<TitleBudget />}>
      {values.destination.map(res => {
        return (
          <BudgetItem
            key={res.id}
            error={error}
            from={res.name.from}
            to={res.name.to}
            budget={res.budget}
            policy={values.policy}
            destination={res}
            smallSize={smallSize}
            budget_component_option={budget_component_option}
            onAddBudgetComponent={() => handleAddBudgetComponent(res)}
            onChangeDestinationDate={(date, target) =>
              handleChangeDestinationDate(res, date, target)
            }
            onChangeNominal={(component, value) =>
              handleChangeNominal(res, component, value)
            }
            values={values}
            setValues={setValues}
          />
        )
      })}
    </FormWrapper>
  )
}

const TitleBudget = () => {
  return (
    <div>
      Budget*
      <InfoFileTooltip
        title={
          <span className="text-xs">
            Budget will be used daily for the whole trip and only used in the
            destination. All budget entered will accommodate all employee needs
            while at the destination including tickets for return and departure
          </span>
        }
        placement="right"
        className="ml-2"
      >
        <InfoOutlinedIcon className="text-xs text-primary" />
      </InfoFileTooltip>
    </div>
  )
}
