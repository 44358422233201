import React from 'react'
import Select from "react-select";
import {
  customSelect,
  DropdownIndicator,
  DropdownIndicatorSearch,
  SingleValue,
  SingleValuePlain,
  MultiValue,
} from "./customSelect";

export const SelectStyled = (props) => {
  return (
    <Select
      styles={customSelect(props)}
      components={{
        DropdownIndicator: props.indicatorSearch
          ? DropdownIndicatorSearch
          : DropdownIndicator,
        MultiValue,
        SingleValue: props.singleValuePlain ? SingleValuePlain : SingleValue,
      }}
      {...props}
    />
  );
};
