import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {StyledDialog} from '../../../components/modal/ModalStyles'

export default function ModalDetailAtt({title, open, onClose, ...child}) {
  return (
    <StyledDialog
      classes={{paper: 'w-693-px'}}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="body"
    >
      <div className="flex flex-col">
        <div className="flex flex-row justify-between p-6 bg-wlbgrey items-center">
          <div className="flex flex-row items-center">
            <h2 className="font-semibold ">{title}</h2>
          </div>
          <CloseIcon className="cursor-pointer" onClick={onClose} />
        </div>

        <div {...child} />
      </div>
    </StyledDialog>
  )
}
