import React from 'react'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'

export default function FormWrapperInline({
  title,
  cond = true,
  style,
  ...child
}) {
  const generateStyle = style => {
    if (style) {
      return style
    } else {
      return {width: 226}
    }
  }
  return (
    <div style={generateStyle(style)} className={`flex flex-col`}>
      {cond ? (
        <TypographyStyled className="font-semibold" size="12px">
          {title}
        </TypographyStyled>
      ) : null}
      <div className={`${cond ? 'mt-2' : 'mt-auto'}`} {...child} />
    </div>
  )
}
