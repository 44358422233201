import {calculateBudget} from '../trip/trip-shared-component/trip-helper'

export const getRemainingMoney = destinations => {
  let leftoverCost = 0

  if (destinations) {
    for (const destination of destinations) {
      for (const budget of destination.budget) {
        const budgetCost = calculateBudget(destination, budget)
        const realizedCost = budgetCost - (budget.realization || 0)

        leftoverCost += realizedCost
      }
    }
  }

  return leftoverCost
}

export const getRemainingState = values => {
  const nmbr = Number(values)
  return nmbr === 0 ? 'NEUTRAL' : nmbr > 0 ? 'POSITIVE' : 'NEGATIVE'
}

export const PAYMENT_METHOD = {
  POSITIVE: {
    message: 'How will you return this money?',
    options: [
      {label: 'Return Directly', value: 'manual'},
      {label: 'Cut it from Salary', value: 'payroll'},
    ],
    type: {
      manual: 'Returned Directly',
      payroll: 'Cut from Salary',
    },
  },
  NEGATIVE: {
    message: 'How should the company pay for the shortfall?',
    options: [
      {label: 'Pay Directly', value: 'manual'},
      {label: 'Claim it by Reimbursement', value: 'claim'},
      {label: 'Add it Up to Salary', value: 'payroll'},
    ],
    type: {
      manual: 'Pay Directly',
      claim: 'Claimed by Reimbursement',
      payroll: 'Add it Up to Salary',
    },
  },
  NEUTRAL: {
    message: 'The money given is fully used and no shortfall',
    options: [],
  },
}

export const updateBudgetComponent = ({
  prev,
  selectedDestination,
  value,
  component,
  target,
}) => {
  const _prevDestination = [...prev.destination]
  const _prevBudget = [...selectedDestination.budget]
  const _selectedBudget = _prevBudget.filter(
    budget => budget.id === component.id
  )

  const _obj = {..._selectedBudget[0], [target]: value}
  const _budget = _prevBudget.map(
    obj => [_obj].find(o => o.id === obj.id) || obj
  )

  const _objDestination = {
    ...selectedDestination,
    budget: _budget,
  }
  const destination = _prevDestination.map(
    obj => [_objDestination].find(o => o.id === obj.id) || obj
  )

  return destination
}

export const getPrevAttachment = ({
  prevState,
  selectedDestination,
  budgetData,
}) => {
  const foundDestinationIndex = {...prevState}.destination.findIndex(
    x => x.id === selectedDestination.id
  )
  const foundBudgetIndex = selectedDestination?.budget?.findIndex(
    x => x.id === budgetData.id
  )
  const prevAttachments =
    prevState.destination?.[foundDestinationIndex]?.budget?.[foundBudgetIndex]
      ?.attachment
  return prevAttachments
}

export const setupRealization = values => {
  const budgetRealization = values?.destination
    ?.map(destination => {
      return destination?.budget?.map(budget => {
        return {
          id: budget.id,
          realization: budget.realization || 0,
          attachment: budget?.attachment?.map(attachment => {
            return {
              url: attachment.url,
              name: attachment.name,
              size: attachment.size,
              ...attachment,
            }
          }),
        }
      })
    })
    .flat()
  return budgetRealization
}

export const getErrorForm = values => {
  let isError = false

  const notNull = ['trip_id', 'trip_id_display', 'budget_action']

  notNull.forEach(field => {
    if (!values[field]) {
      isError = true
    }
  })

  for (
    let destinationIdx = 0;
    destinationIdx < values?.destination?.length;
    destinationIdx++
  ) {
    const destination = values?.destination?.[destinationIdx]
    for (
      let budgetIdx = 0;
      budgetIdx < destination?.budget?.length;
      budgetIdx++
    ) {
      const budget = destination?.budget?.[budgetIdx]
      if (!budget?.attachment?.[0]) {
        isError = true
      }
    }
  }

  return isError
}
