import React, {memo} from 'react'
import CheckIcon from '@material-ui/icons/Check'

export default memo(function IconFilterActive({isFiltered}) {
  if (isFiltered) {
    return (
      <div className="flex items-center">
        <CheckIcon className="text-sm font-semibold" />
      </div>
    )
  }
  
})
