import React from 'react'
import TableDetailTrip from './TableDetailTrip'
import TableDetailTotalTrip from './TableDetailTotalTrip'
import {
  getUserApproval,
  getDestination,
  getDestinationDate,
  getTripDate,
  getUserRejectorTrip,
} from './trip-helper'

import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import ModalDetail from '../../shared-component/dialog/ModalDetail'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import {AttachmentCard} from '@smartasn/wlb-utils-components'
import {FormattedDate} from '../../../utils/helpers'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {Button, useMediaQuery, useTheme} from '@material-ui/core'

const color = {
  pending: 'text-warning',
  confirm1: 'text-warning',
  confirm2: 'text-warning',
  processing: 'text-wlbbrown',
  rejected: 'text-danger',
  cancelled: 'text-disable',
  approved: 'text-success',
}

const textStatus = {
  pending: 'Waiting',
  confirm1: 'Waiting',
  confirm2: 'Waiting',
  processing: 'Processing',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  approved: 'Approved',
}

export default function DetailListTrip({
  open,
  onClose,
  onClickMoreAttachment,
  data,
  loading,
  onCancel,
  onDonwloadSubmission,
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const PADDING_SECTION = 'p-6'

  return (
    <ModalDetail
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      maxWidth="md"
      title="Trip Detail"
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className={PADDING_SECTION}>
            <ItemDetail fullScreen={fullScreen} label="Request ID">
              {data.id_display}
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Name">
              {data.globalUserByAddedBy.name}
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Position">
              {
                data.peopleWorkPlacementByAddedByWorkId.company_job_profile
                  .title
              }
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Submission Date">
              {FormattedDate(data.date_added, 'MMM dd, yyyy')}
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Status">
              {getDataStatus(data)}
            </ItemDetail>
          </div>

          <div style={{padding: 2}} className="bg-wlbgrey" />

          <div className={PADDING_SECTION}>
            <div className="font-semibold text-sm text-primary mb-4">
              Trip Detail
            </div>

            <SectionDetail title="Trip Information">
              <ItemDetail
                semibold={false}
                fullScreen={fullScreen}
                label="Participants"
              >
                <div className="flex flex-col">
                  <span className="mb-1 font-semibold text-sm text-secondary">
                    {data.participant_name?.name}
                  </span>
                  {data.participant_position?.regno && (
                    <span className="mb-1 text-sm">
                      {data.participant_position?.regno}
                    </span>
                  )}
                  {data.participant_position?.company_profile && (
                    <span className="text-sm text-disable">
                      {data.participant_position?.company_job_profile?.title} at{' '}
                      {data.participant_position?.company_profile?.legal_name}
                    </span>
                  )}
                </div>
              </ItemDetail>
              <ItemDetail fullScreen={fullScreen} label="Description">
                {data.description}
              </ItemDetail>

              {data.status === 'rejected' && (
                <ItemDetail fullScreen={fullScreen} label="Reject Statement">
                  {data.business_trip_fields.reason_reject}
                </ItemDetail>
              )}

              <ItemDetail fullScreen={fullScreen} label="Attachment">
                <div className="flex flex-row flex-wrap">
                  {data.attachment.length === 0 && '-'}
                  {data.attachment.map((file, idx) => (
                    <AttachmentCard key={idx} name={file.name} url={file.url} />
                  ))}
                </div>
              </ItemDetail>
            </SectionDetail>

            <SectionDetail title="Destination">
              <ItemDetail fullScreen={fullScreen} label="Date">
                {getTripDate(data.date_start, data.date_end)}
              </ItemDetail>
              <ItemDetail fullScreen={fullScreen} label="Destination">
                {getDestination(data.destination)}
              </ItemDetail>
            </SectionDetail>

            <SectionDetail title={<BudgetTitle />}>
              {data.destination.map(res => {
                return (
                  <TableDetailTrip
                    key={res.id}
                    data={res}
                    status={data.status}
                    onClickMoreAttachment={onClickMoreAttachment}
                    totalDestination={data.destination.length}
                    currencyFrom={data.business_trip_policy.currency_from}
                    currencyTo={data.currency}
                    approvedDate={data.approved_date}
                    date={getDestinationDate(
                      res.destination_start_date,
                      res.destination_end_date
                    )}
                    destination={`${res.globalDestinationByFrom.name} - ${res.globalDestinationByTo.name}`}
                  />
                )
              })}
              {data.destination.length > 1 && (
                <TableDetailTotalTrip
                  fullScreen={fullScreen}
                  currencyFrom={data.business_trip_policy.currency_from}
                  currencyTo={data.currency}
                  approvedDate={data.approved_date}
                  data={data}
                />
              )}
            </SectionDetail>
          </div>

          <div className="pb-6 px-6 flex justify-center gap-6">
            {['pending', 'confirm1', 'confirm2'].includes(data.status) && (
              <Button
                onClick={onCancel}
                variant="outlined"
                className="px-12 py-2"
                style={{
                  border: '1px solid #ef4d5e',
                  color: '#ef4d5e',
                  outline: 'none',
                }}
              >
                Cancel Trip
              </Button>
            )}
            {onDonwloadSubmission && (
              <Button
                variant="contained"
                disableElevation
                className="px-5 py-2"
                color="primary"
                onClick={() => onDonwloadSubmission(data)}
              >
                Download Trip Submission
              </Button>
            )}
          </div>
        </>
      )}
    </ModalDetail>
  )
}

export const ItemDetail = ({label, fullScreen, semibold = true, ...value}) => {
  return (
    <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-3`}>
      <p
        className={`${
          fullScreen ? 'text-sm' : 'font-semibold text-sm'
        } text-disable`}
        style={{width: 158, paddingRight: 28}}
      >
        {label}
      </p>
      <div
        className={`${fullScreen ? 'mb-4 text-sm' : 'text-sm'} ${
          semibold ? ' font-semibold' : ''
        }`}
        style={{maxWidth: 756}}
        {...value}
      />
    </div>
  )
}

export const SectionDetail = ({title, children}) => {
  return (
    <div className="mb-6">
      <div className="font-semibold text-sm mb-4">{title}</div>
      {children}
    </div>
  )
}

export const getDataStatus = data => {
  const {
    status,
    business_trip_policy,
    participant_position,
    globalUserByApprovedBy,
    business_trip_fields,
    globalUserByCancelledBy,
  } = data || {}
  const {
    confirm_type,
    companyJobProfileByFirstConfirmProfile,
    companyJobProfileBySecondConfirmProfile,
    companyJobProfileByThirdConfirmProfile,
  } = business_trip_policy || {}

  switch (status) {
    case 'approved':
    case 'cancelled': {
      const globalUserUpdater = {
        approved: globalUserByApprovedBy,
        cancelled: globalUserByCancelledBy,
      }

      const {name} = globalUserUpdater[status] || {}
      const {title} =
        globalUserUpdater[status]?.people_work_placements?.[0]
          ?.company_job_profile || {}

      return <StatusDetailTouched status={status} name={name} title={title} />
    }
    case 'rejected': {
      const {name, title} = getUserRejectorTrip(business_trip_fields)

      return <StatusDetailTouched status={status} name={name} title={title} />
    }
    case 'processing':
      return (
        <>
          <span className={color[status]}>{textStatus[status]}</span>{' '}
          <span>Trip Budget</span>
        </>
      )
    case 'pending':
      if (confirm_type === 1) {
        const supervisor =
          participant_position?.company_job_profile?.job_profile_supervisor
        const isApproveByAdmin =
          supervisor?.people_work_placements?.length === 0

        // TODO: check origin from SPV
        const {name, title} = getUserApproval(supervisor)

        return (
          <StatusDetailWaiting
            status={status}
            title={title}
            name={name}
            isApproveByAdmin={isApproveByAdmin}
          />
        )
      } else if (confirm_type === 2) {
        const isApproveByAdmin =
          companyJobProfileByFirstConfirmProfile?.people_work_placements
            ?.length === 0

        // TODO: check origin from first position approval
        const {title} = getUserApproval(companyJobProfileByFirstConfirmProfile)

        return (
          <StatusDetailWaiting
            status={status}
            title={title}
            isApproveByAdmin={isApproveByAdmin}
          />
        )
      }
      break
    case 'confirm1': {
      const isApproveByAdmin =
        companyJobProfileBySecondConfirmProfile?.people_work_placements
          ?.length === 0

      // TODO: check origin from second position approval
      const {title} = getUserApproval(companyJobProfileBySecondConfirmProfile)

      return (
        <StatusDetailWaiting
          status={status}
          title={title}
          isApproveByAdmin={isApproveByAdmin}
        />
      )
    }
    case 'confirm2': {
      const isApproveByAdmin =
        companyJobProfileByThirdConfirmProfile?.people_work_placements
          ?.length === 0

      const {title} = getUserApproval(companyJobProfileByThirdConfirmProfile)

      return (
        <StatusDetailWaiting
          status={status}
          title={title}
          isApproveByAdmin={isApproveByAdmin}
        />
      )
    }
    default:
      break
  }
}

const StatusDetailWaiting = ({status, title, isApproveByAdmin, name}) => {
  return (
    <>
      <div>
        <span className={color[status]}>{textStatus[status]}</span> for
        <span className="text-secondary">
          {' '}
          {name} {title}
        </span>
        <span> approval</span>
      </div>
      <div className={`mt-3 ${isApproveByAdmin ? 'block' : 'hidden'}`}>
        <span>Your application will be approved by administrator</span>
      </div>
    </>
  )
}

const StatusDetailTouched = ({status, name, title}) => (
  <>
    <span className={color[status]}>{textStatus[status]}</span> by{' '}
    <span className="text-secondary">
      {name} {title}
    </span>
  </>
)

export const BudgetTitle = () => {
  return (
    <div>
      Budget
      <InfoFileTooltip
        title={
          <span className="text-xs">
            Budget will be used daily for the whole trip and only used in the
            destination
          </span>
        }
        placement="right"
        className="ml-3"
      >
        <InfoOutlinedIcon className="text-xs text-primary" />
      </InfoFileTooltip>
    </div>
  )
}
