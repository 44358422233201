import moment from 'moment'
import {useState} from 'react'
import {convertTimezone} from '../../utils/helpers'

export const asMinutes = (time, initial = 0) => {
  return moment.duration(time).asMinutes() - initial
}

export const useDiferencesTime = () => {
  const [time, setTime] = useState({
    hours: undefined,
    minutes: undefined,
  })

  const getDiference = (time, initial = 0) => {
    const difference =
      (asMinutes(time, initial) - asMinutes(moment().format('HH:mm'))) / 60
    setTime({
      hours: Math.floor(Math.abs(difference)),
      minutes: Math.round((Math.abs(difference) % 1) * 60),
    })
  }

  return [time.hours, time.minutes, getDiference]
}

export const getTimeInOut = (datetime, timezoneName, format = {}) => {
  if (datetime) {
    const dateConverted = convertTimezone({
      date: datetime,
      timezoneName: timezoneName,
    })
    const selectedDate = moment(dateConverted)
    const date = selectedDate.format(format.dateFormat || 'll')
    const time = selectedDate.format(format.timeFormat || 'HH:mm')
    return [date, time]
  } else return ['-', '-']
}

export const getInOutDateDetail = details => {
  const policy = details.time_attendance_policy

  const config = policy?.mobile_setting
  const tz = config?.timezoneName

  const [dateIn] = getTimeInOut(details.clock_in, tz, {
    dateFormat: 'dddd, MMMM D YYYY, HH:mm',
  })
  const [dateOut] = getTimeInOut(details.clock_out, tz, {
    dateFormat: 'dddd, MMMM D YYYY, HH:mm',
  })

  return {
    checkin: dateIn,
    checkout: dateOut,
  }
}

export const getGMT = timezone => {
  if (timezone == null) {
    return `GMT+7`
  }

  return timezone > 0 ? `GMT+${timezone}` : `GMT${timezone}`
}
