import gql from 'graphql-tag'

export const MUTATION_ADD_BANK = gql`
  mutation addAccountBank($object: [people_profile_banks_insert_input!]!) {
    insert_people_profile_banks(objects: $object) {
      affected_rows
    }
  }
`

export const MUTATION_EDIT_BANK = gql`
  mutation update_people_profile_banks(
    $id: Int
    $changes: people_profile_banks_set_input
  ) {
    update_people_profile_banks(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`

export const MUTATION_INSERT_DELETE_TAX = gql`
  mutation($user: String, $bpjskes: String, $npwp: String, $bpjstk: String) {
    addTaxUser(user: $user, bpjskes: $bpjskes, npwp: $npwp, bpjstk: $bpjstk) {
      affected_rows
    }
  }
`

export const MUTATION_EDIT_TAX = gql`
  mutation update_people_identities(
    $id_NPWP: String
    $changes_NPWP: people_identities_set_input
    $id_BPJSKes: String
    $changes_BPJSKes: people_identities_set_input
    $id_BPJSTK: String
    $changes_BPJSTK: people_identities_set_input
  ) {
    update_NPWP: update_people_identities(
      where: {id: {_eq: $id_NPWP}}
      _set: $changes_NPWP
    ) {
      affected_rows
    }

    update_BPJSKes: update_people_identities(
      where: {id: {_eq: $id_BPJSKes}}
      _set: $changes_BPJSKes
    ) {
      affected_rows
    }

    update_BPJSTK: update_people_identities(
      where: {id: {_eq: $id_BPJSTK}}
      _set: $changes_BPJSTK
    ) {
      affected_rows
    }
  }
`
