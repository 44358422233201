import React, {useEffect, useState} from 'react'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {
  Paper,
  Button,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import {IconStyled} from '../../../components/Icons/IconComponent'
import moment from 'moment'
import Geocode from 'react-geocode'
import axios from 'axios'
import {UPLOAD_URL, TOKEN} from '../../../utils/globals'
import {useSnackbar} from 'notistack'
import {useMutation} from '@apollo/react-hooks'
import {MUTATION_CHECKIN} from '../../../graphql/mutations'
import {getFullFormatDateNow} from '../../../utils/helpers'
import ModalDetailMap from './ModalDetailMap'
import NotificationWLB from '../../shared-component/dialog/NotificationWLB'
import * as faceapi from 'face-api.js'

function FormCheckin({
  imgsrc,
  dataAtt,
  close,
  refetch,
  idWork,
  // refetchAttendance,
  setOpenCekin,
  setopenNotif,
  getTime,
  setImgsrc,
}) {
  const {enqueueSnackbar} = useSnackbar()
  const [coord, setCoord] = useState(null)
  const [outsideLocation, setOutsideLocation] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [mutationCheckIn] = useMutation(MUTATION_CHECKIN)

  const isFlexi = dataAtt.dataPolicy.flexi === 1

  const identifyFace = () => {
    setLoading(true)
    const img = new Image()
    img.src = imgsrc

    faceapi
      .loadTinyFaceDetectorModel(
        'https://raw.githubusercontent.com/justadudewhohacks/face-api.js-models/master/tiny_face_detector/tiny_face_detector_model-weights_manifest.json'
      )
      .then(() => {
        faceapi
          .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
          .then(result => {
            if (!result) {
              enqueueSnackbar('Unidentified selfie, please retake selfie', {
                variant: 'error',
              })
              setImgsrc(null)
            }
          })
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Something went wrong when recognizing face', {
          variant: 'error',
        })
      })
      .finally(() => setLoading(false))
  }

  const getLocation = () => {
    setCoord(null)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(response => {
          const address = response.results[0].formatted_address
          setCoord({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            address,
          })
        })
      })
    } else {
      enqueueSnackbar('Please enable location permission', {variant: 'warning'})
    }
  }

  useEffect(() => {
    getLocation()
    if (imgsrc) {
      identifyFace()
    }
  }, [imgsrc])

  // eslint-disable-next-line no-unused-vars
  const checkInNow = (lateMinutes, includePhoto = true, isFlexible) => {
    setLoading(true)

    let promise = Promise.resolve(null)

    if (includePhoto && imgsrc) {
      // converting base64 data URLs to Uint8Array to pass into File object
      const [header, content] = imgsrc.split(',')

      const mime = header.match(/:(.+?);/)[1]
      const bstr = atob(content)

      const length = bstr.length
      const u8 = new Uint8Array(length)

      for (let idx = 0; idx < length; idx++) {
        u8[idx] = bstr.charCodeAt(idx)
      }

      const d = new Date()
      const name = `${d.getDate()}${d.getMonth()}${d.getFullYear()}-${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`

      const file = new File([u8], 'Attendance-' + name, {type: mime})

      const formData = new FormData()
      formData.append('file', file)

      const prom = axios.post(UPLOAD_URL, formData, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      })

      promise = prom.then(res => res.data.url)
    }

    promise.then(
      photoUrl => {
        const variables = {
          userWorkId: idWork.people_work_placements[0].id,
          policyId: dataAtt.id,
          clockIn: getFullFormatDateNow(),
          location: {
            lat: coord.lat,
            lng: coord.lng,
            address: coord.address,
          },
          photo: photoUrl,
          lateMinutes: isFlexible ? null : lateMinutes,
          description: '-',
          ...(dataAtt.dataPolicy.location_option === 2
            ? {}
            : {
                locationId: dataAtt.location[0].id,
              }),
          // locationId:
          //   dataAtt.location.length === 0 ? null : dataAtt.location[0].id,
        }

        const prom = mutationCheckIn({variables})

        prom.then(
          () => {
            setLoading(false)
            enqueueSnackbar(`Check-In successful`, {variant: 'success'})

            close()
            refetch()
          },
          () => {
            setLoading(false)
            enqueueSnackbar(`Failed to check-in`, {variant: 'error'})
          }
        )
      },
      () => {
        setLoading(false)
        enqueueSnackbar(`Failed to check-in`, {variant: 'error'})
      }
    )
  }

  function measure(lat1, lon1, lat2, lon2) {
    // generally used geo measurement function
    const R = 6378.137 // Radius of earth in KM
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c
    return d * 1000 // meters
  }

  const isOutsideLocation = location => {
    let result = true
    if (location.length > 0) {
      location.forEach(loc => {
        const {location, location_radius} = loc.time_attendance_location
        if (
          measure(location.lat, location.lng, coord.lat, coord.lng) <=
          location_radius
        ) {
          result = false
        }
      })
    } else {
      result = false
    }
    return result
  }

  const onSubmitAttendance = lateMinutes => {
    if (
      dataAtt.dataPolicy.location_option === 1 &&
      isOutsideLocation(dataAtt.location)
    ) {
      setOutsideLocation(true)
    } else {
      if (dataAtt.dataPolicy.flexi !== 1) {
        const format = 'HH:mm'
        const time = moment().format(format)
        const time2 = moment()
          .add(dataAtt.dataPolicy.mobile_setting.initLimitClockIn, 'minutes')
          .format(format)
        const waktuTidakDitambah = moment.duration(time).asHours()
        const waktuDitambah = moment.duration(time2).asHours()
        const waktuIn = moment.duration(dataAtt.clockIn).asHours()
        const waktuOut = moment.duration(dataAtt.clockOut).asHours()
        if (waktuTidakDitambah < waktuOut && waktuDitambah > waktuIn) {
          checkInNow(
            lateMinutes,
            dataAtt.dataPolicy.mobile_setting.isIncludePhoto,
            false
          )
        } else {
          if (waktuTidakDitambah < waktuOut) {
            // untuk jadwal belum mulai
            getTime(
              dataAtt.clockIn,
              dataAtt.dataPolicy.mobile_setting.initLimitClockIn
            )
            setOpenCekin(false)
            setopenNotif(prev => ({...prev, toEarlyCheckin: true}))
          } else if (waktuDitambah > waktuIn) {
            getTime(dataAtt.clockOut, 0)
            // untuk jadwal sudah selesai
            setOpenCekin(false)
            setopenNotif(prev => ({...prev, outsideCheckIn: true}))
          }
        }
      } else {
        checkInNow(
          lateMinutes,
          dataAtt.dataPolicy.mobile_setting.isIncludePhoto,
          true
        )
        // console.log('FLEXIBLE')
      }
    }
  }

  const startTime = moment(new Date(), 'HH:mm:ss a')
  const scheduleIn = moment(dataAtt.clockIn, 'HH:mm:ss a')
  const duration = moment.duration(scheduleIn.diff(startTime))
  const lateMinutes = parseInt(duration.asMinutes())

  return (
    <div className="md:flex p-8 md:p-16">
      {loading && (
        <CircularProgress
          className="absolute m-auto"
          size={65}
          style={{top: '50vh', left: '46vw'}}
        />
      )}

      <div className="w-3/4 md:w-2/5 mx-auto md:ml-0 mb-6 md:mr-5 flex justify-center">
        <img
          src={
            dataAtt?.dataPolicy.mobile_setting.isIncludePhoto
              ? imgsrc
              : require('../../../assets/slicing/checkin-no-photo.svg')
          }
          alt="absen"
          style={
            dataAtt?.dataPolicy.mobile_setting.isIncludePhoto
              ? {borderRadius: 5, objectFit: 'cover'}
              : {maxHeight: 380}
          }
        />
      </div>

      <div className="md:w-3/5">
        <TypographyStyled variant="body1" weight="600" className="md:mt-0 my-3">
          Work Schedule
        </TypographyStyled>
        <Paper className="flex items-center my-3 md:mb-6 p-3 shadow-wlb py-6">
          <IconStyled className="mr-4 text-3xl" text="#014a62">
            access_time
          </IconStyled>
          <div>
            <TypographyStyled variant="body1" weight="600">
              {dataAtt.name}
            </TypographyStyled>
            {!isFlexi && (
              <>
                <TypographyStyled
                  style={{marginTop: '0.35em', marginBottom: 0}}
                  variant="body1"
                  text="#a9a8a8"
                >
                  {`(${dataAtt.clockIn} - ${dataAtt.clockOut})`}
                </TypographyStyled>
                {/* <TypographyStyled variant="body1" text="#ef4d5e">
                  {lateMinutes < 0
                    ? `(Late: ${Math.abs(lateMinutes)} minutes)`
                    : '(Late: 0 minute)'}
                </TypographyStyled> */}
              </>
            )}
          </div>
        </Paper>
        <div className="flex justify-between items-center poin cursor-pointer">
          <TypographyStyled variant="body1" weight="600" className="my-3 ">
            Location
          </TypographyStyled>
          <span className="text-secondary" onClick={() => getLocation()}>
            Refresh
          </span>
        </div>
        <Paper
          onClick={() => setOpen(true)}
          className="flex items-center my-3 md:mb-6 p-3 shadow-wlb py-6 cursor-pointer"
        >
          <IconStyled className="mr-4 text-3xl" text="#014a62">
            place
          </IconStyled>

          <TypographyStyled
            variant="body2"
            className="text-secondary"
            size="16px"
            text="secondary"
          >
            {coord ? (
              coord.address
            ) : (
              <LinearProgress className="w-56 md:w-72" />
            )}
          </TypographyStyled>
        </Paper>
        <div className="md:flex  md:justify-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            className="w-full mt-4 md:w-1/5"
            onClick={() => onSubmitAttendance(lateMinutes)}
            disabled={!coord || loading}
          >
            Submit
          </Button>
        </div>
      </div>
      <NotificationWLB
        open={outsideLocation}
        onClick={() => setOutsideLocation(false)}
        message="Your location is outside the allowed radius"
        title="Information"
      />
      <ModalDetailMap
        open={open}
        onClose={() => setOpen(false)}
        coord={coord}
      />
    </div>
  )
}

export default FormCheckin
