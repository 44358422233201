import {TextField} from '@material-ui/core'
import styled from 'styled-components'

export const GlobalPhoneText = styled(TextField)`
  & > .MuiInputBase-formControl {
    font-size: 14px;
    padding-right: 8px;
    cursor: pointer;

    & > input {
      cursor: pointer;
    }
  }
`
