import styled from "styled-components";

export const WrapperScroll = styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "450px")};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
`;
