import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import AttachmentItemList from '../../shared-component/attachment/AttachmentItemList'

import {calculateBudget} from '../../trip/trip-shared-component/trip-helper'
import {
  ExchangeRateDisplay,
  TableInfo,
} from '../../trip/trip-shared-component/TableDetailTrip'
import {ConversionDisplay} from '../../trip/TripStyles'

export default function TableDetailTripReport({
  destination,
  onClickMoreAttachment,
  data,
  date,
  currencyFrom,
  currencyTo,
  approvedDate,
}) {
  let totalBudgetSpent = 0

  const tableInfo = [
    {
      label: 'Destination',
      value: destination,
    },
    {
      label: 'Date',
      value: date,
    },
    currencyFrom !== currencyTo && {
      label: 'Exchange Rate',
      value: (
        <ExchangeRateDisplay
          // NOTE(intrnl): Yes, this is intentionally flipped
          from={currencyTo}
          to={currencyFrom}
          date={approvedDate}
        />
      ),
    },
  ]

  return (
    <>
      <TableInfo data={tableInfo} />

      <TableContainer className="mb-4">
        <Table>
          <TableHead className="bg-wlbgrey">
            <TableRow>
              <TableCell className="font-semibold">Component</TableCell>
              <TableCell className="font-semibold text-right">Budget</TableCell>
              <TableCell className="font-semibold text-right">
                Budget Used
              </TableCell>
              <TableCell className="font-semibold">Description</TableCell>
              <TableCell className="font-semibold">Attachment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.budget.map(row => {
              const budget = calculateBudget(data, row)
              const realized = row.realization
              totalBudgetSpent += realized

              return (
                <TableRow key={row.id}>
                  <TableCell className="text-xs">
                    <div>{row.business_trip_component.name}</div>
                    <div className="text-gray-600">
                      {
                        row.business_trip_component.business_trip_component_type
                          .name
                      }
                    </div>
                  </TableCell>
                  <TableCell className="text-xs">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={budget}
                      date={approvedDate}
                    />
                  </TableCell>
                  <TableCell className="text-xs">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={realized}
                      date={approvedDate}
                    />
                  </TableCell>
                  <TableCell className="text-xs">
                    {row.business_trip_component?.description}
                  </TableCell>
                  <TableCell className="text-xs">
                    {!row.attachment?.length && '-'}
                    {row.attachment?.slice(0, 3).map(({name, url}, i) => (
                      <AttachmentItemList
                        name={name}
                        url={url}
                        key={url + i}
                        onClick={() =>
                          window.open(url, '_blank', 'noopener,noreferrer')
                        }
                      />
                    ))}
                    {row.attachment?.length > 3 && (
                      <span
                        onClick={() => onClickMoreAttachment(row)}
                        className="text-xs text-secondary cursor-pointer"
                      >
                        ... {row.attachment?.length - 3}+ More
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell colSpan={2} className="text-sm font-semibold">
                Total Budget Used
              </TableCell>
              <TableCell colSpan={3} className="text-sm font-semibold">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalBudgetSpent}
                  date={approvedDate}
                  embolden
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
