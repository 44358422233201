import React from 'react'

import {TypographyStyled} from '../../components/typography/TypographyStyles'
import Divider from '../../components/divider/DividerStyles'
import ListBank from './list-component/ListBank'
import ListTax from './list-component/ListTax'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import {ButtonStyled} from '../../components/button/ButtonStyles'
import {IconButton} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import useGetProfile from '../../hooks/useGetProfile'
import {SOSMED_APP_URL} from '../../utils/globals'

function ListBankTax(props) {
  const {id} = useGetProfile()
  const {refetch, setPage, setDataEdit, setType, data} = props

  const hrefProfile = () => {
    window.location.replace(`${SOSMED_APP_URL}/profile/${id}/detail`)
  }

  return (
    <>
      <div
        style={{padding: '24px 30px'}}
        className="flex flex-row items-center justify-between "
      >
        <div className="font-bold text-xl flex justify-center items-center flex-row">
          <LocalAtmIcon
            text="#014a62"
            className="mr-3"
            style={{fontSize: 40, color: '#014a62'}}
          />
          <TypographyStyled
            component="span"
            size="16px"
            weight="600"
            text="#014a62"
          >
            Bank & Tax
          </TypographyStyled>
        </div>
        <ButtonStyled
          classes={{
            root: 'text-sm',
            label: 'h-4 font-semibold',
          }}
          style={{padding: '14px 37px'}}
          size="large"
          state={'primary'}
          onClick={() => {
            setPage('form-bank')
            setType('Add')
          }}
        >
          Add Account Bank
        </ButtonStyled>
      </div>
      <Divider />
      <div>
        <div style={{padding: '24px 30px'}}>
          <TypographyStyled
            className="font-semibold"
            text="#014a62"
            style={{marginBottom: 4, lineHeight: '24px'}}
          >
            Bank
          </TypographyStyled>
          <ListBank
            refetch={refetch}
            data={data || []}
            setType={setType}
            setPage={setPage}
            setDataEdit={setDataEdit}
          />
        </div>
        <Divider />
        <div style={{padding: '24px 30px'}}>
          <div className="flex justify-between">
            <TypographyStyled
              className="font-semibold"
              text="#014a62"
              style={{marginBottom: 4, lineHeight: '24px'}}
            >
              Tax & BPJS
            </TypographyStyled>
            <IconButton
              aria-label="delete"
              style={{color: '#039be5'}}
              onClick={() => {
                hrefProfile()
                // disable form edit tax old feature
                // setPage('form-tax')
                // setDataEdit(data ? data.tax : [])
              }}
            >
              <CreateIcon style={{fontSize: '20px'}} />
            </IconButton>
          </div>
          <ListTax data={data ? data.tax : []} />
        </div>
      </div>
    </>
  )
}

export default ListBankTax
