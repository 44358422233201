import React from 'react'
import {Paper} from '@material-ui/core'
import ListBankTax from './ListBankTax'
import AddBank from './form-component/AddBank'
import {GET_LIST_BANK_TAX} from '../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import LoadingComponent from '../shared-component/loading/LoadingComponent'
import {USER_ID} from '../../utils/globals'
import AddTax from './form-component/AddTax'

export default function Financial() {
  const [page, setPage] = React.useState('bank-tax')
  const [type, setType] = React.useState('add')
  const [dataEdit, setDataEdit] = React.useState([])

  const {data, error, loading, refetch} = useQuery(GET_LIST_BANK_TAX, {
    variables: {
      user: USER_ID,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  if (!error && loading) {
    return <LoadingComponent />
  }

  const pageComponent = page => {
    if (page === 'bank-tax') {
      return (
        <ListBankTax
          refetch={refetch}
          setPage={setPage}
          setDataEdit={setDataEdit}
          setType={setType}
          data={data}
        />
      )
    } else if (page === 'form-bank') {
      return (
        <AddBank
          dataEdit={dataEdit}
          type={type}
          setPage={setPage}
          refetch={refetch}
        />
      )
    } else if (page === 'form-tax') {
      return <AddTax dataEdit={dataEdit} setPage={setPage} refetch={refetch} />
    }
  }

  return (
    <div className="flex flex-col w-full">
      <Paper className="">{pageComponent(page)}</Paper>
    </div>
  )
}
