import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn'

export const ListItemAtt = ({label, value, secondary, location}) => {
  return (
    <div className="flex md:flex-row flex-col mb-6">
      <div className="text-sm text-disable leading-snug w-32 font-semibold">
        {label}
      </div>
      <div className="flex flex-row ">
        <div className="md:visible invisible">
          {value !== '-' && location && (
            <LocationOnIcon className={`text-secondary mr-2 md:block hidden`} />
          )}
        </div>
        <div
          className={`text-sm leading-snug max-w-md font-semibold ${secondary &&
            'text-secondary'} `}
        >
          {value || '-'}
        </div>
      </div>
    </div>
  )
}
