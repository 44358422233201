import React, {useEffect, useState} from 'react'
import ListItemModal from '../../overtime/tab/shared-component-overtime/ListItemModal'
import {Button, useMediaQuery, useTheme} from '@material-ui/core'
import {useLazyQuery} from '@apollo/react-hooks'
import {
  GET_DETAIL_ACTIVITY_LEAVE,
  GET_LIST_DETAIL_OVERTIME,
} from '../../../graphql/queries'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import {
  differenceDateFrom,
  generateDateMonth,
  generateDateSubmit,
  isSameOrBefore,
} from '../../../utils/helpers'
import {TextFieldStyled} from '../../../components/form/FormStyles'
import {generateSubmissionStatus, userCanEdit} from './helperDetail.jsx'
import {USER_ID} from '../../../utils/globals'
import {getCompensationDescription} from '../../overtime/helperForOvertime'

export default function DetailList({
  id,
  onClickCancel,
  type,
  from,
  onClickSubmitReject,
  onClickApprove,
  onCancel,
  salary,
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [openTextfield, setOpenTextfield] = useState(false)
  const [text, setText] = useState('')
  const [errorValidate, setErrorValidate] = useState(false)
  const [
    getDataDetail,
    {data: dataDetail, error, loading},
  ] = useLazyQuery(
    from === 'leave' ? GET_DETAIL_ACTIVITY_LEAVE : GET_LIST_DETAIL_OVERTIME,
    {fetchPolicy: 'no-cache'}
  )

  useEffect(() => {
    if (id) {
      getDataDetail({
        variables: {
          id: id,
        },
      })
    }
  }, [id])

  if (loading) {
    return <LoadingComponent />
  } else if (error) {
    return <span>{error}</span>
  } else if (dataDetail) {
    let dataQuery = null
    let confirm_type = null
    let policy_type = 'time_overtime_policy'
    let field_from = 'overtime_fields'
    let field_bridge = 'overtimeFields'
    let attachment = 'attachment'
    if (from === 'leave') {
      field_bridge = 'leaveFields'
      field_from = 'leave_fields'
      policy_type = 'time_leave_policy_child'
      if (dataDetail && dataDetail.people_work_leaves.length > 0) {
        dataQuery = dataDetail.people_work_leaves[0]
      }
      confirm_type =
        typeof dataQuery === 'object'
          ? dataQuery[policy_type] && dataQuery[policy_type].confirm_type
          : null
    } else {
      attachment = 'attachment'
      if (dataDetail && dataDetail.people_work_overtimes.length > 0) {
        dataQuery = dataDetail.people_work_overtimes[0]
      }
      confirm_type =
        typeof dataQuery === 'object'
          ? dataQuery[policy_type] && dataQuery[policy_type].confirm_type
          : null
    }

    const handleReject = () => {
      if (text === '') {
        setErrorValidate(true)
        setTimeout(() => {
          setErrorValidate(false)
        }, 3000)
      } else {
        onClickSubmitReject(text, dataQuery.id, dataQuery)
      }
    }
    const second =
      typeof dataQuery === 'object'
        ? dataQuery[policy_type] && dataQuery[policy_type].second_job_profile
        : null
    const first =
      typeof dataQuery === 'object'
        ? dataQuery[policy_type] && dataQuery[policy_type].first_job_profile
        : null
    const spv =
      typeof dataQuery === 'object'
        ? dataQuery.people_work_placement.company_job_profile
            .job_profile_supervisor
        : null

    const isUserCanCancelApproved =
      isSameOrBefore(generateDateSubmit(new Date()), dataQuery.dateend) &&
      from === 'leave' &&
      dataQuery.status !== 'cancelled' &&
      dataQuery.status !== 'rejected'

    return (
      <>
        <div className={`flex flex-col ${fullScreen ? 'pt-6 px-6' : 'p-6'}`}>
          <ListItemModal title="Name">
            {dataQuery.people_work_placement.global_user.name}
          </ListItemModal>
          <ListItemModal title="Position">
            {dataQuery.people_work_placement.company_job_profile.title}
          </ListItemModal>
          <ListItemModal title="Submission Date">
            {generateDateMonth(dataQuery.date_added)}
          </ListItemModal>
          <ListItemModal title="Submission Status">
            {generateSubmissionStatus({
              data: dataQuery,
              policy_type: policy_type,
              field_from: field_from,
            })}
          </ListItemModal>
        </div>
        {fullScreen ? null : (
          <div style={{padding: 2}} className="bg-wlbgrey" />
        )}
        <div className="flex flex-col p-6">
          <ListItemModal title="Request ID">
            {dataQuery.id_display}
          </ListItemModal>
          <ListItemModal
            title={`${
              from === 'leave' ? 'Leave and Permission' : 'Overtime'
            } Date`}
          >
            {generateDateMonth(dataQuery.datestart)}{' '}
            {from === 'leave' ? (
              !dataQuery.is_half ? (
                dataQuery.dateend === null ? (
                  '1 Day'
                ) : (
                  <span className="bg-disable text-white rounded-wlb px-2 py-1 ml-2">
                    {dataQuery.quota_taken} Day
                  </span>
                )
              ) : (
                <span className="bg-disable text-white rounded-wlb px-2 py-1 ml-2">
                  Half Day
                </span>
              )
            ) : null}
          </ListItemModal>
          {from === 'overtime' && (
            <>
              <ListItemModal title="Long Time">
                {`${differenceDateFrom(
                  dataQuery?.dateend,
                  dataQuery?.datestart,
                  'hours'
                )} Hours`}
              </ListItemModal>
              <ListItemModal title="Compensation">
                {compensationType[dataQuery.convert_to]}
              </ListItemModal>
              <ListItemModal title="Compensation Description">
                {getCompensationDescription({
                  selectedItem: dataQuery,
                  salary,
                })}
              </ListItemModal>
            </>
          )}
          {dataQuery.is_half &&
            dataQuery[field_from] &&
            dataQuery[field_from].endDate &&
            dataQuery[field_from].startDate && (
              <ListItemModal title="Leave Time">
                {dataQuery[field_from].startDate} -{' '}
                {dataQuery[field_from].endDate}
              </ListItemModal>
            )}
          {from === 'leave' && (
            <ListItemModal title="Contact Number">
              {dataQuery.contacts && dataQuery.contacts.phone}
            </ListItemModal>
          )}
          <ListItemModal title="Description">
            {dataQuery.description}
          </ListItemModal>
          {dataQuery.status === 'rejected' &&
            dataQuery[field_from] &&
            (dataQuery[field_from].reason ||
              dataQuery[field_from].reject_reason ||
              (dataQuery[field_from][field_bridge] &&
                dataQuery[field_from] &&
                dataQuery[field_from][field_bridge].reason)) && (
              <ListItemModal title="Reject Statement">
                {(dataQuery[field_from] && dataQuery[field_from].reason) ||
                  (dataQuery[field_from] &&
                    dataQuery[field_from].reject_reason) ||
                  (dataQuery[field_from] &&
                    dataQuery[field_from][field_bridge] &&
                    dataQuery[field_from][field_bridge].reason)}
              </ListItemModal>
            )}
          <ListItemModal title="Attachment">
            {dataQuery[field_from] &&
            dataQuery[field_from][attachment] &&
            dataQuery[field_from][attachment].length > 0 ? (
              <div className="flex flex-col">
                {dataQuery[field_from][attachment].map((res, i) => (
                  <a
                    key={i}
                    className="text-secondary mb-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={res.link}
                  >
                    {res.name}
                  </a>
                ))}
              </div>
            ) : (
              '-'
            )}
          </ListItemModal>
        </div>
        {type === 'activity' ? (
          (dataQuery.status === 'pending' || isUserCanCancelApproved) && (
            <div className="w-full flex flex-row justify-center pt-4 pb-10 items-center">
              <Button
                className="px-6 py-2"
                style={{border: '1px solid #ef4d5e', color: '#ef4d5e'}}
                variant="outlined"
                onClick={() => onClickCancel(dataQuery.id)}
              >
                Cancel Submission
              </Button>
            </div>
          )
        ) : userCanEdit(
            confirm_type,
            dataQuery.status,
            first,
            second,
            spv,
            USER_ID
          ) ? (
          <div className="flex flex-col ">
            <div className="w-full flex flex-row justify-center pt-4 mb-6 items-center">
              <Button
                className="px-12 py-2"
                style={
                  openTextfield
                    ? {color: '#fff', backgroundColor: '#ef4d5e'}
                    : {border: '1px solid #ef4d5e', color: '#ef4d5e'}
                }
                variant={openTextfield ? 'contained' : 'outlined'}
                onClick={() => setOpenTextfield(!openTextfield)}
              >
                Reject
              </Button>
              <Button
                className="px-12 py-2 ml-6"
                // style={{ border: "1px solid #ef4d5e", color: "#ef4d5e" }}
                variant="contained"
                color="primary"
                onClick={() => onClickApprove(dataQuery)}
              >
                Approve
              </Button>
            </div>
            {openTextfield && (
              // <div className="pb-6 px-20 pt-2">
              <div className={`pb-6 ${fullScreen ? 'px-6' : 'px-20'} pt-2`}>
                <TextFieldStyled
                  placeholder="Add Reject Statment Here"
                  variant="outlined"
                  fullwidth
                  multiline
                  rows={4}
                  error={errorValidate}
                  helperText={errorValidate && 'This field is required'}
                  classes={{
                    root: 'w-full text-sm',
                  }}
                  value={text}
                  onChange={e => setText(e.target.value)}
                  inputProps={{
                    className: 'text-sm',
                  }}
                />
                <div className="flex flex-row justify-end mt-6 mb-6">
                  <Button onClick={onCancel} className="px-6 py-2">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleReject}
                    className="ml-4 px-6 py-2"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </>
    )
  } else {
    return <span />
  }
}

const compensationType = {
  Money: 'Pay with Money',
  Leave: 'Convert to Leave',
}
