import React, {useState} from 'react'
import FormDate from './FormDate'
import FormWrapper from '../../shared-component/form/FormWrapper'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {TextFieldStyled} from '../../../components/form/FormStyles'
import {USER_ID} from '../../../utils/globals'
import Divider from '../../../components/divider/DividerStyles'
import FormActionButton from './FormActionButton'
import FormBudgetUsage from './FormBudgetUsage'
import {useHistory} from 'react-router-dom'
import {useMediaQuery, useTheme} from '@material-ui/core'
import SearchableSelect from '../../shared-component/form/SearchableSelect'
import {
  GET_TIRIP_REPORT_SELECTION,
  GET_TRIP_DETAIL_AUTO_FILLED,
} from '../../../graphql/queries'
import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks'
import {
  getDestination,
  getTripDate,
} from '../../trip/trip-shared-component/trip-helper'
import {UPLOAD_TRIP_REPORT} from '../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {TRIP_CONTEXT} from '../../trip/TripConstant'

import InfiniteScroll from 'react-infinite-scroll-component'
import FormTotalRemainingMoney from './FormTotalRemainingMoney'
import {
  getRemainingMoney,
  getRemainingState,
  setupRealization,
  getErrorForm,
} from '../trip-report-helper'
import {handleUploadAttachment} from '../uploadFilesReport'

export default function UploadReportForm() {
  const {enqueueSnackbar} = useSnackbar()
  const history = useHistory()
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  const [errorFile, setErrorFile] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [error, setError] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [confirmationDialog, setConfirmationDialog] = useState(
    DEFAULT_CONFIRMATION_STATE
  )
  const [values, setValues] = useState({
    trip_id_display: undefined,
    trip_id: undefined,
    trip_user_id: null,
    policy: {},
    position: '',
    start_date: null,
    end_date: null,
    approved_date: null,
    currency: 'idr',
    destination: [],
    budget_action: 'manual',
  })

  const [uploadReport] = useMutation(UPLOAD_TRIP_REPORT)

  useQuery(GET_TRIP_DETAIL_AUTO_FILLED, {
    ...TRIP_CONTEXT,
    skip: !values.trip_id,
    fetchPolicy: 'no-cache',
    variables: {id: values.trip_id},
    onCompleted: data => {
      const {
        business_trip_policy,
        start_date,
        end_date,
        approved_date,
        currency,
        people_work_placement,
        user_id,
        destination,
      } = data?.people_work_business_trips?.[0] || {}

      setValues(prev => ({
        ...prev,
        approved_date,
        currency,
        start_date,
        end_date,
        policy: business_trip_policy,
        trip_user_id: user_id,
        position: data?.people_work_business_trips?.[0]
          ? people_work_placement?.company_job_profile?.title +
            ' at ' +
            people_work_placement?.company_profile?.legal_name
          : '',
        destination: destination?.map(destination => ({
          ...destination,
          budget: destination?.budget?.map(budget => ({
            ...budget,
            attachment:
              budget?.attachment?.map(attachment => ({
                ...attachment,
                id: attachment.url,
                isUploaded: true,
                loaded: attachment.size,
              })) || [],
          })),
        })),
      }))
    },
  })

  const [getListTrip, {data, loading, fetchMore}] = useLazyQuery(
    GET_TIRIP_REPORT_SELECTION,
    {
      ...TRIP_CONTEXT,
      fetchPolicy: 'network-only',
      variables: {user_id: USER_ID},
    }
  )

  const fieldList = [
    {
      number: !smallSize && 1,
      fieldName: 'Trip ID*',
      field: 'trip_id_display',
      type: 'searchable-select',
      placeholder: 'Choose Trip ID',
      value: values.trip_id_display,
      error: error && !values.trip_id_display,
    },
    {
      number: !smallSize && 2,
      fieldName: 'Trip Policy',
      field: 'policy',
      type: 'text',
      value: values.policy?.name,
      placeholder: 'Choose Trip Policy',
      disabled: true,
    },
    {
      number: !smallSize && 3,
      fieldName: 'Position',
      field: 'position',
      type: 'text',
      placeholder: 'Choose Trip Position',
      value: values.position,
      disabled: true,
    },
  ]

  const remainingMoney = getRemainingMoney(values?.destination)
  const remainingState = getRemainingState(remainingMoney)

  const handleChangeSelect = (res, onClose) => {
    setValues(prev => ({
      ...prev,
      trip_id: res.id,
      trip_id_display: res.id_display,
    }))
    onClose()
  }

  const handleSearch = search => {
    getListTrip({variables: {search: `%${search}%`, offset: 0}})
  }

  const handleOpenSelect = () => {
    getListTrip({variables: {search: '%%', offset: 0}})
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data?.people_work_business_trips.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_work_business_trips: [
            ...prev.people_work_business_trips,
            ...fetchMoreResult.people_work_business_trips,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const handleUploadReport = async () => {
    setLoadingSubmit(true)
    const budget = setupRealization(values)
    const realization = await handleUploadAttachment(budget)

    uploadReport({
      variables: {
        id_bustrip: values.trip_id,
        budget_action: values.budget_action,
        budget_realizations: realization,
      },
    })
      .then(() => {
        enqueueSnackbar('Apply trip report success', {variant: 'success'})
        history.push('/trip-report')
        setLoadingSubmit(false)
      })
      .catch(e => enqueueSnackbar(JSON.stringify(e), {variant: 'error'}))
  }

  const openSubmitConfirmation = () => {
    setConfirmationDialog({
      open: true,
      title: 'Apply Trip Report?',
      content: 'Are you sure you want to apply this trip report?',
      onClick: () => handleUploadReport(),
      onClose: () => setConfirmationDialog(DEFAULT_CONFIRMATION_STATE),
      textPrimary: 'Confirm',
      textSecondary: 'Cancel',
    })
  }

  const handleCancel = () => {
    setConfirmationDialog({
      open: true,
      title: 'Discard Changes?',
      content: 'Are you sure you want to discard unsaved changes?',
      onClick: () => history.push('/trip-report'),
      onClose: () => setConfirmationDialog(DEFAULT_CONFIRMATION_STATE),
      textPrimary: 'Discard',
      textSecondary: 'Cancel',
    })
  }

  const handleSubmit = () => {
    const isError = getErrorForm(values)
    if (isError) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      openSubmitConfirmation()
    }
  }

  const generateField = res => {
    if (res.type === 'text') {
      return (
        <TextFieldStyled
          type={res.type}
          fullWidth
          disabled={res.disabled || false}
          InputProps={{inputProps: {className: 'text-xs'}}}
          value={res.value}
          inputProps={{className: 'text-xs'}}
          placeholder={res.placeholder}
          variant="outlined"
          size="small"
        />
      )
    } else if (res.type === 'searchable-select') {
      return (
        <SearchableSelect
          value={res.value}
          onOpen={handleOpenSelect}
          onSearch={handleSearch}
          placeholder={res.placeholder}
          errorRequired={res.error}
          options={onClose => {
            return (
              <InfiniteScroll
                height={data?.total?.aggregate?.count === 0 ? undefined : 170}
                dataLength={data?.people_work_business_trips.length || 0}
                next={fetchMoreData}
                hasMore={
                  data?.people_work_business_trips.length <
                  data?.total?.aggregate?.count
                }
                loader={<p className="text-xs">Loading data...</p>}
              >
                {!loading && data?.total?.aggregate?.count === 0 && (
                  <p className="text-xs">No Trip ID currently listed</p>
                )}
                {!loading &&
                  data?.people_work_business_trips?.map(res => {
                    return (
                      <div
                        key={res.id}
                        onClick={() => handleChangeSelect(res, onClose)}
                        className="cursor-pointer mb-5"
                      >
                        <h4 className="font-semibold m-0 mb-1 text-xs text-md">
                          {res.id_display}
                        </h4>
                        <p className="font-semibold m-0 mb-1 text-xs text-secondary">
                          {getTripDate(res.date_start, res.date_end, true)}
                        </p>
                        <p className="font-semibold m-0 text-xs">
                          {getDestination(
                            res.people_work_business_trip_destinations
                          )}
                        </p>
                      </div>
                    )
                  })}
              </InfiniteScroll>
            )
          }}
        />
      )
    }
  }

  return (
    <>
      <div className="flex flex-col pt-8 px-8 pb-8">
        {fieldList.map(res => {
          return (
            <FormWrapper
              key={res.field}
              number={res.number}
              title={res.fieldName}
            >
              {generateField(res)}
            </FormWrapper>
          )
        })}
        <FormDate
          number="4."
          title="Trip Date"
          values={values}
          smallSize={smallSize}
        />
        {values.trip_id && (
          <FormBudgetUsage
            smallSize={smallSize}
            values={values}
            error={error}
            setValues={setValues}
            errorFile={errorFile}
            errorSize={errorSize}
            setError={setError}
            setErrorFile={setErrorFile}
            setErrorSize={setErrorSize}
            // uploadFiles={uploadFiles}
          />
        )}
        {values.trip_id && (
          <FormTotalRemainingMoney
            smallSize={smallSize}
            error={error}
            values={values}
            setValues={setValues}
            remainingMoney={remainingMoney}
            remainingState={remainingState}
          />
        )}
      </div>
      <Divider />

      <FormActionButton onCancel={handleCancel} onSubmit={handleSubmit} />

      <ConfirmationDialog
        title={confirmationDialog.title}
        content={confirmationDialog.content}
        onClickPrimary={confirmationDialog.onClick}
        textPrimary={confirmationDialog.textPrimary}
        textSecondary={confirmationDialog.textSecondary}
        onClose={confirmationDialog.onClose}
        open={confirmationDialog.open}
        loading={loadingSubmit}
        disable={loadingSubmit}
      />
    </>
  )
}

const DEFAULT_CONFIRMATION_STATE = {
  open: false,
  title: '',
  content: '',
  onClick: null,
  onClose: null,
  textPrimary: '',
  textSecondary: '',
}
