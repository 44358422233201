import React, {useEffect, useState} from 'react'
import BalanceList from '../../shared-component-leave/BalanceList'
import {useQuery, useLazyQuery} from '@apollo/react-hooks'
import {GET_LIST_BALANCE, GET_JOIN_DATE} from '../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import moment from 'moment'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {getDataBalance} from './balance-helper'
import EmptyState from '../../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../../assets/images/nodatalist.png'

export default function TabBalance() {
  const [startDate, setStartDate] = useState(null)

  const {data: dataWork} = useQuery(GET_JOIN_DATE, {
    variables: {
      user: USER_ID,
    },
  })

  const [
    getListBalance,
    {data: dataQuery, error: errorQuery, loading: loadingQuery},
  ] = useLazyQuery(GET_LIST_BALANCE, {fetchPolicy: 'cache-and-network'})

  /**
   * years {array} return range year
   * [before year, now year, after year]
   */
  const thisYear = new Date().getFullYear()
  const years = Array.from(
    {length: 3},
    (...args) => thisYear - args[1] + 1
  ).reverse()
  useEffect(() => {
    if (dataWork) {
      getListBalance({
        variables: {
          user_id: USER_ID,
          company_id: COMPANY_ID,
          period_year: years,
        },
      })
      const {join_date} = dataWork.people_work_placements[0]

      setStartDate(join_date)
    }
  }, [dataWork])

  if (errorQuery) {
    console.error(errorQuery)
  }

  return (
    <div className="flex flex-col px-6 py-6">
      {dataQuery?.time_leave_policy_children?.map((res, i) => {
        const {
          isDeleted,
          deletedDate,

          quotas,
          remainingQuota,
          takenCurrent,
          valueBar,
          dateQuotaActive,

          quotasCarryOver,
          remainingQuotaCarryOver,
          takenCurrentCarryOver,
          valueBarCarryOver,
          validUntilCarryOver,
        } = getDataBalance({data: res, startDate})

        const {start_date, end_date} = dateQuotaActive
        const isExpiredCarryOver = moment(new Date()).isAfter(
          validUntilCarryOver
        )
        return (
          <section key={i} className="flex flex-col mb-10">
            <div className="mb-4 flex flex-row justify-between items-center">
              <h4
                className={`text-sm font-semibold ${
                  isDeleted ? 'text-disable' : 'text-primary'
                }`}
              >
                {res.name}
              </h4>
              {isDeleted && (
                <span className="text-xs text-danger">
                  Deleted at {deletedDate}
                </span>
              )}
            </div>
            <BalanceList
              type={res.infinite_quota ? 'success' : 'primary'}
              start={takenCurrent}
              count={`${remainingQuota} Days Left`}
              end={quotas}
              value={valueBar}
              time={`${start_date} - ${end_date}`}
              isDeleted={isDeleted}
            />
            {quotasCarryOver > 0 && (
              <BalanceList
                type={isExpiredCarryOver ? 'disable' : 'secondary'}
                start={takenCurrentCarryOver}
                count={
                  isExpiredCarryOver
                    ? 'Expired'
                    : `${remainingQuotaCarryOver} Days Left`
                }
                end={quotasCarryOver}
                value={valueBarCarryOver}
                time={`Carry over (Valid until ${validUntilCarryOver})`}
                isDeleted={isDeleted}
              />
            )}
          </section>
        )
      })}
      {!loadingQuery && dataQuery?.total?.aggregate?.count === 0 && (
        <EmptyState image={NoListImage} message1="Sorry, No List" />
      )}
      {loadingQuery && <LoadingComponent />}
    </div>
  )
}
