import React from 'react'
import {TextFieldSearchStyle} from './FormStyle'
import SearchIcon from '@material-ui/icons/Search'

export default function TextfieldSearch({value, onChange}) {
  return (
    <TextFieldSearchStyle
      placeholder="Search"
      variant="outlined"
      InputProps={{
        endAdornment: <SearchIcon style={{width: 21, height: 21}} />,
      }}
      value={value}
      onChange={onChange}
    />
  )
}
