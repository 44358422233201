import styled from 'styled-components'
import {Dialog} from '@material-ui/core'

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const ModalHeader = styled.div`
  padding: 1.6rem 2rem;
  background-color: #014a62;
  color: #ffffff;
`
export const ModalHeaderWhite = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalContent = styled.div`
  padding: 1.6rem 2rem;
`
