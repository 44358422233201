import React from "react";
import { KeyboardDatePickerStyled } from "../../../components/form/FormStyles";
import DatePickerWrapper from "./DatePickerWrapper";

export default function DatePicker({ fullWidth = true, size = "small", wrapperClassName = "", ...props }) {
  return (
    <DatePickerWrapper wrapperClassName={wrapperClassName}>
      <KeyboardDatePickerStyled
        space="0px"
        fullWidth={fullWidth}
        inputVariant="outlined"
        format="MMMM dd, yyyy"
        size={size}
        {...props}
      />
    </DatePickerWrapper>
  );
}
