import React from "react";
import { IconButtonStyled } from "../../../components/Icons/IconComponent";
import { Divider, Paper } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import UploadReportForm from './UploadReportForm'

export default function UploadReport() {
  const history = useHistory()

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className="flex flex-col m-0 rounded">
      <Paper className="shadow-wlb">
        <div className="py-8 px-8 flex flex-row">
          <div className="flex flex-row items-center">
            <IconButtonStyled onClick={handleBack} className="p-0">
              <ArrowBackIcon />
            </IconButtonStyled>
            <div className="text-primary font-semibold text-md ml-3">
              Apply for Trip Reporting
            </div>
          </div>
        </div>
        <Divider />
        <UploadReportForm />
      </Paper>
    </div>
  );
}
