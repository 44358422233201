import React from 'react'
import {
  ButtonStyled,
  IconButtonStyled,
} from '../../../components/button/ButtonStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {GET_LIST_BANK, CEK_ID_BANK} from '../../../graphql/queries'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import Divider from '../../../components/divider/DividerStyles'
import Select from 'react-select'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {useMediaQuery, useTheme} from '@material-ui/core'
import {
  TextFieldStyled,
  InputAdornmentStyled,
  FormControlStyled,
  FormHelperTextStyled,
} from '../../../components/form/FormStyles'
import CancelDialog from '../../shared-component/dialog/CancelDialog'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {USER_ID} from '../../../utils/globals'
import {MUTATION_ADD_BANK, MUTATION_EDIT_BANK} from '../../../graphql/mutations'
import {useSnackbar} from 'notistack'

function AddBank(props) {
  const {dataEdit, type, setPage, refetch} = props
  const [values, setValues] = React.useState({
    bank: null,
    account_name: '',
    account_number: '',
  })

  const {enqueueSnackbar} = useSnackbar()

  const [error, setError] = React.useState(false)

  const [insertApply] = useMutation(MUTATION_ADD_BANK)
  const [editApply] = useMutation(MUTATION_EDIT_BANK)
  const client = useApolloClient()

  const [openSubmit, setOpenSubmit] = React.useState(false)
  const [open, setOpen] = React.useState({
    dialogCancel: false,
  })

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (type === 'Edit') {
      setValues({
        bank: {
          value: dataEdit.bank,
          label: dataEdit.bank_name,
        },
        account_name: dataEdit.account_name,
        account_number: dataEdit.account_number,
      })
    }
  }, [dataEdit])

  const {data, error: error2, loading} = useQuery(GET_LIST_BANK, {
    variables: {
      search: null,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  if (!error2 && loading) {
    return <LoadingComponent />
  }

  const handleChange = (e, target, child) => {
    if (child) {
      setValues({
        ...values,
        [target]: e,
      })
    } else {
      setValues({
        ...values,
        [target]: e.target.value,
      })
    }
  }

  const renderComponent = res => {
    const {type, value, options, inputProps, textArea, field, placeholder} = res
    if (type === 'text' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          fullWidth
          disabled={field === 'name'}
          value={inputProps ? parseInt(value) : value}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e, field)}
          multiline={textArea}
          rows={textArea && 8}
          placeholder={placeholder}
          InputProps={
            inputProps
              ? {
                  inputProps: {className: 'text-xs', min: 0},
                  endAdornment: (
                    <InputAdornmentStyled position="end" disableTypography>
                      <div
                        style={{
                          borderLeft: '1px solid rgba(0, 0, 0, 0.27)',
                          paddingLeft: 13,
                          display: 'flex',
                          alignItems: 'center',
                          height: 34,
                          marginleftt: 6,
                        }}
                      >
                        <p style={{fontSize: 14, color: '#a9a8a8'}}>Hours</p>
                      </div>
                    </InputAdornmentStyled>
                  ),
                }
              : {
                  inputProps: {className: 'text-xs'},
                }
          }
          error={field !== 'name' && values[field] === '' && error}
          helperText={
            field !== 'name' &&
            values[field] === '' &&
            error &&
            'This field is required'
          }
          classes={
            placeholder && {
              root: 'text-xs',
            }
          }
        />
      )
    } else if (type === 'select') {
      return (
        <>
          <FormControlStyled style={{width: '100%'}} size="small">
            <Select
              inputProps={{className: 'text-xs'}}
              style={{width: '100%'}}
              value={value}
              options={options}
              placeholder="Select Bank"
              displayEmpty
              fullWidth
              onChange={(e, f) => handleChange(e, field, f)}
              variant="outlined"
              error={values[field] === '' && error}
            />
            {values[field] === '' ||
              (values[field] === null && error && (
                <FormHelperTextStyled className="text-error mx-3">
                  This field is required
                </FormHelperTextStyled>
              ))}
          </FormControlStyled>
        </>
      )
    }
  }

  const option_bank = []
  data &&
    data.global_banks.map(row => {
      option_bank.push({
        value: row.id,
        label: row.name,
      })
    })

  const fieldList = [
    {
      number: !smallSize && '1',
      fieldName: 'Bank Name*',
      field: 'bank',
      type: 'select',
      value: values.bank,
      options: option_bank.length >= 1 ? option_bank : [],
    },
    {
      number: !smallSize && '2',
      fieldName: 'Name of Account Holder*',
      field: 'account_name',
      type: 'text',
      value: values.account_name,
    },
    {
      number: !smallSize && '3',
      fieldName: 'Account Number*',
      field: 'account_number',
      type: 'number',
      value: values.account_number,
    },
  ]

  const handleSubmit = () => {
    const {bank, account_name, account_number} = values
    if (
      // name === '' ||
      bank === null ||
      account_name === '' ||
      account_number === ''
    ) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      setOpenSubmit(true)
    }
  }

  const handleClickCancel = () => {
    setOpen({
      ...open,
      dialogCancel: false,
    })
    setPage('bank-tax')
  }

  const MutationAdd = async () => {
    try {
      const {loading, data: check} = await client.query({
        query: CEK_ID_BANK,
        variables: {
          account: values.account_number,
          user: USER_ID,
        },
      })
      if (!loading) {
        if (check.people_profile_banks.length >= 1) {
          const change = {
            user: USER_ID,
            bank: values.bank.value,
            bank_name: values.bank.label,
            account_name: values.account_name,
            account_number: values.account_number,
          }
          editApply({
            variables: {id: check.people_profile_banks[0].id, changes: change},
            context: {
              headers: {
                'X-Hasura-Role': 'user',
              },
            },
          })
            .then(() => {
              enqueueSnackbar('Apply for Account Bank Success', {
                variant: 'success',
              })
              // setForm(false)
              setPage('bank-tax')
              refetch()
              setOpenSubmit(false)
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
            })
        } else {
          const object = {
            bank: values.bank.value,
            bank_name: values.bank.label,
            account_name: values.account_name,
            account_number: values.account_number,
          }
          insertApply({
            variables: {object},
            context: {
              headers: {
                'X-Hasura-Role': 'user',
              },
            },
          })
            .then(() => {
              enqueueSnackbar('Apply for Account Bank Success', {
                variant: 'success',
              })
              // setForm(false)
              setPage('bank-tax')
              refetch()
              setOpenSubmit(false)
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
            })
        }
      }
    } catch (error) {
      return <div>{error}</div>
    }
  }

  const submitApplied = () => {
    if (type === 'Add') {
      MutationAdd()
    } else {
      const change = {
        user: USER_ID,
        bank: values.bank.value,
        bank_name: values.bank.label,
        account_name: values.account_name,
        account_number: values.account_number,
      }
      editApply({
        variables: {id: dataEdit.id, changes: change},
        context: {
          headers: {
            'X-Hasura-Role': 'user',
          },
        },
      })
        .then(() => {
          enqueueSnackbar('Apply for Account Bank Success', {
            variant: 'success',
          })
          // setForm(false)
          setPage('bank-tax')
          refetch()
          setOpenSubmit(false)
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
        })
    }
  }

  return (
    <>
      <div className="sm:p-8 p-6 flex flex-row">
        <div className="flex flex-row items-center">
          <IconButtonStyled
            className="p-0"
            // onClick={() => props.history.goBack()}
            onClick={() => setPage('bank-tax')}
            // onClick={() => setOpen({...open, dialogCancel: true})}
          >
            <ArrowBackIcon />
          </IconButtonStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TypographyStyled
              className="font-semibold"
              text="#014a62"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '15px',
              }}
            >
              Bank & Tax /
            </TypographyStyled>
            <TypographyStyled
              className="font-semibold"
              text="#a9a8a8"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '5px',
              }}
            >
              {type} Account Bank
            </TypographyStyled>
          </div>
        </div>
      </div>

      <Divider />

      <div className={`pt-8 sm:px-8 px-6 flex flex-col`}>
        {fieldList.map((res, i) => (
          <FormWrapper
            forMobile={smallSize}
            key={i}
            number={res.number}
            title={res.fieldName}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
      </div>

      <Divider />
      <div
        className="py-8 sm:px-8 px-6 flex flex-row ml-auto"
        style={{justifyContent: 'flex-end'}}
      >
        <ButtonStyled
          onClick={() =>
            setOpen({
              ...open,
              dialogCancel: true,
            })
          }
          text="#000"
          className="px-6 py-2"
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
          state="primary"
          className="px-6 py-2 ml-2"
        >
          Submit
        </ButtonStyled>
      </div>
      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        onClickPrimary={handleClickCancel}
      />

      <ConfirmationDialog
        title="Apply for Account Bank?"
        content="Are you sure you want to apply for this Account bank?"
        onClickPrimary={submitApplied}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={false}
      />
    </>
  )
}

export default AddBank
