import gql from 'graphql-tag'

export const GET_GLOBAL_PHONE_CODE = gql`
  query {
    global_phone_code(order_by: [{country: asc}]) {
      country
      code
    }
  }
`
