import React, {useEffect, useState} from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {IconButtonStyled} from '../../../components/button/ButtonStyles'
import Divider from '../../../components/divider/DividerStyles'
import ApplyForLeaveForm from './ApplyForLeaveForm'
import CancelDialog from '../../shared-component/dialog/CancelDialog'
import {withRouter} from 'react-router-dom'
import NotificationNoPolicy from '../../shared-component/dialog/NotificationNoPolicy'

function ApplyForLeave(props) {
  const {dataPolicy, dataScheduleHoliday, openNopol, setOpenNopol} = props
  const [open, setOpen] = useState({
    dialogCancel: false,
  })

  useEffect(() => {
    if (
      dataPolicy &&
      dataPolicy.people_work_placements[0].company_job_profile
        .company_job_profile_r_leaves.length === 0
    ) {
      setOpenNopol({...openNopol, notificationNoPolicy: true})
    }
  }, [dataPolicy])

  // const handleClickCancel = () => {
  //   setOpen({
  //     ...open,
  //     dialogCancel: false,
  //   })
  //   setForm(false)
  // }

  const back = () => {
    props.history.push({pathname: '/leave'})
  }
  return (
    <>
      <div className="py-8 px-8 flex flex-row">
        <div className="flex flex-row items-center">
          <IconButtonStyled className="p-0" onClick={back}>
            <ArrowBackIcon />
          </IconButtonStyled>
          <div className="text-primary font-semibold text-lg ml-3">
            Apply for Leave and Permission
          </div>
        </div>
      </div>
      <Divider />
      <div className="">
        <ApplyForLeaveForm
          dataScheduleHoliday={dataScheduleHoliday}
          dataPolicy={dataPolicy}
          onClickCancel={() =>
            setOpen({
              ...open,
              dialogCancel: true,
            })
          }
          // onClose={() => setForm(false)}
          onClose={back}
          {...props}
        />
      </div>

      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        // onClickPrimary={handleClickCancel}
        onClickPrimary={back}
      />
      <NotificationNoPolicy
        open={openNopol.notificationNoPolicy}
        onBack={back}
      />
    </>
  )
}

export default withRouter(ApplyForLeave)
