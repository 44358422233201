import moment from 'moment'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {
  GET_CLAIM_POLICY_FILTER_LIST,
  GET_CLAIM_REQUESTER_FILTER_LIST,
} from '../../graphql/queries'

export const REQUEST_FILTER_CONFIG = [
  {
    fieldName: 'policies',
    name: 'Claim Policy',
    type: 'checkbox',
    emptyState: 'No claim policies',
    options: {
      fetch: {
        query: GET_CLAIM_POLICY_FILTER_LIST,
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit,
            where: {
              company_job_profile: {
                people_work_placements: {
                  user: {_eq: USER_ID},
                  company: {_eq: COMPANY_ID},
                },
              },
              finance_claim_policy_child: {
                id: {_in, _nin},
                deletedAt: {_is_null: true},
                name: {_ilike: search},
              },
            },
          }
        },
        setData: data => {
          const options = data.policies.map(item => ({
            value: item.policy.id,
            label: item.policy.name,
          }))

          return [options, data.total.aggregate.count]
        },
      },
    },
  },
  {
    fieldName: 'status',
    name: 'Status',
    type: 'checkbox',
    emptyState: 'No status',
    options: {
      list: [
        {value: 'waiting', label: 'Waiting'},
        {value: 'processing', label: 'Processing'},
        {value: 'approved', label: 'Approved'},
        {value: 'rejected', label: 'Rejected'},
        {value: 'cancelled', label: 'Canceled'},
      ],
    },
  },
  {
    fieldName: 'date',
    name: 'Submission Date',
    type: 'date',
  },
  {
    fieldName: 'placements',
    name: 'Requester',
    type: 'checkbox',
    emptyState: 'No requesters',
    options: {
      fetch: {
        query: GET_CLAIM_REQUESTER_FILTER_LIST,
        context: {
          headers: {
            'X-Hasura-Role': 'organization-staff',
          },
        },
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit,
            where: {
              status: {_eq: 'ACTIVE'},
              company: {_eq: COMPANY_ID},
              global_user: {id: {_in, _nin}, name: {_ilike: search}},
              people_work_claims: {},
            },
          }
        },
        setData: data => {
          const options = data.placements.map(item => ({
            value: item.user.id,
            label: item.user.name,
          }))

          return [options, data.total.aggregate.count]
        },
      },
    },
  },
]

export function getRequestFilterVariables(filterData) {
  if (!filterData) {
    return {
      filter: {},
      policies: [],
      statuses: [],
    }
  }

  const include = filterData[0]
  const undef = undefined

  const policies = include.policies
  const statuses = include.status
  const placements = include.placements

  const date = include.date
  const dateFrom = date?.from
  const dateTo = date?.to

  const dateFromFormatted = dateFrom ? formatDate(dateFrom) : undef
  const dateToFormatted = dateTo ? formatDate(dateTo) : undef

  const variables = {
    filter: {
      date: date ? {from: dateFromFormatted, to: dateToFormatted} : undef,
      user_ids: placements?.length ? mapValues(placements) : undef,
    },
    policies: policies?.length ? mapValues(policies) : [],
    statuses: statuses?.length ? mapValues(statuses) : [],
  }

  return variables
}

function formatDate(date) {
  return moment(date).format('YYYY-MM-DD')
}

function mapValues(array) {
  return array.map(item => item.value)
}
