import THR from './THRPageComponent'

export const THRConfig = {
  routes: [
    {
      path: '/thr',
      component: THR,
      state: 'THR',
      exact: true,
    },
  ],
}
