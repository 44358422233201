import React from 'react'
import {UserLoggedInProfileWrapper} from '../header-menu/HeaderMenuTimeStyles'
import {withRouter} from 'react-router-dom'
import useGetProfile from '../../hooks/useGetProfile'
import {SOSMED_APP_URL} from '../../utils/globals'

const customStyle = {
  usernameLabel: {
    color: '#252525',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 20,
  },

  posisitionLabel: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 8,
    width: 180,
    marginBottom: 40,
  },
}

const UserLoggedIn = () => {
  const {id, avatar, name, position, company} = useGetProfile()

  const handleClick = () => {
    window.location.replace(`${SOSMED_APP_URL}/profile/${id}/detail`)
  }

  return (
    <>
      <UserLoggedInProfileWrapper onClick={handleClick}>
        <img
          alt="user-logged-icon"
          src={avatar || require('../../assets/slicing/default-avatar.png')}
          style={{
            height: '90px',
            width: '90px',
            borderRadius: '50%',
          }}
        />
        <label style={customStyle.usernameLabel}>{name}</label>
        <label style={customStyle.posisitionLabel}>
          {`${position} at ${company}`}
        </label>
      </UserLoggedInProfileWrapper>
    </>
  )
}

export default withRouter(UserLoggedIn)
