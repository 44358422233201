import gql from 'graphql-tag'

export const GET_STATUS_ATTENDANCE = gql`
  query(
    $user: uuid
    $sorting: order_by
    $offset: Int
    $limit: Int
    $idPolicy: [Int!]
    $dateStart: timestamptz
    $dateEnd: timestamptz
  ) {
    total: people_work_attendances_aggregate(
      where: {
        people_work_placement: {user: {_eq: $user}}
        policy_id: {_in: $idPolicy}
        _and: [{clock_in: {_gte: $dateStart}}, {clock_in: {_lte: $dateEnd}}]
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_attendances(
      where: {
        people_work_placement: {user: {_eq: $user}}
        policy_id: {_in: $idPolicy}
        _and: [{clock_in: {_gte: $dateStart}}, {clock_in: {_lte: $dateEnd}}]
      }
      order_by: [{clock_in: $sorting}, {id: $sorting}]
      offset: $offset
      limit: $limit
    ) {
      id
      clock_in
      clock_out
      time_attendance_policy {
        id
        name
        deletedAt
        mobile_setting
        schedule
        description
        flexi
      }
      location
      attendance_fields
    }

    first_data: people_work_attendances(
      where: {
        people_work_placement: {user: {_eq: $user}}
        time_attendance_policy: {}
      }
      limit: 1
      order_by: [{clock_in: desc}, {id: desc}]
    ) {
      id
      clock_in
      clock_out
      time_attendance_policy {
        id
        name
        deletedAt
        mobile_setting
        schedule
        description
        flexi
      }
      location
      attendance_fields
    }
  }
`

export const GET_REPORT_USER = gql`
  query($userWorkId: Int, $startDate: String, $endDate: String) {
    getAttendanceReportsUser(
      userWorkId: $userWorkId
      startDate: $startDate
      endDate: $endDate
    ) {
      countIn
      countAbsent
      countLateDays
      countLateMinutes
      countOnTrip
    }
  }
`
export const GET_ID_PLACEMENT = gql`
  query($user: uuid) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
      start_date
    }
  }
`

export const GET_DETAIL_STATUS_ATTENDANCE = gql`
  query($id: bigint!) {
    people_work_attendances_by_pk(id: $id) {
      id
      date_added
      photo
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
        }
      }
      time_attendance_policy {
        id
        name
        schedule
        deletedAt
        late_tolerance
        flexi
        mobile_setting
        location_option
        time_attendance_policy_locations {
          id
          time_attendance_location {
            id
            name
            location
          }
        }
      }
      clock_in
      clock_out
      location
      attendance_fields
      late_minutes
    }
  }
`
