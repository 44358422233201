import React, {memo} from 'react'
import moment from 'moment'

const formatdDate = (date, format = 'dddd') => {
  if (date) {
    return moment(date).format(format)
  } else {
    return '-'
  }
}

const generateDate = (titleDate, con, format = 'dddd') => {
  if (titleDate) {
    if (titleDate[`_${con}`] !== titleDate[con]) {
      return formatdDate(titleDate[`_${con}`], format)
    } else {
      return formatdDate(titleDate[con], format)
    }
  } else return '-'
}

export default memo(function TitleDate({titleDate}) {
  return (
    <section className="flex flex-row mr-auto items-center">
      <div className="flex flex-col ">
        <h6 className="text-disable text-xs">From</h6>
        <div className="flex flex-row">
          <h3
            className="font-semibold text-primary mr-2"
            style={{fontSize: 25}}
          >
            {generateDate(titleDate, 'start', 'D')}
          </h3>
          <div className="flex flex-col">
            <h4 className="font-semibold text-xs text-primary">
              {generateDate(titleDate, 'start', 'MMMM yyyy')}
            </h4>
            <h5 style={{fontSize: 10}} className="text-disable">
              {generateDate(titleDate, 'start')}
            </h5>
          </div>
        </div>
      </div>
      <div className="mx-10 font-semibold">-</div>
      <div className="flex flex-col ">
        <h6 className="text-disable text-xs">To</h6>
        <div className="flex flex-row">
          <h3
            className="font-semibold text-primary mr-2"
            style={{fontSize: 25}}
          >
            {generateDate(titleDate, 'end', 'D')}
          </h3>
          <div className="flex flex-col">
            <h4 className="font-semibold text-xs text-primary">
              {generateDate(titleDate, 'end', 'MMMM yyyy')}
            </h4>
            <h5 style={{fontSize: 10}} className="text-disable">
              {generateDate(titleDate, 'end')}
            </h5>
          </div>
        </div>
      </div>
    </section>
  )
})
