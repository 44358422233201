import React from 'react'
// import development from '../../assets/slicing/webondev.svg'
// import {Button} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

const UnderDevelopment = () => {
  const style = {
    absolute: {
      height: '100vh',
      width: '100%',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
    },
    wrapper: {
      width: '86vw',
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      alignItems: 'center',
      flexWrap: 'wrap',
      backgroundSize: 'cover',
      justifyContent: 'space-between',
    },
    textRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      fontFamily: 'muli',
    },
    h1: {
      fontFamily: 'inherit',
      fontWeight: '600',
    },
    p: {
      fontFamily: 'inherit',
      color: '#a9a8a8',
      margin: '10px 0px 30px 0px',
      fontSize: 14,
    },
    image: {
      width: '50%',
    },
    textWrapper: {
      maxWidth: 516,
    },
  }

  return (
    <div>
      {/* <div style={style.wrapper}>
        <img style={style.image} src={development} />
        <div style={style.textWrapper}>
          <div style={style.textRight}>
            <h1 style={style.h1}>Website is under development</h1>
            <p style={style.p}>
              We currently working on creating out new website. Be ready, we are
              launching soon. Subscribe to notified.
            </p>
            <div>
              <Button
                disableElevation
                style={{width: 'auto', fontSize: 12, padding: '8px 18px'}}
                color="primary"
                variant="contained"
                onClick={() => props.history.goBack()}
              >
                Notify Me
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <img
        style={style.absolute}
        src={require('../../assets/images/page-under-dev.png')}
        alt=""
      />
    </div>
  )
}

export default withRouter(UnderDevelopment)
