import React, {useState, useEffect} from 'react'
import ListItemModalFinance from '../list/ListItemModalFinance'
import LoadingComponent from '../loading/LoadingComponent'
import moment from 'moment'
import TableFinance from '../table/TableFinance'
import styled from 'styled-components'
import {Button, useMediaQuery, useTheme} from '@material-ui/core'
import {GET_DETAIL_SLIP, GET_DETAIL_THR} from '../../../graphql/queries'
import {useLazyQuery} from '@apollo/react-hooks'
import {
  generateDateMonth,
  convertToRoundedRupiah,
  numberToMonth,
} from '../../../utils/helpers'

export default function DetailListFinance({id, from}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [getDataDetail, {data: dataDetail, error, loading}] = useLazyQuery(
    from === 'thr' ? GET_DETAIL_THR : GET_DETAIL_SLIP
  )

  const [takeHomePay, setTakeHomePay] = useState(0)

  useEffect(() => {
    if (id) {
      getDataDetail({
        variables: {
          id: id,
        },
        context: {
          headers: {
            'X-Hasura-Role': 'organization-staff',
          },
        },
      })
    }
  }, [id])

  if (loading) {
    return <LoadingComponent />
  } else if (error) {
    return <span>{error}</span>
  } else if (dataDetail) {
    // console.log('dataDetail : ', dataDetail)

    const data =
      from === 'thr'
        ? dataDetail.people_work_thr_by_pk
        : dataDetail.people_work_payroll_by_pk

    const dataEmployeePlacement =
      from === 'thr' ? data.people_work_placement : data.employee_placement

    return (
      <>
        <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-2`}>
          <div
            className={`flex flex-col px-6 ${
              fullScreen ? 'pt-6' : 'pt-4'
            } w-1/2`}
          >
            <ListItemModalFinance title="Name">
              {dataEmployeePlacement?.global_user?.name}
            </ListItemModalFinance>
            <ListItemModalFinance title="ID Employee">
              {dataEmployeePlacement?.regno}
            </ListItemModalFinance>
            <ListItemModalFinance title="Organization">
              {
                dataEmployeePlacement?.company_job_profile?.company_organization
                  ?.name
              }
            </ListItemModalFinance>
            <ListItemModalFinance title="Designation">
              {
                dataEmployeePlacement?.company_job_profile
                  ?.company_employee_position?.name
              }
            </ListItemModalFinance>
            <ListItemModalFinance title="Location">
              {
                dataEmployeePlacement?.company_job_profile?.company_address
                  ?.office_name
              }
            </ListItemModalFinance>
          </div>

          <div
            className={`flex flex-col px-6 ${
              fullScreen ? 'pt-6' : 'pt-4'
            } w-1/2`}
          >
            <ListItemModalFinance title="NPWP">
              {dataEmployeePlacement?.people_work_salaries[0]?.npwp || '-'}
            </ListItemModalFinance>
            <ListItemModalFinance title="PTKP">
              {dataEmployeePlacement?.people_work_salaries?.[0]?.salary_fields
                ?.PTKP || '-'}
            </ListItemModalFinance>
            <ListItemModalFinance title="Period">{`${numberToMonth(
              data?.month
            )} ${data?.year}`}</ListItemModalFinance>
            <ListItemModalFinance title="Created Date">
              {data?.date_sent ? generateDateMonth(data?.date_sent) : '-'}
            </ListItemModalFinance>
            <ListItemModalFinance title="Printed Date">
              {data?.date_printed
                ? generateDateMonth(data?.date_printed)
                : moment(new Date()).format('ll')}
            </ListItemModalFinance>
          </div>
        </div>

        <div className={`px-6 ${fullScreen ? 'py-4' : 'py-6'}`}>
          <TableFinance
            data={data}
            from={from}
            setTakeHomePay={setTakeHomePay}
          />
        </div>

        <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-2`}>
          <div
            className={`flex flex-col px-6 ${
              fullScreen ? 'pt-6' : 'pt-4'
            } w-1/2`}
          >
            <ListItemModalFinance title="Bank Account">
              <p>
                {dataEmployeePlacement?.people_work_salaries?.[0]
                  ?.people_profile_bank?.global_bank?.name || '-'}
              </p>
              <p>
                {dataEmployeePlacement?.people_work_salaries?.[0]
                  ?.people_profile_bank?.account_number || '-'}
              </p>
            </ListItemModalFinance>

            <ListItemModalFinance title="A/C Name">
              {dataEmployeePlacement?.people_work_salaries?.[0]
                ?.people_profile_bank?.account_name || '-'}
            </ListItemModalFinance>
          </div>

          <div
            className={`flex flex-col px-6 ${
              fullScreen ? 'pt-6' : 'pt-4'
            } w-1/2`}
          >
            <ListItemModalFinance title="Take Home Pay">
              <p className="font-bold text-base text-right">
                {convertToRoundedRupiah(takeHomePay || 0, false)}
              </p>
            </ListItemModalFinance>
          </div>
        </div>

        <ButtonContainer className="w-full flex flex-row justify-center pt-4 mb-6 items-center">
          <Button
            className="hide-on-print px-12 py-2 ml-6"
            variant="contained"
            color="primary"
            onClick={() => window.print()}
          >
            Download
          </Button>
        </ButtonContainer>
      </>
    )
  } else {
    return <span />
  }
}

export const ButtonContainer = styled.div`
  width: 100%;

  @media print {
    .hide-on-print,
    .hide-on-print * {
      display: none !important;
    }
  }
`
