import { generateDateSubmit } from "../../utils/helpers";

export const listFilter = [
  {
    name: "Submission Date",
    fieldName: "date",
    type: "date",
  },
];

const _formated = (date) => generateDateSubmit(date);

export const generateFilter = (data) => {
  const _data = {};
  for (const property in data) {
    if (property === "date") {
      _data[property] = {
        ...(data[property].from && {
          from: _formated(data[property].from),
        }),
        ...(data[property].to && { to: _formated(data[property].to) }),
      };
    }
  }
  return _data;
};
