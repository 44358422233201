export const fileCheckerDocument = (name, setFileType) => {
  const nameSplited = name.split('.')
  const idx = nameSplited.length - 1
  const res = nameSplited[idx]

  if (
    res === 'doc' ||
    res === 'docx' ||
    res === 'xls' ||
    res === 'pdf' ||
    res === 'xlsx' ||
    res === 'ppt'
  ) {
    setFileType('document')
    return false
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif'
  ) {
    setFileType('image')
    return false
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    setFileType('image')
    return false
  } else {
    return true
  }
}
export const checkFilesType = (files, setFileType) => {
  let result = false
  let isMore25mb = false
  files.forEach(file => {
    if (fileCheckerDocument(file.name, setFileType)) {
      result = true
    }
    if (file.size >= 1024 * 1024 * 25) {
      isMore25mb = true
    }
  })
  return [result, isMore25mb]
}
