import React, {useState} from 'react'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {IconStyled} from '../../../components/Icons/IconComponent'
import {TabsStyled, TabStyled} from '../../../components/tabs/TabsComponents'
import CancelDialog from '../../shared-component/dialog/CancelDialog'

import TabActivity from './activity/TabActivity'
import TabRequest from './request/TabRequest'
import TabBalance from './balance/TabBalance'
import {Link, withRouter} from 'react-router-dom'
function LeaveTab(props) {
  const {dataId, form, dataActivity, open, setOpen} = props
  const [tabsValue, setTabsValue] = useState(0)

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <>
      <div className="py-8 px-8 flex flex-row justify-between">
        <div className="flex flex-row">
          <div className="flex items-center">
            <IconStyled text="#014a62" className="mr-3 align-middle">
              alarm_off
            </IconStyled>
            <TypographyStyled
              component="span"
              className="text-primary font-semibold text-lg"
              text="#014a62"
            >
              Leave and Permission
            </TypographyStyled>
          </div>
        </div>
        <ButtonStyled
          component={Link}
          to="/leave/apply"
          state="primary"
          className="text-xs md:text-sm text-center"
        >
          Apply for Leave and Permission
        </ButtonStyled>
      </div>
      <TabsStyled value={tabsValue} onChange={handleChange}>
        <TabStyled label="Activity" />
        <TabStyled label="Request" />
        <TabStyled label="Balance" />
      </TabsStyled>
      {tabsValue === 0 && (
        <TabActivity
          dataActivity={dataActivity}
          form={form}
          idWork={dataId && dataId.people_work_placements[0].id}
        />
      )}
      {tabsValue === 1 && (
        <TabRequest idWork={dataId && dataId.people_work_placements[0].id} />
      )}
      {tabsValue === 2 && <TabBalance />}
      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        onClickPrimary={() =>
          setOpen({
            ...open,
            form: false,
            dialogCancel: false,
          })
        }
      />
    </>
  )
}

export default withRouter(LeaveTab)
