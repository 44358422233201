import React, {useState} from 'react'
import {
  TextFieldStyledNoLine,
  MenuItemStyled,
} from '../../../../components/form/FormStyles'
import ListItem from '../../../shared-component/list/ListItem'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_LIST_REQUEST_LEAVE} from '../../../../graphql/queries/leave/listLeave.query'
import {
  addDay,
  generateDate,
  generateDateSubmit,
  getApproalType,
} from '../../../../utils/helpers'
import ModalDetailWeb from '../../../shared-component/dialog/ModalDetailWeb'
import DetailList from '../../../shared-component/dialog/DetailList'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {
  UPDATE_STATUS_APPROVE_REQUEST,
  UPDATE_STATUS_CHANGES_LEAVE,
} from '../../../../graphql/mutations/leave/requestLeave.mutation'
import {useSnackbar} from 'notistack'
import FilterWLB from '../../../shared-component/Filter/FilterWLB'
import IconFilterActive from '../../../shared-component/Filter/IconFilterActive'
import TextfieldSearch from '../../../shared-component/form/TextfieldSearch'
import EmptyState from '../../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../../assets/images/nodatalist.png'
import {PAGINATION_RANGE} from '../../../../utils/constants'
import {
  COMPANY_ID,
  POSITION_NAME,
  USER_ID,
  USER_NAME,
} from '../../../../utils/globals'

export default function TabRequest({idWork}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [filter, setFilter] = useState({
    policy: [],
    requester: [],
    date: {
      dateStart: null,
      dateEnd: null,
    },
    status: {
      status: [],
    },
  })
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE[0],
    page: 0,
  })
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState({
    web: false,
    mobile: false,
    data: null,
    cancel: false,
    idCancel: null,
  })
  const [sort, setSort] = useState('desc')

  const {data: dataRequest, loading, error, refetch} = useQuery(
    GET_LIST_REQUEST_LEAVE,
    {
      fetchPolicy: 'network-only',
      variables: {
        search: `%${search}%`,
        user: USER_ID,
        company: COMPANY_ID,
        sorting: sort,
        idPolicy: filter.policy.length > 0 ? filter.policy : null,
        idRequester: filter.requester.length > 0 ? filter.requester : null,
        dateStart: filter.date.dateStart,
        dateEnd: addDay(filter.date.dateEnd, 1),
        status: filter.status.status.length === 0 ? null : filter.status.status,
        limit: pagination.rowsPerPage,
        offset: pagination.page * pagination.rowsPerPage,
      },
    }
  )

  const [updateStatusRequest] = useMutation(UPDATE_STATUS_APPROVE_REQUEST)
  const [updateStatusChange] = useMutation(UPDATE_STATUS_CHANGES_LEAVE)

  if (error) {
    console.error(error)
  }

  const handleClick = res => {
    setOpen({mobile: false, web: true, data: res})
  }

  const handleApprove = data => {
    const second = data.time_leave_policy_child.second_job_profile
    const approvalType = getApproalType(
      data,
      'approved',
      'time_leave_policy_child'
    )
    const send = {
      id: open.data.id,
      status:
        second === null || data.status === 'confirm1' ? 'approved' : 'confirm1',
      leave_fields: {
        ...data.leave_fields,
        [approvalType]: {
          type: 'user',
          user: {
            id: USER_ID,
            name: USER_NAME,
            title: POSITION_NAME,
          },
        },
      },
    }
    updateStatusRequest({
      variables: send,
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Applied successfully Approved', {
          variant: 'success',
        })
        setOpen({...open, web: false, data: null})
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleReject = (text, id, data) => {
    const approvalType = getApproalType(
      data,
      'rejected',
      'time_leave_policy_child'
    )
    const send = {
      id: id,
      changes: {
        status: 'rejected',
        leave_fields: {
          ...open.data.leave_fields,
          status: 'rejected',
          reason: text,
          sub_type: 'company-time-leave-rejected',
          [approvalType]: {
            type: 'user',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      },
    }
    updateStatusChange({
      variables: send,
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Applied successfully rejected', {
          variant: 'success',
        })
        setOpen({...open, web: false, data: null})
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleQueryFiltered = (policy, date, requester, status) => {
    const idPolicy = []
    const idRequester = []
    const statusStg = []
    if (policy.length > 0) {
      policy.forEach(pol => {
        idPolicy.push(pol.id)
      })
    }
    status.forEach(stat => {
      if (stat.id === 1) {
        statusStg.push('pending')
        statusStg.push('confirm1')
      } else if (stat.id === 2) {
        statusStg.push('approved')
      } else if (stat.id === 3) {
        statusStg.push('rejected')
      } else if (stat.id === 4) {
        statusStg.push('cancelled')
      }
    })
    if (requester.length > 0) {
      requester.forEach(people => {
        idRequester.push(people.id)
      })
    }
    setFilter({
      ...filter,
      policy: idPolicy,
      requester: idRequester,
      date: {
        dateStart: generateDateSubmit(date.from),
        dateEnd: generateDateSubmit(date.to),
      },
      status: {
        status: statusStg,
      },
    })
    setAnchorEl(null)
  }

  const openFilter = Boolean(anchorEl)
  const idFilter = openFilter ? 'filter-attendance-list' : undefined
  const {
    policy,
    date: {dateStart, dateEnd},
    status: {status},
    requester,
  } = filter
  return (
    <>
      <div className="flex flex-col px-0 min-h-screen">
        <div
          className="py-4 px-6 flex flex-row justify-between items-center"
          style={{borderBottom: '1px solid #f7f8f9'}}
        >
          <TextFieldStyledNoLine
            value={sort}
            onChange={e => setSort(e.target.value)}
            select
            style={{width: 'auto'}}
            size="small"
          >
            <MenuItemStyled value="desc">Latest</MenuItemStyled>
            <MenuItemStyled value="asc">Oldest</MenuItemStyled>
          </TextFieldStyledNoLine>
          <div
            className="cursor-pointer text-sm items-center flex flex-row"
            onClick={e => setAnchorEl(e.currentTarget)}
            aria-describedby={idFilter}
          >
            <IconFilterActive
              isFiltered={
                policy[0] || dateStart || dateEnd || status[0] || requester[0]
              }
            />
            Filter
          </div>
        </div>
        <TextfieldSearch
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div>
          {loading ? (
            <LoadingComponent />
          ) : !loading &&
            dataRequest &&
            dataRequest.people_work_leaves.length > 0 ? (
            dataRequest.people_work_leaves.map((res, i) => {
              return (
                <ListItem
                  idDisplay={res.id_display}
                  onClick={() => handleClick(res)}
                  key={i}
                  name={res.people_work_placement.global_user.name}
                  title={res.time_leave_policy_child.name}
                  time={
                    res.datestart === res.dateend
                      ? `${generateDate(res.datestart)}`
                      : `${generateDate(res.datestart)} - ${generateDate(
                          res.dateend
                        )}`
                  }
                  submited={generateDate(res.date_added)}
                  status={res.status}
                  confirm_type={res.time_leave_policy_child.confirm_type}
                  data={res}
                />
              )
            })
          ) : (
            <EmptyState
              image={NoListImage}
              message1="Sorry, No List"
              message2="No leave found"
            />
          )}
        </div>
        <TablePaginationStyled
          className="mt-auto"
          rowsPerPageOptions={PAGINATION_RANGE}
          onChangePage={(e, newPage) => {
            e.preventDefault()
            setPagination({...pagination, page: newPage})
          }}
          onChangeRowsPerPage={e => {
            setPagination({
              ...pagination,
              page: 0,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }}
          component="div"
          count={dataRequest && dataRequest.total.aggregate.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
        />

        <FilterWLB
          id={idFilter}
          open={openFilter}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          idWork={idWork}
          withPolicy
          withRequester
          from={'leave-request'}
          onApply={(policy, date, requester, status) => {
            handleQueryFiltered(policy, date, requester, status)
          }}
          components={[
            {
              idx: 'type',
              name: 'Leave and Permission Type',
              type: 'policy',
            },
            {
              idx: 'status',
              name: 'Status',
              type: 'status',
            },
            {
              idx: 'requester',
              name: 'Requester',
              type: 'requester',
            },
            {
              idx: 'submission',
              name: 'Submission Date',
              type: 'date',
            },
          ]}
        />

        <ModalDetailWeb
          title={open.data && open.data.time_leave_policy_child.name}
          open={open.web}
          onClose={() => setOpen({...open, web: false, data: null})}
          status={open.data && open.data.status}
          confirm_type={
            open.data && open.data.time_leave_policy_child.confirm_type
          }
          data={open.data}
        >
          <DetailList
            open={open.web}
            from="leave"
            type="request"
            id={open.data && open.data.id}
            onClickApprove={data => handleApprove(data)}
            onCancel={() => setOpen({...open, web: false, data: null})}
            onClickSubmitReject={(text, id, data) =>
              handleReject(text, id, data)
            }
          />
        </ModalDetailWeb>
      </div>
    </>
  )
}
