import React from 'react'
import {withRouter} from 'react-router-dom'
import {useApolloClient} from '@apollo/react-hooks'

import {Box, Button, FormHelperText, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import {UploadButton, AttachmentItem} from '@smartasn/wlb-utils-components'
import moment from 'moment'
import AsyncSelect from 'react-select/async'
import {useSnackbar} from 'notistack'

import {Paper} from '../../components/paper/PaperStyles'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import {IconButtonStyled} from '../../components/button/ButtonStyles'
import Divider from '../../components/divider/DividerStyles'
import {
  KeyboardDatePickerStyled,
  TextFieldStyled,
} from '../../components/form/FormStyles'
import FormWrapper from '../shared-component/form/FormWrapper'
import DatePickerWrapper from '../shared-component/date/DatePickerWrapper'
import {InfoFileTooltip} from '../shared-component/tooltip/InfoFileTooltip'
import ContentTooltipFile from '../shared-component/tooltip/ContentTooltipFile'
import ConfirmationDialog from '../shared-component/dialog/ConfirmationDialog'
import CancelDialog from '../shared-component/dialog/CancelDialog'

import axios from 'axios'
import {
  COMPANY_ID,
  NAME_USER_LOGIN,
  TOKEN,
  UPLOAD_URL,
  USER_ID,
} from '../../utils/globals'
import {GET_CLAIM_POLICY_FILTER_LIST} from '../../graphql/queries'
import {REQUEST_CLAIM} from '../../graphql/mutations'

const formatter = new Intl.NumberFormat('id-ID')

const CLAIM_FORM_CONFIG = [
  {
    type: 'text',
    field: 'name',
    label: 'Name',
    required: true,
    disabled: true,
  },
  {
    type: 'policy',
    field: 'policy',
    label: 'Claim Policy',
    required: true,
  },
  {
    type: 'date',
    field: 'issued',
    label: 'Issued Date',
    required: true,
  },
  {
    type: 'currency',
    field: 'nominal',
    label: 'Amount',
    required: true,
  },
  {
    type: 'attachment',
    field: 'attachments',
    label: (
      <span className="flex items-center">
        Receipt*
        <div className="ml-2" />
        <InfoFileTooltip title={<ContentTooltipFile />} placement="right">
          <InfoOutlinedIcon className="text-xs text-primary" />
        </InfoFileTooltip>
      </span>
    ),
    required: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    multiline: true,
  },
]

const INITIAL_FORM_STATE = {
  name: NAME_USER_LOGIN,
  policy: null,
  issued: null,
  nominal: '',
  attachments: {
    files: [],
    error: false,
    pending: 0,
    invalidType: false,
    overSize: false,
  },
  description: '',

  confirm_submit: false,
  confirm_close: false,
}

const STATE_AVAILABLE = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const LABEL_REQUIRED = 'This field is required'

function ClaimApply(props) {
  const [state, setState] = React.useState(STATE_AVAILABLE)
  const [formData, setFormData] = React.useState(INITIAL_FORM_STATE)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const fetchClaimPolicies = React.useCallback(
    async searchText => {
      const {data} = await client.query({
        query: GET_CLAIM_POLICY_FILTER_LIST,
        variables: {
          where: {
            company_job_profile: {
              people_work_placements: {
                user: {_eq: USER_ID},
                company: {_eq: COMPANY_ID},
              },
            },
            finance_claim_policy_child: {
              deletedAt: {_is_null: true},
              name: {_ilike: `%${searchText}%`},
            },
          },
          limit: 20,
        },
      })

      const result = data?.policies.map(item => ({
        label: item.policy.name,
        value: item.policy.id,
      }))

      return result || []
    },
    [client]
  )

  const bindInput = item => event => {
    const field = item.field
    let value = event.target.value

    if (item.type === 'attachment') {
      const pending = Array.from(event.target.files)
      const queue = []

      let invalidType = false
      let overSize = false

      for (const file of pending) {
        if (!validateAttachmentFilename(file.name)) {
          invalidType = true
          continue
        }

        if (file.size >= 25 * 1024 * 1024) {
          overSize = true
          continue
        }

        queue.push(file)
      }

      setFormData(prev => {
        const prevd = prev[field]

        return {
          ...prev,
          [field]: {
            ...prevd,
            error: prevd.pending ? prevd.error : false,
            pending: prevd.pending + queue.length,
            invalidType,
            overSize,
          },
        }
      })

      for (const file of queue) {
        const form = new FormData()
        form.append('file', file)

        const promise = axios.post(UPLOAD_URL, form, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })

        cbify(promise, (error, response) => {
          const data = response && response.data

          setFormData(prev => {
            const prevd = prev[field]
            let files = prevd.files

            if (data) {
              files = files.slice()
              files.push({url: data.url, name: file.name, size: file.size})
            }

            return {
              ...prev,
              [field]: {
                ...prevd,
                files,
                error: prevd.error || error !== null,
                pending: prevd.pending - 1,
              },
            }
          })
        })
      }

      return
    }

    if (item.type === 'currency') {
      value = value.replace(/[^0-9]+/g, '')

      if (value) {
        value = parseInt(value)
      }
    }

    setFormData({...formData, [field]: value})
  }

  const bindValue = item => value => {
    if (value && item.type === 'date') {
      value.setHours(0, 0, 0, 0)
    }

    setFormData({...formData, [item.field]: value})
  }

  const bindChangeAttachmentFilename = (item, idx) => event => {
    const field = item.field
    const prevd = formData[field]

    const files = prevd.files.slice()

    files[idx].name = event.target.value
    setFormData({...formData, [field]: {...prevd, files}})
  }

  const bindRemoveAttachment = (item, idx) => () => {
    const field = item.field
    const prevd = formData[field]

    const files = prevd.files.slice()

    files.splice(idx, 1)
    setFormData({...formData, [field]: {...prevd, files}})
  }

  const handleCloseOpen = () => {
    setFormData({...formData, confirm_close: true})
  }

  const handleCloseCancel = () => {
    setFormData({...formData, confirm_close: false})
  }

  const handleSubmitOpen = () => {
    for (const item of CLAIM_FORM_CONFIG) {
      const field = item.field
      const required = item.required

      const value = formData[field]

      if (item.type === 'attachment') {
        if ((required && value.files.length < 1) || value.pending > 0) {
          setState(STATE_ERROR)
          return
        }
      } else if (required && !value) {
        setState(STATE_ERROR)
        return
      }
    }

    setFormData({...formData, confirm_submit: true})
  }

  const handleSubmitCancel = () => {
    setFormData({...formData, confirm_submit: false})
  }

  const handleSubmitConfirm = () => {
    setState(STATE_DISPATCH)

    const promise = client.mutate({
      mutation: REQUEST_CLAIM,
      variables: {
        policyId: formData.policy.value,
        issuedDate: moment(formData.issued).format('YYYY-MM-DD'),
        nominal: formData.nominal,
        receipts: formData.attachments.files,
        description: formData.description,
      },
      context: {headers: {'X-Hasura-Role': 'user'}},
    })

    promise
      .then(result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        enqueueSnackbar('Claim request successfully created', {
          variant: 'success',
        })

        props.history.goBack()
      })
      .catch(() => {
        setState(STATE_AVAILABLE)

        enqueueSnackbar('Failed to create claim request', {
          variant: 'error',
        })
      })
  }

  const renderComponent = item => {
    const {type, field, disabled, required} = item

    let value = formData[field]

    const isDisabled = disabled || state >= STATE_DISPATCH
    const isError = state === STATE_ERROR && required && !value

    if (type === 'text' || type === 'currency') {
      const isCurrency = type === 'currency'

      if (isCurrency && value) {
        value = formatter.format(value)
      }

      return (
        <TextFieldStyled
          value={value}
          disabled={isDisabled}
          error={isError}
          helperText={isError && LABEL_REQUIRED}
          onChange={bindInput(item)}
          multiline={item.multiline}
          minRows={5}
          fullWidth
          InputProps={{
            className: 'text-xs',
            startAdornment: type === 'currency' && (
              <div className="pr-2">Rp</div>
            ),
          }}
          variant="outlined"
          size="small"
        />
      )
    } else if (type === 'date') {
      return (
        <DatePickerWrapper>
          <KeyboardDatePickerStyled
            value={value}
            disabled={isDisabled}
            error={isError}
            helperText={isError && LABEL_REQUIRED}
            onChange={bindValue(item)}
            fullWidth
            inputVariant="outlined"
            format="MMMM dd, yyyy"
            inputProps={{className: 'text-xs'}}
            variant="outlined"
            size="small"
          />
        </DatePickerWrapper>
      )
    } else if (type === 'policy') {
      return (
        <>
          <AsyncSelect
            isDisabled={isDisabled}
            cacheOptions
            defaultOptions
            loadOptions={fetchClaimPolicies}
            value={value}
            onChange={bindValue(item)}
            menuPosition="fixed"
            className="text-xs"
          />

          {isError && (
            <Typography
              variant="caption"
              color="error"
              style={{marginLeft: 14}}
            >
              {LABEL_REQUIRED}
            </Typography>
          )}
        </>
      )
    } else if (type === 'attachment') {
      return (
        <>
          <UploadButton
            multiple
            disabled={isDisabled}
            onChange={bindInput(item)}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {value.files.map((file, idx) => (
              <AttachmentItem
                key={idx}
                name={file.name}
                size={file.size}
                url={file.url}
                disabled={isDisabled}
                onNameChange={bindChangeAttachmentFilename(item, idx)}
                onRemove={bindRemoveAttachment(item, idx)}
              />
            ))}

            {value.pending > 0 && <AttachmentItem isUploading />}
          </Box>

          {value.error && (
            <FormHelperText className="text-error">
              Some attachments failed to upload
            </FormHelperText>
          )}
          {value.invalidType && (
            <FormHelperText className="text-error">
              Attachments cannot have other file types
            </FormHelperText>
          )}
          {value.overSize && (
            <FormHelperText className="text-error">
              Attachments cannot exceed 25 MB in size
            </FormHelperText>
          )}
          {state === STATE_ERROR && value.pending > 0 && (
            <FormHelperText className="text-error">
              Attachments are still being uploaded
            </FormHelperText>
          )}
          {state === STATE_ERROR && required && value.files.length < 1 && (
            <FormHelperText className="text-error">
              {LABEL_REQUIRED}
            </FormHelperText>
          )}
        </>
      )
    }
  }

  return (
    <Paper>
      <div className="flex items-center px-8 py-4 text-xl font-bold">
        <IconButtonStyled
          disabled={state >= STATE_DISPATCH}
          onClick={handleCloseOpen}
          className="my-1 mr-1"
          edge="start"
        >
          <ArrowBackIcon />
        </IconButtonStyled>
        <TypographyStyled
          component="span"
          size="16px"
          weight="600"
          text="#039be5"
        >
          Apply Claim
        </TypographyStyled>
      </div>

      <Divider />

      <div className="p-6 pb-0">
        {CLAIM_FORM_CONFIG.map((item, idx) => (
          <FormWrapper
            key={idx}
            nomor={idx + 1}
            title={
              typeof item.label === 'string'
                ? item.label + (item.required ? '*' : '')
                : item.label
            }
          >
            {renderComponent(item)}
          </FormWrapper>
        ))}
      </div>

      <Divider />

      <div className="flex items-center justify-end px-8 py-6">
        <Button
          disabled={state >= STATE_DISPATCH}
          onClick={handleCloseOpen}
          className="mr-6"
        >
          Cancel
        </Button>

        <Button
          disabled={state >= STATE_DISPATCH}
          onClick={handleSubmitOpen}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </div>

      <CancelDialog
        open={formData.confirm_close}
        title="Discard Changes?"
        content="Are you sure you want to discard unsaved changes?"
        textPrimary="Discard"
        textSecondary="Cancel"
        onClose={handleCloseCancel}
        onClickPrimary={props.history.goBack}
      />

      <ConfirmationDialog
        open={formData.confirm_submit}
        title="Apply Claim?"
        content="Are you sure you want to apply this claim?"
        textPrimary="Confirm"
        textSecondary="Cancel"
        onClose={handleSubmitCancel}
        onClickPrimary={handleSubmitConfirm}
      />
    </Paper>
  )
}

export default withRouter(ClaimApply)

function cbify(promise, cb) {
  return promise.then(
    res => cb(null, res),
    err => cb(err, null)
  )
}

function validateAttachmentFilename(filename) {
  const arr = filename.split('.')

  if (arr.length < 2) {
    return false
  }

  const ext = arr[arr.length - 1].toLowerCase()

  switch (ext) {
    case 'pdf':
    case 'docx':
    case 'doc':
    case 'xlsx':
    case 'xls':
    case 'pptx':
    case 'ppt':
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return true
  }

  return false
}
