import React, {useState} from 'react'
import {TabsStyled, TabStyled} from '../../../components/tabs/TabsComponents'
import CancelDialog from '../../shared-component/dialog/CancelDialog'

import NotificationNoPolicy from '../../shared-component/dialog/NotificationNoPolicy'
import ListDigitalDocument from './list/ListDigitalDocument'
export default function DigitalDocumentTabs(props) {
  const {setTabsValue, tabsValue, setRefetch} = props
  // const {setForm, dataId, form, dataActivity, dataPolicyForm} = props
  const [open, setOpen] = useState({
    form: false,
    dialogCancel: false,
    notificationNoPolicy: false,
  })

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <>
      <TabsStyled value={tabsValue} onChange={handleChange}>
        <TabStyled label="Personal File" />
        <TabStyled label="Company File" />
      </TabsStyled>
      {/* <ListDigitalDocument tabsValue={tabsValue} /> */}
      {tabsValue === 0 && (
        <ListDigitalDocument tabsValue={0} setRefetch={setRefetch} />
      )}
      {tabsValue === 1 && (
        <ListDigitalDocument tabsValue={1} setRefetch={setRefetch} />
      )}

      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        onClickPrimary={() =>
          setOpen({
            ...open,
            form: false,
            dialogCancel: false,
          })
        }
      />

      <NotificationNoPolicy
        open={open.notificationNoPolicy}
        onBack={() => setOpen({...open, notificationNoPolicy: false})}
      />
    </>
  )
}
