import React from 'react'
import {
  TextFieldStyledNoLine,
  MenuItemStyled,
} from '../../../components/form/FormStyles'

import IconFilterActive from '../Filter/IconFilterActive'
import TextfieldSearch from '../form/TextfieldSearch'

import {Filter} from '@smartasn/wlb-utils-components'

export default function ListSearcher({
  setSearch,
  search,
  setSort,
  sort,
  isFiltered,
  idFilter = 'wlb-filter',
  listFilter = [],
  onApplyFilter,
}) {
  return (
    <>
      <div
        className="py-4 px-6 flex flex-row justify-between items-center"
        style={{borderBottom: '1px solid #f7f8f9'}}
      >
        {setSort && (
          <TextFieldStyledNoLine
            value={sort}
            onChange={e => setSort(e.target.value)}
            select
            style={{width: 'auto'}}
            size="small"
          >
            <MenuItemStyled value="desc">Latest</MenuItemStyled>
            <MenuItemStyled value="asc">Oldest</MenuItemStyled>
          </TextFieldStyledNoLine>
        )}
        {listFilter.length > 0 && (
          <Filter
            id={idFilter}
            includeExclude={false}
            onApply={onApplyFilter}
            listFilter={listFilter}
          >
            <div className="cursor-pointer text-sm items-center flex flex-row">
              <IconFilterActive isFiltered={isFiltered} />
              Filter
            </div>
          </Filter>
        )}
      </div>

      {setSearch && (
        <TextfieldSearch
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      )}
    </>
  )
}
