import React from 'react'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'

export default function FormWrapper({
  number,
  title,
  titleComponent,
  forMobile,
  children,
}) {
  const Title = titleComponent || TypographyStyled
  return (
    <div className={`flex flex-row relative ${forMobile ? 'mb-5' : 'mb-8'} `}>
      {number && (
        <TypographyStyled className="absolute font-semibold" size="12px">
          {number}.
        </TypographyStyled>
      )}
      <div className={`flex flex-col w-full ${number && 'ml-10'}`}>
        <Title className="font-semibold text-xs" size="12px">
          {title}
        </Title>
        <div className="mt-2">{children}</div>
      </div>
    </div>
  )
}
