import gql from 'graphql-tag'

export const GET_WORK_PLACEMENT = gql`
  query($user: uuid!) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
    }
  }
`
export const GET_LIST_ACTIVITY_LEAVE = gql`
  query(
    $sorting: order_by
    $idPolicy: [Int!]
    $user: uuid
    $company: uuid
    $dateStart: timestamp
    $dateEnd: timestamp
    $status: [String!]
    $search: String
    $limit: Int
    $offset: Int
  ) {
    people_work_leaves(
      where: {
        status: {_in: $status}
        policy_id: {_in: $idPolicy}
        people_work_placement: {user: {_eq: $user}, company: {_eq: $company}}
        id_display: {_ilike: $search}
        date_added: {_gte: $dateStart, _lte: $dateEnd}
      }
      order_by: {last_modified: $sorting}
      limit: $limit
      offset: $offset
    ) {
      id
      id_display
      people_work_placement {
        id
        global_user {
          id
          name
        }
      }
      time_leave_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
        }
        second_job_profile {
          id
          people_work_placements {
            id
          }
        }
      }
      date_added
      datestart
      dateend
      globalUserByModifiedBy {
        id
        name
      }
      status
    }
    total: people_work_leaves_aggregate(
      where: {
        status: {_in: $status}
        policy_id: {_in: $idPolicy}
        people_work_placement: {user: {_eq: $user}, company: {_eq: $company}}
        id_display: {_ilike: $search}
        date_added: {_gte: $dateStart, _lte: $dateEnd}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GET_LIST_REQUEST_LEAVE = gql`
  query(
    $user: uuid!
    $company: uuid!
    $sorting: order_by
    $idPolicy: [Int!]
    $idRequester: [Int!]
    $dateStart: timestamp
    $dateEnd: timestamp
    $status: [String!]
    $search: String
    $limit: Int
    $offset: Int
  ) {
    people_work_leaves(
      where: {
        _or: [
          {
            time_leave_policy_child: {
              confirm_type: {_eq: "2"}
              first_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_leave_policy_child: {
              confirm_type: {_eq: "2"}
              second_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_leave_policy_child: {confirm_type: {_eq: "1"}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          }
        ]
        date_added: {_gte: $dateStart, _lte: $dateEnd}
        time_leave_policy_child: {id: {_in: $idPolicy}}
        people_work_placement: {id: {_in: $idRequester}}
        status: {_in: $status}
        id_display: {_ilike: $search}
      }
      order_by: [{id: $sorting}]
      limit: $limit
      offset: $offset
    ) {
      id
      id_display
      is_half
      quota_taken
      contacts
      people_work_placement {
        id
        global_user {
          id
          name
        }
      }
      time_leave_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
        }
        second_job_profile {
          id
          title
          people_work_placements {
            id
            global_user {
              id
            }
          }
        }
      }
      leave_fields
      date_added
      datestart
      dateend
      cancelled_by_user {
        id
        name
      }
      globalUserByModifiedBy {
        id
        name
      }
      status
    }
    total: people_work_leaves_aggregate(
      where: {
        _or: [
          {
            time_leave_policy_child: {
              confirm_type: {_eq: "2"}
              first_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_leave_policy_child: {
              confirm_type: {_eq: "2"}
              second_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_leave_policy_child: {confirm_type: {_eq: "1"}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          }
        ]
        date_added: {_gte: $dateStart, _lte: $dateEnd}
        time_leave_policy_child: {id: {_in: $idPolicy}}
        people_work_placement: {id: {_in: $idRequester}}
        status: {_in: $status}
        id_display: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GET_LIST_PEOPLE_LEAVE_REQUEST = gql`
  query($userWorkId: Int!, $search: String) {
    people_work_placements(
      where: {
        people_work_leaves: {
          _or: [
            {
              time_leave_policy_child: {
                first_job_profile: {
                  people_work_placements: {id: {_eq: $userWorkId}}
                }
              }
            }
            {
              time_leave_policy_child: {
                second_job_profile: {
                  people_work_placements: {id: {_eq: $userWorkId}}
                }
              }
            }
            {
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {id: {_eq: $userWorkId}}
                  }
                }
              }
            }
          ]
        }
        global_user: {name: {_ilike: $search}}
      }
      order_by: [{global_user: {name: asc}}]
    ) {
      filter_people_id: id
      regno
      global_user {
        id
        name
        avatar
      }
    }
  }
`
export const GET_DETAIL_ACTIVITY_LEAVE = gql`
  query GetDetailLeave($id: bigint!) {
    people_work_leaves(where: {id: {_eq: $id}}) {
      id
      id_display
      is_half
      contacts
      quota_taken
      people_work_placement {
        id
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
              id
              global_user {
                id
                name
              }
            }
            spv_2: job_profile_supervisor {
              id
              title
              people_work_placements(
                limit: 1
                where: {status: {_eq: "ACTIVE"}}
              ) {
                id
                global_user {
                  id
                  name
                }
              }
              spv_3: job_profile_supervisor {
                id
                title
                people_work_placements(
                  limit: 1
                  where: {status: {_eq: "ACTIVE"}}
                ) {
                  id
                  global_user {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      time_leave_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
      leave_fields
      date_added
      description
      datestart
      dateend
      cancelled_by_user {
        id
        name
      }
      globalUserByModifiedBy {
        id
        name
      }
      status
    }
  }
`

export const GET_LIST_SCHEDULE = gql`
  query getEmployeeAttendancePolicy($userWorkId: Int!) {
    time_attendance_policies(
      where: {
        company_job_profile_r_attendances: {
          company_job_profile: {
            people_work_placements: {id: {_eq: $userWorkId}}
          }
        }
        flexi: {_eq: 0}
      }
    ) {
      id
      name
      schedule
    }
  }
`
export const GET_LIST_COMPANY_HOLIDAY = gql`
  query($company: uuid!, $date_start: date, $date_end: date) {
    time_holidays(
      where: {
        company: {_eq: $company}
        _and: [{date: {_gte: $date_start}}, {date: {_lte: $date_end}}]
      }
    ) {
      id
      name
      date
    }
  }
`

export const GET_WAITING_LEAVE_COUNT = gql`
  query($userId: uuid!, $policyId: [Int!], $startDate: date, $endDate: date) {
    people_work_leaves_aggregate(
      where: {
        people_work_placement: {user: {_eq: $userId}}
        policy_id: {_in: $policyId}
        status: {_in: ["pending", "confirm1"]}
        datestart: {_gte: $startDate}
        dateend: {_lte: $endDate}
      }
    ) {
      aggregate {
        sum {
          quota_taken
        }
      }
    }
  }
`
