import {getCookie} from './helpers'

const {
  REACT_APP_GRAPHQL_URL,
  REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_SSO,
  REACT_APP_UPLOAD_URL,
  REACT_APP_API_URL,
  REACT_APP_HC_SOSMED,
  REACT_APP_HC_HOLDING,
  REACT_APP_HC_ADMIN,
  REACT_APP_COOKIE,
  REACT_APP_SENTRY,
  REACT_APP_SENTRY_ENV,
  REACT_APP_LANDING,
} = process.env
export const userData = getCookie('userData')
  ? JSON.parse(getCookie('userData'))
  : []
let access_token = ''
let company_id = ''
let company_logo = ''
let company_name = ''
let user_id = ''
let user_name = ''
let position_id = ''
let position_name = ''
let supervisor_id = ''
let roles = []
let organization = ''
let avatar = ''
let name = ''
if (userData && userData.oauth) {
  company_id = userData.company_id
  company_logo = userData.company_logo
  company_name = userData.company_name
  access_token = userData.oauth.access_token
  user_id = userData.id
  user_name = userData.name
  position_id = userData.position_id
  position_name = userData.position_name
  supervisor_id = userData.supervisor_id
  roles = userData.roles
  organization = userData.position_id ? userData.position_id : 'sa'
  avatar = userData.avatar
  name = userData.name
}
export const REAST_API_URL = REACT_APP_API_URL
export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL
export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_URL_WS
export const TOKEN = access_token
export const COMPANY_ID = company_id
export const COMPANY_LOGO = company_logo
export const COMPANY_NAME = company_name
export const ROLE = 'organization-staff'
export const ROLES = roles
export const USER_ID = user_id
export const USER_NAME = user_name
export const POSITION_ID = position_id
export const POSITION_NAME = position_name
export const ORGANIZATION = organization
export const UPLOAD_URL = REACT_APP_UPLOAD_URL
export const SOSMED_APP_URL = REACT_APP_HC_SOSMED
export const HOLDING_APP_URL = REACT_APP_HC_HOLDING
export const ADMIN_APP_URL = REACT_APP_HC_ADMIN
export const LANDING_APP_URL = REACT_APP_LANDING
export const COOKIE_URL = REACT_APP_COOKIE
export const SSO_URL = REACT_APP_SSO
export const AVATAR = avatar
export const NAME_USER_LOGIN = name
export const SUPERVISOR_ID = supervisor_id
export const SENTRY_URL = REACT_APP_SENTRY
export const SENTRY_ENV = REACT_APP_SENTRY_ENV
