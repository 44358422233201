import gql from 'graphql-tag'

export const GET_LIST_POLICY_FORM = gql`
  query($placement: Int) {
    time_leave_policy_children(
      where: {
        company_job_profile_r_leaves: {
          company_job_profile: {people_work_placements: {id: {_eq: $placement}}}
        }
      }
      order_by: [{name: asc}]
    ) {
      id
      name
      time_leave_policy {
        quota_effective_after_month
      }
    }
  }
`
export const GET_LIST_NEW_POLICY_FORM = gql`
  query($user_id: uuid, $company_id: uuid, $year: [Int!], $user_work_id: Int!) {
    people_work_placements(
      where: {user: {_eq: $user_id}, company: {_eq: $company_id}}
    ) {
      company_job_profile {
        company_job_profile_r_leaves(
          where: {time_leave_policy_child: {deletedAt: {_is_null: true}}}
          order_by: [{time_leave_policy_child: {name: asc}}]
        ) {
          time_leave_policy_child {
            id
            name
            deletedAt
            infinite_quota
            time_leave_policy {
              id
              name
              allow_leave_debt
              calc_method
              deletedAt
              leftover_valid_until_month
              quota_effective_after_month
              quota_prorate_option
              quota_reset_option
              quota_reset_option_date
              quota_reset_option_month
              label_color
              text_color
              date_added
              last_modified
            }
            confirm_type
            quota
            infinite_quota
            first_job_profile {
              id
              title
            }
            second_job_profile {
              id
              title
            }
            date_added
            last_modified
            people_work_leave_quotas(
              where: {
                user_work_id: {_eq: $user_work_id}
                period_year: {_in: $year}
              }
              order_by: {period_year: desc}
              limit: 1
            ) {
              id
              quota
              period_year
              carry_over
              taken_current
              taken_carry_over
            }
          }
        }
      }
    }
  }
`
export const GET_LIST_POLICY_FILTER = gql`
  query($userWorkId: Int!, $search: String) {
    time_leave_policy_children(
      where: {
        company_job_profile_r_leaves: {
          company_job_profile: {
            people_work_placements: {id: {_eq: $userWorkId}}
          }
        }
        name: {_ilike: $search}
      }
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
export const GET_LIST_POLICY_REQUEST_FILTER = gql`
  query($userWorkId: Int!, $search: String) {
    time_leave_policy_children(
      where: {
        people_work_leaves: {
          _or: [
            {
              time_leave_policy_child: {
                first_job_profile: {
                  people_work_placements: {id: {_eq: $userWorkId}}
                }
              }
            }
            {
              time_leave_policy_child: {
                second_job_profile: {
                  people_work_placements: {id: {_eq: $userWorkId}}
                }
              }
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {id: {_eq: $userWorkId}}
                      }
                    }
                  }
                }
                {time_leave_policy_child: {confirm_type: {_eq: "1"}}}
              ]
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {id: {_eq: $userWorkId}}
                      }
                    }
                  }
                }
                {time_leave_policy_child: {confirm_type: {_eq: "3"}}}
              ]
            }
          ]
        }
        name: {_ilike: $search}
      }
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
export const GET_LIST_REQUESTER_FILTER = gql`
  query($userWorkId: Int!, $search: String) {
    time_leave_policy_children(
      where: {
        company_job_profile_r_leaves: {
          company_job_profile: {
            people_work_placements: {
              id: {_eq: $userWorkId}
              global_user: {name: {_ilike: $search}}
            }
          }
        }
      }
      order_by: [{name: asc}]
    ) {
      id
      company_job_profile_r_leaves {
        company_job_profile {
          id
          people_work_placements {
            id
            global_user {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_PLACEMENT = gql`
  query($user: uuid!) {
    global_users(where: {id: {_eq: $user}}) {
      id
      people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
        id
        start_date
        join_date
      }
    }
  }
`
