import React from 'react'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import Divider from '../../../components/divider/DividerStyles'
import {ButtonStyled} from '../../../components/button/ButtonStyles'

export default function NotificationNoPolicy({open, onBack}) {
  return (
    <StyledDialog open={open} onClose={onBack} fullWidth maxWidth="xs">
      <div className="flex flex-col">
        <div className="p-4">
          <span className="text-primary text-sm font-semibold ">
            Information
          </span>
        </div>
        <Divider />
        <div className="p-4 flex flex-col">
          <span className="text-xs mb-12">
            No policy, please contact your company admin
          </span>
          <ButtonStyled
            classes={{
              root: 'ml-auto px-6 text-xs',
            }}
            size="small"
            state="primary"
            onClick={onBack}
          >
            Back
          </ButtonStyled>
        </div>
      </div>
    </StyledDialog>
  )
}
