import React, {useState, useEffect} from 'react'
import {Paper} from '../../components/paper/PaperStyles'
import {TabsStyled, TabStyled} from '../../components/tabs/TabsComponents'
import {IconStyled} from '../../components/Icons/IconComponent'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import StatusComponent from './StatusComponent'
import ListComponent from './ListComponent'
import ModalCheckin from './check-in/ModalCheckIn'
import DetailCheckin from './check-in/DetailCheckin'
import {useQuery, useLazyQuery, useMutation} from '@apollo/react-hooks'
import {
  GET_STATUS_ATTENDANCE,
  GET_USERWORK,
  GET_LIST_ATTENDANCE_POLICY,
} from '../../graphql/queries'
import {USER_ID} from '../../utils/globals'
import {useSnackbar} from 'notistack'
import Geocode from 'react-geocode'
import moment from 'moment'
import NotificationNoPolicy from '../shared-component/dialog/NotificationNoPolicy'
import NotificationWLB from '../shared-component/dialog/NotificationWLB'
import ConfirmationDialog from '../shared-component/dialog/ConfirmationDialog'
import {useDiferencesTime} from './helperAttendance'
import {MUTATION_CHECKOUT} from '../../graphql/mutations'
import useDayHoliday from '../../hooks/useDayHoliday'
import ButtonAttendanceComponent from './ButtonAttendanceComponent'
import {PAGINATION_RANGE} from '../../utils/constants'

export default function Attendance() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_RANGE[0])
  const {enqueueSnackbar} = useSnackbar()
  const [value, setValue] = useState(0)
  const [openWork, setOpenWork] = useState(false)
  const [openCekin, setOpenCekin] = useState(false)
  const [dataAtt, setDataAtt] = useState(null)
  const [disable, setDisable] = useState({
    checkout: false,
  })
  const [sort, setSort] = useState('desc')
  const [filter, setFilter] = useState({
    policy: [],
    date: {
      dateStart: null,
      dateEnd: null,
    },
  })
  const [openNotif, setopenNotif] = useState({
    open: false,
    isTherePolicy: false,
    outsideCheckIn: false,
    outsideCheckOut: false,
    checkout: false,
    toEarlyCheckin: false,
  })
  const [
    hoursCheckout,
    minutesCheckout,
    getDiferenceCheckout,
  ] = useDiferencesTime()
  const [hours, minutes, getTime] = useDiferencesTime()

  const [getHoliady, holiday] = useDayHoliday()
  const {data, error, loading, refetch} = useQuery(GET_STATUS_ATTENDANCE, {
    variables: {
      user: USER_ID,
      sorting: sort,
      idPolicy: filter.policy.length > 0 ? filter.policy : null,
      dateStart: filter.date.dateStart,
      dateEnd:
        moment(filter.date.dateEnd || undefined).format('yyyy-MM-DD') +
        'T23:59:59',
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
  })

  const {data: idWork} = useQuery(GET_USERWORK, {
    variables: {
      user: USER_ID,
    },
  })

  const [checkoutMutation] = useMutation(MUTATION_CHECKOUT)

  const [getListPolicy, {data: dataPolicy, error: errorPolicy}] = useLazyQuery(
    GET_LIST_ATTENDANCE_POLICY
  )

  useEffect(() => {
    if (idWork) {
      getListPolicy({
        variables: {
          userWorkId: idWork.people_work_placements[0].id,
        },
      })
      getHoliady(idWork.people_work_placements[0].id)
    }
    if (dataPolicy && dataPolicy.time_attendance_policies.length > 0) {
      setopenNotif({...openNotif, isTherePolicy: true})
    }
  }, [idWork, dataPolicy])

  if (errorPolicy) {
    return <div>{JSON.stringify(errorPolicy)}</div>
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }

  const handleOpenCekin = () => {
    setOpenCekin(prev => !prev)
  }

  const handleOpenWork = () => {
    if (openNotif.isTherePolicy) {
      setOpenWork(prev => !prev)
    } else {
      setopenNotif({...openNotif, open: true})
    }
  }

  const checkOutNow = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          response => {
            const address = response.results[0].formatted_address
            const data = {
              userWorkId: idWork.people_work_placements[0].id,
              attendanceFields: {
                checkout: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  address,
                },
              },
            }
            checkoutMutation({
              variables: data,
            })
              .then(() => {
                enqueueSnackbar('Check - Out Success', {variant: 'success'})
                refetch()
                setDisable({
                  ...disable,
                  checkout: false,
                })
              })
              .catch(e => {
                enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
              })
          },
          () => {}
        )
      })
    } else {
      enqueueSnackbar('Please enable location permission', {variant: 'warning'})
    }
  }

  return (
    <div className="flex flex-col w-full">
      <Paper className="">
        <div
          style={{padding: '24px 30px'}}
          className="flex flex-row items-center justify-between "
        >
          <div className="font-bold text-xl flex justify-center items-center flex-row">
            <IconStyled text="#014a62" className="mr-3">
              alarm
            </IconStyled>
            <TypographyStyled
              component="span"
              className="text-primary font-semibold text-lg"
              text="#014a62"
            >
              Attendance
            </TypographyStyled>
          </div>
          <ButtonAttendanceComponent
            data={data}
            user_work_id={idWork && idWork.people_work_placements[0].id}
            dataPolicy={dataPolicy}
            handleOpenWork={handleOpenWork}
            setopenNotif={setopenNotif}
            getDiferenceCheckout={getDiferenceCheckout}
            holiday={
              data?.first_data?.[0]?.clock_out || data?.first_data?.length === 0
                ? holiday
                : false
            }
          />
        </div>
        <TabsStyled
          value={value}
          indicatorColor="secondary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <TabStyled label="Status" />
          <TabStyled label="List" />
        </TabsStyled>
        {value === 0 && <StatusComponent />}
        {value === 1 && (
          <ListComponent
            data={data}
            error={error}
            loading={loading}
            setSort={setSort}
            sort={sort}
            idWork={idWork}
            setFilter={setFilter}
            filter={filter}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            page={page}
            // getListAttendance={getListAttendance}
          />
        )}
      </Paper>
      <ModalCheckin
        setopenNotif={setopenNotif}
        open={openWork}
        handleOpenCekin={handleOpenWork}
        setDataAtt={setDataAtt}
        handleOpenCam={handleOpenCekin}
        dataPolicy={dataPolicy}
      />
      <DetailCheckin
        getTime={getTime}
        setopenNotif={setopenNotif}
        setOpenCekin={setOpenCekin}
        // refetchAttendance={refetchAttendance}
        idWork={idWork}
        refetch={() => refetch()}
        open={openCekin}
        handleOpenCekin={handleOpenCekin}
        dataAtt={dataAtt}
      />
      <NotificationNoPolicy
        open={openNotif.open}
        onBack={() => setopenNotif({...openNotif, open: false})}
      />
      <NotificationWLB
        open={
          openNotif.outsideCheckIn ||
          openNotif.outsideCheckOut ||
          openNotif.toEarlyCheckin
        }
        title={
          openNotif.outsideCheckIn || openNotif.toEarlyCheckin
            ? 'Unable to check in'
            : openNotif.outsideCheckOut
            ? 'Unable to check out'
            : ''
        }
        type={'danger'}
        message={
          openNotif.outsideCheckIn
            ? `Check-in time schedule is ended ${hours} hours ${minutes} minutes ago`
            : openNotif.toEarlyCheckin
            ? `Your check-in is too early, please try again after ${hours} hours ${minutes} minutes`
            : openNotif.outsideCheckOut
            ? `Your check-out is too early, please try again after ${hoursCheckout} hours ${minutesCheckout} minutes`
            : ''
        }
        onClick={() => {
          if (openNotif.outsideCheckIn) {
            setOpenCekin(true)
            setopenNotif({...openNotif, outsideCheckIn: false})
          } else if (openNotif.toEarlyCheckin) {
            setOpenCekin(true)
            setopenNotif({...openNotif, toEarlyCheckin: false})
          } else if (openNotif.outsideCheckOut) {
            setopenNotif({...openNotif, outsideCheckOut: false})
          } else {
            setopenNotif({...openNotif, outsideCheckOut: false})
          }
        }}
      />
      <ConfirmationDialog
        open={openNotif.checkout}
        title={'Check - Out Confirmation'}
        content={'Are you sure want to check - out today?'}
        onClickPrimary={() => {
          checkOutNow()
          setopenNotif({...openNotif, checkout: false})
          setDisable({
            ...disable,
            checkout: true,
          })
        }}
        disable={disable.checkout}
        textPrimary={'Check - out'}
        textSecondary={'Cancel'}
        type="warning"
        onClose={() => {
          setopenNotif({...openNotif, checkout: false})
        }}
      />
    </div>
  )
}
