import gql from "graphql-tag";

export const APPLY_FOR_TRIP = gql`
  mutation(
    $user_work_id: Int!
    $user_id: String
    $added_by_work_id: Int
    $trip_type: Int
    $policy_id: Int!
    $date_start: String
    $date_end: String
    $description: String
    $attachment: JSON
    $destinations: JSON
  ) {
    BusinessTripApplyUser(
      user_work_id: $user_work_id
      user_id: $user_id
      added_by_work_id: $added_by_work_id
      trip_type: $trip_type
      policy_id: $policy_id
      date_start: $date_start
      date_end: $date_end
      description: $description
      attachment: $attachment
      destinations: $destinations
    ) {
      success
    }
  }
`;
