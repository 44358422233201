import styled from 'styled-components/macro'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

export const IconStyled = styled(Icon)`
  color: ${props => props.text};
`

export const IconButtonStyled = styled(IconButton)`
  outline: none !important;
`
