import gql from 'graphql-tag'

export const GET_CURRENCY_EXCHANGE = gql`
  query($currency: String!, $date: String) {
    getCurrencyExchange(currency: $currency, date: $date) {
      id
      currency
      date
      idr
    }
  }
`
