import {useQuery} from '@apollo/react-hooks'
import React, {memo, useState} from 'react'
import {GET_GLOBAL_PHONE_CODE} from '../../../graphql/queries'
import {Button, InputAdornment, Popover} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {GlobalPhoneText} from '../LeavePageStyles'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'

export default memo(function GlobalPhoneCode({
  error = false,
  onChangePhone,
  defaultValue,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [phoneCode, setPhoneCode] = useState(null)

  const {data: globalPhone} = useQuery(GET_GLOBAL_PHONE_CODE, {
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickPhone = value => {
    const sent = {
      target: {
        value,
      },
    }
    setPhoneCode(value)
    setAnchorEl(null)
    onChangePhone(sent)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <GlobalPhoneText
        onClick={handleClick}
        //
        read
        aria-describedby={id}
        style={{
          width: 110,
          marginRight: 14,
          cursor: 'pointer',
        }}
        size="small"
        variant="outlined"
        value={phoneCode || defaultValue}
        helperText={error ? ' ' : null}
        error={error}
        InputProps={{
          readOnly: true,
          inputProps: {className: 'text-xs'},
          endAdornment: (
            <InputAdornment
              style={{cursor: 'pointer', paddingRight: 0}}
              position="end"
            >
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        classes={{
          paper: 'shadow-wlb',
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="flex flex-col py-2">
          {globalPhone ? (
            globalPhone.global_phone_code.map((res, i) => {
              return (
                <Button
                  key={i}
                  classes={{
                    root: 'flex justify-start pl-4',
                    label: 'text-xs',
                  }}
                  onClick={() => handleClickPhone(res.code)}
                >{`${res.country} (${res.code})`}</Button>
              )
            })
          ) : (
            <LoadingComponent />
          )}
        </div>
      </Popover>
    </div>
  )
})
