import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {useMediaQuery, useTheme} from '@material-ui/core'

import DetailListTripReport from '../trip-report-shared-component/DetailListTripReport'
import DetailAttachment from '../../trip/trip-shared-component/DetailAttachment'

import {TRIP_CONTEXT} from '../../trip/TripConstant'
import {GET_DETAIL_TRIP_REPORT} from '../../../graphql/queries'

export default function DetailReport(props) {
  const {open, onClose, id, detail, setDetail} = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const {data} = useQuery(GET_DETAIL_TRIP_REPORT, {
    ...TRIP_CONTEXT,
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
    },
  })

  const toggleShowAttachment = data => {
    setDetail(prev => ({
      ...prev,
      open: prev.openAttachment,
      openAttachment: prev.open,
      dataAttachment: data || null,
    }))
  }

  return (
    <>
      <DetailListTripReport
        open={open}
        fullScreen={fullScreen}
        onClose={onClose}
        data={data?.people_work_business_trips?.[0]}
        loading={!data || data.people_work_business_trips[0].id !== id}
        onClickMoreAttachment={toggleShowAttachment}
      />

      <DetailAttachment
        data={detail.dataAttachment}
        open={detail.openAttachment}
        onClose={toggleShowAttachment}
      />
    </>
  )
}
