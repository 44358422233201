import Attendance from './AttendanceComponent'

export const AttendanceConfig = {
  routes: [
    {
      path: '/attendance',
      component: Attendance,
      state: 'attendance',
      exact: true,
    },
  ],
}
