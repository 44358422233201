import React from 'react'
import {LinearProgress} from '@material-ui/core'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'

function numberWithCommas(x) {
  const num = String(x).split('.')
  return `${num[0]}.${num.length === 2 ? num[1] : '0'}`
}

export default function BalanceList({
  time,
  count,
  start,
  end,
  value,
  type = 'primary',
  isDeleted,
}) {
  const getColorText = type => {
    if (isDeleted) {
      return 'disable'
    } else {
      switch (type) {
        case 'primary':
        case 'secondary':
          return 'primary'
        case 'disable':
          return 'disable'
        default:
          return 'success'
      }
    }
  }

  return (
    <div className="flex flex-col mb-8">
      <div className="flex flex-row justify-between">
        <span className="text-xs text-disable font-semibold">{time}</span>
        <span
          className={`text-disable text-xs text-${getColorText(
            type
          )} font-semibold`}
        >
          {type === 'success' ? (
            <AllInclusiveIcon
              className={`text-md ${
                isDeleted ? 'text-disable' : 'text-success'
              }`}
            />
          ) : (
            count
          )}
        </span>
      </div>
      <LinearProgress
        classes={{
          root: 'pb-3 rounded-wlb my-2 bg-wlbgrey',
          bar: `bg-${isDeleted ? 'disable' : type} rounded-wlb`,
        }}
        variant="determinate"
        value={value}
      />
      <div className="flex flex-row justify-between">
        <span
          className={`text-xs font-semibold ${isDeleted ? 'text-disable' : ''}`}
        >
          {numberWithCommas(start)} Days Used
        </span>
        <span
          className={`text-xs font-semibold ${isDeleted ? 'text-disable' : ''}`}
        >
          {type === 'success' ? (
            <AllInclusiveIcon
              className={`text-md ${
                isDeleted ? 'text-disable' : 'text-success'
              }`}
            />
          ) : (
            end
          )}
        </span>
      </div>
    </div>
  )
}
