import gql from 'graphql-tag'

export const UPDATE_NOMINAL_TRIP_BUDGET = gql`
  mutation UpdateNominalComponent(
    $id: Int!
    $nominal: float8
    $destination_id: Int!
    $update_by: uuid!
  ) {
    update_people_work_business_trip_destination_budgets(
      _set: {nominal: $nominal}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        nominal
      }
    }

    update_people_work_business_trip_destinations(
      _set: {last_modified: "now()", updated_by: $update_by}
      where: {id: {_eq: $destination_id}}
    ) {
      affected_rows
      returning {
        id
        last_modified
        updated_by
      }
    }
  }
`

export const REJECT_TRIP = gql`
  mutation RejectedStatus(
    $id: Int!
    $fields: jsonb!
    $approvedDate: timestamp!
  ) {
    rejected: update_people_work_business_trips(
      _set: {status: "rejected", approved_date: $approvedDate}
      _append: {business_trip_fields: $fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        approved_date
        business_trip_fields
      }
    }
  }
`

export const UPDATE_TRIP_STATUS = gql`
  mutation TupdateTripStatus($id: Int!, $fields: jsonb!, $status: String!) {
    update_people_work_business_trips(
      _set: {status: $status}
      _append: {business_trip_fields: $fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        business_trip_fields
      }
    }
  }
`
