import React, {useState} from 'react'
import {TabsStyled, TabStyled} from '../../../components/tabs/TabsComponents'
import UpdateIcon from '@material-ui/icons/Update'
import Activity from './activity/Activity'
import Request from './request/Request'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import Status from './status/Status'
import {Link} from 'react-router-dom'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function OvertimeTab(props) {
  const {dataWork, salary} = props

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }

  return (
    <>
      <div className=" flex flex-row justify-between py-8 px-8">
        <div className="flex flex-row items-center">
          <UpdateIcon className="text-primary" />
          <div className="text-primary font-semibold text-lg ml-3">
            Overtime
          </div>
        </div>
        <ButtonStyled
          component={Link}
          to="/overtime/apply"
          className="text-xs md:text-sm"
          state="primary"
        >
          Apply for Overtime
        </ButtonStyled>
      </div>
      <TabsStyled value={value} onChange={handleChange}>
        <TabStyled label="Activity" {...a11yProps(0)} />
        <TabStyled label="Request" {...a11yProps(1)} />
        {/* <TabStyled label="Status" {...a11yProps(2)} /> */}
      </TabsStyled>
      <TabPanel value={value} index={0}>
        <Activity salary={salary} dataWork={dataWork} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Request salary={salary} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Status />
      </TabPanel>
    </>
  )
}
