import {format} from 'date-fns'
import {convertNominal, differenceDateFrom} from '../../utils/helpers'

export const fileCheckerOvertime = (name, setFileType) => {
  const nameSplited = name.split('.')
  const idx = nameSplited.length - 1
  const res = nameSplited[idx]

  if (
    res === 'doc' ||
    res === 'docx' ||
    res === 'xls' ||
    res === 'pdf' ||
    res === 'xlsx' ||
    res === 'ppt'
  ) {
    setFileType && setFileType('document')
    return false
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif'
  ) {
    setFileType && setFileType('image')
    return false
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    setFileType && setFileType('image')
    return false
  } else {
    return true
  }
}
export const checkFilesType = (files, setFileType) => {
  let result = false
  let isMore25mb = false
  files.forEach(file => {
    if (fileCheckerOvertime(file.name, setFileType)) {
      result = true
    }
    if (file.size >= 1024 * 1024 * 25) {
      isMore25mb = true
    }
  })
  return [result, isMore25mb]
}

export const generateFulldateFormat = (date, time) => {
  const generateTime = timevar => {
    return format(new Date(timevar), 'HH:mm:ss')
  }
  const generateDate = datevar => {
    return format(new Date(datevar), 'yyyy-MM-dd')
  }

  if (date) {
    // return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
    return `${generateDate(date)}T${generateTime(time)}`
  } else {
    return null
  }
}
export const generateFulldateFormatFull = date => {
  if (date) {
    return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
  } else {
    return null
  }
}

const minutesToHours = totalMinutes => Math.floor(totalMinutes / 60)

const getCompensationLeave = selectedItem => {
  const {minimum_duration_leave, name} =
    selectedItem?.time_overtime_policy || {}
  const totalHours = minutesToHours(
    selectedItem?.minimum_duration_leave || minimum_duration_leave
  )
  return `Minimum ${totalHours || 0} hours for 1 day ${name || '-'} quota`
}

const baseOnSalary = (selectedItem, longTime, salary = 0) => {
  let totalPaid = 0

  const totoalHours =
    longTime ||
    differenceDateFrom(selectedItem?.dateend, selectedItem?.datestart, 'hours')

  const convert_to_money =
    selectedItem?.convert_to_money ||
    selectedItem?.time_overtime_policy?.convert_to_money

  const {multipliers, divisor} = convert_to_money

  multipliers.slice(0, totoalHours).forEach(multiplier => {
    totalPaid += (parseFloat(salary) * parseFloat(multiplier)) / divisor
  })

  return `Paid Rp${convertNominal(totalPaid.toFixed(2), false)}`
}

const perHour = (selectedItem, longTime) => {
  const totoalHours =
    longTime ||
    differenceDateFrom(selectedItem?.dateend, selectedItem?.datestart, 'hours')
  const moneyPerHour =
    selectedItem?.convert_to_money?.IDR ||
    selectedItem?.time_overtime_policy?.convert_to_money?.IDR
  return `Paid Rp${convertNominal(totoalHours * Number(moneyPerHour), false)}`
}

const getCompensationMoney = (selectedItem, longTime, salary) => {
  const isBaseOnSalary =
    selectedItem?.convert_to_money?.multipliers?.[0] ||
    selectedItem?.time_overtime_policy?.convert_to_money?.multipliers?.[0]

  return isBaseOnSalary
    ? baseOnSalary(selectedItem, longTime, salary)
    : perHour(selectedItem, longTime)
}

export const getCompensationDescription = ({
  selectedItem,
  longTime,
  salary,
}) => {
  return selectedItem?.convert_to === 'Leave'
    ? getCompensationLeave(selectedItem)
    : getCompensationMoney(selectedItem, longTime, salary)
}

const getDetailCompensation = (selectedItem, longTime, salary) => {
  return selectedItem.convert_to_leave
    ? getCompensationLeave(selectedItem)
    : getCompensationMoney(selectedItem, longTime, salary)
}

export const getCompensationDescriptionSelected = ({
  selectedItem,
  longTime = 0,
  salary,
}) => {
  return selectedItem
    ? getDetailCompensation(selectedItem, Number(longTime), salary)
    : '-'
}

export function getLastSalary(pws = []) {
  const reversed = pws.reverse()
  const filterByPassed = reversed.filter(
    e => e.start_date != null && new Date(e.start_date) <= new Date()
  )
  if (filterByPassed.length === 1) return filterByPassed[0]
  else if (filterByPassed.length > 1) {
    const filterByPassedActive = filterByPassed.filter(e => e.active)
    if (filterByPassedActive.length > 0) return filterByPassedActive[0]
    else return filterByPassed[0]
  }
  return reversed.find(e => e.active)
}
