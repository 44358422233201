import gql from 'graphql-tag'

export const GET_LIST_APPLY_TRIP_PARTICIPANT = gql`
  query getParticipantlist($userId: uuid!, $company: uuid, $search: String) {
    people_work_placements(
      where: {
        user: {_neq: $userId}
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        status: {_in: ["ACTIVE"]}
        company_job_profile: {
          company_job_profile_r_business_trips: {id: {_is_null: false}}
        }
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {global_user: {email: {_ilike: $search}}}
        ]
      }
      limit: 3
      order_by: [{global_user: {name: asc}}]
    ) {
      id
      regno
      global_user {
        id
        name
        email
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_APPLY_TRIP_CURRENT_POSITION = gql`
  query getCurrentPosition($userId: uuid!) {
    people_work_placements(
      where: {
        _and: [
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {status: {_in: ["ACTIVE"]}}
        ]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      global_user {
        id
        name
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_TRIP_POLICY = gql`
  query getPolicy($profile: Int, $search: String) {
    company_job_profile_r_business_trip(
      where: {
        profile: {_eq: $profile}
        business_trip_policy: {
          deletedAt: {_is_null: true}
          name: {_ilike: $search}
        }
      }
      limit: 5
    ) {
      id
      business_trip_policy {
        id
        name
        description
        destination_type
        budget_component_type
        currency_from
        currency_to
      }
    }
  }
`

export const GET_LIST_TRIP_DESTINATION = gql`
  query getDestination(
    $policy: Int
    $isStrictPolicy: Boolean
    $search: String
  ) {
    global_destination(
      where: {
        _or: [
          {
            business_trip_policy_destinations: {
              _not: {id: {_is_null: $isStrictPolicy}}
              policy: {_eq: $policy}
              _or: [
                {global_destination: {name: {_ilike: $search}}}
                {
                  global_destination: {
                    global_country: {country_name: {_ilike: $search}}
                  }
                }
              ]
            }
          }
          {
            _or: [
              {
                id: {_is_null: $isStrictPolicy}
                _or: [
                  {name: {_ilike: $search}}
                  {global_country: {country_name: {_ilike: $search}}}
                ]
              }
            ]
          }
        ]
      }
      limit: 20
      order_by: {name: asc}
    ) {
      id
      name
      global_country {
        id
        country_name
      }
    }
  }
`

export const GET_LIST_FLEX_BUDGET = gql`
  query getComponent($search: String) {
    business_trip_component(
      where: {deletedAt: {_is_null: true}, name: {_ilike: $search}}
      limit: 6
    ) {
      id
      name
      description
    }
  }
`

export const GET_LIST_STRICT_BUDGET = gql`
  query getBudgetWhenStrict($policy: Int, $destination: Int) {
    business_trip_component(
      where: {
        deletedAt: {_is_null: true}
        business_trip_policy_destination_budgets: {
          policy: {_eq: $policy}
          business_trip_policy_destination: {city: {_eq: $destination}}
        }
      }
      order_by: {name: asc}
    ) {
      id
      name
      description
      business_trip_policy_destination_budgets(
        where: {
          policy: {_eq: $policy}
          business_trip_policy_destination: {city: {_eq: $destination}}
        }
      ) {
        id
        nominal
        budget_calculation
      }
    }
  }
`
