import Overtime from './OvertimeComponent'

export const OvertimeConfig = {
  routes: [
    {
      path: '/overtime',
      component: Overtime,
      state: 'overtime',
      exact: true,
    },
    {
      path: '/overtime/apply',
      component: Overtime,
      state: 'apply-overtime',
      exact: true,
    },
  ],
}
