import { Divider } from "@material-ui/core";
import React from "react";

export default function ListTripReportItem({
  onClick,
  policy,
  date,
  submitted,
  id,
}) {
  return (
    <div onClick={onClick} style={{ padding: "0px 30px" }}>
      <div className="py-5 flex flex-row justify-between items-start cursor-pointer">
        <div style={{ maxWidth: "68%" }} className="flex flex-col">
          <h4 className="text-primary text-sm capitalize">{policy}</h4>
          <p className="text-disable text-md pt-3 pb-2 text-xs md:text-xs">
            {id}
          </p>
          <p className="text-disable text-md pb-2 text-xs md:text-xs">{date}</p>
        </div>
        <div className="flex flex-col">
          <div className="text-disable text-md text-xs md:text-xs mb-2 hidden md:block ml-auto">
            Submitted: {submitted}
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
}
