import React from "react";
import { Route, useHistory } from "react-router";

import ListContainer from "../shared-component/list/ListContainer";
import LoadingComponent from "../shared-component/loading/LoadingComponent";

const Trip = () => {
  const history = useHistory()

  const handleClickApply = () => {
    history.push("/trip/apply")
  }
  
  return (
    <ListContainer
      title="Business Trip"
      icon="flight"
      initialUrl="/trip"
      listTab={listTab}
      directUrl={listTab[0].value}
      textButton="Apply for Trip"
      onClickButton={handleClickApply}
    >
      <React.Suspense fallback={<LoadingComponent />}>
        {TRIP_TAB.map(({ path, component }) => (
          <Route key={path} component={component} path={path} exact />
        ))}
      </React.Suspense>
    </ListContainer>
  );
};

const TRIP_TAB = [
  {
    path: "/trip/activity",
    component: React.lazy(() => import("./tab/activity/Activity")),
  },
  {
    path: "/trip/group-activity",
    component: React.lazy(() => import("./tab/group-activity/GroupActivity")),
  },
  {
    path: "/trip/request",
    component: React.lazy(() => import("./tab/request/Request")),
  },
];

const listTab = [
  { label: "Activity", value: "/trip/activity" },
  { label: "Group Activity", value: "/trip/group-activity" },
  { label: "Request", value: "/trip/request" },
];

export default Trip;
