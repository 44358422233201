import React from 'react'

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import {ButtonStyled} from '../../components/button/ButtonStyles'
import Divider from '../../components/divider/DividerStyles'
import DigitalDocumentTabs from './tab/DigitalDocumentTabs'

function DigitalComponent(props) {
  const {setPage, setRefetch} = props

  const [tabsValue, setTabsValue] = React.useState(0)
  return (
    <>
      <div
        style={{padding: '24px 30px'}}
        className="flex flex-row items-center justify-between "
      >
        <div className="font-bold text-xl flex justify-center items-center flex-row">
          <InsertDriveFileIcon
            text="#014a62"
            className="mr-3"
            style={{fontSize: 40, color: '#014a62'}}
          />
          <TypographyStyled
            component="span"
            size="16px"
            weight="600"
            text="#014a62"
          >
            Document
          </TypographyStyled>
        </div>
        {tabsValue === 0 && (
          <ButtonStyled
            classes={{
              root: 'text-sm',
              label: 'h-4 font-semibold',
            }}
            style={{padding: '14px 37px'}}
            size="large"
            state={'primary'}
            onClick={() => {
              setPage('upload')
              // setType('add')
            }}
          >
            Upload File
          </ButtonStyled>
        )}
      </div>
      <Divider />
      <DigitalDocumentTabs
        setRefetch={setRefetch}
        tabsValue={tabsValue}
        setTabsValue={setTabsValue}
      />
    </>
  )
}

export default DigitalComponent
