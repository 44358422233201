import React, {useEffect, useState} from 'react'

import {useQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'

import {USER_ID} from '../../utils/globals'
import {GET_WORK_OVERTIME, GET_SALARY} from '../../graphql/queries'
import {Paper} from '../../components/paper/PaperStyles'
import OvertimeTab from './tab/OvertimeTab'
import ApplyForOvertime from './apply-for-overtime/ApplyForOvertime'
import {getLastSalary} from './helperForOvertime'

const Overtime = props => {
  const [salary, setSalary] = useState(0)
  const {data: dataWork} = useQuery(GET_WORK_OVERTIME, {
    variables: {
      user: USER_ID,
    },
  })

  const {error: errorSalary} = useQuery(GET_SALARY, {
    skip: !dataWork,
    variables: {user_work_id: dataWork?.people_work_placements?.[0].id},
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
    onCompleted: data => {
      const last_salary = getLastSalary(data?.people_work_salaries)?.value
      setSalary(last_salary || 0)
    },
  })

  const renderComponent = activeUrl => {
    switch (activeUrl) {
      case '/overtime':
        return <OvertimeTab salary={salary} dataWork={dataWork} {...props} />
      case '/overtime/apply':
        return <ApplyForOvertime salary={salary} {...props} />
      default:
        return <OvertimeTab salary={salary} dataWork={dataWork} {...props} />
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  if (errorSalary) {
    console.error(errorSalary)
  }

  return (
    <div className="flex flex-col m-0 rounded">
      <Paper>{renderComponent(props.match.url)}</Paper>
    </div>
  )
}

export default withRouter(Overtime)
