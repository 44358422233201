import ApplyForTrip from "./apply-for-trip/ApplyForTrip";
import Trip from "./TripComponent";

export const TripConfig = {
  routes: [
    {
      path: "/trip/apply",
      component: ApplyForTrip,
      state: "Trip",
      exact: true,
    },
    {
      path: "/trip",
      component: Trip,
      state: "Trip",
    },
  ],
};
