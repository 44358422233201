import gql from 'graphql-tag'

export const GET_LIST_ATTENDANCE = gql`
  query(
    $user: uuid
    $sorting: order_by
    $offset: Int
    $limit: Int
    $idPolicy: [Int!]
    $dateStart: timestamptz
    $dateEnd: timestamptz
  ) {
    total: people_work_attendances_aggregate(
      where: {
        people_work_placement: {user: {_eq: $user}}
        time_attendance_policy: {id: {_in: $idPolicy}}
        _and: [{clock_in: {_gte: $dateStart}}, {clock_in: {_lte: $dateEnd}}]
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_attendances(
      where: {
        people_work_placement: {user: {_eq: $user}}
        time_attendance_policy: {id: {_in: $idPolicy}}
        _and: [{clock_in: {_gte: $dateStart}}, {clock_in: {_lte: $dateEnd}}]
      }
      order_by: [{id: $sorting}]
      offset: $offset
      limit: $limit
    ) {
      id
      time_attendance_policy {
        id
        name
        description
        schedule
        flexi
        mobile_setting
      }
      clock_in
      clock_out
      location
      attendance_fields
    }
  }
`

export const GET_LIST_ATTENDANCE_POLICY = gql`
  query getEmployeeAttendancePolicy($userWorkId: Int!) {
    time_attendance_policies(
      where: {
        company_job_profile_r_attendances: {
          company_job_profile: {
            people_work_placements: {id: {_eq: $userWorkId}}
          }
        }
        deletedAt: {_is_null: true}
      }
    ) {
      id
      name
      description
      type
      schedule
      flexi
      late_tolerance
      label_color
      text_color
      date_added
      last_modified
      deletedAt
      location_option
      mobile_setting
      time_attendance_policy_locations {
        id
        time_attendance_location {
          id
          name
          location
          location_info
          location_radius
        }
      }
    }
  }
`
export const GET_LIST_ATTENDANCE_POLICY_FILTER = gql`
  query getEmployeeAttendancePolicy($userWorkId: Int!, $search: String) {
    time_attendance_policies(
      where: {
        company_job_profile_r_attendances: {
          company_job_profile: {
            people_work_placements: {id: {_eq: $userWorkId}}
          }
        }
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      name
    }
  }
`

export const GET_USERWORK = gql`
  query($user: uuid) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
    }
  }
`

export const GET_LIST_HOLIDAY = gql`
  query($user_work_id: Int!, $date: date) {
    people_work_leaves(
      where: {
        user_work_id: {_eq: $user_work_id}
        status: {_eq: "approved"}
        datestart: {_lte: $date}
        dateend: {_gte: $date}
        is_half: {_eq: false}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      is_half
      leave_fields
    }
    leave_half: people_work_leaves(
      where: {
        user_work_id: {_eq: $user_work_id}
        status: {_eq: "approved"}
        datestart: {_gte: $date}
        dateend: {_lte: $date}
        is_half: {_eq: true}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      is_half
      leave_fields
    }
    trip: people_work_business_trips(
      where: {
        user_work_id: {_eq: $user_work_id}
        status: {_eq: "approved"}
        date_start: {_lte: $date}
        date_end: {_gte: $date}
      }
    ) {
      id
    }
  }
`

export const GET_TODAY_HOLIDAY = gql`
  query CheckerTodayHoliday($company: uuid!, $date: date!) {
    time_holidays(
      where: {
        company: {_eq: $company}
        date: {_eq: $date}
        deletedAt: {_is_null: true}
        type: {_neq: "leave"}
      }
    ) {
      id
      date
      description
      type
    }
  }
`

export const GET_EVENT_TODAY = gql`
  query($date: date, $user_work_id: Int, $user_id: uuid) {
    people_work_leaves(
      where: {
        datestart: {_lte: $date}
        dateend: {_gte: $date}
        status: {_eq: "approved"}
        user_work_id: {_eq: $user_work_id}
      }
      limit: 1
    ) {
      id
    }
    people_work_business_trips(
      where: {
        deletedAt: {_is_null: true}
        user_id: {_eq: $user_id}
        status: {_eq: "approved"}
        date_start: {_lte: $date}
        date_end: {_gte: $date}
      }
      limit: 1
    ) {
      id
    }
  }
`
