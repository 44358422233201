import Document from './DocumentComponent'

export const DocumentConfig = {
  routes: [
    {
      path: '/digital-document',
      component: Document,
      state: 'document',
      exact: true,
    },
  ],
}
