import React from 'react'
import {withRouter} from 'react-router'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import {IconStyled} from '../../../components/Icons/IconComponent'
import {Paper} from '../../../components/paper/PaperStyles'
import {TabsStyled, TabStyled} from '../../../components/tabs/TabsComponents'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'

const getActiveTab = (location, initialUrl, directUrl) => {
  if (location.pathname === initialUrl) return directUrl
  else return location.pathname
}

const ListContainer = ({
  title,
  listTab,
  initialUrl,
  directUrl,
  location,
  history,
  icon,
  textButton,
  onClickButton,
  stateButton = 'primary',
  children,
}) => {
  React.useEffect(() => {
    if (directUrl && location.pathname === initialUrl) {
      history.replace({pathname: directUrl})
    }
  }, [location, history, directUrl])

  const activeTab = getActiveTab(location, initialUrl, directUrl)

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    history.push({
      pathname: newValue,
    })
  }

  return (
    <div className="flex flex-col w-full">
      <Paper>
        <div
          style={{padding: '24px 30px'}}
          className="flex flex-row items-center justify-between "
        >
          <div className="font-bold text-xl flex justify-center items-center flex-row">
            <IconStyled text="#014a62" className="mr-3">
              {icon}
            </IconStyled>
            <TypographyStyled
              component="span"
              size="16px"
              weight="600"
              text="#014a62"
            >
              {title}
            </TypographyStyled>
          </div>
          {onClickButton && (
            <ButtonStyled
              classes={{root: 'text-sm'}}
              size="large"
              state={stateButton}
              onClick={onClickButton}
              style={{padding: '8px 22px', outline: 'none'}}
            >
              {textButton}
            </ButtonStyled>
          )}
        </div>
        {listTab && (
          <TabsStyled
            value={activeTab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChangeTabs}
          >
            {listTab.map(({label, value}) => (
              <TabStyled key={value} label={label} value={value} />
            ))}
          </TabsStyled>
        )}
        {children}
      </Paper>
    </div>
  )
}

export default withRouter(ListContainer)
