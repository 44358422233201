import React, {useEffect, useState} from 'react'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import {GET_REPORT_USER, GET_ID_PLACEMENT} from '../../graphql/queries'
import {useQuery, useLazyQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../utils/globals'
import LoadingComponent from '../shared-component/loading/LoadingComponent'
import {
  TextFieldStyledNoLine2,
  MenuItemStyled,
} from '../../components/form/FormStyles'
import moment from 'moment'
import ModalCalendar from './SharedCompoenentAttendance/ModalCalendar'
import DateWLB from './SharedCompoenentAttendance/DateWLB'
import TitleDate from './SharedCompoenentAttendance/TitleDate'

const StatusComponent = () => {
  const [open, setOpen] = useState(false)
  const [sort, setSort] = useState('this')
  const [titleDate, setTitleDate] = useState({
    start: null,
    end: null,
    _start: null,
    _end: null,
  })
  const [reset, setReset] = useState(false)
  const [filter, setFilter] = useState(null)

  const {data: dataIdPlacement, error: errorId} = useQuery(GET_ID_PLACEMENT, {
    variables: {
      user: USER_ID,
    },
  })
  const [getDataReportUser, {data: dataQuery, error, loading}] = useLazyQuery(
    GET_REPORT_USER
  )

  useEffect(() => {
    if (dataIdPlacement) {
      const now = moment()

      if (sort !== 'all' && sort !== 'custom' && sort !== 'this') {
        getDataReportUser({
          variables: {
            userWorkId: dataIdPlacement.people_work_placements[0].id,
            startDate: moment(now)
              .startOf('month')
              .subtract(sort, 'month')
              .toISOString(),
            endDate: moment(now)
              .endOf('day')
              .toISOString(),
          },
        })
      } else {
        getDataReportUser({
          variables: {
            userWorkId: dataIdPlacement.people_work_placements[0].id,
            startDate:
              sort === 'custom' && filter
                ? filter.startDate
                : sort === 'this'
                ? moment(now).startOf('month')
                : dataIdPlacement.people_work_placements[0].start_date,
            endDate:
              sort === 'custom' && filter
                ? filter.endDate
                : moment(now)
                    .endOf('day')
                    .toISOString(),
          },
        })
      }
    }
  }, [dataIdPlacement, getDataReportUser, filter, sort])

  if (error || errorId) {
    console.error(error || errorId)
  }

  const AttendanceStatus = [
    {
      id: 'countIn',
      label: 'In',
      description: 'Total day of your attendance',
      value: '[value] Days',
    },
    {
      id: 'countAbsent',
      label: 'Absence',
      description: 'Total day of your absence',
      value: '[value] Days',
    },
    {
      id: 'countOnTrip',
      label: 'On Trip',
      description: 'Total day of your trip in working day',
      value: '[value] Days',
    },
    {
      id: 'countLateDays',
      label: 'Late in Day',
      description: 'Total day of your late',
      value: '[value] Days',
    },
    {
      id: 'countLateMinutes',
      label: 'Late in Minute',
      description: 'Total minute of your late',
      value: '[value] Minutes',
    },
  ]

  const handleChangeSortDate = value => {
    setFilter(null)
    if (value !== 'custom') {
      setSort(value)
    }
    if (value === 'custom') {
      setOpen(true)
    }
  }

  const handleSubmitCustomFilter = () => {
    setOpen(false)

    setSort('custom')
    setFilter({
      startDate: moment(titleDate._start)
        .startOf('day')
        .toISOString(),
      endDate: moment(titleDate._end)
        .endOf('day')
        .toISOString(),
    })

    setTitleDate(e => ({...e, start: e._start, end: e._end}))
  }

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between">
        <TextFieldStyledNoLine2
          type="hidden"
          value={sort}
          onChange={e => handleChangeSortDate(e.target.value)}
          select
          style={{width: 'auto'}}
          size="small"
          InputProps={{
            inputProps: {
              classes: {
                root: 'text-sm cursor-pointer',
              },
            },
          }}
        >
          <MenuItemStyled className="text-xs" value="all">
            All
          </MenuItemStyled>
          <MenuItemStyled className="text-xs" value="this">
            This Month
          </MenuItemStyled>
          <MenuItemStyled className="text-xs" value={1}>
            Last 1 Month
          </MenuItemStyled>
          <MenuItemStyled className="text-xs" value={3}>
            Last 3 Month
          </MenuItemStyled>
          <MenuItemStyled className="text-xs" value={6}>
            Last 6 Month
          </MenuItemStyled>
          <MenuItemStyled className="text-xs" value={12}>
            Last 1 Year
          </MenuItemStyled>
          <MenuItemStyled
            onClick={() => setOpen(true)}
            className="text-xs"
            value="custom"
          >
            <div onClick={() => setOpen(true)}>Custom Date</div>
          </MenuItemStyled>
        </TextFieldStyledNoLine2>
        <TypographyStyled className="text-sm" text="#000" variant="body1">
          {dataIdPlacement &&
            (sort === 'custom' && filter ? (
              `${moment(filter.startDate).format('LL')} - ${moment(
                filter.endDate
              ).format('LL')}`
            ) : (
              <span>
                {sort === 'all'
                  ? moment(
                      dataIdPlacement.people_work_placements[0].start_date
                    ).format('LL')
                  : sort === 'custom'
                  ? moment()
                      .subtract(1, 'years')
                      .format('LL')
                  : sort === 'this'
                  ? moment()
                      .startOf('month')
                      .format('LL')
                  : moment()
                      .subtract(sort, 'months')
                      .format('LL')}{' '}
                - {moment().format('LL')}
              </span>
            ))}
        </TypographyStyled>
      </div>
      <div>
        {dataQuery && !loading ? (
          AttendanceStatus.map((res, i) => {
            const value = dataQuery?.getAttendanceReportsUser?.[res.id]
            return (
              <div
                style={{border: '1px solid rgba(0, 0, 0, 0.08)'}}
                className="p-4 my-6 rounded-wlb"
                key={i}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <TypographyStyled text="#014a62" variant="body1">
                      {res.label}
                    </TypographyStyled>
                    <TypographyStyled
                      text="#a9a8a8"
                      className="text-xs"
                      variant="body2"
                    >
                      {res.description}
                    </TypographyStyled>
                  </div>
                  <TypographyStyled text="#014a62" variant="body1">
                    {res.value.replace('[value]', value)}
                  </TypographyStyled>
                </div>
              </div>
            )
          })
        ) : (
          <LoadingComponent />
        )}
      </div>
      <ModalCalendar
        open={open}
        onClose={() => setOpen(false)}
        title={<TitleDate titleDate={reset ? null : titleDate} />}
      >
        <DateWLB
          setReset={setReset}
          onClose={() => {
            setOpen(false)
          }}
          onSubmit={handleSubmitCustomFilter}
          titleDate={titleDate}
          setTitleDate={setTitleDate}
        />
      </ModalCalendar>
    </div>
  )
}
export default StatusComponent
