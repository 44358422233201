import React from 'react'
import AsyncSelect from "react-select/async";
import {
  customSelect,
  DropdownIndicator,
  DropdownIndicatorSearch,
  SingleValue,
  SingleValuePlain,
  MultiValue,
} from "./customSelect";

export const AsyncSelectStyled = (props) => {
  return (
    <AsyncSelect
      styles={customSelect(props)}
      components={{
        DropdownIndicator: props.indicatorSearch
          ? DropdownIndicatorSearch
          : DropdownIndicator,
        MultiValue,
        SingleValue: props.singleValuePlain ? SingleValuePlain : SingleValue,
      }}
      {...props}
    />
  );
};
