import gql from 'graphql-tag'

export const MUTATION_CHECKIN = gql`
  mutation(
    $userWorkId: Int!
    $policyId: Int!
    $clockIn: String
    $location: JSON!
    $photo: String
    $lateMinutes: Int
    $locationId: Int
    $description: String
  ) {
    attendanceIn(
      userWorkId: $userWorkId
      policyId: $policyId
      clockIn: $clockIn
      location: $location
      photo: $photo
      lateMinutes: $lateMinutes
      locationId: $locationId
      description: $description
    ) {
      id
    }
  }
`
