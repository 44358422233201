import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {Typography} from '@material-ui/core'

import {GET_CURRENCY_EXCHANGE} from '../../graphql/queries'

import {formatCurrency, getCurrencySign} from './currencyExchanges'

export function ConversionDisplay({from, to, date, value, embolden = false}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    skip: from === to,
    fetchPolicy: date ? 'cache-first' : 'cache-and-network',
    variables: {
      currency: from,
      date: date ? date.split('T')[0] : '',
    },
  })

  const formattedFrom = React.useMemo(() => {
    return formatCurrency(from, value)
  }, [from, value])

  const formattedTo = React.useMemo(() => {
    return (
      data &&
      formatCurrency(to, value * Math.round(data.getCurrencyExchange.idr))
    )
  }, [to, value, data])

  return (
    <div>
      <Typography
        className={embolden ? 'text-sm font-bold' : 'text-xs'}
        align="right"
      >
        {formattedFrom}
      </Typography>

      {from !== to && (
        <Typography
          className={embolden ? 'text-sm' : 'text-xs'}
          align="right"
          color="textSecondary"
        >
          {data ? formattedTo : `${getCurrencySign(to)} ⋯`}
        </Typography>
      )}
    </div>
  )
}

export function InputConversionDisplay({
  from,
  to,
  value,
  date,
  dense = false,
  approximate = !date,
}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    skip: from === to,
    fetchPolicy: date ? 'cache-first' : 'cache-and-network',
    variables: {
      currency: from,
      date: date ? date.split('T')[0] : '',
    },
  })

  const formattedTo = React.useMemo(() => {
    const rate =
      data &&
      formatCurrency(to, value * Math.round(data.getCurrencyExchange.idr))

    return (approximate ? '~ ' : '') + rate
  }, [to, value, data, approximate])

  return (
    <Typography
      className={`${dense ? 'mt-1' : 'mt-2'} text-xs leading-3`}
      align="right"
      color="textSecondary"
    >
      {data ? formattedTo : `${getCurrencySign(to)} ⋯`}
    </Typography>
  )
}
