import React, {useEffect, useState} from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  IconButtonStyled,
  ButtonStyled,
} from '../../../components/button/ButtonStyles'
import Divider from '../../../components/divider/DividerStyles'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {
  TextFieldStyled,
  KeyboardDatePickerStyled,
  KeyboardTimePickerStyled,
  InputAdornmentStyled,
  SelectStyled,
  MenuItemStyled,
  FormControlStyled,
  FormHelperTextStyled,
} from '../../../components/form/FormStyles'
import DatePickerWrapper from '../../shared-component/date/DatePickerWrapper'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import {AttachmentItem, UploadButton} from '@smartasn/wlb-utils-components'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import ContentTooltipFile from '../../shared-component/tooltip/ContentTooltipFile'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Axios from 'axios'
import {TOKEN, UPLOAD_URL, USER_ID} from '../../../utils/globals'
import {
  checkFilesType,
  generateFulldateFormat,
  generateFulldateFormatFull,
  getCompensationDescriptionSelected,
} from '../helperForOvertime'
import {useMutation, useApolloClient, useQuery} from '@apollo/react-hooks'
import {
  GET_CHECKER_COMPENSATION_OVERTIME,
  GET_LIST_POLICY_OVERTIME,
} from '../../../graphql/queries'
import {addHours} from 'date-fns'
import {useSnackbar} from 'notistack'
import {INSERT_APPLY_FOR_OVERTIME} from '../../../graphql/mutations'
import CancelDialog from '../../shared-component/dialog/CancelDialog'
import {Box, useMediaQuery, useTheme} from '@material-ui/core'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {withRouter} from 'react-router-dom'
import NotificationNoPolicy from '../../shared-component/dialog/NotificationNoPolicy'
import NotificationWLB from '../../shared-component/dialog/NotificationWLB'
import useGetProfile from '../../../hooks/useGetProfile'

function ApplyForOvertime(props) {
  const {salary} = props
  const client = useApolloClient()
  const {user_work_id, name} = useGetProfile()
  const {enqueueSnackbar} = useSnackbar()
  const [error, setError] = useState(false)
  const [maxLongTime, setMaxLongTime] = useState(null)
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [errorFile, setErrorFile] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [fileType, setFileType] = useState('')
  const [openSubmit, setOpenSubmit] = useState(false)
  const [convertTo, setConvertTo] = useState('Money')
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [open, setOpen] = useState({
    dialogCancel: false,
    unableChooseCompensation: false,
    notif: false,
    errorPayWithMoney: false,
  })
  const [values, setValues] = useState({
    name: '',
    date: new Date(),
    time: new Date(),
    longTime: '',
    type: '',
    description: '',
    attachments: [],
  })
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  const {data: dataPolicy} = useQuery(GET_LIST_POLICY_OVERTIME, {
    variables: {
      user: USER_ID,
    },
  })

  const [insertApply] = useMutation(INSERT_APPLY_FOR_OVERTIME)

  useEffect(() => {
    if (dataPolicy && dataPolicy.time_overtime_policies.length === 0) {
      setOpen(prev => ({...prev, notif: true}))
    }
  }, [dataPolicy])

  const fieldList = [
    {
      number: !smallSize && '1',
      fieldName: 'Name*',
      field: 'name',
      type: 'text',
      value: name,
    },
    {
      number: !smallSize && '2',
      fieldName: 'Overtime Date*',
      field: 'date',
      type: 'datepicker',
      value: values.date,
      error: error && !values.date,
    },
    {
      number: !smallSize && '3',
      fieldName: 'Start Time*',
      field: 'time',
      type: 'timepicker',
      value: values.time,
      error: error && !values.time,
    },
    {
      number: !smallSize && '4',
      fieldName: 'Long Time*',
      field: 'longTime',
      type: 'number',
      value: values.longTime,
      inputProps: true,
      placeholder: 'Long Time',
      error:
        error &&
        (!values.longTime || (maxLongTime && values.longTime > maxLongTime)),
      helperText:
        maxLongTime && values.longTime > maxLongTime
          ? `The maximum hours for overtime is ${maxLongTime} hours long`
          : 'This field is required',
    },
    {
      number: !smallSize && '5',
      fieldName: 'Compensation*',
      field: 'type',
      type: 'select',
      value: values.type,
      options: dataPolicy ? dataPolicy.time_overtime_policies : [],
      error: error && !values.type,
    },
    {
      number: !smallSize && '6',
      fieldName: 'Description*',
      field: 'description',
      type: 'text',
      value: values.description,
      textArea: true,
      placeholder: 'Add description',
      error: error && !values.description,
    },
    {
      number: !smallSize && '7',
      field: 'attachments',
      titleComponent: 'div',
      fieldName: (
        <div className="flex flex-row items-center">
          <span>Attachments</span>
          <span className="mx-2 text-disable text-xs">(optional)</span>
          <InfoFileTooltip title={<ContentTooltipFile />} placement="right">
            <InfoOutlinedIcon className="text-xs text-primary" />
          </InfoFileTooltip>
        </div>
      ),
      type: 'attachment',
      attachments: values.attachments,
      error: error && values.description.length === 0,
    },
  ]

  const isUserCanUseCompensation = async ({policy, placement_id}) => {
    await setLoadingSubmit(true)
    const {data} = await client.query({
      query: GET_CHECKER_COMPENSATION_OVERTIME,
      variables: {placement_id, policy},
    })
    await setLoadingSubmit(false)
    return data?.company_job_profile_r_leave_aggregate?.aggregate?.count >= 1
  }

  const handleChange = async (e, target, child) => {
    if (target === 'date' || target === 'time') {
      setValues({
        ...values,
        [target]: e,
      })
    } else if (target === 'attachments') {
      setErrorFile(false)
      setErrorSize(false)
      const tempFile = Array.from(e.target.files)
      const [isErrorType, isMaxsize] = checkFilesType(tempFile, setFileType)
      if (isErrorType) {
        setErrorFile(true)
      } else if (isMaxsize) {
        setErrorSize(true)
      } else {
        setLoadingUpload(true)
        tempFile.forEach(val => {
          const formData = new FormData()
          formData.append('file', val)
          return Axios.post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          })
            .then(res => {
              setLoadingUpload(false)
              setValues(prevState => {
                return {
                  ...values,
                  attachments: [
                    ...prevState.attachments,
                    {link: res.data.url, name: val.name, size: val.size},
                  ],
                }
              })
            })
            .catch(() => {
              setLoadingUpload(false)
            })
        })
      }
    } else if (target === 'type') {
      const {
        convert_to_leave,
        convert_to_money,
        time_overtime_policy,
      } = child.props.data
      setConvertTo(convert_to_leave ? 'Leave' : 'Money')

      if (convert_to_leave) {
        const isUserCanUse = await isUserCanUseCompensation({
          policy: time_overtime_policy?.id,
          placement_id: user_work_id,
        })

        if (isUserCanUse) setValues({...values, [target]: e.target.value})
        else setOpen({...open, unableChooseCompensation: true})
      } else {
        setMaxLongTime(convert_to_money?.multipliers?.length || null)
        setValues({...values, [target]: e.target.value})
      }
      setSelectedPolicy(child.props.data)
    } else {
      setValues({
        ...values,
        [target]: e.target.value,
      })
    }
  }

  const submitApplied = () => {
    setLoadingSubmit(true)
    const submit = {
      objects: [
        {
          user_work_id,
          policy_id: values.type,
          convert_to: convertTo,
          description: values.description,
          datestart: generateFulldateFormat(values.date, values.time),
          dateend: generateFulldateFormatFull(
            addHours(
              new Date(generateFulldateFormat(values.date, values.time)),
              Number(values.longTime)
            )
          ),
          overtime_fields: {
            attachment: values.attachments,
          },
        },
      ],
    }
    insertApply({
      variables: submit,
    })
      .then(() => {
        enqueueSnackbar('Apply for Overtime Success', {variant: 'success'})
        back()
        setOpenSubmit(false)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const errorSetter = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  const handleSubmit = () => {
    const nullError = [null, '']
    const notNull = ['date', 'time', 'longTime', 'type', 'description']

    const isErrorMaxTime = maxLongTime && values?.longTime > maxLongTime
    const isErrorNotNull = notNull.some(r => nullError.includes(values[r]))

    if (isErrorMaxTime) {
      setOpen(prev => ({...prev, errorPayWithMoney: true}))
    } else if (isErrorNotNull) {
      errorSetter()
    } else {
      setOpenSubmit(true)
    }
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...values.attachments]
    newState[i].name = e.target.value
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const handleDeleteAttachement = i => {
    const newState = [...values.attachments]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({...values, attachments: newState})
  }

  const renderComponent = res => {
    const {
      type,
      value,
      options,
      inputProps,
      textArea,
      attachments,
      fieldName,
      field,
      placeholder,
      helperText = 'This field is required',
      error,
    } = res
    if (type === 'text' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          fullWidth
          disabled={field === 'name'}
          value={inputProps ? parseInt(value) : value}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e, field)}
          multiline={textArea}
          rows={textArea && 8}
          placeholder={placeholder}
          InputProps={
            inputProps
              ? {
                  inputProps: {className: 'text-xs', min: 0},
                  endAdornment: (
                    <InputAdornmentStyled position="end" disableTypography>
                      <div
                        className="flex items-center"
                        style={{
                          borderLeft: `1px solid ${
                            error ? '#ef4d5e' : 'rgba(0, 0, 0, 0.27)'
                          }`,
                          paddingLeft: 13,
                          height: 34,
                        }}
                      >
                        <p
                          className={`text-sm ${
                            error ? 'text-error' : 'text-disable'
                          }`}
                        >
                          Hours
                        </p>
                      </div>
                    </InputAdornmentStyled>
                  ),
                }
              : {
                  inputProps: {className: 'text-xs'},
                }
          }
          error={error}
          helperText={error && helperText}
          classes={
            placeholder && {
              root: 'text-xs',
            }
          }
        />
      )
    } else if (type === 'datepicker') {
      return (
        <DatePickerWrapper>
          <KeyboardDatePickerStyled
            style={{maxWidth: 200}}
            value={value}
            inputVariant="outlined"
            format="MMMM dd, yyyy"
            size="small"
            space="0px"
            onChange={e => handleChange(e, field)}
            error={values[field] === null && error}
            helperText={
              values[field] === null && error && 'This field is required'
            }
            inputProps={{className: 'text-xs'}}
            disablePast
          />
        </DatePickerWrapper>
      )
    } else if (type === 'timepicker') {
      return (
        <DatePickerWrapper>
          <KeyboardTimePickerStyled
            space="0px"
            value={value}
            onChange={e => handleChange(e, field)}
            style={{maxWidth: 140}}
            inputVariant="outlined"
            size="small"
            keyboardIcon={<QueryBuilderIcon />}
            error={values[field] === null && error}
            helperText={
              values[field] === null && error && 'This field is required'
            }
            inputProps={{className: 'text-xs'}}
          />
        </DatePickerWrapper>
      )
    } else if (type === 'select') {
      return (
        <>
          <FormControlStyled
            // style={{width: '75%'}}
            size="small"
          >
            <SelectStyled
              inputProps={{className: 'text-xs'}}
              // style={{maxWidth: 339}}
              value={value}
              displayEmpty
              fullWidth
              onChange={(e, f) => handleChange(e, field, f)}
              variant="outlined"
              error={values[field] === '' && error}
            >
              <MenuItemStyled
                classes={{
                  root: 'text-xs',
                }}
                value=""
                disabled
              >
                Choose {fieldName}
              </MenuItemStyled>
              {options.map((option, i) => (
                <MenuItemStyled
                  classes={{
                    root: 'text-xs',
                  }}
                  key={i}
                  dataconvert={option.convert_to_money}
                  value={option.id}
                  data={option}
                >
                  {option.name}
                </MenuItemStyled>
              ))}
            </SelectStyled>
            {values[field] === '' && error && (
              <FormHelperTextStyled className="text-error mx-3">
                This field is required
              </FormHelperTextStyled>
            )}
          </FormControlStyled>
          <div className="mt-6">
            <p className="font-semibold text-xs">Compensation Description</p>
            <p className="font-semibold text-xs mt-2">
              {(selectedPolicy &&
                !open.unableChooseCompensation &&
                getCompensationDescriptionSelected({
                  selectedItem: selectedPolicy,
                  longTime: values.longTime,
                  salary,
                })) ||
                '-'}
            </p>
          </div>
        </>
      )
    } else if (type === 'attachment') {
      return (
        <>
          <UploadButton
            multiple
            // disabled={values.attachments.length > 0 || loadingUpload}
            onChange={ev => handleChange(ev, field)}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {attachments.map((file, idx) => {
              return (
                <AttachmentItem
                  key={idx}
                  name={file.name}
                  size={file.size}
                  url={file.link}
                  onNameChange={ev => handleChangeNameFile(ev, idx)}
                  onRemove={() => handleDeleteAttachement(idx)}
                />
              )
            })}

            {loadingUpload && <AttachmentItem isUploading />}
          </Box>

          {errorFile && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              There are file types that are not allowed
            </FormHelperTextStyled>
          )}
          {errorSize && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              Uploaded file size cannot exceed 25MB
            </FormHelperTextStyled>
          )}
        </>
      )
    }
  }

  const handleResetCompensation = () => {
    setOpen({...open, unableChooseCompensation: false})
    setSelectedPolicy(null)
    setValues({...values, type: ''})
  }

  const handleCloseErrorDialogPWM = () => {
    setOpen(prev => ({...prev, errorPayWithMoney: false}))
    errorSetter()
  }

  const back = () => {
    props.history.push({pathname: '/overtime'})
  }

  return (
    <>
      <div className="sm:p-8 p-6 flex flex-row">
        <div className="flex flex-row items-center">
          <IconButtonStyled
            className="p-0"
            // onClick={() => props.history.goBack()}
            onClick={back}
            // onClick={() => setOpen({...open, dialogCancel: true})}
          >
            <ArrowBackIcon />
          </IconButtonStyled>
          <div className="text-primary font-semibold sm:text-lg text-sm ml-3">
            Apply for Overtime
          </div>
        </div>
      </div>
      <Divider />
      <div className={`pt-8 sm:px-8 px-6 flex flex-col`}>
        {fieldList.map((res, i) => (
          <FormWrapper
            forMobile={smallSize}
            key={i}
            number={res.number}
            title={res.fieldName}
            titleComponent={res.titleComponent}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
      </div>
      <Divider />
      <div className="py-8 sm:px-8 px-6 flex flex-row ml-auto">
        <ButtonStyled
          onClick={() =>
            setOpen({
              ...open,
              dialogCancel: true,
            })
          }
          text="#000"
          className="px-6 py-2"
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          disabled={loadingSubmit || !user_work_id}
          onClick={handleSubmit}
          state="primary"
          className="px-6 py-2 ml-2"
        >
          Submit
        </ButtonStyled>
      </div>
      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        // onClickPrimary={handleClickCancel}
        onClickPrimary={back}
      />

      <ConfirmationDialog
        title="Apply for Overtime?"
        content="Are you sure you want to apply for this overtime?"
        onClickPrimary={submitApplied}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={loadingSubmit}
      />
      <NotificationWLB
        open={open.unableChooseCompensation}
        onClick={handleResetCompensation}
        message="Unable to choose policy because you are don't have the privilege to use this compensation policy"
        title="Unable to Choose Compensation Policy"
        type="primary"
        textButton="Confirm"
      />
      <NotificationWLB
        open={open.errorPayWithMoney}
        onClick={handleCloseErrorDialogPWM}
        message={`The maximum hour for overtime has been reached or exceeded. The maximum hour for overtime is ${maxLongTime} hours long.`}
        title="Unable to Apply Overtime"
        type="primary"
        textButton="Confirm"
      />
      <NotificationNoPolicy open={open.notif} onBack={back} />
    </>
  )
}

export default withRouter(ApplyForOvertime)
