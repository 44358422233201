import moment from 'moment'

import {GET_CLAIM_POLICY_FILTER_LIST} from '../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../utils/globals'

export const ACTIVITY_FILTER_CONFIG = [
  {
    fieldName: 'policies',
    name: 'Claim Policy',
    type: 'checkbox',
    emptyState: 'No claim policies',
    options: {
      fetch: {
        query: GET_CLAIM_POLICY_FILTER_LIST,
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit,
            where: {
              company_job_profile: {
                people_work_placements: {
                  user: {_eq: USER_ID},
                  company: {_eq: COMPANY_ID},
                },
              },
              finance_claim_policy_child: {
                id: {_in, _nin},
                deletedAt: {_is_null: true},
                name: {_ilike: search},
              },
            },
          }
        },
        setData: data => {
          const options = data.policies.map(item => ({
            value: item.policy.id,
            label: item.policy.name,
          }))

          return [options, data.total.aggregate.count]
        },
      },
    },
  },
  {
    fieldName: 'status',
    name: 'Status',
    type: 'checkbox',
    emptyState: 'No status',
    options: {
      list: [
        {value: 'waiting', label: 'Waiting'},
        {value: 'processing', label: 'Processing'},
        {value: 'approved', label: 'Approved'},
        {value: 'rejected', label: 'Rejected'},
        {value: 'cancelled', label: 'Canceled'},
      ],
    },
  },
  {
    fieldName: 'date',
    name: 'Date',
    type: 'date',
  },
]

export function getActivityFilterVariables(filterData) {
  if (!filterData) {
    return null
  }

  const include = filterData[0]
  const undef = undefined

  const policies = include.policies
  const status = include.status

  const date = include.date
  const dateFrom = date?.from
  const dateTo = date?.to

  const variables = {
    policies: policies?.length ? mapValues(policies) : undef,
    statuses: status?.length ? mapValues(status) : undef,
    startDate: dateFrom ? moment(dateFrom).format() : undef,
    endDate: dateTo ? moment(dateTo).format() : undef,
  }

  return variables
}

function mapValues(array) {
  return array.map(item => item.value)
}
