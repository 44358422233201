import gql from 'graphql-tag'

export const MUTATION_CHECKOUT = gql`
  mutation($userWorkId: Int!, $attendanceFields: JSON!) {
    attendanceOut(
      userWorkId: $userWorkId
      attendanceFields: $attendanceFields
    ) {
      id
    }
  }
`
