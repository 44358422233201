import React from 'react'

import {Box} from '@material-ui/core'

export default function FormEditBudgetWrapper({title, children}) {
  return (
    <Box>
      <h3 className="text-sm text-primary font-semibold mb-3">{title}</h3>
      {children}
    </Box>
  )
}
