import React from 'react'
import {
  ButtonStyled,
  IconButtonStyled,
} from '../../../components/button/ButtonStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {GET_LIST_TYPE} from '../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import Divider from '../../../components/divider/DividerStyles'
import Select from 'react-select'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {Box, useMediaQuery, useTheme} from '@material-ui/core'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import {checkFilesType} from './helperForDocument'
import {
  TextFieldStyled,
  InputAdornmentStyled,
  FormControlStyled,
  FormHelperTextStyled,
} from '../../../components/form/FormStyles'
import CancelDialog from '../../shared-component/dialog/CancelDialog'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {TOKEN, UPLOAD_URL} from '../../../utils/globals'
import {MUTATION_ADD_DOCUMENT} from '../../../graphql/mutations'
import Axios from 'axios'
import {useSnackbar} from 'notistack'
import {AttachmentItem, UploadButton} from '@smartasn/wlb-utils-components'
import ContentTooltipFile from '../../shared-component/tooltip/ContentTooltipFile'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

function FormUploadFile(props) {
  const {setPage} = props
  const [values, setValues] = React.useState({
    name: '',
    type: '',
    attachments: [],
  })

  const [loadingUpload, setLoadingUpload] = React.useState(false)
  const [errorFile, setErrorFile] = React.useState(false)
  const [fileType, setFileType] = React.useState('')
  const [errorSize, setErrorSize] = React.useState(false)

  const {enqueueSnackbar} = useSnackbar()

  const [error, setError] = React.useState(false)

  const [insertApply] = useMutation(MUTATION_ADD_DOCUMENT)

  const [openSubmit, setOpenSubmit] = React.useState(false)
  const [open, setOpen] = React.useState({
    dialogCancel: false,
  })

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  const {data, error: error2, loading} = useQuery(GET_LIST_TYPE, {
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  if (!error2 && loading) {
    return <LoadingComponent />
  }

  const handleChange = (e, target, child) => {
    if (child) {
      setValues({
        ...values,
        [target]: e,
      })
    } else {
      if (target === 'attachments') {
        setErrorFile(false)
        setErrorSize(false)
        const tempFile = Array.from(e.target.files)
        const [isErrorType, isMaxsize] = checkFilesType(tempFile, setFileType)
        if (isErrorType) {
          setErrorFile(true)
        } else if (isMaxsize) {
          setErrorSize(true)
        } else {
          setLoadingUpload(true)
          tempFile.forEach(val => {
            const formData = new FormData()
            formData.append('file', val)
            return Axios.post(UPLOAD_URL, formData, {
              headers: {
                Authorization: 'Bearer ' + TOKEN,
                'Content-Type': 'multipart/form-data',
              },
              withCredentials: true,
            })
              .then(res => {
                setLoadingUpload(false)
                setValues(prevState => {
                  return {
                    ...values,
                    attachments: [
                      ...prevState.attachments,
                      {link: res.data.url, name: val.name, size: val.size},
                    ],
                  }
                })
              })
              .catch(() => {
                setLoadingUpload(false)
              })
          })
          Array.from(document.querySelectorAll('#atch')).forEach(
            input => (input.value = '')
          )
        }
      } else {
        setValues({
          ...values,
          [target]: e.target.value,
        })
      }
    }
  }

  const renderComponent = res => {
    const {
      type,
      value,
      options,
      inputProps,
      textArea,
      field,
      placeholder,
      attachments,
    } = res
    if (type === 'text' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          value={inputProps ? parseInt(value) : value}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e, field)}
          multiline={textArea}
          rows={textArea && 8}
          placeholder={placeholder}
          style={{width: '80%'}}
          InputProps={
            inputProps
              ? {
                  inputProps: {className: 'text-xs', min: 0, maxLength: 254},
                  endAdornment: (
                    <InputAdornmentStyled position="end" disableTypography>
                      <div
                        style={{
                          borderLeft: '1px solid rgba(0, 0, 0, 0.27)',
                          paddingLeft: 13,
                          display: 'flex',
                          alignItems: 'center',
                          height: 34,
                          marginleftt: 6,
                        }}
                      >
                        <p style={{fontSize: 14, color: '#a9a8a8'}}>Hours</p>
                      </div>
                    </InputAdornmentStyled>
                  ),
                }
              : {
                  inputProps: {className: 'text-xs', maxLength: 254},
                }
          }
          error={field === 'name' && values[field] === '' && error}
          helperText={
            field === 'name' &&
            values[field] === '' &&
            error &&
            'This field is required'
          }
          classes={
            placeholder && {
              root: 'text-xs',
            }
          }
        />
      )
    } else if (type === 'select') {
      return (
        <>
          <FormControlStyled style={{width: '40%'}} size="small">
            <Select
              inputProps={{className: 'text-xs'}}
              style={{width: '40%'}}
              value={value}
              options={options}
              displayEmpty
              fullWidth
              onChange={(e, f) => handleChange(e, field, f)}
              variant="outlined"
              error={values[field] === '' && error}
            />
            {values[field] === '' && error && (
              <FormHelperTextStyled className="text-error mx-3">
                This field is required
              </FormHelperTextStyled>
            )}
          </FormControlStyled>
        </>
      )
    } else if (type === 'attachment') {
      return (
        <>
          <UploadButton
            multiple
            disabled={attachments.length > 0 || loadingUpload}
            onChange={ev => handleChange(ev, field)}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {attachments.map((file, idx) => {
              return (
                <AttachmentItem
                  key={idx}
                  name={file.name}
                  size={file.size}
                  url={file.link}
                  onNameChange={ev => handleChangeNameFile(ev, idx)}
                  onRemove={() => handleDeleteAttachement(idx)}
                />
              )
            })}

            {loadingUpload && <AttachmentItem isUploading />}
          </Box>

          {errorFile && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              There are file types that are not allowed
            </FormHelperTextStyled>
          )}
          {errorSize && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              Uploaded file size cannot exceed 25MB
            </FormHelperTextStyled>
          )}
          {error && attachments.length === 0 && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              This field is required
            </FormHelperTextStyled>
          )}
        </>
      )
    }
  }

  const option_type = []
  data &&
    data.global_file_types.map(row => {
      option_type.push({
        value: row.id,
        label: row.name,
      })
    })

  const fieldList = [
    {
      number: !smallSize && '',
      fieldName: 'File Name',
      field: 'name',
      type: 'text',
      value: values.name,
    },
    {
      number: !smallSize && '',
      fieldName: 'File Type',
      field: 'type',
      type: 'select',
      value: values.type,
      options: option_type.length >= 1 ? option_type : [],
    },
    {
      number: !smallSize && '',
      field: 'attachments',
      fieldName: (
        <div className="flex flex-row items-center">
          Attachments
          {/* <span className="mx-2 text-disable text-xs">(optional)</span> */}
          <InfoFileTooltip title={<ContentTooltipFile />} placement="right">
            <InfoOutlinedIcon className="text-xs text-primary" />
          </InfoFileTooltip>
        </div>
      ),
      type: 'attachment',
      attachments: values.attachments,
    },
  ]

  const handleSubmit = () => {
    const {name, type, attachments} = values
    if (
      // name === '' ||
      name === '' ||
      type === '' ||
      attachments.length === 0
    ) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      setOpenSubmit(true)
    }
  }

  const handleDeleteAttachement = i => {
    const newState = [...values.attachments]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({...values, attachments: newState})
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...values.attachments]
    newState[i].name = e.target.value
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const handleClickCancel = () => {
    setOpen({
      ...open,
      dialogCancel: false,
    })
    setPage('list')
  }

  const submitApplied = () => {
    const objects = []

    values.attachments &&
      values.attachments.map(row => {
        objects.push({
          name: values.name,
          type: values.type.value,
          file: row.link,
        })
      })

    insertApply({
      variables: {objects},
      context: {
        headers: {
          'X-Hasura-Role': 'user',
        },
      },
    })
      .then(() => {
        enqueueSnackbar(' Success', {
          variant: 'success',
        })
        // setForm(false)
        setPage('list')
        setOpenSubmit(false)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }
  return (
    <>
      <div className="sm:p-8 p-6 flex flex-row">
        <div className="flex flex-row items-center">
          <IconButtonStyled
            className="p-0"
            // onClick={() => props.history.goBack()}
            onClick={() => setPage('list')}
            // onClick={() => setOpen({...open, dialogCancel: true})}
          >
            <ArrowBackIcon />
          </IconButtonStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TypographyStyled
              className="font-semibold"
              text="#014a62"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '15px',
              }}
            >
              Personal File/
            </TypographyStyled>
            <TypographyStyled
              className="font-semibold"
              text="#a9a8a8"
              style={{
                fontSize: '18px',
                marginBottom: 4,
                lineHeight: '24px',
                paddingLeft: '5px',
              }}
            >
              Upload File
            </TypographyStyled>
          </div>
        </div>
      </div>

      <Divider />

      <div className={`pt-8 sm:px-8 px-6 flex flex-col`}>
        {fieldList.map((res, i) => (
          <FormWrapper
            forMobile={smallSize}
            key={i}
            number={res.number}
            title={res.fieldName}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
      </div>

      <Divider />
      <div
        className="py-8 sm:px-8 px-6 flex flex-row ml-auto"
        style={{justifyContent: 'flex-end'}}
      >
        <ButtonStyled
          onClick={() =>
            setOpen({
              ...open,
              dialogCancel: true,
            })
          }
          text="#000"
          className="px-6 py-2"
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
          state="primary"
          className="px-6 py-2 ml-2"
        >
          Submit
        </ButtonStyled>
      </div>
      <CancelDialog
        open={open.dialogCancel}
        title="Discard Changes?"
        content="Are you sure want to discard unsaved changes?"
        textSecondary="Cancel"
        textPrimary="Confirm"
        onClose={() =>
          setOpen({
            ...open,
            dialogCancel: false,
          })
        }
        onClickPrimary={handleClickCancel}
      />

      <ConfirmationDialog
        title="Apply for Personal File?"
        content="Are you sure you want to apply for this Personal File?"
        onClickPrimary={submitApplied}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={false}
      />
    </>
  )
}

export default FormUploadFile
