import gql from 'graphql-tag'

export const UPDATE_STATUS_APPROVE_REQUEST = gql`
  mutation($id: bigint!, $status: String!, $leave_fields: jsonb) {
    update_people_work_leaves(
      _set: {status: $status, leave_fields: $leave_fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_STATUS_CHANGES_LEAVE = gql`
  mutation update_people_work_leaves(
    $id: bigint!
    $changes: people_work_leaves_set_input
  ) {
    update_people_work_leaves(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
