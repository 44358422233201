import React, {useState} from 'react'
import ListItem from '../../../shared-component/list/ListItem'
import {
  TextFieldStyledNoLine,
  MenuItemStyled,
} from '../../../../components/form/FormStyles'
import {useMutation, useQuery} from '@apollo/react-hooks'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {
  addDay,
  generateDate,
  generateDateSubmit,
} from '../../../../utils/helpers'
import ModalDetailWeb from '../../../shared-component/dialog/ModalDetailWeb'
import {CANCEL_SUBMISSION_LEAVE} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import CancelDialog from '../../../shared-component/dialog/CancelDialog'
import DetailList from '../../../shared-component/dialog/DetailList'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'
import FilterWLB from '../../../shared-component/Filter/FilterWLB'
import IconFilterActive from '../../../shared-component/Filter/IconFilterActive'
import {GET_LIST_ACTIVITY_LEAVE} from '../../../../graphql/queries'
import TextfieldSearch from '../../../shared-component/form/TextfieldSearch'
import EmptyState from '../../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../../assets/images/nodatalist.png'
import {PAGINATION_RANGE} from '../../../../utils/constants'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'

const TabActivity = props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [search, setSearch] = useState('')
  const {idWork} = props
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE[0],
    page: 0,
  })
  const [filter, setFilter] = useState({
    policy: [],
    date: {
      dateStart: null,
      dateEnd: null,
    },
    status: {
      status: [],
      second: true,
    },
    requester: [],
  })
  const [sort, setSort] = useState('desc')
  const [open, setOpen] = useState({
    web: false,
    mobile: false,
    data: null,
    cancel: false,
    idCancel: null,
  })

  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const {data: dataActivity, error: errorList, loading, refetch} = useQuery(
    GET_LIST_ACTIVITY_LEAVE,
    {
      variables: {
        search: `%${search}%`,
        sorting: sort,
        user: USER_ID,
        company: COMPANY_ID,
        idPolicy: filter.policy.length === 0 ? null : filter.policy,
        dateStart: filter.date.dateStart,
        dateEnd: addDay(filter.date.dateEnd, 1),
        status: filter.status.status.length === 0 ? null : filter.status.status,
        limit: pagination.rowsPerPage,
        offset: pagination.page * pagination.rowsPerPage,
      },
      context: {headers: {'X-Hasura-Role': 'user'}},
      fetchPolicy: 'no-cache',
    }
  )

  const [cancelSubmission] = useMutation(CANCEL_SUBMISSION_LEAVE)

  if (errorList) {
    let renderedError = null

    if (typeof errorList === 'string') {
      renderedError = <span>{errorList}</span>
    } else if (Array.isArray(errorList)) {
      // Handle as an array, e.g., join the array elements into a string
      renderedError = <span>{errorList.join(', ')}</span>
    } else if (typeof errorList === 'object' && errorList !== null) {
      // Handle as an object, e.g., convert object keys/values into a string
      const errorString = Object.entries(errorList)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
      renderedError = <span>{errorString}</span>
    }

    return renderedError
  }

  const handleClick = res => {
    setOpen({mobile: false, web: true, data: res})
  }

  const cancelNow = id => {
    setDisabledSubmit(true)
    cancelSubmission({
      variables: {
        id,
      },
    })
      .then(() => {
        enqueueSnackbar('Submission Canceled', {variant: 'success'})
        setOpen({
          web: false,
          mobile: false,
          data: null,
          cancel: false,
          idCancel: null,
        })
        setDisabledSubmit(false)
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleCancelSubmission = id => {
    setOpen({
      ...open,
      cancel: true,
      idCancel: id,
      web: false,
    })
  }

  const handleQueryFiltered = (policy, date, status, requester) => {
    const idPolicy = []
    const statusStg = []
    let second = true
    if (policy.length > 0) {
      policy.forEach(pol => {
        idPolicy.push(pol.id)
      })
    }
    status.forEach(stat => {
      if (stat.id === 1) {
        statusStg.push('pending')
        statusStg.push('confirm1')
        second = false
      } else if (stat.id === 2) {
        statusStg.push('approved')
        second = false
      } else if (stat.id === 3) {
        statusStg.push('rejected')
        second = false
      } else if (stat.id === 4) {
        statusStg.push('cancelled')
      }
    })
    setFilter({
      ...filter,
      policy: idPolicy,
      date: {
        dateStart: generateDateSubmit(date.from),
        dateEnd: generateDateSubmit(date.to),
      },
      status: {
        status: statusStg,
        second: second,
      },
      requester,
    })
    setAnchorEl(null)
  }

  const openFilter = Boolean(anchorEl)
  const idFilter = openFilter ? 'filter-attendance-list' : undefined
  const {
    policy,
    date: {dateStart, dateEnd},
    status: {status},
    requester,
  } = filter
  return (
    <div className="flex flex-col px-0 min-h-screen">
      <div
        className="py-4 px-6 flex flex-row justify-between items-center"
        style={{borderBottom: '1px solid #f7f8f9'}}
      >
        <TextFieldStyledNoLine
          value={sort}
          onChange={e => setSort(e.target.value)}
          select
          style={{width: 'auto'}}
          size="small"
        >
          <MenuItemStyled value="desc">Latest</MenuItemStyled>
          <MenuItemStyled value="asc">Oldest</MenuItemStyled>
        </TextFieldStyledNoLine>
        <div
          className="cursor-pointer text-sm items-center flex flex-row"
          onClick={e => setAnchorEl(e.currentTarget)}
          aria-describedby={idFilter}
        >
          <IconFilterActive
            isFiltered={
              policy[0] || dateStart || dateEnd || status[0] || requester[0]
            }
          />
          Filter
        </div>
      </div>
      <TextfieldSearch
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {loading ? (
        <LoadingComponent />
      ) : !loading &&
        dataActivity &&
        dataActivity.people_work_leaves.length > 0 ? (
        dataActivity.people_work_leaves.map((res, i) => {
          return (
            <ListItem
              idDisplay={res.id_display}
              onClick={() => handleClick(res)}
              key={i}
              name={res.people_work_placement.global_user.name}
              title={
                res.time_leave_policy_child && res.time_leave_policy_child.name
              }
              time={
                res.datestart === res.dateend
                  ? `${generateDate(res.datestart)}`
                  : `${generateDate(res.datestart)} - ${generateDate(
                      res.dateend
                    )}`
              }
              submited={generateDate(res.date_added)}
              status={res.status}
              confirm_type={
                res.time_leave_policy_child &&
                res.time_leave_policy_child.confirm_type
              }
              data={res}
            />
          )
        })
      ) : (
        <EmptyState
          image={NoListImage}
          message1="Sorry, No List"
          message2="No leave found"
        />
      )}

      <TablePaginationStyled
        className="mt-auto"
        rowsPerPageOptions={PAGINATION_RANGE}
        onChangePage={(e, newPage) => {
          e.preventDefault()
          setPagination({...pagination, page: newPage})
        }}
        onChangeRowsPerPage={e => {
          setPagination({
            ...pagination,
            page: 0,
            rowsPerPage: parseInt(e.target.value, 10),
          })
        }}
        component="div"
        count={(dataActivity && dataActivity.total.aggregate.count) || 0}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
      />
      <ModalDetailWeb
        title={
          open.data &&
          open.data.time_leave_policy_child &&
          open.data.time_leave_policy_child.name
        }
        open={open.web}
        onClose={() => setOpen({...open, web: false, data: null})}
        status={open.data && open.data.status}
        confirm_type={
          open.data &&
          open.data.time_leave_policy_child &&
          open.data.time_leave_policy_child.confirm_type
        }
        data={open.data}
      >
        <DetailList
          open={open.web}
          from="leave"
          type="activity"
          id={open.data && open.data.id}
          onClickCancel={id => handleCancelSubmission(id)}
        />
      </ModalDetailWeb>

      <FilterWLB
        id={idFilter}
        open={openFilter}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        idWork={idWork}
        withPolicy
        from={'leave-activity'}
        onApply={(policy, date, requester, status) => {
          handleQueryFiltered(policy, date, status, requester)
        }}
        components={[
          {
            idx: 'type',
            name: 'Leave and Permission Type',
            type: 'policy',
          },
          {
            idx: 'status',
            name: 'Status',
            type: 'status',
          },
          {
            idx: 'submission',
            name: 'Submission Date',
            type: 'date',
          },
        ]}
      />

      <CancelDialog
        open={open.cancel}
        title="Cancel Leave Submission?"
        content="Are you sure you want to cancel this leave?"
        textSecondary="No"
        textPrimary="Yes, Cancel"
        onClose={() =>
          setOpen({
            ...open,
            cancel: false,
            idCancel: null,
            web: true,
          })
        }
        onClickPrimary={() => cancelNow(open.idCancel)}
        disabled={disabledSubmit}
      />
    </div>
  )
}

export default TabActivity
