import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

export const TabsStyled = styled(Tabs)`
  background-color: #f7f8f9;
`

export const TabStyled = styled(Tab)`
  outline: none !important;
  &:hover {
    background-color: #f7f8f9;
    color: #000000;
  }
  &.Mui-selected {
    background-color: #f7f8f9;
    color: #000000;
    span {
      font-weight: 600;
    }
  }
`

// const Styled = {
//   color: {
//     backgroundColor: '#f6f8f9',
//     boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
//     top: 58,
//     zIndex: 1,
//   },
//   activeTab: {
//     color: '#000',
//     borderBottom: '3px solid #039be5',
//   },
//   inactiveTab: {
//     color: '#a9a8a8',
//   },
// }

// export function Tabs(props) {
//   const {value, ...other} = props
//   return (
//     <div
//       value={value}
//       style={Styled.color}
//       className="inline-block w-full sticky h-auto"
//     >
//       <div
//         className="h-12 flex justify-center items-center w-full flex-row"
//         {...other}
//       ></div>
//     </div>
//   )
// }

// export function Tab({active, index, label, value}) {
//   return (
//     <div
//       index={index}
//       value={value}
//       style={active ? Styled.activeTab : Styled.inactiveTab}
//       className="font-semibold py-3 mx-2 px-5 w-auto text-sm cursor-pointer"
//     >
//       {label}
//     </div>
//   )
// }
