import TripReport from "./TripReportComponent";
import UploadReport from "./upload-report/UploadReport";

export const TripReportConfig = {
  routes: [
    {
      path: "/trip-report",
      component: TripReport,
      state: "Trip Report",
      exact: true,
    },
    {
      path: "/trip-report/upload-report",
      component: UploadReport,
      state: "Trip Report",
      exact: true,
    },
  ],
};
