import gql from 'graphql-tag'

export const CANCEL_SUBMISSION_LEAVE = gql`
  mutation($id: bigint!) {
    update_people_work_leaves(
      _set: {status: "cancelled"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
