import React from "react";
import DatePicker from "../../shared-component/date/DatePicker";
import FormWrapperInline from "../../shared-component/form/FormWrapperInline";

export default function FormDate({ smallSize, values, number, title = "Trip Date*" }) {
  return (
    <div className="flex sm:flex-row flex-row w-full ">
      <div
        style={!smallSize ? { marginRight: 30 } : { marginRight: 0 }}
        className="font-semibold text-xs"
      >
        {!smallSize && number}
      </div>
      <div className="flex sm:flex-row flex-col justify-between w-full mb-5">
        <FormWrapperInline
          style={{ width: "100%" }}
          title={title}
          className={`mt-2 mb-4 w-full`}
        >
          <DatePicker
            disabled
            fullWidth={false}
            style={{ width: "48%" }}
            placeholder="Add Start Date"
            className={`mt-2 mr-4`}
            value={values.start_date || null}
            inputProps={{ className: "text-xs" }}
          />

          <DatePicker
            disabled
            fullWidth={false}
            style={{ width: "48%" }}
            wrapperClassName="mb-auto"
            className="mt-2"
            placeholder="Add End Date"
            value={values.end_date || null}
            inputProps={{ className: "text-xs" }}
          />
        </FormWrapperInline>
      </div>
    </div>
  );
}
