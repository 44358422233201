import React from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
// import {
//   ModalHeaderWhite,
//   ModalContent,
// } from '../../../components/modal/ModalStyles'
import {IconStyled} from '../../../components/Icons/IconComponent'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'

function ModalCheckin({
  handleOpenCekin,
  open,
  handleOpenCam,
  setDataAtt,
  dataPolicy,
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const newData = []

  if (dataPolicy) {
    dataPolicy.time_attendance_policies.forEach(att => {
      if (
        moment()
          .format('dddd')
          .toLowerCase() in att.schedule
      ) {
        newData.push(att)
      }
    })
  }

  return (
    <Dialog
      onClose={handleOpenCekin}
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      fullScreen={fullScreen}
    >
      <DialogTitle style={{backgroundColor: '#eff2f4'}}>
        <div className="flex justify-between items-center">
          <TypographyStyled variant="h6" style={{fontSize: 16}} weight="600">
            Select Work Schedule
          </TypographyStyled>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleOpenCekin}
            aria-label="close"
            // className="md:hidden lg:hidden"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: 'p-0'}}>
        {newData.length > 0 &&
          newData.map((res, i) => {
            const today = moment()
              .format('dddd')
              .toLowerCase()
            const todaySchedule = Object.values(res.schedule[today])

            return (
              <>
                <div
                  className="flex items-center px-6 py-4 cursor-pointer"
                  onClick={() => {
                    handleOpenCekin()
                    handleOpenCam()
                    setDataAtt({
                      id: res.id,
                      name: res.name,
                      clockIn: todaySchedule[1],
                      clockOut: todaySchedule[2],
                      recess: todaySchedule[0],
                      location: res.time_attendance_policy_locations,
                      dataPolicy: res,
                    })
                  }}
                  key={i}
                >
                  <IconStyled text="#014a62" className="mr-3 md:mr-8 text-4xl">
                    access_time
                  </IconStyled>
                  <div>
                    <TypographyStyled
                      variant="h6"
                      style={{fontSize: 18}}
                      weight="600"
                    >
                      {res.name}
                    </TypographyStyled>
                    <TypographyStyled
                      variant="h6"
                      style={{fontSize: 18}}
                      text="#a9a8a8"
                      weight="500"
                    >
                      {res.flexi === 1
                        ? '(Flexible)'
                        : `(${todaySchedule[1]} - ${todaySchedule[2]})`}
                    </TypographyStyled>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: 'solid 1px rgba(0, 0, 0, 0.06)',
                    maxWidth: 300,
                    marginLeft: 'auto',
                  }}
                />
              </>
            )
          })}
      </DialogContent>
    </Dialog>
  )
}

export default ModalCheckin
