import React, {useState} from 'react'
import ListSearcher from '../../shared-component/list/ListSearcher'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import EmptyState from '../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../assets/images/nodatalist.png'
import {TablePaginationStyled} from '../../../components/pagination/PaginationStyles'
import {PAGINATION_RANGE} from '../../../utils/constants'
import {GET_LIST_TRIP_REPORT} from '../../../graphql/queries'
import ListTripReportItem from './ListTripReportItem'
import {USER_ID} from '../../../utils/globals'
import {generateDate} from '../../../utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import {generateFilter, listFilter} from '../FilterConfig'
import DetailReport from './DetailReport'
import {TRIP_CONTEXT} from '../../trip/TripConstant'

export const getDate = (startdate, enddate) => {
  return startdate === enddate
    ? `${generateDate(startdate)}`
    : `${generateDate(startdate)} - ${generateDate(enddate)}`
}

export default function ListTripReport() {
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('desc')
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE[0],
    page: 0,
  })
  const [filter, setFilter] = useState({
    filter_date_start: null,
    filter_date_end: null,
  })

  const [detail, setDetail] = useState({
    open: false,
    data: null,
    openAttachment: false,
    dataAttachment: null,
  })

  const {data, error, loading} = useQuery(GET_LIST_TRIP_REPORT, {
    ...TRIP_CONTEXT,
    fetchPolicy: 'cache-and-network',
    variables: {
      ...filter,
      userId: USER_ID,
      search: `%${search}%`,
      sorting: sort,
      limit: pagination.rowsPerPage,
      offset: pagination.rowsPerPage * pagination.page,
    },
  })

  const handleChangePage = (e, newPage) => {
    e.preventDefault()
    setPagination({...pagination, page: newPage})
  }

  const handleChangeRowsPerPage = e => {
    setPagination({
      ...pagination,
      page: 0,
      rowsPerPage: parseInt(e.target.value, 10),
    })
  }

  const handleApplyFilter = dataFilter => {
    const filter = generateFilter(dataFilter[0])

    setFilter({
      filter_date_start: filter?.date?.from
        ? filter?.date?.from + ' 00:00:00'
        : null,
      filter_date_end: filter?.date?.to ? filter?.date?.to + ' 23:59:59' : null,
    })
  }

  const handleClickList = data =>
    setDetail(prev => ({...prev, open: true, data}))

  const handleCloseDetail = () =>
    setDetail(prev => ({...prev, open: false, data: null}))

  const COUNT = data?.total?.aggregate?.count || 0

  return (
    <>
      <div className="flex flex-col px-0 min-h-screen">
        <ListSearcher
          setSearch={setSearch}
          search={search}
          setSort={setSort}
          sort={sort}
          onApplyFilter={handleApplyFilter}
          listFilter={listFilter}
        />
        {!loading &&
          COUNT > 0 &&
          data?.people_work_business_trips?.map(res => (
            <ListTripReportItem
              key={res.id}
              onClick={() => handleClickList(res)}
              id={res.id_display}
              policy={res.business_trip_policy?.name}
              date={getDate(res.date_start, res.date_end)}
              submitted={generateDate(res?.date_added)}
            />
          ))}
        {!loading && COUNT === 0 && (
          <EmptyState
            image={NoListImage}
            message1="Sorry, No List"
            message2={
              search
                ? "It seems we can't find any results based on your search"
                : "You haven't submitted anything"
            }
          />
        )}

        {loading && <LoadingComponent />}
        {error && <span>{JSON.stringify(error)}</span>}
      </div>

      <DetailReport
        id={detail?.data?.id}
        open={detail.open}
        onClose={handleCloseDetail}
        detail={detail}
        setDetail={setDetail}
      />

      <TablePaginationStyled
        className="mt-auto"
        rowsPerPageOptions={PAGINATION_RANGE}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        count={COUNT}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
      />
    </>
  )
}
