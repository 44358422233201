import React from 'react'
import {
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import {TextFieldStyled} from '../../../components/form/FormStyles'
import SearchIcon from '@material-ui/icons/Search'

export default function CheckboxFilterComponent({
  value,
  onChangeSearch,
  onSelectAll,
  onReset,
  data,
  check,
  setCheck,
}) {
  const isChecked = (res, check) => {
    let result = false
    check.forEach(cek => {
      if (cek.id === res.id) {
        result = true
      }
    })
    return result
  }

  const isAdded = (check, dataSelected) => {
    let result = false
    if (check.length > 0) {
      check.map(cek => {
        if (cek.id === dataSelected.id) {
          result = true
        }
      })
      return result
    } else {
      return false
    }
  }

  const handleCheck = dataSelected => {
    if (isAdded(check, dataSelected)) {
      const stgCheck = [...check]
      const index = stgCheck
        .map(e => {
          return e.id
        })
        .indexOf(dataSelected.id)
      if (index > -1) {
        stgCheck.splice(index, 1)
      }
      setCheck(stgCheck)
    } else {
      const stgCheck = [...check]
      stgCheck.push(dataSelected)
      setCheck(stgCheck)
    }
  }

  return (
    <>
      <div className="textfield">
        <TextFieldStyled
          size="small"
          fullWidth
          variant="outlined"
          value={value}
          style={{backgroundColor: '#fff'}}
          placeholder="Search"
          classes={{
            root: 'h-8 text-xs',
          }}
          onChange={onChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            classes: {
              root: 'h-8 text-xs w-full',
            },
          }}
        />
      </div>
      <div className="title flex items-center justify-between mt-2">
        <span
          onClick={onSelectAll}
          className="font-semibold text-black cursor-pointer text-xs"
        >
          Select All
        </span>
        <span onClick={onReset} className="text-disable cursor-pointer text-xs">
          Reset
        </span>
      </div>
      <div className="content-checkbox">
        <FormGroup>
          {data ? (
            data.map((res, i) => {
              return (
                <FormControlLabel
                  key={i}
                  classes={{
                    root: 'flex items-start mb-1',
                    label: 'text-xs mt-2',
                  }}
                  control={
                    <Checkbox
                      checked={isChecked(res, check)}
                      color="primary"
                      classes={{
                        root: 'py-1',
                      }}
                      onChange={() => handleCheck(res)}
                      name={res.id}
                    />
                  }
                  label={res.name}
                />
              )
            })
          ) : (
            <div className="w-full flex justify-center items-center mt-8">
              <CircularProgress />
            </div>
          )}
        </FormGroup>
      </div>
    </>
  )
}
