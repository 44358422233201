import gql from 'graphql-tag'

export const GET_LIST_BANK_TAX = gql`
  query getListBankTaxUser($user: uuid) {
    main_bank: people_work_salaries(
      where: {
        active: {_eq: true}
        deletedAt: {_is_null: true}
        people_profile_bank: {deletedAt: {_is_null: true}}
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      id
      people_profile_bank {
        id
        bank
        bank_name
        account_name
        account_number
        creator_company
      }
    }

    bank: people_profile_banks(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: [{creator_company: desc}, {id: desc}]
    ) {
      id
      bank
      bank_name
      account_name
      account_number
      creator_company
    }

    tax: people_identities(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
    ) {
      id
      type
    }
  }
`

export const GET_LIST_BANK = gql`
  query getListBank($search: String) {
    global_banks(
      where: {_or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]}
    ) {
      id
      code
      name
    }
  }
`

export const CEK_ID_BANK = gql`
  query CheckerBank($account: String, $user: uuid) {
    people_profile_banks(
      where: {account_number: {_eq: $account}, user: {_eq: $user}}
    ) {
      id
    }
  }
`
