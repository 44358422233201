import gql from 'graphql-tag'

export const GET_LIST_SALARY = gql`
  query getListSalary(
    $company: uuid
    $user: uuid
    $year: [Int!]
    $month: [Int!]
    $year_start: Int
    $month_start: Int
    $year_end: Int
    $month_end: Int
    $order: order_by
    $offset: Int
    $limit: Int
  ) {
    payroll_count: people_work_payroll_aggregate(
      where: {
        year: {_in: $year}
        month: {_in: $month}
        _and: [
          {year: {_gte: $year_start}, month: {_gte: $month_start}}
          {year: {_lte: $year_end}, month: {_lte: $month_end}}
        ]
        employee_placement: {company: {_eq: $company}, user: {_eq: $user}}
        process_step: {_eq: 5}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        year: {_in: $year}
        month: {_in: $month}
        _and: [
          {year: {_gte: $year_start}, month: {_gte: $month_start}}
          {year: {_lte: $year_end}, month: {_lte: $month_end}}
        ]
        employee_placement: {company: {_eq: $company}, user: {_eq: $user}}
        process_step: {_eq: 5}
      }
      order_by: [{year: $order}, {month: $order}]
    ) {
      id
      year
      month
      date_printed
    }
  }
`
