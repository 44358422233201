import React, {useState} from 'react'
import {TypographyStyled} from '../../components/typography/TypographyStyles'
import {IconStyled} from '../../components/Icons/IconComponent'
import Divider from '../../components/divider/DividerStyles'
import {TablePaginationStyled} from '../../components/pagination/PaginationStyles'
import moment from 'moment'
import DrawerDetail from '../shared-component/drawer/DrawerDetail'
import DetailList from './DetailList/DetailList'
import ModalDetailAtt from './SharedCompoenentAttendance/ModalDetail'
import {
  TextFieldStyledNoLine,
  MenuItemStyled,
} from '../../components/form/FormStyles'
import LoadingComponent from '../shared-component/loading/LoadingComponent'
import FilterWLB from '../shared-component/Filter/FilterWLB'
import {generateDateSubmit} from '../../utils/helpers'
import IconFilterActive from '../shared-component/Filter/IconFilterActive'
import {getTimeInOut} from './helperAttendance'
import EmptyState from '../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../assets/images/nodatalist.png'
import {PAGINATION_RANGE} from '../../utils/constants'

const ListComponent = ({
  data,
  error,
  loading,
  setSort,
  sort,
  idWork,
  filter,
  setFilter,
  setRowsPerPage,
  setPage,
  page,
  rowsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const [open, setOpen] = useState({
    open: false,
    openWeb: false,
    id: null,
  })

  if (error) return <div>{error}</div>

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClickList = id => {
    if (window.innerWidth <= 768) {
      setOpen({open: true, openWeb: false, id: id})
    } else {
      setOpen({open: false, openWeb: true, id: id})
    }
  }

  const handleQueryFiltered = (policy, date) => {
    const idPolicy = []
    if (policy.length > 0) {
      policy.forEach(pol => {
        idPolicy.push(pol.id)
      })
    }
    setFilter({
      ...filter,
      policy: idPolicy,
      date: {
        dateStart: generateDateSubmit(date.from),
        dateEnd: generateDateSubmit(date.to),
      },
    })
    setAnchorEl(null)
  }

  const openFilter = Boolean(anchorEl)
  const idFilter = openFilter ? 'filter-attendance-list' : undefined
  const {
    policy,
    date: {dateStart, dateEnd},
  } = filter

  return (
    <>
      <div className="w-full">
        <div
          style={{padding: '16px 30px'}}
          className="flex justify-between items-center"
        >
          <TextFieldStyledNoLine
            value={sort}
            onChange={e => setSort(e.target.value)}
            select
            style={{width: 'auto'}}
            size="small"
          >
            <MenuItemStyled value="desc">Latest</MenuItemStyled>
            <MenuItemStyled value="asc">Oldest</MenuItemStyled>
          </TextFieldStyledNoLine>
          <div
            className="cursor-pointer text-sm items-center flex flex-row"
            onClick={e => setAnchorEl(e.currentTarget)}
            aria-describedby={idFilter}
          >
            <IconFilterActive isFiltered={policy[0] || dateStart || dateEnd} />
            Filter
          </div>
        </div>
        <Divider />
        {loading ? (
          <LoadingComponent />
        ) : (
          <div>
            {data &&
              data.people_work_attendances.map((res, i) => {
                const policy = res.time_attendance_policy

                const today = moment(res.clock_in)
                  .format('dddd')
                  .toLowerCase()

                const todaySchedule = Object.values(
                  (policy && policy.schedule[today]) || {}
                )

                const config = policy?.mobile_setting
                const tz = config?.timezoneName

                const [dateIn, timeIn] = getTimeInOut(res.clock_in, tz)
                const [dateOut, timeOut] = getTimeInOut(res.clock_out, tz)

                // const isDeleted =
                //   res.time_attendance_policy &&
                //   res.time_attendance_policy.deletedAt
                return (
                  <div
                    onClick={() => handleClickList(res.id)}
                    className="cursor-pointer"
                    key={i}
                  >
                    <div style={{padding: '24px 30px'}}>
                      <TypographyStyled
                        className="font-semibold"
                        text="#014a62"
                        style={{marginBottom: 4, lineHeight: '24px'}}
                      >
                        {(policy && policy.name) || '-'}
                      </TypographyStyled>
                      <TypographyStyled
                        style={{
                          fontSize: 14,
                          lineHeight: 1.43,
                          marginBottom: 4,
                        }}
                        text="#a9a8a8"
                      >
                        {policy
                          ? config.timezone > 0
                            ? `GMT+${config.timezone}`
                            : `GMT${config.timezone}`
                          : 'GMT+7'}
                      </TypographyStyled>
                      <TypographyStyled
                        style={{fontSize: 14, lineHeight: 1.43}}
                        text="#a9a8a8"
                      >
                        {policy
                          ? policy.flexi === 1
                            ? '(Flexible)'
                            : `(${todaySchedule[1]} - ${todaySchedule[2]})`
                          : ''}
                      </TypographyStyled>
                      <div
                        style={{marginTop: 20, marginBottom: 6}}
                        className="flex justify-between items-center lg:px-16 md:px-4 sm:px-12 px-2"
                      >
                        <div className="flex items-center">
                          <IconStyled
                            fontSize="large"
                            className="mr-2 md:mr-6"
                            text="#039be5"
                          >
                            location_on
                          </IconStyled>
                          <div>
                            <TypographyStyled
                              style={{marginBottom: 2}}
                              className="text-sm"
                              text="#039be5"
                              variant="body1"
                            >
                              Clock - In
                            </TypographyStyled>
                            <TypographyStyled
                              style={{marginBottom: 2}}
                              className="text-xs"
                              text="#a9a8a8"
                              variant="body2"
                            >
                              {dateIn}
                            </TypographyStyled>
                            <TypographyStyled
                              className="text-xs"
                              text="#000"
                              variant="body1"
                            >
                              {timeIn}
                            </TypographyStyled>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <IconStyled
                            fontSize="large"
                            className="mr-2 md:mr-6"
                            text="#ffa000"
                          >
                            location_on
                          </IconStyled>
                          <div>
                            <TypographyStyled
                              style={{marginBottom: 2}}
                              className="text-sm"
                              text="#ffa000"
                              variant="body1"
                            >
                              Clock - Out
                            </TypographyStyled>
                            <TypographyStyled
                              style={{marginBottom: 2}}
                              className="text-xs"
                              text="#a9a8a8"
                              variant="body2"
                            >
                              {dateOut}
                            </TypographyStyled>
                            <TypographyStyled
                              className="text-xs"
                              text="#000"
                              variant="body1"
                            >
                              {timeOut}
                            </TypographyStyled>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                )
              })}
          </div>
        )}

        {!loading && data?.total?.aggregate?.count === 0 && (
          <EmptyState
            image={NoListImage}
            message1="Sorry, No List"
            message2="You haven't attend anything"
          />
        )}

        <TablePaginationStyled
          className="mt-auto"
          rowsPerPageOptions={PAGINATION_RANGE}
          component="div"
          count={data?.total?.aggregate?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{
            actions: 'ml-1',
            caption: 'md:text-sm text-xs text-disable',
            input: 'md:text-sm text-xs text-disable',
          }}
        />
      </div>

      <DrawerDetail
        styleHeader={{backgroundColor: '#eff2f4'}}
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        title="Attendance Detail"
      >
        <DetailList id={open.id} />
      </DrawerDetail>

      <ModalDetailAtt
        open={open.openWeb}
        onClose={() =>
          setOpen({
            ...open,
            openWeb: false,
          })
        }
        title="Attendance Detail"
      >
        <DetailList id={open.id} />
      </ModalDetailAtt>

      <FilterWLB
        id={idFilter}
        open={openFilter}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        idWork={idWork && idWork.people_work_placements[0].id}
        withPolicy
        from={'attendance'}
        onApply={(policy, date) => {
          handleQueryFiltered(policy, date)
        }}
        components={[
          {
            idx: 'type',
            name: 'Type',
            type: 'policy',
          },
          {
            idx: 'submission',
            name: 'Submission Date',
            type: 'date',
          },
        ]}
      />
    </>
  )
}
export default ListComponent
