import styled from 'styled-components/macro'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
// import tw from 'twin.macro'

const generateType = (type, condition) => {
  if (condition === 'hover') {
    switch (type) {
      case 'primary':
        return '#003344 !important'
      case 'success':
        return '#429645 !important'
      case 'danger':
        return '#d44554 !important'
      case 'warning':
        return '#e69102 !important'
      case 'lavender':
        return '#9352bd !important'
      default:
        return '#ffffff !important'
    }
  } else {
    switch (type) {
      case 'primary':
        return '#014a62 !important'
      case 'success':
        return '#4caf50 !important'
      case 'danger':
        return '#ef4d5e !important'
      case 'warning':
        return '#ffa000 !important'
      case 'lavender':
        return '#874caf !important'
      default:
        return '#ffffff !important'
    }
  }
}

//* Backup color code */
// background-color: ${props =>
//   props.primary
//     ? '#014a62 !important'
//     : props.success
//     ? '#4caf50 !important'
//     : props.warning
//     ? '#ffa000 !important'
//     : props.danger
//     ? '#ef4d5e !important'
//     : '#ffffff !important'};
//
// background: ${props =>
//   props.primary
//     ? '#003344 !important'
//     : props.success
//     ? '#429645 !important'
//     : props.warning
//     ? '#e69102 !important'
//     : props.danger
//     ? '#d44554 !important'
//     : '#ffffff !important'};
//
// border-color: ${props =>
//   props.primary
//     ? '#003344 !important'
//     : props.success
//     ? '#429645 !important'
//     : props.warning
//     ? '#e69102 !important'
//     : props.danger
//     ? '#d44554 !important'
//     : '#ffffff !important'};
/*  =  */
export const ButtonStyled = styled(Button)`
  background-color: ${props => generateType(props.state)};
  border-color: ${props =>
    props.primary
      ? '#014a62 !important'
      : props.success
      ? '#4caf50 !important'
      : props.warning
      ? '#ffa000 !important'
      : props.danger
      ? '#ef4d5e !important'
      : '#ffffff !important'};
  padding: 10px;
  border-style: solid;
  color: ${props => (props.text ? props.text : '#fff !important')};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: ${props => generateType(props.state, 'hover')};
    color: ${props => (props.text ? props.text : '#fff !important')};
    border-color: ${props => generateType(props.state, 'hover')};
  }
`
export const IconButtonStyled = styled(IconButton)``

// export const Button = styled.button`
//   ${tw`px-8 py-4 text-white m-4`}
//   ${({primary}) => (primary ? tw`bg-primary` : tw`bg-disable`)}
