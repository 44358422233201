import {useLazyQuery} from '@apollo/react-hooks'
import {GET_LIST_COMPANY_HOLIDAY} from '../graphql/queries'
import {COMPANY_ID} from '../utils/globals'

export default function useCompanyHoliday() {
  const [getListCompanyHoliday, {data, loading}] = useLazyQuery(
    GET_LIST_COMPANY_HOLIDAY
  )

  const getData = (date_start, date_end) => {
    getListCompanyHoliday({
      variables: {
        company: COMPANY_ID,
        date_start,
        date_end,
      },
    })
  }

  return [data ? data.time_holidays.length : undefined, getData, loading]
}
