import {useApolloClient} from '@apollo/react-hooks'
import moment from 'moment'
import React from 'react'
import {ButtonStyled} from '../../components/button/ButtonStyles'
import {GET_EVENT_TODAY} from '../../graphql/queries'
import {USER_ID} from '../../utils/globals'

const CHECKIN_TEXT = 'Check-In'
const CHECKOUT_TEXT = 'Check-Out'

const isHoliday = data => {
  if (data.length > 0) {
    let allSchedule = {}
    const today = moment()
      .format('dddd')
      .toLowerCase()
    data.forEach(policy => {
      allSchedule = {...allSchedule, ...policy.schedule}
    })

    if (today in allSchedule) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

const ButtonAttendance = ({onClick, state, text, user_work_id}) => {
  const client = useApolloClient()
  const handleClick = async () => {
    if (text === CHECKOUT_TEXT) {
      const {data} = await client.query({
        context: {headers: {'X-Hasura-Role': 'user'}},
        query: GET_EVENT_TODAY,
        variables: {
          user_work_id,
          user_id: USER_ID,
          date: moment().format('YYYY-MM-DD'),
        },
      })
      onClick({
        isHaveLeave: data.people_work_leaves.length > 0,
        isHaveTrip: data.people_work_business_trips.length > 0,
      })
    } else {
      onClick()
    }
  }
  return (
    <ButtonStyled
      classes={{
        root: 'text-sm',
        label: 'h-4 ',
      }}
      style={{padding: '14px 37px'}}
      state={state}
      onClick={onClick ? handleClick : null}
    >
      {text}
    </ButtonStyled>
  )
}

const getMaxHourShift = dataPolicy => {
  if (dataPolicy) {
    const checkOutTimePolicy = []
    const today = moment()
      .format('dddd')
      .toLowerCase()
    dataPolicy.time_attendance_policies.forEach(policy => {
      if (policy.mobile_setting.isClockInMoreThenOne) {
        if (today in policy.schedule) {
          checkOutTimePolicy.push(
            moment.duration(policy.schedule[today].clockOut).asHours()
          )
          moment.duration(policy.schedule[today].clockOut).asHours()
        }
      }
    })
    const arr = getUnique(checkOutTimePolicy)
    return Math.max(...arr)
  }
}

function getUnique(array) {
  const uniqueArray = []
  let i
  for (i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i])
    }
  }
  return uniqueArray
}

const handleCheckout = ({
  limitChekcout,
  schedule,
  clock_in,
  setopenNotif,
  getDiferenceCheckout,
  data,
  isHaveLeave = false,
  isHaveTrip = false,
}) => {
  const isFlex =
    data.first_data[0] && data.first_data[0].time_attendance_policy.flexi === 1
  const today = moment(new Date())
  const lastClockIn = moment(clock_in)
  const isDateSame = lastClockIn.isSame(today, 'day')
  if (isFlex || isHaveLeave || isHaveTrip) {
    setopenNotif(openNotif => ({...openNotif, checkout: true}))
  } else {
    if (isDateSame) {
      if (today.format('dddd').toLowerCase() in schedule) {
        const timeNowAddLimit = moment()
          .add(limitChekcout, 'minutes')
          .format('HH:mm')
        const timeCheckout =
          schedule[today.format('dddd').toLowerCase()].clockOut
        const timeNow = moment.duration(timeNowAddLimit).asHours()
        const timeLimit = moment.duration(timeCheckout).asHours()
        if (timeNow >= timeLimit) {
          // console.log("time sudah masuk waktu checkout")
          setopenNotif(openNotif => ({...openNotif, checkout: true}))
        } else {
          const dataSelected = data.first_data[0]
          const {
            initLimitClockOut,
          } = dataSelected.time_attendance_policy.mobile_setting
          const dayCheckin = moment(new Date(dataSelected.clock_in))
            .format('dddd')
            .toLowerCase()
          const schedule = dataSelected.time_attendance_policy.schedule
          if (dayCheckin in schedule) {
            getDiferenceCheckout(
              schedule[dayCheckin].clockOut,
              initLimitClockOut
            )
          }
          // console.log("time Belum masuk waktu checkout")
          setopenNotif(openNotif => ({
            ...openNotif,
            outsideCheckOut: true,
          }))
        }
      }
    } else {
      // console.log("ini beda hari")
      setopenNotif(openNotif => ({...openNotif, checkout: true}))
    }
  }
}

export default function ButtonAttendanceComponent({
  data,
  dataPolicy,
  handleOpenWork,
  setopenNotif,
  getDiferenceCheckout,
  user_work_id,
  holiday,
}) {
  if (data && dataPolicy) {
    if (holiday) {
      const TYPE = holiday === 'On Trip' ? 'lavender' : 'warning'
      return (
        <ButtonStyled
          classes={{root: 'text-sm'}}
          size="large"
          state={TYPE}
          style={{padding: '8px 40px'}}
        >
          {holiday}
        </ButtonStyled>
      )

      // No policy
    } else if (dataPolicy.time_attendance_policies.length === 0) {
      return (
        <ButtonAttendance
          onClick={handleOpenWork}
          state="primary"
          text={CHECKIN_TEXT}
        />
      )

      // no schedule
    } else if (isHoliday(dataPolicy.time_attendance_policies)) {
      return (
        <ButtonStyled
          classes={{
            root: 'text-sm',
            label: 'h-4 ',
          }}
          style={{padding: '14px 37px'}}
          size="large"
          state="warning"
        >
          Holiday
        </ButtonStyled>
      )
    } else {
      if (data.first_data.length > 0) {
        const today = moment(new Date())
        const lastClockIn = moment(data.first_data[0].clock_in)
        const isDateSame = lastClockIn.isSame(today, 'day')
        if (isDateSame) {
          if (!data.first_data[0].clock_out) {
            return (
              // Hari sama, belum checkout
              <ButtonAttendance
                state="warning"
                onClick={({isHaveLeave, isHaveTrip}) =>
                  handleCheckout({
                    limitChekcout:
                      data.first_data[0].time_attendance_policy.mobile_setting
                        .initLimitClockOut,
                    schedule:
                      data.first_data[0].time_attendance_policy.schedule,
                    clock_in: data.first_data[0].clock_in,
                    setopenNotif: setopenNotif,
                    getDiferenceCheckout: getDiferenceCheckout,
                    data,
                    isHaveLeave,
                    isHaveTrip,
                  })
                }
                user_work_id={user_work_id}
                text={CHECKOUT_TEXT}
              />
            )
          } else {
            if (
              data.first_data[0].time_attendance_policy &&
              data.first_data[0].time_attendance_policy.mobile_setting
                .isClockInMoreThenOne
            ) {
              if (
                // Pengecekan checkin shift terakhir
                getMaxHourShift(dataPolicy) >
                  moment.duration(moment().format('HH:mm')).asHours() ||
                data.first_data[0].time_attendance_policy.flexi === 1
              ) {
                return (
                  <ButtonAttendance
                    onClick={handleOpenWork}
                    state="primary"
                    text={CHECKIN_TEXT}
                  />
                )
              } else {
                return (
                  <ButtonAttendance
                    state="warning"
                    onClick={null}
                    text="You're Tap Today"
                  />
                )
              }
            } else {
              return (
                <ButtonAttendance
                  state="warning"
                  onClick={null}
                  text="You're Tap Today"
                />
              )
            }
          }
        } else if (!isDateSame) {
          if (!data.first_data[0].clock_out) {
            return (
              <ButtonAttendance
                state="warning"
                onClick={({isHaveLeave, isHaveTrip}) =>
                  handleCheckout({
                    limitChekcout:
                      data.first_data[0].time_attendance_policy.mobile_setting
                        .initLimitClockOut,
                    schedule:
                      data.first_data[0].time_attendance_policy.schedule,
                    clock_in: data.first_data[0].clock_in,
                    setopenNotif: setopenNotif,
                    getDiferenceCheckout: getDiferenceCheckout,
                    data,
                    isHaveLeave,
                    isHaveTrip,
                  })
                }
                user_work_id={user_work_id}
                text={CHECKOUT_TEXT}
              />
            )
          } else {
            return (
              <ButtonAttendance
                onClick={handleOpenWork}
                state="primary"
                text={CHECKIN_TEXT}
              />
            )
          }
        }
      } else {
        // checkin buat akun yang baru pertama kali checkin
        return (
          <ButtonAttendance
            onClick={handleOpenWork}
            state="primary"
            text={CHECKIN_TEXT}
          />
        )
      }
    }
  } else return ''
}
