import React, {useState} from 'react'

import {Button} from '@material-ui/core'

import FormBudgetUsage from '../upload-report/FormBudgetUsage'
import FormTotalRemainingMoney from '../upload-report/FormTotalRemainingMoney'

import {getRemainingMoney, getRemainingState} from '../trip-report-helper'

export default function EditBudgetUsage(props) {
  const {onSubmit, onCancel, data, loadingSubmit, error, setError} = props

  const [values, setValues] = useState(() => ({
    trip_id_display: data.id_display,
    trip_id: data.id,
    trip_user_id: data.participant_name?.id,
    policy: data.business_trip_policy,
    position: data.participant_position?.company_profile?.title,
    start_date: data.date_start,
    end_date: data.date_end,
    approved_date: data.approved_date,
    currency: data.currency,
    budget_action: data.budget_diff_action,
    destination: data.destination?.map(destination => ({
      ...destination,
      budget: destination?.budget?.map(budget => ({
        ...budget,
        attachment:
          budget?.attachment?.map(attachment => ({
            ...attachment,
            id: attachment.url,
            isUploaded: true,
            loaded: attachment.size,
          })) || [],
      })),
    })),
  }))

  const [errorFile, setErrorFile] = useState(false)
  const [errorSize, setErrorSize] = useState(false)

  const remainingMoney = getRemainingMoney(values.destination)
  const remainingState = getRemainingState(remainingMoney)

  return (
    <div>
      <div className="mb-8">
        <FormBudgetUsage
          isEditBudget
          smallSize={false}
          values={values}
          error={error}
          setValues={setValues}
          errorFile={errorFile}
          errorSize={errorSize}
          setError={setError}
          setErrorFile={setErrorFile}
          setErrorSize={setErrorSize}
        />
      </div>

      <div className="mb-8">
        <FormTotalRemainingMoney
          isEditBudget
          smallSize={false}
          error={error}
          values={values}
          setValues={setValues}
          remainingMoney={remainingMoney}
          remainingState={remainingState}
        />
      </div>

      <div className="flex justify-end w-full">
        <Button
          className="text-sm mr-4 outline-none py-2 px-6"
          size="large"
          variant="text"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="text-sm outline-none py-2 px-6"
          size="large"
          onClick={() => onSubmit(values)}
          color="primary"
          disableElevation
          disabled={loadingSubmit}
        >
          {loadingSubmit ? 'Loading..' : 'Edit Budget'}
        </Button>
      </div>
    </div>
  )
}
