import React from 'react'
import {useMediaQuery, useTheme} from '@material-ui/core'

export default function ListItemModal({title, ...value}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-2`}>
      <span
        className={`${
          fullScreen ? 'text-sm' : 'font-semibold text-xs'
        } text-disable`}
        style={{width: 200, paddingRight: 28}}
      >
        {title}
      </span>
      <span
        className={`${fullScreen ? 'mb-4 text-sm' : 'font-semibold text-xs'}`}
        style={{maxWidth: '450px'}}
        {...value}
      />
    </div>
  )
}
