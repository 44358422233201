import React from 'react'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {Chip, IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {MUTATION_EDIT_BANK} from '../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {SOSMED_APP_URL} from '../../../utils/globals'
import useGetProfile from '../../../hooks/useGetProfile'

function ListBank(props) {
  const {id} = useGetProfile()
  const {refetch, data, setPage} = props

  const bankList = [
    ...data.main_bank?.map(res => ({
      ...res?.people_profile_bank,
      is_main: true,
    })),
    ...data.bank.filter(
      account =>
        account?.account_number !==
        data.main_bank?.[0]?.people_profile_bank?.account_number
    ),
  ]

  const [openSubmit, setOpenSubmit] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState()
  const {enqueueSnackbar} = useSnackbar()
  const [editApply] = useMutation(MUTATION_EDIT_BANK)

  const deleteBank = () => {
    const change = {
      deletedAt: new Date(),
    }
    editApply({
      variables: {id: idDelete, changes: change},
      context: {
        headers: {
          'X-Hasura-Role': 'user',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Delete for Account Bank Success', {variant: 'success'})
        // setForm(false)
        setPage('bank-tax')
        refetch()
        setOpenSubmit(false)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const hrefProfile = () => {
    window.location.replace(`${SOSMED_APP_URL}/profile/${id}/detail`)
  }

  return (
    <>
      {bankList.length >= 1 &&
        bankList.map((row, key) => (
          <>
            <div
              key={key}
              style={{marginTop: 20, marginBottom: 6}}
              className="flex justify-between"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  Bank Name
                </TypographyStyled>
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  Name of Account Holder
                </TypographyStyled>
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  Account Number
                </TypographyStyled>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '140px',
                }}
              >
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  {row.bank_name}
                </TypographyStyled>
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  {row.account_name}
                </TypographyStyled>
                <TypographyStyled
                  className="font-semibold"
                  text="#000000"
                  style={{
                    fontSize: '14px',
                    marginBottom: 4,
                    lineHeight: '24px',
                  }}
                >
                  {row.account_number}
                </TypographyStyled>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                {row.is_main ? (
                  <Chip
                    label="Main Bank"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                ) : (
                  !row?.creator_company && (
                    <>
                      <IconButton
                        aria-label="delete"
                        style={{color: '#ef4d5e'}}
                        onClick={() => {
                          setOpenSubmit(true)
                          setIdDelete(row.id)
                        }}
                      >
                        <DeleteIcon style={{fontSize: '20px'}} />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        style={{color: '#039be5'}}
                        onClick={() => {
                          hrefProfile()
                          //  disable form edit bank old feature
                          // setPage('form-bank')
                          // setType('Edit')
                          // setDataEdit(row)
                        }}
                      >
                        <CreateIcon style={{fontSize: '20px'}} />
                      </IconButton>
                    </>
                  )
                )}
              </div>
            </div>
          </>
        ))}

      <ConfirmationDialog
        title="Apply for Account Bank?"
        content="Are you sure you want to delete for this Account bank?"
        onClickPrimary={deleteBank}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={false}
      />
    </>
  )
}

export default ListBank
