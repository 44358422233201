import {differenceInCalendarDays} from 'date-fns'

export const generateQuotaTaken = (enddate, startdate) => {
  if (startdate && enddate) {
    return differenceInCalendarDays(new Date(enddate), new Date(startdate))
  } else {
    return null
  }
}

export const fileChecker = (name, setFileType) => {
  const nameSplited = name.split('.')
  const idx = nameSplited.length - 1
  const res = nameSplited[idx]

  if (
    res === 'doc' ||
    res === 'docx' ||
    res === 'xls' ||
    res === 'pdf' ||
    res === 'xlsx' ||
    res === 'ppt'
  ) {
    setFileType('document')
    return false
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif' ||
    res === 'tiff' ||
    res === 'pjp' ||
    res === 'pjpeg'
  ) {
    setFileType('image')
    return false
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    setFileType('image')
    return false
  } else {
    return true
  }
}

const getDateNumber = day => {
  let number = 0
  switch (day) {
    case 'sunday':
      number = 0
      break
    case 'monday':
      number = 1
      break
    case 'tuesday':
      number = 2
      break
    case 'wednesday':
      number = 3
      break
    case 'thursday':
      number = 4
      break
    case 'friday':
      number = 5
      break
    case 'saturday':
      number = 6
      break
    default:
      number = 0
      break
  }
  return number
}

function getNumberOfWeekend(start, finish, schedule) {
  const dayMilliseconds = 1000 * 60 * 60 * 24
  let weekendDays = 0
  while (start.getTime() <= finish.getTime()) {
    const day = start.getDay()
    if (day === getDateNumber(schedule)) {
      weekendDays++
    }
    start = new Date(+start + dayMilliseconds)
  }
  return weekendDays
}

export const getTotalHoliday = (start, end, schedules) => {
  let diff = 0
  if (start && end) {
    schedules.forEach(schedule => {
      diff += getNumberOfWeekend(new Date(start), new Date(end), schedule)
    })
  }
  return diff
}
