import React, {useEffect, useState} from 'react'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import Divider from '../../../components/divider/DividerStyles'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {
  TextFieldStyled,
  KeyboardDatePickerStyled,
  KeyboardTimePickerStyled,
  SelectStyled,
  MenuItemStyled,
  FormControlStyled,
  FormHelperTextStyled,
  FormControlLabelStyled,
} from '../../../components/form/FormStyles'
import DatePickerWrapper from '../../shared-component/date/DatePickerWrapper'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import {AttachmentItem, UploadButton} from '@smartasn/wlb-utils-components'
import {Box, Checkbox, useMediaQuery, useTheme} from '@material-ui/core'
import FormWrapperInline from '../../shared-component/form/FormWrapperInline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import ContentTooltipFile from '../../shared-component/tooltip/ContentTooltipFile'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_PLACEMENT, GET_WAITING_LEAVE_COUNT} from '../../../graphql/queries'
import {
  USER_ID,
  UPLOAD_URL,
  TOKEN,
  NAME_USER_LOGIN,
} from '../../../utils/globals'
import Axios from 'axios'
import {INSERT_APPLY_FOR_LEAVE} from '../../../graphql/mutations'
import {generateQuotaTaken, getTotalHoliday} from '../helperForLeave'
import {
  generateDateSubmit,
  generateTimeSubmit,
  isPolicyCanUse,
} from '../../../utils/helpers'
import {checkFilesType} from '../../overtime/helperForOvertime'
import NotificationWLB from '../../shared-component/dialog/NotificationWLB'
import useCompanyHoliday from '../../../hooks/useCompanyHoliday'
import GlobalPhoneCode from './GlobalPhoneCode' //
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'
import {useSnackbar} from 'notistack'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import {getDataBalance} from '../tab/balance/balance-helper'
import {addDays} from 'date-fns'

export default function ApplyForLeaveForm({
  forMobile,
  onClickCancel,
  onClose,
  dataPolicy,
  dataScheduleHoliday,
}) {
  const {enqueueSnackbar} = useSnackbar()
  const [
    totalCompanyHoliday,
    getDataCompanyHoliday,
    loadingHoliday,
  ] = useCompanyHoliday()
  const [openSubmit, setOpenSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [errorFile, setErrorFile] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [fileType, setFileType] = useState('')
  const [active, setActive] = useState('one_day')
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [notification, setNotification] = useState({
    open: false,
    type: 'submit',
    date: '',
  })
  const [values, setValues] = useState(() => ({
    name: NAME_USER_LOGIN,
    leavePolicy: null,
    leaveDate: 'one_day',
    start_date: new Date(),
    end_date: addDays(new Date(), 1),
    half_date: false,
    start_time: new Date(),
    end_time: new Date(),
    phone: '',
    description: '',
    attachments: [],
    phone_code: '+62',
  }))
  const [policyData, setPolicyData] = useState(null)
  const [minDate, setMinDate] = useState(() => new Date())
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))

  const {data: dataPlacement, error: errorQuery} = useQuery(GET_PLACEMENT, {
    variables: {user: USER_ID},
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  const {data: dataWaitlist} = useQuery(GET_WAITING_LEAVE_COUNT, {
    skip: !values.leavePolicy,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      policyId: values.leavePolicy,
    },
  })

  const [insertApplyForLeave] = useMutation(INSERT_APPLY_FOR_LEAVE)

  useEffect(() => {
    const dateTomorrow = new Date()
    dateTomorrow.setDate(dateTomorrow.getDate() + 1)
    getDataCompanyHoliday(
      generateDateSubmit(new Date()),
      values.leaveDate === 'one_day'
        ? generateDateSubmit(new Date())
        : generateDateSubmit(dateTomorrow)
    )
  }, [values.leaveDate])

  if (!dataPlacement) {
    return <LoadingComponent />
  }

  const fieldList = [
    {
      number: !smallSize && 1,
      fieldName: 'Name*',
      field: 'name',
      type: 'text',
      value: values.name,
      disabled: true,
    },
    {
      number: !smallSize && 2,
      fieldName: 'Leave and Permission Policy*',
      field: 'leavePolicy',
      type: 'select',
      value: values.leavePolicy,
      options: dataPolicy
        ? dataPolicy.people_work_placements[0].company_job_profile
            .company_job_profile_r_leaves
        : [],
    },
    {
      number: !smallSize && 3,
      fieldName: 'Leave and Permission Date*',
      field: 'leaveDate',
      type: 'radio',
      value: values.leaveDate,
    },
  ]

  const fieldList2 = [
    {
      number: !smallSize && 5,
      fieldName: 'Contact Number*',
      field: 'phone',
      type: 'number',
      value: values.phone,
      placeholder: 'Add phone number',
    },
    {
      number: !smallSize && 6,
      fieldName: 'Description*',
      field: 'description',
      type: 'text',
      value: values.description,
      textArea: true,
      placeholder: 'Add description',
    },
    {
      number: !smallSize && 7,
      field: 'attachments',
      fieldName: (
        <div className="flex flex-row items-center">
          Attachments
          <span className="mx-2 text-disable text-xs">(optional)</span>
          <InfoFileTooltip title={<ContentTooltipFile />} placement="right">
            <InfoOutlinedIcon className="text-xs text-primary" />
          </InfoFileTooltip>
        </div>
      ),
      type: 'attachment',
      attachments: values.attachments,
    },
  ]

  const handleChange = (e, target, radio) => {
    if (target === 'start_date') {
      setMinDate(e)
      setValues({
        ...values,
        start_date: e,
        end_date: null,
      })
    } else if (target === 'end_date') {
      setValues({
        ...values,
        [target]: e,
      })

      getDataCompanyHoliday(
        generateDateSubmit(values.start_date),
        generateDateSubmit(e)
      )
    } else if (target === 'attachments') {
      setErrorFile(false)
      setErrorSize(false)
      const tempFile = Array.from(e.target.files)
      const [isErrorType, isMaxsize] = checkFilesType(tempFile, setFileType)
      if (isErrorType) {
        setErrorFile(true)
      } else if (isMaxsize) {
        setErrorSize(true)
      } else {
        setLoadingUpload(true)
        tempFile.forEach(val => {
          const formData = new FormData()
          formData.append('file', val)
          return Axios.post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          })
            .then(res => {
              setLoadingUpload(false)
              setValues(prevState => {
                return {
                  ...values,
                  attachments: [
                    ...prevState.attachments,
                    {link: res.data.url, name: val.name, size: val.size},
                  ],
                }
              })
            })
            .catch(() => {
              setLoadingUpload(false)
            })
        })
      }
    } else if (target === 'half_date') {
      setValues({
        ...values,
        [target]: radio,
      })
    } else if (target === 'leavePolicy') {
      const policy = radio.props.dataLeave

      const {
        join_date,
      } = dataPlacement.global_users[0].people_work_placements[0]
      const [canUse, formatted] = isPolicyCanUse(join_date, radio.props.data)

      if (!canUse) {
        setNotification({
          open: true,
          type: 'disabled',
          date: formatted,
        })
      } else {
        setPolicyData(policy)
        setValues({
          ...values,
          [target]: e.target.value,
        })
      }
    } else {
      setValues({
        ...values,
        [target]: e.target.value,
      })
    }
  }

  const submitApplied = () => {
    setLoadingSubmit(true)
    const startdate = generateDateSubmit(values.start_date)
    const enddate = generateDateSubmit(values.end_date)

    const totalHoliday = getTotalHoliday(
      values.start_date,
      values.end_date,
      dataScheduleHoliday
    )

    const submit = {
      objects: [
        {
          user_work_id:
            dataPlacement.global_users[0].people_work_placements[0].id,
          policy_id: values.leavePolicy,
          datestart: startdate,
          dateend: values.leaveDate === 'one_day' ? startdate : enddate,
          quota_taken:
            values.leaveDate === 'one_day'
              ? values.half_date
                ? 0.5
                : 1
              : generateQuotaTaken(values.end_date, values.start_date) +
                1 -
                totalHoliday -
                (totalCompanyHoliday < 0 ? 0 : totalCompanyHoliday),
          is_half: values.leaveDate === 'one_day' ? values.half_date : false,
          contacts: {phone: `${values.phone_code}${values.phone}`}, //
          leave_fields: {
            ...(values.leaveDate === 'one_day' &&
              values.half_date && {
                endDate: generateTimeSubmit(values.end_time),
                startDate: generateTimeSubmit(values.start_time),
              }),
            attachment: values.attachments,
          },
          description: values.description,
        },
      ],
    }
    insertApplyForLeave({
      variables: submit,
    })
      .then(() => {
        enqueueSnackbar('Apply for leave submitted', {variant: 'success'})
        onClose()
        setOpenSubmit(false)
      })
      .catch(() => {
        enqueueSnackbar('Apply for leave error, please try again later', {
          variant: 'error',
        })
      })
  }

  const handleSubmit = () => {
    const {
      name,
      leavePolicy,
      start_date,
      end_date,
      start_time,
      end_time,
      phone,
      phone_code,
      description,
      leaveDate,
    } = values
    if (
      name === '' ||
      leavePolicy === null ||
      start_date === null ||
      (end_date === null && leaveDate === 'several_day') ||
      start_time === null ||
      end_time === null ||
      phone_code === null ||
      phone === '' ||
      description === ''
    ) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)

      return
    }

    const totalHoliday = getTotalHoliday(
      start_date,
      end_date,
      dataScheduleHoliday
    )

    const range =
      values.leaveDate === 'one_day'
        ? values.half_date
          ? 0.5
          : 1
        : generateQuotaTaken(values.end_date, values.start_date) +
          1 -
          totalHoliday -
          (totalCompanyHoliday < 0 ? 0 : totalCompanyHoliday)

    const {join_date} = dataPlacement.global_users[0].people_work_placements[0]
    const balanceData = getDataBalance({data: policyData, startDate: join_date})

    const {remainingQuota, remainingQuotaCarryOver} = balanceData
    const isAccrual = policyData?.time_leave_policy?.calc_method === 'accrual'

    const waitingRange =
      dataWaitlist.people_work_leaves_aggregate.aggregate.sum.quota_taken

    const isRangeMoreThanQuota =
      range > remainingQuota + remainingQuotaCarryOver
    const isRangeMoreThanWaiting =
      waitingRange + range > remainingQuota + remainingQuotaCarryOver

    const isDebtForbidden =
      isAccrual ||
      !(
        policyData?.time_leave_policy.allow_leave_debt ||
        policyData?.infinite_quota
      )

    if (isDebtForbidden && isRangeMoreThanQuota) {
      setNotification({open: true, type: 'noquota'})
    } else if (isDebtForbidden && isRangeMoreThanWaiting) {
      setNotification({open: true, type: 'overwait'})
    } else {
      setOpenSubmit(true)
    }
  }

  const handleDeleteAttachement = i => {
    const newState = [...values.attachments]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({...values, attachments: newState})
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...values.attachments]
    newState[i].name = e.target.value
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const renderComponent = res => {
    const {
      type,
      value,
      options,
      disabled,
      textArea,
      attachments,
      fieldName,
      field,
      placeholder,
    } = res
    if (type === 'text' || type === 'number') {
      return (
        <div className="flex flex-row">
          {field === 'phone' && (
            <GlobalPhoneCode
              error={error && !values.phone_code}
              defaultValue={values.phone_code}
              onChangePhone={e => handleChange(e, 'phone_code')}
            />
          )}
          <TextFieldStyled
            type={type}
            fullWidth
            disabled={disabled}
            placeholder={placeholder}
            InputProps={
              type === 'number'
                ? {inputProps: {min: 0, className: 'text-xs'}}
                : {inputProps: {className: 'text-xs'}}
            }
            value={type === 'number' ? parseInt(value) : value}
            inputProps={{className: 'text-xs'}}
            variant="outlined"
            size="small"
            onChange={e => handleChange(e, field)}
            multiline={textArea}
            rows={textArea && 8}
            error={values[field] === '' && error}
            helperText={
              values[field] === '' && error && 'This field is required'
            }
          />
        </div>
      )
    } else if (type === 'select') {
      return (
        <FormControlStyled classes={{root: 'w-full'}} size="small">
          <SelectStyled
            classes={{root: 'text-xs'}}
            value={value}
            displayEmpty
            fullWidth
            onChange={(e, f) => handleChange(e, field, f)}
            variant="outlined"
            error={(values[field] === '' || values[field] === null) && error}
          >
            <MenuItemStyled classes={{root: 'text-xs'}} value={null} disabled>
              Select {fieldName}
            </MenuItemStyled>
            {options.map(({time_leave_policy_child}) => (
              <MenuItemStyled
                dataLeave={time_leave_policy_child}
                classes={{root: 'text-xs'}}
                data={
                  time_leave_policy_child.time_leave_policy
                    .quota_effective_after_month
                }
                key={time_leave_policy_child.id}
                value={time_leave_policy_child.id}
              >
                {time_leave_policy_child.name}
              </MenuItemStyled>
            ))}
          </SelectStyled>
          {(values[field] === '' || values[field] === null) && error && (
            <FormHelperTextStyled className="text-error mx-3">
              This field is required
            </FormHelperTextStyled>
          )}
        </FormControlStyled>
      )
    } else if (type === 'attachment') {
      return (
        <>
          <UploadButton
            multiple
            // disabled={values.attachments.length > 0 || loadingUpload}
            onChange={ev => handleChange(ev, field)}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {attachments.map((file, idx) => {
              return (
                <AttachmentItem
                  key={idx}
                  name={file.name}
                  size={file.size}
                  url={file.link}
                  onNameChange={e => handleChangeNameFile(e, idx)}
                  onRemove={() => handleDeleteAttachement(idx)}
                />
              )
            })}

            {loadingUpload && <AttachmentItem isUploading />}
          </Box>

          {errorFile && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              There are file types that are not allowed
            </FormHelperTextStyled>
          )}
          {errorSize && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              Uploaded file size cannot exceed 25MB
            </FormHelperTextStyled>
          )}
        </>
      )
    } else if (type === 'radio') {
      return (
        <div className="flex sm:flex-row flex-col justify-between">
          <label
            style={{height: 40, width: '42%', minWidth: 205}}
            className={`cursor-pointer rounded-wlb flex items-center justify-center mb-5 md:mb-0 ${
              active === 'one_day'
                ? ' bg-primary text-white text-xs font-semibold'
                : 'border-2 text-xs font-semibold'
            }`}
            htmlFor="oneDayMobile"
            onClick={() => setActive('one_day')}
          >
            One Day
          </label>
          <input
            onChange={e => handleChange(e, field)}
            checked={values.leaveDate === 'one_day'}
            type="radio"
            name="leave_date"
            id="oneDayMobile"
            value="one_day"
            className="hidden"
          />
          <label
            style={{height: 40, width: '42%', minWidth: 205}}
            className={`cursor-pointer rounded-wlb flex items-center justify-center ${
              active === 'several_day'
                ? ' bg-primary text-white text-xs font-semibold'
                : 'border-2 text-xs font-semibold'
            }`}
            htmlFor="severalDayMobile"
            onClick={() => setActive('several_day')}
          >
            Several Day
          </label>
          <input
            checked={values.leaveDate === 'several_day'}
            onChange={e => handleChange(e, field)}
            type="radio"
            name="leave_date"
            id="severalDayMobile"
            value="several_day"
            className="hidden"
          />
        </div>
      )
    }
  }

  const renderMessageNotif = () => {
    switch (notification.type) {
      case 'disabled':
        return `This policy is enabled after ${notification.date}`
      case 'noquota':
        return `No quota available`
      case 'overwait':
        return `Your available quota is insufficient due to your unapproved leave applications`
      default:
        return ''
    }
  }

  if (errorQuery) {
    return <div>{errorQuery}</div>
  }

  return (
    <>
      <div className={`flex flex-col ${!forMobile && 'pt-8 px-8 pb-8'}`}>
        {fieldList.map((res, i) => (
          <FormWrapper
            forMobile
            key={i}
            number={res.number}
            title={res.fieldName}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
        <div className="flex flex-col justify-start relative ">
          <div className="flex sm:flex-row flex-row w-full ">
            <div
              style={!smallSize ? {marginRight: 30} : {marginRight: 0}}
              className="font-semibold text-xs"
            >
              {!smallSize && '4.'}
            </div>
            <div className="flex sm:flex-row flex-col justify-between w-full ">
              <FormWrapperInline
                style={{width: '42%', minWidth: 205}}
                title="Start Date"
                className="mb-5"
              >
                <DatePickerWrapper>
                  <KeyboardDatePickerStyled
                    space="0px"
                    fullWidth
                    inputVariant="outlined"
                    format="MMMM dd, yyyy"
                    size="small"
                    value={values.start_date}
                    onChange={e => handleChange(e, 'start_date')}
                    // error
                    // helperText={'This field is required'}
                    error={values.start_date === null && error}
                    helperText={
                      values.start_date === null &&
                      error &&
                      'This field is required'
                    }
                    inputProps={{className: 'text-xs'}}
                    // disablePast
                  />
                </DatePickerWrapper>
              </FormWrapperInline>
              <FormWrapperInline
                style={{width: '42%', minWidth: 205, marginTop: 'auto'}}
                title="End Date"
                className="mb-5"
                cond={values.leaveDate !== 'one_day'}
              >
                {values.leaveDate !== 'one_day' ? (
                  <DatePickerWrapper className={`mb-auto`}>
                    <KeyboardDatePickerStyled
                      space="0px"
                      fullWidth
                      inputVariant="outlined"
                      format="MMMM dd, yyyy"
                      size="small"
                      onChange={e => handleChange(e, 'end_date')}
                      value={values.end_date}
                      error={values.end_date === null && error}
                      helperText={
                        values.end_date === null &&
                        error &&
                        'This field is required'
                      }
                      inputProps={{className: 'text-xs'}}
                      minDate={addDays(minDate, 1)}
                    />
                  </DatePickerWrapper>
                ) : (
                  <div
                    className={`flex items-center ${
                      error && values.start_date === null ? 'mb-5' : 'mt-auto'
                    }`}
                  >
                    <FormControlLabelStyled
                      checked={values.half_date}
                      control={
                        <Checkbox
                          onChange={(e, value) =>
                            handleChange(e, 'half_date', value)
                          }
                          size="small"
                          color="primary"
                        />
                      }
                      label="Half Day"
                      classes={{
                        label: 'text-xs',
                      }}
                    />
                  </div>
                )}
              </FormWrapperInline>
            </div>
          </div>
          {values.leaveDate === 'one_day' && values.half_date ? (
            <div className="flex sm:flex-row flex-col justify-between relative sm:ml-10 ml-0">
              <FormWrapperInline
                style={{width: '42%', minWidth: 205}}
                title="Start Time"
                className="mb-5"
              >
                <DatePickerWrapper>
                  <KeyboardTimePickerStyled
                    fullWidth
                    space="0px"
                    inputVariant="outlined"
                    size="small"
                    keyboardIcon={<QueryBuilderIcon />}
                    value={values.start_time}
                    onChange={e => handleChange(e, 'start_time')}
                    error={values.start_time === null && error}
                    helperText={
                      values.start_time === null &&
                      error &&
                      'This field is required'
                    }
                    inputProps={{className: 'text-xs'}}
                  />
                </DatePickerWrapper>
              </FormWrapperInline>
              <FormWrapperInline
                style={{width: '42%', minWidth: 205}}
                title="End Time"
                className="mb-5"
              >
                <DatePickerWrapper>
                  <KeyboardTimePickerStyled
                    fullWidth
                    space="0px"
                    inputVariant="outlined"
                    size="small"
                    keyboardIcon={<QueryBuilderIcon />}
                    value={values.end_time}
                    onChange={e => handleChange(e, 'end_time')}
                    error={values.end_time === null && error}
                    helperText={
                      values.end_time === null &&
                      error &&
                      'This field is required'
                    }
                    inputProps={{className: 'text-xs'}}
                  />
                </DatePickerWrapper>
              </FormWrapperInline>
            </div>
          ) : null}
        </div>
        {fieldList2.map((res, i) => (
          <FormWrapper
            forMobile
            key={i}
            number={res.number}
            title={res.fieldName}
          >
            {renderComponent(res)}
          </FormWrapper>
        ))}
      </div>
      {forMobile ? null : <Divider />}
      <div
        className={`pt-8 flex flex-row ml-auto ${
          forMobile ? 'justify-around' : 'justify-end px-8 pb-8'
        }`}
      >
        <ButtonStyled
          text="#000"
          className={`${forMobile ? 'px-10 py-2' : 'px-6 py-2'} mr-2 `}
          onClick={onClickCancel}
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
          state="primary"
          disabled={loadingHoliday || !dataWaitlist}
          className={`${forMobile ? 'px-10 py-2' : 'px-6 py-2'} `}
        >
          Submit
        </ButtonStyled>
        <NotificationWLB
          open={notification.open}
          onClick={() => setNotification({...notification, open: false})}
          message={renderMessageNotif()}
          title="Information"
        />
      </div>

      <ConfirmationDialog
        title="Apply for Leave?"
        content="Are you sure you want to apply for this leave?"
        onClickPrimary={submitApplied}
        textPrimary="Confirm"
        textSecondary="Cancel"
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        disable={loadingSubmit}
      />
    </>
  )
}
