import LeavePage from './LeavePageComponent'

export const LeavePageConfig = {
  routes: [
    {
      path: '/leave',
      component: LeavePage,
      state: 'leave',
      exact: true,
    },
    {
      path: '/leave/apply',
      component: LeavePage,
      state: 'apply-leave',
      exact: true,
    },
  ],
}
