import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'

import AttachmentItemList from '../../shared-component/attachment/AttachmentItemList'
import {ConversionDisplay} from '../TripStyles'

import {formatCurrency, getCurrencySign} from '../currencyExchanges'
import {calculateBudget, getBudgetCalculationLabel} from './trip-helper'
import {GET_CURRENCY_EXCHANGE} from '../../../graphql/queries'

export default function TableDetailTrip({
  destination,
  onClickMoreAttachment,
  data,
  status,
  totalDestination,
  currencyFrom,
  currencyTo,
  approvedDate,
  date,
}) {
  const IS_APPROVED = status === 'approved'

  let totalBudgetSpent = 0

  const tableInfo = [
    totalDestination > 1 && {
      label: 'Destination',
      value: destination,
    },
    totalDestination > 1 && {
      label: 'Date',
      value: date,
    },
    currencyFrom !== currencyTo && {
      label: 'Exchange Rate',
      value: (
        <ExchangeRateDisplay
          // NOTE(intrnl): Yes, this is intentionally flipped
          from={currencyTo}
          to={currencyFrom}
          date={approvedDate}
        />
      ),
    },
  ]

  return (
    <>
      <TableInfo data={tableInfo} />

      <TableContainer className="mb-4">
        <Table>
          <TableHead className="bg-wlbgrey">
            <TableRow>
              <TableCell className="font-semibold">Component</TableCell>
              <TableCell className="font-semibold">Trip Duration</TableCell>
              <TableCell className="font-semibold">
                Budget Calculation
              </TableCell>
              <TableCell className="font-semibold text-right">Budget</TableCell>
              <TableCell className="font-semibold text-right">Total</TableCell>
              {IS_APPROVED && (
                <TableCell className="font-semibold">Payment Type</TableCell>
              )}
              {IS_APPROVED && (
                <TableCell className="font-semibold">Attachment</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.budget.map(row => {
              const budget = calculateBudget(data, row)
              totalBudgetSpent += budget

              return (
                <TableRow key={row.id}>
                  <TableCell className="text-xs">
                    {row?.business_trip_component?.name}
                  </TableCell>
                  <TableCell className="text-xs">
                    {data?.duration} Days
                  </TableCell>
                  <TableCell className="text-xs">
                    {getBudgetCalculationLabel(row.budget_calculation)}
                  </TableCell>
                  <TableCell className="text-xs">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={row.nominal}
                      date={approvedDate}
                    />
                  </TableCell>
                  <TableCell className="text-xs">
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={budget}
                      date={approvedDate}
                    />
                  </TableCell>
                  {IS_APPROVED && (
                    <TableCell className="text-xs">
                      <PaymentTypeItem
                        payment_type={row.payment_type}
                        people_profile_bank={row?.people_profile_bank}
                      />
                    </TableCell>
                  )}
                  {IS_APPROVED && (
                    <TableCell className="text-xs">
                      {!row.attachment?.length && '-'}
                      {row.attachment?.slice(0, 3).map(({name, url}, i) => (
                        <AttachmentItemList
                          name={name}
                          url={url}
                          key={url + i}
                          onClick={() =>
                            window.open(url, '_blank', 'noopener,noreferrer')
                          }
                        />
                      ))}
                      {row.attachment?.length > 3 && (
                        <span
                          onClick={() => onClickMoreAttachment(row)}
                          className="text-xs text-secondary cursor-pointer"
                        >
                          ... {row.attachment?.length - 3}+ More
                        </span>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} className="text-black text-sm font-bold">
                Total Budget Spent
              </TableCell>

              <TableCell colSpan={IS_APPROVED ? 4 : 2} className="text-black">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalBudgetSpent}
                  date={approvedDate}
                  embolden
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

const PaymentTypeItem = ({payment_type, people_profile_bank}) => {
  const {bank_name, account_number, account_name} = people_profile_bank || {}

  const type = {
    '1': 'Transfer',
    '2': 'Ticket / Voucher',
  }

  return (
    <div className="flex flex-col">
      <span className="text-xs">{type[payment_type] || '-'}</span>
      {people_profile_bank && (
        <div className="mt-2 flex flex-col">
          <span className="text-xs text-disable mb-1">Transferred to</span>
          <span className="text-xs font-semibold mb-1">{account_number}</span>
          <span className="text-xs font-semibold">
            {bank_name + ' - ' + account_name}
          </span>
        </div>
      )}
    </div>
  )
}

export const TableInfo = ({data}) => {
  const filtered = data.filter(el => !!el)

  if (filtered.length < 1) {
    return null
  }

  return (
    <table className="mb-4">
      <tbody>
        {filtered.map(({label, value}) => (
          <tr className="text-gray-600 text-xs mb-4" key={value}>
            <td>{label}</td>
            <td className="px-2">:</td>
            <td className="text-black">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const ExchangeRateDisplay = ({from, to, date}) => {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    fetchPolicy: date ? 'cache-first' : 'cache-and-network',
    variables: {
      currency: from,
      date: date ? date.split('T')[0] : '',
    },
  })

  const formattedFrom = React.useMemo(() => {
    return formatCurrency(from, 1)
  }, [from])

  const formattedTo = React.useMemo(() => {
    return data && formatCurrency(to, data.getCurrencyExchange.idr)
  }, [to, data])

  return (
    <span>
      <span>{formattedFrom}</span>
      <span> = </span>
      <span>{data ? formattedTo : `${getCurrencySign(to)} ⋯`}</span>
    </span>
  )
}
