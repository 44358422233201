import React from "react";
import ListTripReport from './list-report/ListTripReport'
import ListContainer from "../shared-component/list/ListContainer";
import { useHistory } from "react-router-dom";

const TripReport = () => {
  const history = useHistory()

  const handleClickApply = () => {
    history.push("/trip-report/upload-report")
  }

  return (
    <ListContainer
      title="Business Trip"
      icon="flight"
      initialUrl="/trip-report"
      textButton="Upload Report"
      onClickButton={handleClickApply}
    >
      <ListTripReport />
    </ListContainer>
  );
};


export default TripReport;
