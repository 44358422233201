import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import styled from 'styled-components'
import {InputAdornment} from '@material-ui/core'

import FormWrapperInline from '../../shared-component/form/FormWrapperInline'
import {TextFieldStyled} from '../../../components/form/FormStyles'
import {InputConversionDisplay} from '../TripStyles'
import {LINE_STYLE} from './FormBudget'

import {convertNominal} from '../../../utils/helpers'
import {getCurrencySign} from '../currencyExchanges'
import {getBudgetCalculationLabel} from '../trip-shared-component/trip-helper'
import {GET_LIST_STRICT_BUDGET} from '../../../graphql/queries'

const LineClamp = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.clamp || 1};
`

/**
 * @param {number} props.budget_component_option - 1: General, 2: Spesific
 */
export default function BudgetStrictComponent({
  name,
  nominal,
  description,
  onChangeNominal,
  policy,
  destination,
  setValues,
  budget_component_type,
  budget_component_option,
  budgetCalculation,
}) {
  useQuery(GET_LIST_STRICT_BUDGET, {
    variables: {
      policy: policy?.business_trip_policy?.id || null,
      destination: budget_component_option === 2 ? destination.to : null,
    },
    onCompleted: data => {
      const budget = data?.business_trip_component.map(component => {
        const {nominal, budget_calculation} =
          component?.business_trip_policy_destination_budgets?.[0] || {}
        return {
          id: component.id,
          nominal,
          budget_calculation,
          budget_component_type: nominal === null ? 1 : 2,
          type: {
            description: component.description,
            label: component.name,
            value: component.id,
          },
        }
      })

      const _obj = {
        ...destination,
        budget,
      }
      setValues(prev => {
        const _prevDestination = [...prev.destination]
        const destination = _prevDestination.map(
          obj => [_obj].find(o => o.id === obj.id) || obj
        )
        return {
          ...prev,
          destination,
        }
      })
    },
  })

  return (
    <BudgetStrictItem
      name={name}
      description={description}
      onChangeNominal={onChangeNominal}
      nominal={nominal}
      budget_component_type={budget_component_type}
      currency_from={policy.business_trip_policy.currency_from}
      currency_to={policy.business_trip_policy.currency_to}
      budgetCalculation={budgetCalculation}
    />
  )
}

const BudgetStrictItem = ({
  name,
  description,
  onChangeNominal,
  nominal,
  budgetCalculation,
  budget_component_type,
  currency_from,
  currency_to,
}) => {
  return (
    <FormWrapperInline
      style={{width: '100%'}}
      className="mt-4 flex items-start flex-col"
    >
      <div className="flex flex-row w-full items-center justify-between">
        <div className="pr-4">
          <LineClamp className="text-xs text-black mb-">{name}</LineClamp>
          <span className="text-xs text-black mb-1">
            {getBudgetCalculationLabel(budgetCalculation)}
          </span>
          <LineClamp clamp="3" className="text-xs text-gray-600">
            {description}
          </LineClamp>
        </div>

        <div className="flex-shrink-0">
          <TextFieldStyled
            disabled={budget_component_type === 2}
            variant="outlined"
            size="small"
            placeholder="Nominal"
            onChange={onChangeNominal}
            value={convertNominal(nominal)}
            classes={{root: 'text-xs m-0'}}
            InputProps={{
              style: {height: 42},
              inputProps: {className: 'text-xs text-right', min: 0},
              startAdornment: (
                <InputAdornment position="start" disableTypography>
                  <div style={LINE_STYLE}>
                    <p style={{fontSize: 14, color: '#a9a8a8'}}>
                      {getCurrencySign(currency_to)}
                    </p>
                  </div>
                </InputAdornment>
              ),
            }}
          />

          {currency_to !== currency_from && (
            <InputConversionDisplay
              // NOTE(intrnl): yes, this is meant to be flipped
              from={currency_to}
              to={currency_from}
              value={nominal}
            />
          )}
        </div>
      </div>
    </FormWrapperInline>
  )
}
