import React from 'react'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
function ListTax(props) {
  const {data} = props

  let npwp = '-'
  let bpjskes = '-'
  let bpjsket = '-'
  data &&
    data.map(row => {
      if (row.type === 'NPWP') {
        npwp = row.id
      } else if (row.type === 'BPJSKes') {
        bpjskes = row.id
      } else if (row.type === 'BPJSTK') {
        bpjsket = row.id
      }
    })
  return (
    <>
      <div
        style={{marginTop: 20, marginBottom: 6}}
        className="flex justify-between items-center"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            NPWP
          </TypographyStyled>
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            BPJS Kesehatan
          </TypographyStyled>
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            BPJS Ketenagakerjaan
          </TypographyStyled>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            {npwp}
          </TypographyStyled>
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            {bpjskes}
          </TypographyStyled>
          <TypographyStyled
            className="font-semibold"
            text="#000000"
            style={{fontSize: '14px', marginBottom: 4, lineHeight: '24px'}}
          >
            {bpjsket}
          </TypographyStyled>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* <IconButton
            aria-label="delete"
            style={{color: '#039be5'}}
            onClick={() => {
              setPage('form-tax')
              setDataEdit(data)
            }}
          >
            <CreateIcon style={{fontSize: '20px'}} />
          </IconButton> */}
        </div>
      </div>
    </>
  )
}

export default ListTax
