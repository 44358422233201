import React from 'react'
import styled from 'styled-components'

const PrimitiveDetailsTable = styled.table`
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 20px 16px;
  margin: -16px -20px;

  & th {
    color: ${props => props.theme.palette.grey[700]};
    min-width: 160px;
    text-align: left;
    vertical-align: top;
  }
`

function DetailsTable(props) {
  const {data, fields, context} = props

  const children = React.useMemo(
    () =>
      fields.map((field, index) =>
        field.hide?.(data, context) ? null : (
          <tr key={index}>
            <th>{field.title}</th>
            <td>{field.render(data, context)}</td>
          </tr>
        )
      ),
    [data, fields, context]
  )

  return (
    <PrimitiveDetailsTable>
      <tbody>{children}</tbody>
    </PrimitiveDetailsTable>
  )
}

export default DetailsTable
