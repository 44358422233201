import React from 'react'
import {capitalize} from '../../../utils/helpers'
import Divider from '../../../components/divider/DividerStyles'

export default function ListItem({
  title,
  idDisplay,
  time,
  submited,
  status,
  name,
  onClick,
  confirm_type,
  data,
  from,
}) {
  let type_policy = 'time_leave_policy_child'
  if (from === 'overtime') {
    type_policy = 'time_overtime_policy'
  }

  const generateColor = status => {
    switch (status) {
      case 'Approved':
        return 'bg-success'
      case 'Pending':
        return 'bg-warning'
      case 'Waiting':
        return 'bg-warning'
      case 'Waiting for second approval':
        return 'bg-warning'
      case 'Rejected':
        return 'bg-danger'
      case 'Cancelled':
        return 'bg-disable'
      default:
        return null
    }
  }

  const generateStatus = (status, type, modified, second) => {
    if (status === 'approved') {
      return 'Approved'
    } else if (status === 'pending') {
      return 'Waiting'
    } else if (status === 'rejected') {
      return 'Rejected'
    } else if (status === 'cancelled') {
      return 'Cancelled'
    } else if (status === 'confirm1' && type === '1') {
      if (second && second.people_work_placements.length > 0) {
        if (
          modified &&
          modified.id === second.people_work_placements[0].global_user &&
          second.people_work_placements[0].global_user.id
        ) {
          return 'Approved'
        } else {
          return 'Waiting for second approval'
        }
      } else {
        return 'Waiting for second approval'
      }
    } else if (type === '2') {
      if (status === 'confirm1') {
        return 'Waiting for second approval'
      } else {
        return 'Approved'
      }
    } else {
      return capitalize(status)
    }
  }

  return (
    <div onClick={onClick} style={{padding: '0px 30px 0px 30px'}}>
      <div className="py-5 flex flex-row justify-between items-start cursor-pointer">
        <div style={{maxWidth: '68%'}} className="flex flex-col">
          <h4 className="text-primary text-sm ">{title}</h4>
          <p className="text-disable text-md pt-3 pb-2 text-xs md:text-xs">
            {idDisplay}
          </p>
          {name && (
            <p className="text-disable text-md pb-2 text-xs md:text-xs">
              {name}
            </p>
          )}
          <p className="text-disable text-md pb-2 text-xs md:text-xs">
            For : {time}
          </p>
          <p className="text-disable text-md text-xs md:text-xs block md:hidden">
            Submitted : {submited}
          </p>
        </div>
        <div className="flex flex-col">
          <div className="text-disable text-md text-xs md:text-xs mb-2 hidden md:block ml-auto">
            Submitted : {submited}
          </div>
          <div className="ml-auto">
            <div
              className={`${generateColor(
                generateStatus(
                  status,
                  confirm_type,
                  data.globalUserByModifiedBy,
                  data[type_policy] && data[type_policy].second_job_profile
                )
              )} py-1 px-2 border rounded-wlb w-auto`}
            >
              <p
                style={{maxWidth: 109, textAlign: 'right'}}
                className="text-white text-xs"
              >
                {generateStatus(
                  status,
                  confirm_type,
                  data.globalUserByModifiedBy,
                  data[type_policy] && data[type_policy].second_job_profile
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  )
}
