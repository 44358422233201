import React from 'react'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import {Button} from '@material-ui/core'
import Divider from '../../../components/divider/DividerStyles'

export default function CancelDialog({
  title,
  content,
  onClickPrimary,
  textPrimary,
  textSecondary,
  open,
  onClose,
  disabled = false,
}) {
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <div className="flex flex-col">
        <div className="p-6">
          <span className="text-primary text-sm font-semibold">{title}</span>
        </div>
        <Divider />
        <div className="p-6 flex flex-col">
          <span className="text-xs">{content}</span>
          <div className="mt-8 ml-auto">
            <Button
              className="text-xs font-semibold px-2"
              onClick={onClose}
              style={{color: '#a9a8a8 !important'}}
            >
              {textSecondary}
            </Button>
            <Button
              className="text-xs ml-4 font-semibold px-4"
              onClick={onClickPrimary}
              color="primary"
              variant="contained"
              disabled={disabled}
            >
              {textPrimary}
            </Button>
          </div>
        </div>
      </div>
    </StyledDialog>
  )
}
