import {useLazyQuery} from '@apollo/react-hooks'
import {useEffect, useState} from 'react'
import {GET_LIST_SCHEDULE} from '../graphql/queries'

const DAY_IN_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export default function useScheduleHoliday() {
  const [getListSchedule, {data}] = useLazyQuery(GET_LIST_SCHEDULE)
  const [holiday, setHoliday] = useState(undefined)

  const getData = userWorkId => {
    getListSchedule({
      variables: {
        userWorkId,
      },
    })
  }

  useEffect(() => {
    if (data) {
      let newSchedule = []
      data.time_attendance_policies.forEach(({schedule}) => {
        newSchedule = [...newSchedule, ...Object.keys(schedule)]
      })
      const dayToWork = [...new Set(newSchedule)]
      const dayholiday = DAY_IN_WEEK.filter(day => !dayToWork.includes(day))
      setHoliday(dayholiday)
    }
  }, [data])

  return [holiday, getData]
}
