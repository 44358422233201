import React from 'react'

import {ListItem} from '@material-ui/core'

import Divider from '../../../components/divider/DividerStyles'

import {getClaimType, getStatusColor} from '../ClaimStyles'

import {capitalize, convertToRupiah, generateDate} from '../../../utils/helpers'

function ClaimItem(props) {
  const {data, onClick} = props

  const handleClick = ev => {
    if (onClick) {
      onClick(ev, data)
    }
  }

  return (
    <ListItem
      button
      className="flex-col items-stretch py-0 px-6"
      onClick={handleClick}
    >
      <div className="flex py-5">
        <div className="flex flex-col flex-grow items-start">
          <h4 className="text-primary">
            {data.policy ? data.policy.name : `${getClaimType(data)} Claim`}
          </h4>
          <p className="text-gray-600 text-sm">{data.formatted_id}</p>

          <p className="text-sm mt-2">
            {convertToRupiah(
              data.invoice ? data.invoice.final_price : data.nominal
            )}
          </p>
          <p className="text-gray-600 text-sm">
            for {generateDate(data.date_issued) || '-'}
          </p>
        </div>
        <div className="flex flex-col ml-4 items-end">
          <p className="text-gray-600 text-sm">
            Submitted on {generateDate(data.date_added)}
          </p>

          <div
            className="mt-2 px-2 py-1 font-semibold text-sm rounded text-white"
            style={{backgroundColor: getStatusColor(data.status)}}
          >
            {capitalize(data.status)}
          </div>
        </div>
      </div>
      <Divider />
    </ListItem>
  )
}

export default ClaimItem
