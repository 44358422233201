import React from 'react'
import {Paper} from '../../components/paper/PaperStyles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import {withRouter} from 'react-router-dom'
import {hasModule} from '../../utils/helpers'

const MenuItem = ({ pathname, title, description, history, Icon }) => {
  return (
    <Paper
      onClick={() => history.push({ pathname })}
      style={{ marginBottom: "20px" }}
      className="py-2 px-8 flex flex-row shadow-wlb rounded-wlb items-center cursor-pointer"
    >
      <Icon style={{ fontSize: 90, color: "#014a62" }} className="pr-8" />
      <div className="flex flex-col">
        <h3 className="font-semibold mb-1" style={{ fontSize: 18 }}>
          {title}
        </h3>
        <h5 className="text-disable">{description}</h5>
      </div>
    </Paper>
  );
};

const HomepageComponent = props => {
  const HOMEPAGE_MENUS = [
    {
      pathname: "/attendance",
      title: "Time",
      description: "Attendance, Leave and Permission, Overtime, and Calendar",
      icon: AccessTimeIcon,
      hasModule: hasModule(props.modular, 'time'),
    },
    {
      pathname: '/bank-and-tax',
      title: "Financial",
      description: "Salary, THR, and Bank & Tax",
      icon: AttachMoneyIcon,
      hasModule: hasModule(props.modular, 'financial'),
    },
    {
      pathname: "/trip",
      title: "Business Trip",
      description: "Business trip submission activities",
      icon: AirplanemodeActiveIcon,
      hasModule: hasModule(props.modular, 'business_trip'),
    },
    {
      pathname: '/digital-document',
      title: "Document",
      description: "Digital Component",
      icon: InsertDriveFileIcon,
      hasModule: true,
    },
  ];

  return (
    <div className="flex flex-col m-0 rounded">
      {HOMEPAGE_MENUS.filter(({ hasModule }) => hasModule).map((res) => (
        <MenuItem
          key={res.title}
          pathname={res.pathname}
          title={res.title}
          description={res.description}
          history={props.history}
          Icon={res.icon}
        />
      ))}
    </div>
  )
}

export default withRouter(HomepageComponent)
