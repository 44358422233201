import gql from 'graphql-tag'

export const REQUEST_CLAIM = gql`
  mutation(
    $policyId: Int!
    $issuedDate: String!
    $nominal: Float!
    $receipts: [RequestClaimReceiptsInput]!
    $description: String
  ) {
    requestClaim(
      claim_policy_id: $policyId
      issued_date: $issuedDate
      amount: $nominal
      receipts: $receipts
      description: $description
    ) {
      success
    }
  }
`

export const SET_CLAIM_STATUS = gql`
  mutation($requestId: bigint!, $status: String!) {
    update_people_work_claims_by_pk(
      pk_columns: {id: $requestId}
      _set: {status: $status}
    ) {
      id
      status
    }
  }
`

export const REQUEST_CLAIM_INVOICE_EXPORTS = gql`
  mutation($invoiceId: String!) {
    exportInvoice(invoiceId: $invoiceId) {
      success
    }
  }
`

// Waiting -> Rejected
export const SET_CLAIM_REJECTED = gql`
  mutation($requestId: bigint!, $fields: jsonb) {
    update_people_work_claims_by_pk(
      pk_columns: {id: $requestId}
      _set: {status: "rejected"}
      _append: {claim_fields: $fields}
    ) {
      id
      status
      claim_fields
    }
  }
`

// Waiting -> Processing
export const APPROVE_CLAIM = gql`
  mutation($requestId: Int!) {
    approveClaim(id: $requestId, as: "user") {
      success
      number_of_approvals {
        current
        required
      }
      people_work_claim {
        id
        status
      }
    }
  }
`
