import gql from 'graphql-tag'

export const GET_LIST_ACTIVITY = gql`
  query(
    $offset: Int
    $limit: Int
    $sorting: order_by
    $idPolicy: [Int!]
    $dateStart: timestamp
    $dateEnd: timestamp
    $status: [String!]
    $search: String
    $user: uuid
    $company: uuid
  ) {
    total: people_work_overtimes_aggregate(
      where: {
        status: {_in: $status}
        policy_id: {_in: $idPolicy}
        people_work_placement: {user: {_eq: $user}, company: {_eq: $company}}
        id_display: {_ilike: $search}
        date_added: {_gte: $dateStart, _lte: $dateEnd}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_overtimes(
      where: {
        status: {_in: $status}
        policy_id: {_in: $idPolicy}
        people_work_placement: {user: {_eq: $user}, company: {_eq: $company}}
        id_display: {_ilike: $search}
        date_added: {_gte: $dateStart, _lte: $dateEnd}
      }
      order_by: {last_modified: $sorting}
      offset: $offset
      limit: $limit
    ) {
      id
      id_display
      time_overtime_policy {
        id
        name
      }
      date_added
      datestart
      dateend
      status
      globalUserByModifiedBy {
        id
        name
      }
      people_work_placement {
        id
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
              id
              global_user {
                id
                name
              }
            }
          }
        }
      }
      time_overtime_policy {
        id
        name
        confirm_type
        convert_to_money
        first_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const GET_WORK_OVERTIME = gql`
  query($user: uuid!) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
    }
  }
`
export const GET_LIST_REQUEST_OVERTIME = gql`
  query(
    $user: uuid!
    $company: uuid!
    $sorting: order_by
    $idPolicy: [Int!]
    $idRequester: [uuid!]
    $dateStart: timestamp
    $dateEnd: timestamp
    $status: [String!]
    $search: String
    $limit: Int
    $offset: Int
  ) {
    people_work_overtimes(
      where: {
        _or: [
          {
            time_overtime_policy: {
              confirm_type: {_eq: "2"}
              first_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_overtime_policy: {
              confirm_type: {_eq: "2"}
              second_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_overtime_policy: {confirm_type: {_eq: "1"}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          }
        ]
        date_added: {_gte: $dateStart, _lte: $dateEnd}
        time_overtime_policy: {id: {_in: $idPolicy}}
        people_work_placement: {user: {_in: $idRequester}}
        status: {_in: $status}
        id_display: {_ilike: $search}
      }
      order_by: [{id: $sorting}]
      limit: $limit
      offset: $offset
    ) {
      id
      id_display
      convert_to
      people_work_placement {
        id
        status
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
              id
              global_user {
                id
                name
              }
            }
          }
        }
      }
      time_overtime_policy {
        id
        name
        confirm_type
        convert_to_money
        first_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
      overtime_fields
      date_added
      description
      datestart
      dateend
      cancelled_by_user {
        name
        id
      }
      globalUserByModifiedBy {
        name
        id
      }
      status
    }
    total: people_work_overtimes_aggregate(
      where: {
        _or: [
          {
            time_overtime_policy: {
              confirm_type: {_eq: "2"}
              first_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_overtime_policy: {
              confirm_type: {_eq: "2"}
              second_job_profile: {
                people_work_placements: {
                  user: {_eq: $user}
                  company: {_eq: $company}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          }
          {
            time_overtime_policy: {confirm_type: {_eq: "1"}}
            people_work_placement: {
              company_job_profile: {
                job_profile_supervisor: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            }
          }
        ]
        date_added: {_gte: $dateStart, _lte: $dateEnd}
        time_overtime_policy: {id: {_in: $idPolicy}}
        people_work_placement: {user: {_in: $idRequester}}
        status: {_in: $status}
        id_display: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GET_LIST_DETAIL_OVERTIME = gql`
  query($id: bigint!) {
    people_work_overtimes(where: {id: {_eq: $id}}, order_by: [{id: desc}]) {
      id
      id_display
      convert_to
      people_work_placement {
        id
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
              id
              global_user {
                id
                name
              }
            }
          }
        }
      }
      time_overtime_policy {
        id
        name
        confirm_type
        convert_to_money
        convert_to_leave
        minimum_duration_leave
        first_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
      overtime_fields
      date_added
      description
      datestart
      dateend
      cancelled_by_user {
        name
        id
      }
      globalUserByModifiedBy {
        name
        id
      }
      status
    }
  }
`
export const GET_LIST_POLICY_OVERTIME = gql`
  query($user: uuid, $search: String) {
    time_overtime_policies(
      where: {
        company_job_profile_r_overtimes: {
          company_job_profile: {people_work_placements: {user: {_eq: $user}}}
        }
        name: {_ilike: $search}
      }
      order_by: [{name: asc}]
    ) {
      id
      name
      convert_to_leave
      convert_to_money
      minimum_duration_leave
      time_overtime_policy: TimeLeavePolicyChildren {
        id
        name
      }
    }
  }
`
export const GET_LIST_POLICY_OVERTIME_FILTER = gql`
  query(
    $user: uuid!
    $company: uuid!
    $search: String
    $nin: [Int!]
    $limit: Int
  ) {
    time_overtime_policies(
      where: {
        people_work_overtimes: {
          _or: [
            {
              time_overtime_policy: {
                first_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              time_overtime_policy: {
                second_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user}
                          company: {_eq: $company}
                        }
                      }
                    }
                  }
                }
                {time_overtime_policy: {confirm_type: {_eq: "1"}}}
              ]
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user}
                          company: {_eq: $company}
                        }
                      }
                    }
                  }
                }
                {time_overtime_policy: {confirm_type: {_eq: "3"}}}
              ]
            }
          ]
        }
        name: {_ilike: $search}
        id: {_nin: $nin}
      }
      order_by: [{name: asc}]
      limit: $limit
    ) {
      id
      name
    }
    total: time_overtime_policies_aggregate(
      where: {
        people_work_overtimes: {
          _or: [
            {
              time_overtime_policy: {
                first_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              time_overtime_policy: {
                second_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user}
                          company: {_eq: $company}
                        }
                      }
                    }
                  }
                }
                {time_overtime_policy: {confirm_type: {_eq: "1"}}}
              ]
            }
            {
              _and: [
                {
                  people_work_placement: {
                    company_job_profile: {
                      job_profile_supervisor: {
                        people_work_placements: {
                          user: {_eq: $user}
                          company: {_eq: $company}
                        }
                      }
                    }
                  }
                }
                {time_overtime_policy: {confirm_type: {_eq: "3"}}}
              ]
            }
          ]
        }
        name: {_ilike: $search}
        id: {_nin: $nin}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_REQUESTER_OVERTIME_REQUEST_FILTER = gql`
  query(
    $user: uuid!
    $company: uuid!
    $search: String
    $limit: Int
    $nin: [uuid!]
  ) {
    people_work_placements(
      where: {
        people_work_overtimes: {
          _or: [
            {
              time_overtime_policy: {
                first_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              time_overtime_policy: {
                second_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      company: {_eq: $company}
                    }
                  }
                }
              }
            }
          ]
        }
        global_user: {_and: [{name: {_ilike: $search}}, {id: {_nin: $nin}}]}
      }
      distinct_on: [user]
      order_by: [{user: asc}, {id: desc}]
      limit: $limit
    ) {
      id
      global_user {
        id
        name
      }
    }
    total: people_work_placements_aggregate(
      where: {
        people_work_overtimes: {
          _or: [
            {
              time_overtime_policy: {
                first_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              time_overtime_policy: {
                second_job_profile: {
                  people_work_placements: {
                    user: {_eq: $user}
                    company: {_eq: $company}
                  }
                }
              }
            }
            {
              people_work_placement: {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      company: {_eq: $company}
                    }
                  }
                }
              }
            }
          ]
        }
        global_user: {_and: [{name: {_ilike: $search}}, {id: {_nin: $nin}}]}
      }
      distinct_on: [user]
      order_by: [{user: asc}, {id: desc}]
    ) {
      aggregate {
        count
      }
    }
  }
`
