import gql from 'graphql-tag'

export const GET_CHECKER_COMPENSATION_OVERTIME = gql`
  query CheckerLeavePolicyinOvertime($policy: Int, $placement_id: Int) {
    company_job_profile_r_leave_aggregate(
      where: {
        policy: {_eq: $policy}
        company_job_profile: {
          people_work_placements: {id: {_eq: $placement_id}}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_SALARY = gql`
  query($user_work_id: Int) {
    people_work_salaries(
      where: {
        user_work_id: {_eq: $user_work_id}
        _and: [{start_date: {_is_null: false}}, {start_date: {_lte: "now()"}}]
      }
      order_by: {start_date: asc_nulls_last}
    ) {
      id
      value
      start_date
      end_date
      active
    }
  }
`
