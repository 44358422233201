import { Icon } from "@material-ui/core";
import { components } from "react-select";
import React from "react";
import styled from "styled-components";

export const BlueIcon = styled(Icon)`
  color: #014a62;
`;

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon>arrow_drop_down</Icon>
    </components.DropdownIndicator>
  );
};

export const DropdownIndicatorSearch = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <BlueIcon>search</BlueIcon>
    </components.DropdownIndicator>
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

export const SingleValuePlain = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value}
    </components.SingleValue>
  );
};

export const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>{props.data.value}</components.MultiValue>
  );
};

export const customSelect = (props) => ({
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minWidth: props.minWidth || 250,
    outline: "none",
    fontSize: props.fontSize || 12,
    margin: props.marginNormal ? "16px 0 8px" : "0",
    backgroundColor: state.isDisabled ? '#fafafa' : '#fff',
    boxShadow: '0 !important',
    border: '1px solid #a9a8a8',
    '&:hover': {
      border: '1px solid #000 !important'
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#039be5",
    padding: 8,
    borderRadius: 5,
    margin: 5,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#fff",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#ffff",
    "&:hover": { color: "#ffff", backgroundColor: "rgba(0,0,0,0)" },
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    minWidth: props.minWidth || 250,
  }),
  option: (base) => {
    return {
      ...base,
      backgroundColor: '#fff',
      color: '#000',
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      },
    }
  },
  singleValue:(base) => {
    return {
      ...base,
      ...props.singleValueStyle || {}
    }
  }
});
