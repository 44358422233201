import React, {useState} from 'react'

import FormWrapper from '../../shared-component/form/FormWrapper'
import {InfoFileTooltip} from '../../shared-component/tooltip/InfoFileTooltip'
import ContentTooltipFile from '../../shared-component/tooltip/ContentTooltipFile'
import {AttachmentItem, UploadButton} from '@smartasn/wlb-utils-components'
import ConfirmationDialog from '../../shared-component/dialog/ConfirmationDialog'

import {AsyncSelectStyled} from '../../../components/select/AsyncSelectStyled'
import {SelectStyled} from '../../../components/select/SelectStyled'
import {
  FormHelperTextStyled,
  TextFieldStyled,
} from '../../../components/form/FormStyles'
import {
  isErrorSubmit,
  isUploadingFiles,
} from '../trip-shared-component/trip-helper'
import {checkFilesType} from '../../overtime/helperForOvertime'

import {
  Box,
  Avatar,
  Divider,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Axios from 'axios'
import {useSnackbar} from 'notistack'
import {useHistory} from 'react-router'
import {useApolloClient, useQuery, useMutation} from '@apollo/react-hooks'
import {
  COMPANY_ID,
  TOKEN,
  UPLOAD_URL,
  USER_ID,
  USER_NAME,
  POSITION_NAME,
} from '../../../utils/globals'
import {generateDateSubmit} from '../../../utils/helpers'

import {APPLY_FOR_TRIP} from '../../../graphql/mutations'
import {
  GET_LIST_APPLY_TRIP_CURRENT_POSITION,
  GET_LIST_APPLY_TRIP_PARTICIPANT,
  GET_LIST_TRIP_POLICY,
} from '../../../graphql/queries'

import FormActionButton from './FormActionButton'
import FormDate from './FormDate'
import FormDestination from './FormDestination'
import FormBudget from './FormBudget'

export default function ApplyForTripForm() {
  const {enqueueSnackbar} = useSnackbar()
  const history = useHistory()
  const client = useApolloClient()
  const theme = useTheme()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm')) // TODO: responsive condition
  const [isTripForSelf, setIsTripForSelf] = useState(false)
  const [error, setError] = useState(false)
  const [fileType, setFileType] = useState('')
  const [errorFile, setErrorFile] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [searchPolicy, setSearchPolicy] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmitAnotherTrip, setIsSubmitAnotherTrip] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState(
    DEFAULT_CONFIRMATION_STATE
  )
  const [values, setValues] = useState(DEFAULT_VALUES)
  const [applyTrip] = useMutation(APPLY_FOR_TRIP)
  const {data: dataPolicy, loading: loadingPolicy} = useQuery(
    GET_LIST_TRIP_POLICY,
    {
      skip: !values?.participant,
      variables: {
        profile: values?.participant?.company_job_profile?.id,
        search: `%${searchPolicy}%`,
      },
    }
  )

  const {data: dataSelf, loading: loadingSelf} = useQuery(
    GET_LIST_APPLY_TRIP_CURRENT_POSITION,
    {
      variables: {
        userId: USER_ID,
      },
    }
  )

  const isUploadingAttahments = isUploadingFiles(values.attachments)

  const fieldList = [
    {
      number: !smallSize && 1,
      fieldName: 'Name*',
      field: 'name',
      type: 'text',
      value:
        dataSelf?.people_work_placements?.[0]?.global_user?.name || values.name,
      disabled: true,
    },
    {
      number: !smallSize && 2,
      fieldName: 'Position*',
      field: 'position',
      type: 'text',
      value:
        dataSelf?.people_work_placements?.[0]?.company_job_profile?.title ||
        values.position,
      disabled: true,
    },
    {
      number: !smallSize && 3,
      fieldName: 'Participant*',
      field: 'participant',
      type: 'async-select',
      value: values.participant,
      isHide: isTripForSelf,
      placeholder: 'Choose Participant',
      singleValueStyle: singleValueStyleParticipant,
      getOptionLabel: option => {
        return (
          <div className="wrap-option flex flex-row items-center">
            <Avatar
              src={option?.global_user?.avatar}
              alt={option?.global_user?.name}
              sizes="18px"
              className="mr-4 w-16 h-16 avatar"
            />
            <div className="content">
              <h3 className="text-sm">{option?.global_user?.name || '-'}</h3>
              <h5 className="hide-when-selected text-xs">
                {option?.regno || '-'}
              </h5>
              <span className="hide-when-selected text-xs text-gray-600">
                {option?.global_user?.email}
              </span>
            </div>
          </div>
        )
      },
      extra: (
        <div>
          <Typography variant="caption">Trip for Myself</Typography>
          <Switch
            checked={isTripForSelf}
            onChange={e => handleChangeTripForSelf(e.target.checked)}
            color="primary"
          />
        </div>
      ),
    },
    {
      number: !smallSize && 4,
      fieldName: 'Trip Policy*',
      field: 'policy',
      type: 'select',
      value: values.policy,
      placeholder: 'Choose Policy',
      disabled: !values?.participant,
      singleValueStyle: singleValueStylePolicy,
      option:
        dataPolicy?.company_job_profile_r_business_trip.map(res => res) || [],
      isSearchable: true,
      isLoading: loadingPolicy,
      getOptionLabel: option => {
        return (
          <div className="wrap-option">
            <h3 className="text-sm">{option?.business_trip_policy?.name}</h3>
            <p className="hide-when-selected text-xs m-0 mt-1 text-gray-600">
              {option?.business_trip_policy?.description}
            </p>
          </div>
        )
      },
    },
  ]

  const fieldList2 = [
    {
      number: !smallSize && 8,
      fieldName: 'Description*',
      field: 'description',
      type: 'text',
      value: values.description,
      textArea: true,
      placeholder: 'Add description',
    },
    {
      number: !smallSize && 8,
      field: 'attachments',
      titleComponent: 'div',
      fieldName: (
        <div className="flex flex-row items-center">
          Attachments
          <span className="mx-2 text-gray-600 text-xs">(optional)</span>
          <InfoFileTooltip title={<ContentTooltipFile />} placement="right">
            <InfoOutlinedIcon className="text-xs text-primary" />
          </InfoFileTooltip>
        </div>
      ),
      type: 'attachment',
      value: values.attachments,
    },
  ]

  const handleChangeTripForSelf = async isChecked => {
    setIsTripForSelf(isChecked)
    if (isChecked) {
      const {data} = await client.query({
        query: GET_LIST_APPLY_TRIP_CURRENT_POSITION,
        variables: {
          userId: USER_ID,
        },
      })
      setValues({
        ...values,
        policy: null,
        participant: data?.people_work_placements?.[0] || null,
      })
    } else {
      setValues({
        ...values,
        policy: null,
        participant: null,
      })
    }
  }

  const handleSearch = async (search, field) => {
    if (field === 'participant') {
      const {data} = await client.query({
        query: GET_LIST_APPLY_TRIP_PARTICIPANT,
        variables: {
          userId: USER_ID,
          company: COMPANY_ID,
          search: `%${search}%`,
        },
      })
      return data?.people_work_placements?.map(res => res)
    }
  }

  const uploadFile = tempFile => {
    tempFile.forEach(async val => {
      const formData = new FormData()
      formData.append('file', val)
      const _initialUpload = {
        id: val.name,
        name: val.name,
        size: val.size,
        url: '',
        loaded: 0,
        isUploaded: false,
      }
      setValues(prevState => {
        return {
          ...prevState,
          attachments: [...(prevState.attachments || []), _initialUpload],
        }
      })
      const response = await Axios.post(UPLOAD_URL, formData, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
        onUploadProgress: progressEvent => {
          setValues(prevState => {
            const item = {
              id: val.name,
              name: val.name,
              size: val.size,
              url: '',
              loaded: progressEvent.loaded,
              isUploaded: false,
            }
            const items = prevState.attachments
            const foundIndex = items.findIndex(
              x => x.id === item.id && !x.isUploaded
            )
            items[foundIndex] = item
            return {...prevState, attachments: items}
          })
        },
      })
      setValues(prevState => {
        const item = {
          id: val.name,
          name: val.name,
          size: val.size,
          url: response.data.url,
          loaded: val.size,
          isUploaded: true,
        }
        const items = prevState.attachments
        const foundIndex = items.findIndex(
          x => x.id === item.id && !x.isUploaded
        )
        items[foundIndex] = item
        return {...prevState, attachments: items}
      })
    })
  }

  const handleChange = (data, field) => {
    if (field === 'attachments') {
      setErrorFile(false)
      setErrorSize(false)
      const tempFile = Array.from(data)
      const [isErrorType, isMaxsize] = checkFilesType(tempFile, setFileType)
      if (isErrorType) {
        setErrorFile(true)
      } else if (isMaxsize) {
        setErrorSize(true)
      } else {
        uploadFile(tempFile)
      }
    } else if (field === 'participant') {
      setValues({
        ...values,
        policy: null,
        [field]: data,
      })
    } else if (field === 'policy') {
      setValues({
        ...values,
        destination: DEFAULT_DESTINATION,
        [field]: data,
      })
    } else {
      setValues({
        ...values,
        [field]: data,
      })
    }
  }

  const handleDeleteAttachement = i => {
    const newState = [...values.attachments]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({...values, attachments: newState})
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...values.attachments]
    newState[i].name = e.target.value
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const handleInputChange = (search, fieldName) => {
    if (fieldName === 'policy') {
      setSearchPolicy(search)
    }
  }

  const handleApplyTrip = () => {
    setLoading(true)
    const variables = {
      user_work_id: values.participant.id,
      user_id: values.participant.global_user.id,
      added_by_work_id: dataSelf?.people_work_placements?.[0]?.id,
      trip_type: isTripForSelf ? 1 : 2,
      policy_id: values?.policy?.business_trip_policy?.id,
      date_start: generateDateSubmit(values.start_date),
      date_end: generateDateSubmit(values.end_date),
      description: values.description,
      attachment: values.attachments.map(({size, name, url}) => ({
        size,
        name,
        url,
      })),
      destinations: values.destination.map(destination => ({
        from: destination.from,
        to: destination.to,
        duration: destination.duration,
        start_date: generateDateSubmit(destination.start_date),
        end_date: generateDateSubmit(destination.end_date),
        budget: destination.budget.map(budget => ({
          type: budget.type.value,
          nominal: budget.nominal || 0,
          budget_calculation: budget.budget_calculation,
        })),
      })),
    }
    applyTrip({variables})
      .then(() => {
        enqueueSnackbar('Apply trip success', {variant: 'success'})
        if (isSubmitAnotherTrip) {
          setConfirmationDialog(DEFAULT_CONFIRMATION_STATE)
          setValues(DEFAULT_VALUES)
          setIsSubmitAnotherTrip(false)
          setIsTripForSelf(false)
        } else history.push('/trip/activity')
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
      .finally(() => setLoading(false))
  }

  const openSubmitConfirmation = () => {
    setConfirmationDialog({
      open: true,
      title: 'Apply Trip?',
      content: 'Are you sure you want to apply this trip?',
      onClick: () => handleApplyTrip(),
      onClose: () => setConfirmationDialog(DEFAULT_CONFIRMATION_STATE),
      textPrimary: 'Confirm',
      textSecondary: 'Cancel',
    })
  }

  const handleCancel = () => {
    setConfirmationDialog({
      open: true,
      title: 'Discard Changes?',
      content: 'Are you sure you want to discard unsaved changes?',
      onClick: () => history.push('/trip/activity'),
      onClose: () => setConfirmationDialog(DEFAULT_CONFIRMATION_STATE),
      textPrimary: 'Discard',
      textSecondary: 'Cancel',
    })
  }

  const handleSubmit = () => {
    const isError = isErrorSubmit(values)
    if (isError) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      openSubmitConfirmation()
    }
  }

  const generateField = res => {
    if (res.type === 'text') {
      return (
        <TextFieldStyled
          type={res.type}
          fullWidth
          disabled={res.disabled}
          InputProps={{inputProps: {className: 'text-xs'}}}
          value={res.value}
          inputProps={{className: 'text-xs'}}
          placeholder={res.placeholder}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e.target.value, res.field)}
          multiline={res.textArea}
          rows={res.textArea && 8}
          error={values[res.field] === '' && error}
          helperText={
            values[res.field] === '' && error && 'This field is required'
          }
        />
      )
    } else if (res.type === 'async-select') {
      return (
        <>
          {res.extra}
          {!res.isHide && (
            <AsyncSelectStyled
              cacheOptions
              defaultOptions
              loadOptions={search => handleSearch(search, res.field)}
              marginNormal
              value={res.value}
              placeholder={res.placeholder}
              getOptionLabel={res.getOptionLabel}
              onChange={value => handleChange(value, res.field)}
              singleValueStyle={res.singleValueStyle}
            />
          )}
          {error && res.value === null && !res.isHide && (
            <Typography variant="caption" color="error">
              This field is required
            </Typography>
          )}
        </>
      )
    } else if (res.type === 'attachment') {
      return (
        <>
          <UploadButton
            multiple
            onChange={ev => handleChange(ev.target.files, res.field)}
          />

          <Box display="grid" gridGap={12} mt={1.5}>
            {res.value.map((file, idx) => {
              return (
                <AttachmentItem
                  key={idx}
                  name={file.name}
                  size={file.size}
                  url={file.url}
                  isUploading={!file.isUploaded}
                  progress={file.loaded}
                  onNameChange={ev => handleChangeNameFile(ev, idx)}
                  onRemove={() => handleDeleteAttachement(idx)}
                />
              )
            })}
          </Box>

          {errorFile && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              There are file types that are not allowed
            </FormHelperTextStyled>
          )}
          {errorSize && (
            <FormHelperTextStyled
              fileType={fileType}
              className="text-error mx-3"
            >
              Uploaded file size cannot exceed 25MB
            </FormHelperTextStyled>
          )}
        </>
      )
    } else if (res.type === 'select') {
      return (
        <>
          <SelectStyled
            filterOption={false}
            onChange={v => handleChange(v, res.field)}
            onInputChange={v => handleInputChange(v, res.field)}
            value={res.value}
            options={res.option}
            isSearchable={res.isSearchable}
            isLoading={res.isLoading}
            autoload={false}
            placeholder={res.placeholder}
            getOptionLabel={res.getOptionLabel}
            singleValueStyle={res.singleValueStyle}
            isDisabled={res.disabled}
          />
          {error && res.value === null && (
            <Typography variant="caption" color="error">
              This field is required
            </Typography>
          )}
        </>
      )
    }
  }

  return (
    <>
      <div className={`flex flex-col pt-8 px-8 pb-8`}>
        {fieldList.map(res => {
          return (
            <FormWrapper
              key={res.field}
              number={res.number}
              title={res.fieldName}
            >
              {generateField(res)}
            </FormWrapper>
          )
        })}
        {values.policy && (
          <FormDestination
            error={error}
            smallSize={smallSize}
            values={values}
            setValues={setValues}
            destination_type={
              values.policy?.business_trip_policy?.destination_type
            }
          />
        )}
        {values.policy && (
          <FormDate
            number="6."
            error={error}
            values={values}
            setValues={setValues}
            smallSize={smallSize}
          />
        )}
        {values.policy && (
          <FormBudget
            error={error}
            values={values}
            setValues={setValues}
            smallSize={smallSize}
            budget_component_option={
              values.policy?.business_trip_policy.budget_component_type
            }
          />
        )}
        {values.policy &&
          fieldList2.map(res => {
            return (
              <FormWrapper
                key={res.field}
                number={res.number}
                title={res.fieldName}
                titleComponent={res.titleComponent}
              >
                {generateField(res)}
              </FormWrapper>
            )
          })}
      </div>

      <Divider />

      <FormActionButton
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onCheckAnotherTrip={e => setIsSubmitAnotherTrip(e.target.checked)}
        isSubmitAnotherTrip={isSubmitAnotherTrip}
        isDisableSubmit={loadingSelf || isUploadingAttahments}
      />

      <ConfirmationDialog
        title={confirmationDialog.title}
        content={confirmationDialog.content}
        onClickPrimary={confirmationDialog.onClick}
        textPrimary={confirmationDialog.textPrimary}
        textSecondary={confirmationDialog.textSecondary}
        onClose={confirmationDialog.onClose}
        open={confirmationDialog.open}
        disable={loading}
      />
    </>
  )
}

const singleValueStyleParticipant = {
  '.wrap-option': {
    '.avatar': {
      display: 'none',
    },
    '.content': {
      '.hide-when-selected': {
        display: 'none',
      },
    },
  },
}

const singleValueStylePolicy = {
  '.wrap-option': {
    '.hide-when-selected': {
      display: 'none',
    },
  },
}

export const DEFAULT_DESTINATION_NAME = {from: '', to: ''}
export const DEFAULT_SELECTED = {from: null, to: null} // Selected Destination, form nomor 5
export const DEFAULT_BUDGET_COMPONENT = [
  {id: 1, type: null, nominal: 0, budget_calculation: 'multiply_by_days'},
]
export const DEFAULT_DESTINATION = [
  {
    id: 1,
    from: null,
    to: null,
    duration: null,
    start_date: null,
    end_date: null,
    selected: DEFAULT_SELECTED,
    name: DEFAULT_DESTINATION_NAME,
    budget: DEFAULT_BUDGET_COMPONENT,
  },
]
const DEFAULT_VALUES = {
  name: USER_NAME,
  position: POSITION_NAME,
  participant: null,
  policy: null,
  destination: DEFAULT_DESTINATION,
  start_date: new Date(),
  end_date: new Date(),
  description: '',
  attachments: [],
}
const DEFAULT_CONFIRMATION_STATE = {
  open: false,
  title: '',
  content: '',
  onClick: null,
  onClose: null,
  textPrimary: '',
  textSecondary: '',
}
