import React from "react";
import { StyledDialog } from "../../../components/modal/ModalStyles";
import CloseIcon from "@material-ui/icons/Close";

export default function ModalDetail({
  open,
  fullScreen,
  onClose,
  title,
  classes,
  maxWidth = "sm",
  fullWidth = true,
  ...child
}) {
  return (
    <StyledDialog
      classes={classes}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="body"
      fullScreen={fullScreen}
    >
      <div className="flex flex-col">
        <div
          className={`flex flex-row justify-between ${
            fullScreen ? "p-4" : "p-6"
          } bg-wlbgrey items-center`}
        >
          <div className="flex flex-row items-center">
            <h2 className={fullScreen ? null : "font-semibold"}>{title}</h2>
          </div>
          <CloseIcon className="cursor-pointer" onClick={onClose} />
        </div>

        <div {...child} />
      </div>
    </StyledDialog>
  );
}
