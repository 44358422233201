import gql from 'graphql-tag'

export const GET_LIST_THR = gql`
  query GetListThr(
    $offset: Int
    $limit: Int
    $order: order_by
    $period_start: timestamptz
    $period_end: timestamptz
    $year: [Int!]
  ) {
    thr_count: people_work_thr_aggregate(
      where: {
        year: {_in: $year}
        people_work_placement: {status: {_eq: "ACTIVE"}}
        process_step: {_eq: 5}
        _and: [
          {date_added: {_gte: $period_start}}
          {date_added: {_lte: $period_end}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    thr_data: people_work_thr(
      limit: $limit
      offset: $offset
      where: {
        year: {_in: $year}
        people_work_placement: {status: {_eq: "ACTIVE"}}
        process_step: {_eq: 5}
        _and: [
          {date_added: {_gte: $period_start}}
          {date_added: {_lte: $period_end}}
        ]
      }
      order_by: [{finance_thr_schedule: {schedule: $order}}]
    ) {
      finance_thr_schedule {
        id
        schedule
        religions
      }
      year
      id
      thr
      date_sent
      date_printed
      process_step
      approve_step
      people_work_placement {
        id
        regno
        company_job_profile {
          id
          title
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
        }
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
