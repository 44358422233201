import Button from "@material-ui/core/Button";
import React from "react";
import { ButtonStyled } from "../../../components/button/ButtonStyles";

export default function FormActionButton({
  onCancel,
  onSubmit,
  isDisableSubmit,
}) {
  return (
    <div className="flex flex-row justify-between p-8">
      <div className={`flex flex-row ml-auto justify-end`}>
        <ButtonStyled
          style={{ outline: 'none' }}
          text="#000"
          className="px-6 py-2 mr-2"
          onClick={onCancel}
        >
          Cancel
        </ButtonStyled>
        <Button
          variant="contained"
          color="primary"
          className="px-6 py-2"
          onClick={onSubmit}
          disabled={isDisableSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
