import React, {useState} from 'react'

import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {AttachmentCard} from '@smartasn/wlb-utils-components'

import {UPLOAD_TRIP_REPORT} from '../../../graphql/mutations'
import {FormattedDate} from '../../../utils/helpers'
import {handleUploadAttachment} from '../uploadFilesReport'
import {getErrorForm, setupRealization} from '../trip-report-helper'
import LoadingComponent from '../../shared-component/loading/LoadingComponent'
import ModalDetail from '../../shared-component/dialog/ModalDetail'

import {
  ItemDetail,
  SectionDetail,
} from '../../trip/trip-shared-component/DetailListTrip'
import {
  getDestination,
  getTripDate,
} from '../../trip/trip-shared-component/trip-helper'

import BudgetUsage from './BudgetUsage'
import EditBudgetUsage from './EditBudgetUsage'

export default function DetailListTripReport({
  open,
  fullScreen,
  onClose,
  loading,
  data,
  onClickMoreAttachment,
}) {
  const {enqueueSnackbar} = useSnackbar()

  const [error, setError] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [edit, setEdit] = useState(false)
  const [updateReport] = useMutation(UPLOAD_TRIP_REPORT)

  const PADDING_SECTION = 'p-6'

  const handleToggleEdit = () => setEdit(prev => !prev)

  const handleClose = () => {
    setEdit(false)
    onClose()
  }

  const handleUploadReport = async values => {
    setLoadingSubmit(true)

    const budget = setupRealization(values)
    const realization = await handleUploadAttachment(budget)

    updateReport({
      variables: {
        id_bustrip: values.trip_id,
        budget_action: values.budget_action,
        budget_realizations: realization,
      },
    })
      .then(() => {
        enqueueSnackbar('Apply trip report success', {variant: 'success'})
        setLoadingSubmit(false)
        setEdit(false)
      })
      .catch(e => enqueueSnackbar(JSON.stringify(e), {variant: 'error'}))
  }

  const handleSubmitEdit = values => {
    const isError = getErrorForm(values)
    if (isError) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      handleUploadReport(values)
    }
  }

  return (
    <ModalDetail
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      maxWidth="md"
      title="Trip Report Detail"
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className={PADDING_SECTION}>
            <ItemDetail fullScreen={fullScreen} label="Request ID">
              {data.id_display}
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Name">
              {data.globalUserByAddedBy?.name}
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Position">
              {
                data.peopleWorkPlacementByAddedByWorkId?.company_job_profile
                  ?.title
              }
            </ItemDetail>
            <ItemDetail fullScreen={fullScreen} label="Submission Date">
              {FormattedDate(data.date_added, 'MMM dd, yyyy')}
            </ItemDetail>
          </div>

          <div className="bg-wlbgrey pt-1" />

          <div className={PADDING_SECTION}>
            <div className="font-semibold text-sm text-primary mb-4">
              Trip Detail
            </div>

            <SectionDetail title="Trip Information">
              <ItemDetail
                semibold={false}
                fullScreen={fullScreen}
                label="Participants"
              >
                <div className="flex flex-col">
                  <span className="mb-1 font-semibold text-sm text-secondary">
                    {data.participant_name?.name}
                  </span>
                  {data.participant_position?.regno && (
                    <span className="mb-1 text-sm">
                      {data.participant_position?.regno}
                    </span>
                  )}
                  {data.participant_position?.company_profile && (
                    <span className="text-sm text-disable">
                      {data.participant_position?.company_job_profile?.title} at{' '}
                      {data.participant_position?.company_profile?.legal_name}
                    </span>
                  )}
                </div>
              </ItemDetail>
              <ItemDetail fullScreen={fullScreen} label="Description">
                {data.description || '-'}
              </ItemDetail>
              <ItemDetail fullScreen={fullScreen} label="Attachment">
                <div className="flex flex-row flex-wrap">
                  {data.attachment?.[0]
                    ? data.attachment?.map((file, idx) => (
                        <AttachmentCard
                          key={idx}
                          name={file.name}
                          url={file.url}
                        />
                      ))
                    : '-'}
                </div>
              </ItemDetail>
            </SectionDetail>

            <SectionDetail title="Destination">
              <ItemDetail fullScreen={fullScreen} label="Date">
                {getTripDate(data.date_start, data.date_end)}
              </ItemDetail>
              <ItemDetail fullScreen={fullScreen} label="Destination">
                {getDestination(data.destination)}
              </ItemDetail>
            </SectionDetail>
          </div>

          <div className="bg-wlbgrey pt-1" />

          <div className={PADDING_SECTION}>
            {edit ? (
              <EditBudgetUsage
                data={data}
                onSubmit={handleSubmitEdit}
                onCancel={handleToggleEdit}
                loadingSubmit={loadingSubmit}
                error={error}
                setError={setError}
              />
            ) : (
              <BudgetUsage
                data={data}
                onClickMoreAttachment={onClickMoreAttachment}
                fullScreen={fullScreen}
                onSetEdit={handleToggleEdit}
              />
            )}
          </div>
        </>
      )}
    </ModalDetail>
  )
}
