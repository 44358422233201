import React from 'react'
import {useMediaQuery, useTheme} from '@material-ui/core'

export default function ListItemModalFinance({title, ...value}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div className={`flex ${fullScreen ? 'flex-col' : 'flex-row'} mb-2`}>
      <span
        className={`${fullScreen ? 'text-sm' : 'text-xs'} font-semibold w-2/5`}
      >
        {title}
      </span>
      <span
        className={`${fullScreen ? 'mb-4 text-sm' : 'text-xs'} w-3/5`}
        {...value}
      />
    </div>
  )
}
