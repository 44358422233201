import gql from 'graphql-tag'

export const GET_LIST_TRIP_REPORT = gql`
  query GetListReportBusinessTrip(
    $userId: uuid
    $filter_date_start: timestamp
    $filter_date_end: timestamp
    $search: String
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        deletedAt: {_is_null: true}
        user_id: {_eq: $userId}
        status: {_eq: "approved"}
        report_status: {
          _in: ["reported", "verified", "verify", "unverified", "unverify"]
        }
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        _or: [
          {business_trip_policy: {name: {_ilike: $search}}}
          {id_display: {_ilike: $search}}
          {
            people_work_business_trip_destinations: {
              globalDestinationByFrom: {name: {_ilike: $search}}
            }
          }
          {
            people_work_business_trip_destinations: {
              globalDestinationByTo: {name: {_ilike: $search}}
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_business_trips(
      where: {
        deletedAt: {_is_null: true}
        user_id: {_eq: $userId}
        status: {_eq: "approved"}
        report_status: {
          _in: ["reported", "verified", "verify", "unverified", "unverify"]
        }
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        _or: [
          {business_trip_policy: {name: {_ilike: $search}}}
          {id_display: {_ilike: $search}}
          {
            people_work_business_trip_destinations: {
              globalDestinationByFrom: {name: {_ilike: $search}}
            }
          }
          {
            people_work_business_trip_destinations: {
              globalDestinationByTo: {name: {_ilike: $search}}
            }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{id: $sorting}]
    ) {
      id
      id_display
      date_start
      date_end
      date_added
      report_status
      business_trip_policy {
        id
        name
      }
    }
  }
`

export const GET_DETAIL_TRIP_REPORT = gql`
  query GetDetailReportBusinessTrip($id: Int) {
    people_work_business_trips(where: {id: {_eq: $id}}) {
      id
      id_display
      date_added
      currency
      approved_date
      description
      attachment
      report_status
      budget_diff_action
      globalUserByAddedBy {
        id
        name
      }
      business_trip_policy {
        id
        name
        currency_from
      }
      peopleWorkPlacementByAddedByWorkId {
        id
        company_job_profile {
          id
          title
        }
      }
      participant_name: global_user {
        id
        name
      }
      participant_position: people_work_placement {
        id
        regno
        company_profile {
          id
          legal_name
        }
        company_job_profile {
          id
          title
        }
      }
      date_start
      date_end
      status
      globalUserByApprovedBy {
        id
        name
      }
      destination: people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        duration
        destination_start_date
        destination_end_date
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
        budget: people_work_business_trip_destination_budgets(
          order_by: {business_trip_component: {name: asc}}
        ) {
          id
          business_trip_component {
            id
            name
            type
            description
            business_trip_component_type {
              id
              name
            }
          }
          payment_type
          people_profile_bank {
            id
            bank_name
            account_number
            account_name
          }
          nominal
          nominal_from
          nominal_to
          budget_calculation
          realization
          attachment: report_attachments
        }
      }
    }
  }
`

export const GET_TIRIP_REPORT_SELECTION = gql`
  query DropDownBusinessTrip($user_id: uuid, $search: String, $offset: Int) {
    total: people_work_business_trips_aggregate(
      where: {
        status: {_eq: "approved"}
        deletedAt: {_is_null: true}
        user_id: {_eq: $user_id}
        _not: {people_work_business_trip_reports: {}}
        report_status: {_eq: "unreported"}
        _or: [
          {id_display: {_ilike: $search}}
          {
            people_work_business_trip_destinations: {
              globalDestinationByFrom: {name: {_ilike: $search}}
            }
          }
          {
            people_work_business_trip_destinations: {
              globalDestinationByTo: {name: {_ilike: $search}}
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_business_trips(
      limit: 20
      offset: $offset
      order_by: [{date_start: desc}]
      where: {
        status: {_eq: "approved"}
        deletedAt: {_is_null: true}
        user_id: {_eq: $user_id}
        _not: {people_work_business_trip_reports: {}}
        report_status: {_eq: "unreported"}
        _or: [
          {id_display: {_ilike: $search}}
          {
            people_work_business_trip_destinations: {
              globalDestinationByFrom: {name: {_ilike: $search}}
            }
          }
          {
            people_work_business_trip_destinations: {
              globalDestinationByTo: {name: {_ilike: $search}}
            }
          }
        ]
      }
    ) {
      id
      id_display
      date_start
      date_end
      people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
      }
    }
  }
`

export const GET_TRIP_DETAIL_AUTO_FILLED = gql`
  query BusinessTripDetailAutoFilled($id: Int) {
    people_work_business_trips(where: {id: {_eq: $id}}) {
      id
      id_display
      approved_date
      currency
      start_date: date_start
      end_date: date_end
      business_trip_policy {
        id
        name
        currency_from
        currency_to
      }
      people_work_placement {
        id
        company_job_profile {
          id
          title
        }
        company_profile {
          id
          legal_name
        }
      }
      user_id
      destination: people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
        duration
        destination_start_date
        destination_end_date
        budget: people_work_business_trip_destination_budgets(
          order_by: {business_trip_component: {name: asc}}
        ) {
          id
          business_trip_component {
            id
            name
            type
            description
          }
          nominal
          nominal_from
          nominal_to
          budget_calculation
          realization
          attachment: report_attachments
        }
      }
      budget_diff_action
    }
  }
`
