import React from 'react'
import {Redirect} from 'react-router-dom'
import {generateRoutesFromConfigs, hasModule} from '../utils/helpers'
import {AttendanceConfig} from '../pages/attendance/AttendanceConfig'
import {OvertimeConfig} from '../pages/overtime/OvertimeConfig'
import {LeavePageConfig} from '../pages/leave/LeavePageConfig'
import {FinancialConfig} from '../pages/financial/FinancialConfig'
import {SalaryConfig} from '../pages/salary/SalaryConfig'
import {THRConfig} from '../pages/THR/THRConfig'
import {DocumentConfig} from '../pages/document/DocumentConfig'
import {TripConfig} from '../pages/trip/TripConfig'
import {TripReportConfig} from '../pages/trip-report/TripReportConfig'
import {ClaimConfig} from '../pages/claim/ClaimConfig'

const routes = data => {
  const routeConfigs = [DocumentConfig]

  if (hasModule(data, 'time')) {
    routeConfigs.push(AttendanceConfig, OvertimeConfig, LeavePageConfig)
  }
  if (hasModule(data, 'financial')) {
    routeConfigs.push(FinancialConfig, SalaryConfig, THRConfig, ClaimConfig)
  }
  if (hasModule(data, 'business_trip')) {
    routeConfigs.push(TripConfig, TripReportConfig)
  }

  return [
    ...generateRoutesFromConfigs(routeConfigs),
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/attendance" />,
    },
  ]
}

export default routes
