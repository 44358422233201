import React from 'react'
import {Button} from '@material-ui/core'

import {SectionDetail} from '../../trip/trip-shared-component/DetailListTrip'
import TableDetailRemainingMoney from './TableDetailRemainingMoney'
import TableDetailTripReport from './TableDetailTripReport'

import {getDestinationDate} from '../../trip/trip-shared-component/trip-helper'

export default function BudgetUsage({
  data,
  onClickMoreAttachment,
  fullScreen,
  onSetEdit,
}) {
  return (
    <>
      <div className="font-semibold text-sm text-primary mb-4">Budget</div>
      <SectionDetail title="Budget Usage">
        {data?.destination?.map(res => {
          return (
            <TableDetailTripReport
              key={res.id}
              data={res}
              status={data?.status}
              onClickMoreAttachment={onClickMoreAttachment}
              totalDestination={data?.destination?.length}
              date={getDestinationDate(
                res.destination_start_date,
                res.destination_end_date
              )}
              destination={`${res?.globalDestinationByFrom?.name} - ${res?.globalDestinationByTo?.name}`}
              currencyFrom={data.business_trip_policy.currency_from}
              currencyTo={data.currency}
              approvedDate={data.approved_date}
            />
          )
        })}
        <TableDetailRemainingMoney fullScreen={fullScreen} data={data} />
      </SectionDetail>

      {!['verify', 'verified'].includes(data?.report_status) && (
        <div className="flex justify-center w-full">
          <Button
            className="py-2 px-6"
            variant="contained"
            color="primary"
            disableElevation
            onClick={onSetEdit}
          >
            Edit Budget
          </Button>
        </div>
      )}
    </>
  )
}
