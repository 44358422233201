import Axios from 'axios'
import {TOKEN, UPLOAD_URL} from '../../utils/globals'
import {isValidURL} from '../../utils/helpers'

const constructVars = budgets => {
  return budgets.map(budget => {
    const _attch = budget.attachment.map(attachment => {
      return {
        url: attachment.url,
        name: attachment.name,
        size: attachment.size,
      }
    })
    // update key name from `attachment` to `report attachments`
    // see https://mm.worklifebeyond.com/devops/pl/rwfxh54u978o3jbfsrneeqhpwh
    return {
      id: budget.id,
      realization: budget.realization,
      report_attachments: _attch,
    }
  })
}

export const handleUploadAttachment = async budget => {
  const _budgets = [...(budget || [])]
  for (let idxBudget = 0; idxBudget < _budgets.length; idxBudget++) {
    const selectedBudget = _budgets[idxBudget]
    for (
      let idxAttachment = 0;
      idxAttachment < selectedBudget.attachment.length;
      idxAttachment++
    ) {
      const selectedAttachment = selectedBudget.attachment[idxAttachment]

      const isUrl = isValidURL(selectedAttachment.url)
      const url = isUrl
        ? selectedAttachment.url
        : await mutateUpload(selectedAttachment)

      _budgets[idxBudget].attachment[idxAttachment].url = url
    }
  }

  return constructVars(_budgets)
}

const mutateUpload = async val => {
  const formData = new FormData()
  formData.append('file', val.data)

  const response = await Axios.post(UPLOAD_URL, formData, {
    headers: {
      Authorization: 'Bearer ' + TOKEN,
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true
  })

  return await response.data.url
}
