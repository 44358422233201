import React from "react";
import DatePicker from "../../shared-component/date/DatePicker";
import FormWrapperInline from "../../shared-component/form/FormWrapperInline";

const isError = (value) => [null, "", undefined].includes(value);

export default function FormDate({ smallSize, values, setValues, error }) {
  const handleChange = (e, target) => {
    if (target === "start_date") {
      e && e.setHours(0, 0, 0, 0);
    } else if (target === "end_date") {
      e && e.setHours(23, 59, 59, 999);
    }
    setValues((prev) => {
      const destination = prev.destination.map((destination) => ({
        ...destination,
        duration: null,
        start_date: null,
        end_date: null,
      }));
      return {
        ...prev,
        [target]: e,
        destination,
        ...(target === "start_date" && { end_date: null }),
      };
    });
  };

  return (
    <div className="flex sm:flex-row flex-row w-full ">
      <div
        style={!smallSize ? { marginRight: 30 } : { marginRight: 0 }}
        className="font-semibold text-xs"
      >
        {!smallSize && "6."}
      </div>
      <div className="flex sm:flex-row flex-col justify-between w-full mb-5">
        <FormWrapperInline
          style={{ width: '100%' }}
          title="Trip Date*"
          className={`mt-2 mb-4 w-full`}
        >
          <DatePicker
            fullWidth={false}
            style={{width: '48%'}}
            placeholder="Add Start Date"
            className={`mt-2 mr-4`}
            value={values.start_date}
            onChange={(e) => handleChange(e, "start_date")}
            inputProps={{ className: "text-xs" }}
            error={error && isError(values.start_date)}
            helperText={
              error && isError(values.start_date) && "This field is required"
            }
          />
          
          <DatePicker
            fullWidth={false}
            style={{width: '48%'}}
            wrapperClassName="mb-auto"
            className="mt-2"
            placeholder="Add End Date"
            value={values.end_date}
            onChange={(e) => handleChange(e, "end_date")}
            inputProps={{ className: "text-xs" }}
            error={error && isError(values.end_date)}
            minDate={values.start_date}
            helperText={
              error && isError(values.end_date) && "This field is required"
            }
          />
        </FormWrapperInline>
      </div>
    </div>
  );
}
