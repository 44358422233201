import React from 'react'
import {Switch, Route} from 'react-router-dom'
import NotFoundComponent from '../pages/error/NotFoundComponent'
import styled from 'styled-components'
import routes from '../configs/routesConfig'
import HomepageComponent from '../pages/homepage/HomepageComponent'
import PrivateRoute from '../pages/private/PrivateRoute'
import NoRoles from '../pages/error/NoRoles'

const AppWrapper = styled.div``

const Main = ({modular, loading}) => {
  return (
    <AppWrapper>
      <Switch>
        {/* <Route exact={true} path="/" component={() => <HomepageComponent />} /> */}
        <PrivateRoute
          exact={true}
          path="/"
          component={() => <HomepageComponent modular={modular} />}
        />
        <Route path="/access-denied" component={NoRoles} />

        {routes(modular).map((val, key) => (
          <Route
            exact={val.exact}
            key={key}
            path={val.path}
            component={val.component}
          />
        ))}
        {!loading && <Route component={NotFoundComponent} />}
      </Switch>
    </AppWrapper>
  )
}

export default Main
