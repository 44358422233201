import React from "react";

export default function ContentTooltipFile() {
  return (
    <div className="flex flex-col">
      <span className="text-xs">Appropriate file extension:</span>
      <ul className="ml-4">
        <li className="list-disc">
          <div className="flex flex-row">
            <span className="text-xs">Document {"("}</span>
            <span className="text-xs text-secondary">
              doc, docx, pdf, xls, xlsx, ppt
            </span>
            <span className="text-xs">{")"}</span>
          </div>
        </li>
        <li className="list-disc">
          <div className="flex flex-row">
            <span className="text-xs">Image {"("}</span>
            <span className="text-xs text-secondary">jpg, jpeg, png, gif</span>
            <span className="text-xs">{")"}</span>
          </div>
        </li>
      </ul>
      <span className="text-xs mt-2">
        Max size: <span className="text-secondary">25MB</span>
      </span>
    </div>
  );
}
