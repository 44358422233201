import React, {useEffect} from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  DialogTitle,
  DialogContent,
  Divider,
} from '@material-ui/core'
// import {
//   ModalHeaderWhite,
//   ModalContent,
// } from '../../../components/modal/ModalStyles'
// import {IconStyled} from '../../../components/Icons/IconComponent'
// import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import CloseIcon from '@material-ui/icons/Close'
import {TypographyStyled} from '../../../components/typography/TypographyStyles'
import {ButtonStyled} from '../../../components/button/ButtonStyles'
import {
  InputAdornmentStyled,
  TextFieldStyled,
} from '../../../components/form/FormStyles'
import FormWrapper from '../../shared-component/form/FormWrapper'
import {useSnackbar} from 'notistack'
import {MUTATION_EDIT_DOCUMENT} from '../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'

function ModalEditName({handleClose, open, data}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {enqueueSnackbar} = useSnackbar()
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'))
  const [editDocument] = useMutation(MUTATION_EDIT_DOCUMENT)

  useEffect(() => {
    if (data) {
      setValues({
        name: data.name,
      })
    }
  }, [open, data])
  const [values, setValues] = React.useState({
    name: data && data.name,
  })

  const handleSubmit = () => {
    editDocument({
      variables: {
        id: data.id,
        name: values.name,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'user',
        },
      },
    })
      .then(() => {
        enqueueSnackbar(' Success', {
          variant: 'success',
        })
        handleClose()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const renderComponent = res => {
    const {type, value, inputProps, textArea, field, placeholder, error} = res
    if (type === 'text' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          value={inputProps ? parseInt(value) : value}
          variant="outlined"
          size="small"
          onChange={e => handleChange(e, field)}
          multiline={textArea}
          rows={textArea && 8}
          placeholder={placeholder}
          fullWidth
          style={{width: '100%'}}
          InputProps={
            inputProps
              ? {
                  inputProps: {className: 'text-xs', min: 0, maxLength: 254},
                  endAdornment: (
                    <InputAdornmentStyled position="end" disableTypography>
                      <div
                        style={{
                          borderLeft: '1px solid rgba(0, 0, 0, 0.27)',
                          paddingLeft: 13,
                          display: 'flex',
                          alignItems: 'center',
                          height: 34,
                          marginleftt: 6,
                        }}
                      >
                        <p style={{fontSize: 14, color: '#a9a8a8'}}>Hours</p>
                      </div>
                    </InputAdornmentStyled>
                  ),
                }
              : {
                  inputProps: {className: 'text-xs', maxLength: 254},
                }
          }
          error={field === 'name' && values[field] === '' && error}
          helperText={
            field === 'name' &&
            values[field] === '' &&
            error &&
            'This field is required'
          }
          classes={
            placeholder && {
              root: 'text-xs',
            }
          }
        />
      )
    }
  }

  const fieldList = [
    {
      number: !smallSize && '',
      fieldName: 'File Name',
      field: 'name',
      type: 'text',
      value: values.name,
    },
  ]

  const handleChange = (e, target) => {
    setValues({
      ...values,
      [target]: e.target.value,
    })
  }

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      fullScreen={fullScreen}
    >
      <DialogTitle style={{backgroundColor: '#eff2f4'}}>
        <div className="flex justify-between items-center">
          <TypographyStyled variant="h6" style={{fontSize: 16}} weight="600">
            Rename
          </TypographyStyled>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
            // className="md:hidden lg:hidden"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: 'p-0'}}>
        <div className={`pt-8 sm:px-8 px-6 flex flex-col`}>
          {fieldList.map((res, i) => (
            <FormWrapper
              forMobile={smallSize}
              key={i}
              number={res.number}
              title={res.fieldName}
            >
              {renderComponent(res)}
            </FormWrapper>
          ))}
        </div>
      </DialogContent>

      <Divider />
      <div
        className="py-8 sm:px-8 px-6 flex flex-row ml-auto"
        style={{justifyContent: 'flex-end'}}
      >
        <ButtonStyled
          onClick={() => handleClose()}
          text="#000"
          className="px-6 py-2"
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          onClick={handleSubmit}
          state="primary"
          className="px-6 py-2 ml-2"
        >
          Submit
        </ButtonStyled>
      </div>
    </Dialog>
  )
}

export default ModalEditName
