import React, {useState, useEffect} from 'react'
import {Popover, Button} from '@material-ui/core'
import {
  WrapperFilter,
  WrapperLeftFilter,
  WrapperRightFilter,
} from './FilterWLBStyled'
import {KeyboardDatePickerStyled} from '../../../components/form/FormStyles'
import Divider from '../../../components/divider/DividerStyles'
import {useLazyQuery} from '@apollo/react-hooks'
import {
  GET_LIST_ATTENDANCE_POLICY_FILTER,
  GET_LIST_POLICY_FILTER,
  GET_LIST_REQUESTER_FILTER,
  GET_LIST_PEOPLE_LEAVE_REQUEST,
  GET_LIST_POLICY_REQUEST_FILTER,
  GET_LIST_POLICY_OVERTIME,
  GET_LIST_REQUESTER_OVERTIME_REQUEST_FILTER,
  GET_LIST_POLICY_OVERTIME_FILTER,
} from '../../../graphql/queries'
import DatePickerWrapper from '../date/DatePickerWrapper'
import CheckboxFilterComponent from './CheckboxFilter'
import {USER_ID} from '../../../utils/globals'

const Style = {
  buttonActive: {
    backgroundColor: '#014a62',
    color: '#fff',
  },
  buttonInactive: {
    backgroundColor: '#fff',
    color: '#000',
  },
  countActive: {
    backgroundColor: '#fff',
    color: '#014a62',
  },
  countInactive: {
    backgroundColor: '#014a62',
    color: '#fff',
  },
}

const _generateComponent = (
  active,
  setCheck,
  check,
  dataPolicy,
  textPolicy,
  setTextPolicy,
  date,
  setDate,
  textRequester,
  setTextRequester,
  checkRequester,
  setCheckRequester,
  requesterList,
  status,
  setStatus,
  initialStatus,
  textStatus,
  setTextStatus,
  initialTextStatus,
  setinitialTextStatus
) => {
  if (active.type === 'policy') {
    return (
      <CheckboxFilterComponent
        value={textPolicy}
        onChangeSearch={e => setTextPolicy(e.target.value)}
        onSelectAll={() => setCheck(dataPolicy)}
        onReset={() => setCheck([])}
        data={dataPolicy}
        check={check}
        setCheck={setCheck}
      />
    )
  } else if (active.type === 'date') {
    const handleChangeDate = (e, target) => {
      setDate(date => ({...date, [target]: e}))
    }

    return (
      <div className="flex flex-col px-4">
        <div className="flex flex-row justify-between items-center mb-3">
          <h6 className="text-xs font-semibold">Pick a date</h6>
          <h6
            onClick={() => setDate(date => ({...date, from: null, to: null}))}
            className="text-xs text-disable cursor-pointer"
          >
            Reset
          </h6>
        </div>
        <div className="flex flex-col mb-3">
          <div className="mb-1 text-xs font-semibold">Form</div>
          <DatePickerWrapper
            classes={{
              root: 'h-8 bg-white',
            }}
          >
            <KeyboardDatePickerStyled
              InputProps={{
                classes: {
                  root: ' h-8 bg-white',
                },
                style: {backgroundColor: '#fff', fontSize: 12},
              }}
              value={date.from}
              inputVariant="outlined"
              format="MMMM dd, yyyy"
              size="small"
              space="0px"
              onChange={e => handleChangeDate(e, 'from')}
              // error={date.from === null && error.date}
              // helperText={
              //   date.from === null && error.date && 'This field is required'
              // }
            />
          </DatePickerWrapper>
        </div>
        <div className="flex flex-col ">
          <div className="mb-1 text-xs font-semibold">To</div>
          <DatePickerWrapper
            classes={{
              root: 'h-8 bg-white',
            }}
          >
            <KeyboardDatePickerStyled
              InputProps={{
                classes: {
                  root: ' h-8 bg-white',
                },
                style: {backgroundColor: '#fff', fontSize: 12},
              }}
              value={date.to}
              inputVariant="outlined"
              format="MMMM dd, yyyy"
              size="small"
              space="0px"
              onChange={e => handleChangeDate(e, 'to')}
              // error={date.to === null && error.date}
              // helperText={
              //   date.to === null && error.date && 'This field is required'
              // }
            />
          </DatePickerWrapper>
        </div>
      </div>
    )
  } else if (active.type === 'requester') {
    return (
      <CheckboxFilterComponent
        value={textRequester}
        onChangeSearch={e => setTextRequester(e.target.value)}
        onSelectAll={() => setCheckRequester(requesterList)}
        onReset={() => setCheckRequester([])}
        data={requesterList}
        check={checkRequester}
        setCheck={setCheckRequester}
      />
    )
  } else if (active.type === 'status') {
    const newStatus = [...status]
    const newInitial = [...initialTextStatus]
    const handleChangeText = e => {
      setTextStatus(e)
      if (textStatus === '') {
        setinitialTextStatus(initialStatus)
      } else {
        const results = newInitial.filter(status =>
          status.name.toLowerCase().includes(e)
        )
        setinitialTextStatus(results)
      }
    }
    return (
      <CheckboxFilterComponent
        value={textStatus}
        onChangeSearch={e => handleChangeText(e.target.value)}
        onSelectAll={() => setStatus(initialStatus)}
        onReset={() => setStatus([])}
        data={initialTextStatus}
        check={newStatus}
        setCheck={setStatus}
      />
    )
  } else {
    return 'Default'
  }
}
const ListItem = ({count, active, onClick, ...child}) => {
  return (
    <Button
      classes={{
        label: 'flex justify-between px-2 text-xs',
        root: 'rounded-none',
      }}
      fullWidth
      style={active ? Style.buttonActive : Style.buttonInactive}
      onClick={() => onClick()}
    >
      <span {...child} />{' '}
      {count !== 0 && (
        <span
          className="rounded-full h-5 px-2 w-auto flex items-center justify-center text-xs"
          style={active ? Style.countActive : Style.countInactive}
        >
          {count}
        </span>
      )}
    </Button>
  )
}

const LeftComponent = ({
  active,
  setActive,
  components,
  onApply,
  count,
  handleResetAll,
  onCancel,
}) => {
  return (
    <WrapperLeftFilter>
      <div>
        {components.map((list, i) => (
          <ListItem
            key={i}
            onClick={() => setActive(list)}
            active={active && active.idx === list.idx}
            count={count[i]}
          >
            {list.name}
          </ListItem>
        ))}
      </div>
      <Divider className="mt-auto" />
      <div className="action flex flex-row px-3 pt-2">
        <p
          onClick={handleResetAll}
          className="reset-all text-xs cursor-pointer"
        >
          Reset All
        </p>
        <div className="cancel-apply ml-auto flex flex-row">
          <p onClick={onCancel} className="text-xs text-disable cursor-pointer">
            Cancel
          </p>
          <p
            onClick={onApply}
            className="text-xs text-secondary cursor-pointer ml-3"
          >
            Apply
          </p>
        </div>
      </div>
    </WrapperLeftFilter>
  )
}
const RightComponent = ({
  active,
  setCheck,
  check,
  dataPolicy,
  textPolicy,
  setTextPolicy,
  date,
  setDate,
  textRequester,
  setTextRequester,
  checkRequester,
  setCheckRequester,
  requesterList,
  status,
  setStatus,
  initialStatus,
  textStatus,
  setTextStatus,
  initialTextStatus,
  setinitialTextStatus,
}) => {
  return (
    <WrapperRightFilter>
      {active !== null &&
        _generateComponent(
          active,
          setCheck,
          check,
          dataPolicy,
          textPolicy,
          setTextPolicy,
          date,
          setDate,
          textRequester,
          setTextRequester,
          checkRequester,
          setCheckRequester,
          requesterList,
          status,
          setStatus,
          initialStatus,
          textStatus,
          setTextStatus,
          initialTextStatus,
          setinitialTextStatus
        )}
    </WrapperRightFilter>
  )
}

export default function FilterWLB({
  id,
  open,
  anchorEl,
  onClose,
  components,
  idWork,
  withPolicy = false,
  withRequester = false,
  from,
  onApply,
}) {
  const initialStatus = [
    {id: 1, name: 'Waiting'},
    {id: 2, name: 'Approved'},
    {id: 3, name: 'Rejected'},
    {id: 4, name: 'Cancelled'},
  ]
  const [active, setActive] = useState(components[0])
  const [check, setCheck] = useState([])
  const [status, setStatus] = useState([])
  const [checkRequester, setCheckRequester] = useState([])
  const [date, setDate] = useState({
    from: null,
    to: null,
  })
  const [requesterList, setRequesterList] = useState(undefined)
  const [textPolicy, setTextPolicy] = useState('')
  const [textRequester, setTextRequester] = useState('')
  const [textStatus, setTextStatus] = useState('')
  const [initialTextStatus, setinitialTextStatus] = useState(initialStatus)

  let query = null
  let requester = null
  let count = []
  let bridge = 'time_attendance_policies'
  if (from === 'attendance') {
    count = [check.length, 0]
    query = GET_LIST_ATTENDANCE_POLICY_FILTER
    bridge = 'time_attendance_policies'
  } else if (from === 'leave-activity') {
    count = [check.length, status.length, checkRequester.length, 0]
    query = GET_LIST_POLICY_FILTER
    bridge = 'time_leave_policy_children'
    requester = GET_LIST_REQUESTER_FILTER
  } else if (from === 'leave-request') {
    count = [check.length, status.length, checkRequester.length, 0]
    query = GET_LIST_POLICY_REQUEST_FILTER
    requester = GET_LIST_PEOPLE_LEAVE_REQUEST
    bridge = 'time_leave_policy_children'
  } else if (from === 'overtime-activity') {
    count = [check.length, status.length, 0]
    query = GET_LIST_POLICY_OVERTIME
    requester = GET_LIST_PEOPLE_LEAVE_REQUEST
    bridge = 'time_overtime_policies'
  } else if (from === 'overtime-request') {
    count = [check.length, status.length, checkRequester.length, 0]
    query = GET_LIST_POLICY_OVERTIME_FILTER
    requester = GET_LIST_REQUESTER_OVERTIME_REQUEST_FILTER
    bridge = 'time_overtime_policies'
  }

  const [getListPolicy, {data: dataPolicy, error: errorQuery}] = useLazyQuery(
    query
  )
  const [
    getListRequester,
    {data: dataRequester, error: errorRequester},
  ] = useLazyQuery(requester)

  // console.log("dataPolicy : ", dataPolicy)
  // console.log("errorQuery : ", errorQuery)

  useEffect(() => {
    if (idWork && withPolicy) {
      getListPolicy(
        from === 'overtime-activity'
          ? {
              variables: {
                user: USER_ID,
                search: `%${textPolicy}%`,
              },
            }
          : {
              variables: {
                userWorkId: idWork,
                search: `%${textPolicy}%`,
              },
            }
      )
    }
  }, [idWork, textPolicy])

  // ?
  // :

  useEffect(() => {
    if (textStatus === '') {
      setinitialTextStatus(initialStatus)
    }
  }, [textStatus])

  useEffect(() => {
    if (idWork && withRequester) {
      getListRequester({
        variables: {
          userWorkId: idWork,
          search: `%${textRequester}%`,
        },
      })
    }
    if (dataRequester) {
      const requester = []
      dataRequester.people_work_placements.forEach(people => {
        requester.push({
          id: people.filter_people_id,
          name: people.global_user.name,
        })
      })
      setRequesterList(requester)
    }
  }, [idWork, textRequester, dataRequester])

  // console.log("errorRequester : ", errorRequester)
  // console.log("dataRequester : ", dataRequester)
  // console.log("requesterList : ", requesterList)
  // console.log("textRequester : ", textRequester)
  if (errorQuery || errorRequester) {
    return (
      <span>
        {errorQuery}
        {errorRequester}
      </span>
    )
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: 'shadow-wlb',
      }}
    >
      <WrapperFilter>
        <LeftComponent
          setActive={setActive}
          active={active}
          components={components}
          count={count}
          handleResetAll={() => {
            setCheck([])
            setStatus([])
            setDate({
              from: null,
              to: null,
            })
            setCheckRequester([])
          }}
          onCancel={onClose}
          onApply={() => onApply(check, date, checkRequester, status)}
        />
        <RightComponent
          dataPolicy={dataPolicy && dataPolicy[bridge]}
          requesterList={requesterList}
          components={components}
          active={active}
          setCheck={setCheck}
          check={check}
          textPolicy={textPolicy}
          setTextPolicy={setTextPolicy}
          date={date}
          setDate={setDate}
          textRequester={textRequester}
          setTextRequester={setTextRequester}
          checkRequester={checkRequester}
          setCheckRequester={setCheckRequester}
          status={status}
          setStatus={setStatus}
          initialStatus={initialStatus}
          textStatus={textStatus}
          setTextStatus={setTextStatus}
          initialTextStatus={initialTextStatus}
          setinitialTextStatus={setinitialTextStatus}
        />
      </WrapperFilter>
    </Popover>
  )
}
