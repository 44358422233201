import gql from 'graphql-tag'

export const GET_LIST_ANNUAL_HOLIDAY = gql`
  query getTimeHoliday($company: uuid, $date_start: date, $date_end: date) {
    time_holidays(
      where: {
        company: {_eq: $company}
        date: {_gte: $date_start, _lte: $date_end}
        deletedAt: {_is_null: true}
      }
      order_by: {date: asc}
    ) {
      id
      date
      name
      description
      type
    }
  }
`
