import React, {useState} from 'react'
import Divider from '../../../../components/divider/DividerStyles'
import {useQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../../../utils/globals'
import {
  generateDate,
  getDifferenceDateInMinutes,
} from '../../../../utils/helpers'
import {GET_LIST_STATUS_OVERTIME} from '../../../../graphql/queries'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'

export default function Status() {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const {data, error} = useQuery(GET_LIST_STATUS_OVERTIME, {
    variables: {
      user: USER_ID,
    },
  })

  if (error) {
    return <span>{error}</span>
  }

  const generateTotalTime = datas => {
    let totalTime = 0
    datas.forEach(data => {
      totalTime += getDifferenceDateInMinutes(data.dateend, data.datestart) / 60
    })
    return totalTime
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="flex justify-between flex-col" style={{minHeight: 500}}>
      <div>
        {data ? (
          <div className="sm:px-8 px-4 flex flex-col">
            <div className="py-4 flex flex-row justify-between items-center">
              <h5 className="text-sm font-semibold">Total Overtime</h5>
              <h5 className="text-md text-secondary font-semibold">
                {generateTotalTime(data.people_work_overtimes).toFixed(2)}h
              </h5>
            </div>
            <Divider />
            {data.people_work_overtimes.length > 0
              ? data.people_work_overtimes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((res, i) => {
                    const time =
                      getDifferenceDateInMinutes(res.dateend, res.datestart) /
                      60
                    return (
                      <div key={i}>
                        <div className="flex flex-row justify-between items-center py-4 ">
                          <div>
                            <h5 className="text-sm font-semibold text-primary mb-2">
                              {res.time_overtime_policy.name}
                            </h5>
                            <h6 className="text-disable text-xs font-semibold">
                              {generateDate(res.datestart)}
                            </h6>
                          </div>
                          <h5 className="text-md text-primary font-semibold">
                            {time.toFixed(2)}h
                          </h5>
                        </div>
                        <Divider />
                      </div>
                    )
                  })
              : ''}
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>
      <TablePaginationStyled
        className="mt-auto"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data && data.people_work_overtimes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        classes={{
          actions: 'ml-1',
          caption: 'md:text-sm text-xs text-disable',
          input: 'md:text-sm text-xs text-disable',
        }}
      />
    </div>
  )
}
