import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { SearcableSelectField } from "./FormStyle";
import {
  Popover,
  TextField,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { useGetDimensions } from "../advance-filter/hooks/useGetDimensions";
import SearchIcon from "@material-ui/icons/Search";
import { WrapperScroll } from "../../../components/paper/Wrapper";

const useStyles = makeStyles(() => ({
  inputSearch: {
    padding: "10px 14px",
    fontSize: 12,
    backgroundColor: "#f7f8f9",
  },
  searchIcon: { fontSize: 18, color: "#014a62" },
  textField: { background: "#f7f8f9 !important" },
}));

export default function SearchableSelect({
  id = "",
  placeholder,
  onSearch,
  value,
  onOpen,
  options,
  errorRequired,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ref, { width }] = useGetDimensions();

  const handleClick = (event) => {
    onOpen();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const _id = open ? `searchable_select_${id}` : undefined;
  return (
    <>
      <div>
        <SearcableSelectField
          ref={ref}
          aria-describedby={_id}
          onClick={handleClick}
          borderColor={errorRequired && "#ef4d5e"}
        >
          <p className={value ? "value" : "placeholder"}>{value || placeholder}</p>
          <ArrowDropDownIcon className="icon" />
        </SearcableSelectField>
        {errorRequired && (
          <span className="text-xs text-danger ml-2">
            This field is required
          </span>
        )}
      </div>

      <Popover
        id={_id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: "shadow-md" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div style={{ width }} className="p-4">
          <TextField
            fullWidth
            className="mb-4"
            placeholder="Search"
            variant="filled"
            onChange={(e) => onSearch(e.target.value)}
            autoFocus={open}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.textField, input: classes.inputSearch },
              startAdornment: (
                <InputAdornment className="mt-0" position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
          <WrapperScroll maxHeight="190px">
            {options(handleClose)}
          </WrapperScroll>
        </div>
      </Popover>
    </>
  );
}
