import gql from 'graphql-tag'

export const GET_LIST_TRIP_ACTIVITY = gql`
  query getListBusinessTripActivity(
    $userId: uuid
    $search: String
    $status: [String!]
    $filter_date_start: timestamp
    $filter_date_end: timestamp
    $limit: Int
    $offset: Int
    $sorting: order_by
  ) {
    total: people_work_business_trips_aggregate(
      where: {
        deletedAt: {_is_null: true}
        user_id: {_eq: $userId}
        status: {_in: $status}
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        _or: [
          {id_display: {_ilike: $search}}
          {business_trip_policy: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_business_trips(
      where: {
        deletedAt: {_is_null: true}
        user_id: {_eq: $userId}
        status: {_in: $status}
        date_added: {_gte: $filter_date_start, _lte: $filter_date_end}
        _or: [
          {id_display: {_ilike: $search}}
          {business_trip_policy: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{id: $sorting}]
    ) {
      id
      user_id
      id_display
      date_start
      date_end
      status
      date_added
      business_trip_policy {
        id
        name
      }
    }
  }
`

export const GET_DETAIL_TRIP = gql`
  query getDetail($id: Int) {
    people_work_business_trips(where: {id: {_eq: $id}}) {
      id
      id_display
      globalUserByAddedBy {
        id
        name
      }
      peopleWorkPlacementByAddedByWorkId {
        id
        company_job_profile {
          id
          title
        }
      }
      date_added
      currency
      approved_date
      status
      business_trip_policy {
        id
        name
        currency_from
        confirm_type
        companyJobProfileByFirstConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
        companyJobProfileBySecondConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
        companyJobProfileByThirdConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      participant_name: global_user {
        id
        name
      }
      participant_position: people_work_placement {
        id
        regno
        company_profile {
          id
          legal_name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_2: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
              spv_3: job_profile_supervisor {
                id
                title
                people_work_placements(
                  where: {status: {_eq: "ACTIVE"}}
                  limit: 1
                ) {
                  id
                  global_user {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      description
      attachment
      date_start
      date_end
      globalUserByCancelledBy {
        id
        name
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      globalUserByApprovedBy {
        id
        name
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      business_trip_fields
      total_destination: people_work_business_trip_destinations_aggregate {
        aggregate {
          count
        }
      }
      destination: people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
        duration
        destination_start_date
        destination_end_date
        budget: people_work_business_trip_destination_budgets(
          order_by: {business_trip_component: {name: asc}}
        ) {
          id
          business_trip_component {
            id
            name
          }
          nominal
          payment_type
          status
          budget_calculation
          people_profile_bank {
            id
            bank_name
            account_number
            account_name
          }
          attachment
        }
      }
    }
  }
`
