import gql from 'graphql-tag'

export const GET_CLAIM_POLICY_FILTER_LIST = gql`
  query($where: company_job_profile_r_claim_bool_exp, $limit: Int) {
    total: company_job_profile_r_claim_aggregate(
      where: $where
      distinct_on: [policy]
    ) {
      aggregate {
        count
      }
    }
    policies: company_job_profile_r_claim(
      where: $where
      distinct_on: [policy]
      limit: $limit
    ) {
      policy: finance_claim_policy_child {
        id
        name
      }
    }
  }
`

export const GET_CLAIM_ACTIVITY_LIST = gql`
  query(
    $policies: [Int!]
    $statuses: [String!]
    $startDate: timestamp
    $endDate: timestamp
    $userId: uuid!
    $search: String
    $orderBy: order_by
    $limit: Int
    $offset: Int
  ) {
    total: people_work_claims_aggregate(
      where: {
        people_work_placement: {global_user: {id: {_eq: $userId}}}
        formatted_id: {_ilike: $search}
        policy_id: {_in: $policies}
        status: {_in: $statuses, _nin: ["Pending"]}
        date_added: {_gte: $startDate, _lte: $endDate}
      }
    ) {
      aggregate {
        count
      }
    }
    claims: people_work_claims(
      where: {
        people_work_placement: {global_user: {id: {_eq: $userId}}}
        formatted_id: {_ilike: $search}
        policy_id: {_in: $policies}
        status: {_in: $statuses, _nin: ["Pending"]}
        date_added: {_gte: $startDate, _lte: $endDate}
      }
      order_by: {id: $orderBy}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      status
      nominal: value
      date_issued
      date_added
      policy: finance_claim_policy_child {
        id
        name
      }
      invoice: global_invoice {
        id
        code
        module_name
        final_price
      }
    }
  }
`

export const GET_CLAIM_REQUEST_LIST = gql`
  query(
    $filter: ClaimRequestsFilterInput
    $policies: JSON!
    $statuses: JSON!
    $userId: String!
    $search: String
    $sortBy: String
    $limit: Int
    $offset: Int
  ) {
    requests: getClaimRequests(
      filter: $filter
      policy_id: $policies
      status: $statuses
      user_id: $userId
      search: $search
      sort_by: $sortBy
      limit: $limit
      offset: $offset
    ) {
      total: total_data
      claims: people_work_claims {
        id
        formatted_id
        status
        nominal: value
        date_issued
        date_added
        can_approve
        policy: finance_claim_policy_child {
          id
          name
        }
      }
    }
  }
`

export const GET_CLAIM_DETAILS = gql`
  query($claimId: bigint!) {
    details: people_work_claims_by_pk(id: $claimId) {
      id
      formatted_id
      status
      nominal: value
      date_added
      date_issued
      description
      attachments
      proofs
      claim_fields
      approved_by
      bank: people_profile_bank {
        id
        account_number
        account_name
        provider: global_bank {
          id
          name
        }
      }
      employee: people_work_placement {
        id
        user: global_user {
          id
          name
        }
        profile: company_job_profile {
          id
          title
          supervisor: job_profile_supervisor {
            id
            title
            placements: people_work_placements(
              where: {status: {_eq: "ACTIVE"}}
              limit: 1
            ) {
              id
              user: global_user {
                id
                name
              }
            }
            supervisor: job_profile_supervisor {
              id
              title
              placements: people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                user: global_user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      policy: finance_claim_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
        }
        second_job_profile {
          id
          title
        }
      }
      invoice: global_invoice {
        id
        code
        final_price
        module_name
      }
    }
  }
`

export const GET_CLAIM_INVOICE_EXPORTS = gql`
  query($invoiceId: uuid!) {
    invoice_exports: global_invoice_exports(
      where: {invoice_id: {_eq: $invoiceId}}
    ) {
      id
      invoice_id
      url
    }
  }
`

export const GET_CLAIM_REQUESTER_FILTER_LIST = gql`
  query($where: people_work_placements_bool_exp, $limit: Int) {
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    placements: people_work_placements(
      where: $where
      order_by: {global_user: {name: asc}}
      limit: $limit
    ) {
      id
      user: global_user {
        id
        name
      }
    }
  }
`

export const GET_CLAIM_USER_DETAILS = gql`
  query($userId: uuid!, $positionId: Int!) {
    user: global_users_by_pk(id: $userId) {
      id
      name
    }
    position: company_job_profiles_by_pk(id: $positionId) {
      id
      title
    }
  }
`
