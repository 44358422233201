import React, {useState} from 'react'
import {
  MenuItemStyled,
  TextFieldStyledNoLine,
} from '../../../../components/form/FormStyles'
import ListItem from '../../../shared-component/list/ListItem'
import {TablePaginationStyled} from '../../../../components/pagination/PaginationStyles'
import CancelDialog from '../../../shared-component/dialog/CancelDialog'
import {GET_LIST_ACTIVITY} from '../../../../graphql/queries/overtime/getListActivity'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  addDay,
  generateDate,
  generateDateSubmit,
} from '../../../../utils/helpers'
import ModalDetailWeb from '../../../shared-component/dialog/ModalDetailWeb'
import DetailList from '../../../shared-component/dialog/DetailList'
import LoadingComponent from '../../../shared-component/loading/LoadingComponent'
import {CANCEL_APPLY_OVERTIME} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import FilterWLB from '../../../shared-component/Filter/FilterWLB'
import IconFilterActive from '../../../shared-component/Filter/IconFilterActive'
import TextfieldSearch from '../../../shared-component/form/TextfieldSearch'
import EmptyState from '../../../shared-component/empty-state/EmptyStateComponent'
import NoListImage from '../../../../assets/images/nodatalist.png'
import {PAGINATION_RANGE} from '../../../../utils/constants'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'

export default function Activity({dataWork, salary}) {
  const {enqueueSnackbar} = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    rowsPerPage: PAGINATION_RANGE[0],
    page: 0,
  })
  const [detail, setDetail] = useState({
    open: false,
    openWeb: false,
    data: null,
  })

  const [openCancelSubmission, setOpenCancelSubmission] = useState({
    open: false,
    id: null,
  })

  const [sort, setSort] = useState('desc')
  const [filter, setFilter] = useState({
    policy: [],
    date: {
      dateStart: null,
      dateEnd: null,
    },
    status: {
      status: [],
      second: true,
    },
    requester: [],
  })

  const {data, error, refetch, loading} = useQuery(GET_LIST_ACTIVITY, {
    variables: {
      search: `%${search}%`,
      user: USER_ID,
      company: COMPANY_ID,
      sorting: sort,
      idPolicy: filter.policy.length === 0 ? null : filter.policy,
      dateStart: filter.date.dateStart,
      dateEnd: addDay(filter.date.dateEnd, 1),
      status: filter.status.status.length === 0 ? null : filter.status.status,
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
    },
    context: {headers: {'X-Hasura-Role': 'user'}},
    fetchPolicy: 'no-cache',
  })

  const [cancelApplyOvertime] = useMutation(CANCEL_APPLY_OVERTIME)

  if (error) {
    return JSON.stringify(error)
  }

  const handleClick = res => {
    setDetail({open: false, openWeb: true, data: res})
  }

  const handleCancelSubmission = id => {
    setDetail({...detail, open: false, openWeb: false})
    setOpenCancelSubmission({
      open: true,
      id: id,
    })
  }

  const cancelNow = id => {
    cancelApplyOvertime({
      variables: {
        id,
      },
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Submission Canceled', {variant: 'success'})
        setOpenCancelSubmission({
          open: false,
          id: null,
        })
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const handleQueryFiltered = (policy, date, status, requester) => {
    const idPolicy = []
    const statusStg = []
    let second = true
    if (policy.length > 0) {
      policy.forEach(pol => {
        idPolicy.push(pol.id)
      })
    }
    status.forEach(stat => {
      if (stat.id === 1) {
        statusStg.push('pending')
        statusStg.push('confirm1')
        second = false
      } else if (stat.id === 2) {
        statusStg.push('approved')
        second = false
      } else if (stat.id === 3) {
        statusStg.push('rejected')
        second = false
      } else if (stat.id === 4) {
        statusStg.push('cancelled')
      }
    })
    setFilter({
      ...filter,
      policy: idPolicy,
      date: {
        dateStart: generateDateSubmit(date.from),
        dateEnd: generateDateSubmit(date.to),
      },
      status: {
        status: statusStg,
        second: second,
      },
      requester,
    })
    setAnchorEl(null)
  }

  const openFilter = Boolean(anchorEl)
  const idFilter = openFilter ? 'filter-overtime-activity-list' : undefined
  const {
    policy,
    date: {dateStart, dateEnd},
    status: {status},
    requester,
  } = filter

  return (
    <>
      <div className="flex flex-col px-0 min-h-screen">
        <div
          className="py-4 px-6 flex flex-row justify-between items-center"
          style={{borderBottom: '1px solid #f7f8f9'}}
        >
          <TextFieldStyledNoLine
            value={sort}
            onChange={e => setSort(e.target.value)}
            select
            style={{width: 'auto'}}
            size="small"
          >
            <MenuItemStyled value="desc">Latest</MenuItemStyled>
            <MenuItemStyled value="asc">Oldest</MenuItemStyled>
          </TextFieldStyledNoLine>
          <div
            className="cursor-pointer text-sm items-center flex flex-row"
            onClick={e => setAnchorEl(e.currentTarget)}
            aria-describedby={idFilter}
          >
            <IconFilterActive
              isFiltered={
                policy[0] || dateStart || dateEnd || status[0] || requester[0]
              }
            />
            Filter
          </div>
        </div>
        <TextfieldSearch
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div>
          {loading ? (
            <LoadingComponent />
          ) : !loading && data && data.people_work_overtimes.length > 0 ? (
            data.people_work_overtimes.map((res, i) => {
              const startdate = generateDate(res.datestart)
              const enddate = generateDate(res.dateend)
              return (
                <ListItem
                  onClick={() => handleClick(res)}
                  key={i}
                  idDisplay={res.id_display}
                  time={
                    startdate === enddate
                      ? startdate
                      : `${startdate} - ${enddate}`
                  }
                  name={res.people_work_placement.global_user.name}
                  submited={generateDate(res.date_added)}
                  status={res.status}
                  title={
                    res.time_overtime_policy && res.time_overtime_policy.name
                  }
                  confirm_type={
                    res.time_overtime_policy &&
                    res.time_overtime_policy.confirm_type
                  }
                  data={res}
                  from="overtime"
                />
              )
            })
          ) : (
            <EmptyState
              image={NoListImage}
              message1="Sorry, No List"
              message2="No overtime found"
            />
          )}
        </div>

        <TablePaginationStyled
          className="mt-auto"
          onChangePage={(e, newPage) => {
            e.preventDefault()
            setPagination({...pagination, page: newPage})
          }}
          onChangeRowsPerPage={e => {
            setPagination({
              ...pagination,
              page: 0,
              rowsPerPage: parseInt(e.target.value, 10),
            })
          }}
          rowsPerPageOptions={PAGINATION_RANGE}
          component="div"
          count={data && data.total.aggregate.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
        />

        <FilterWLB
          id={idFilter}
          open={openFilter}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          idWork={dataWork && dataWork.people_work_placements[0].id}
          withPolicy
          from={'overtime-activity'}
          onApply={(policy, date, requester, status) => {
            handleQueryFiltered(policy, date, status, requester)
          }}
          components={[
            {
              idx: 'type',
              name: 'Leave Type',
              type: 'policy',
            },
            {
              idx: 'status',
              name: 'Status',
              type: 'status',
            },
            {
              idx: 'submission',
              name: 'Submission Date',
              type: 'date',
            },
          ]}
        />

        <ModalDetailWeb
          title={
            detail.data &&
            detail.data.time_overtime_policy &&
            detail.data.time_overtime_policy.name
          }
          open={detail.openWeb}
          onClose={() =>
            setDetail({...detail, open: false, openWeb: false, data: null})
          }
          status={detail.data && detail.data.status}
          confirm_type={
            detail.data &&
            detail.data.time_overtime_policy &&
            detail.data.time_overtime_policy.confirm_type
          }
          data={detail.data}
          from="overtime"
        >
          <DetailList
            from="overtime"
            type="activity"
            salary={salary}
            id={detail.data && detail.data.id}
            onClickCancel={id => handleCancelSubmission(id)}
          />
        </ModalDetailWeb>

        <CancelDialog
          open={openCancelSubmission.open}
          title="Cancel to Apply for Overtime?"
          content="You have unsaved changes. Are you sure want to cancel your submission?"
          textSecondary="Not Now"
          textPrimary="Yes, Cancel"
          onClose={() => {
            setDetail({
              ...detail,
              openWeb: true,
            })
            setOpenCancelSubmission({
              open: false,
              id: null,
            })
          }}
          onClickPrimary={() => cancelNow(openCancelSubmission.id)}
        />
      </div>
    </>
  )
}
