import React from 'react'

const generateStatus = (status, confirm_type, second, isSame = false) => {
  let statusnew = status
  if (status === 'pending') {
    statusnew = 'pending'
  } else if (confirm_type === '1' && status === 'confirm1' && second === null) {
    statusnew = 'approved'
  } else if (confirm_type === '1' && status === 'confirm1' && second) {
    if (isSame) {
      statusnew = 'approved'
    } else {
      statusnew = 'pending'
    }
  } else if (confirm_type === '2' && status === 'pending') {
    statusnew = 'pending'
  } else if (confirm_type === '2' && status === 'confirm1') {
    statusnew = 'pending'
  }
  switch (statusnew) {
    case 'pending':
      return <span className="text-warning">Waiting</span>
    case 'approved':
      return <span className="text-success">Approved</span>
    case 'cancelled':
      return <span className="text-disable">Cancelled</span>
    case 'rejected':
      return <span className="text-danger">Rejected</span>
    default:
      return <span />
  }
}

export const generateSubmissionStatus = ({data, policy_type, field_from}) => {
  const status = data && data.status
  const policy = data && data[policy_type]
  const confirm_type = policy && policy.confirm_type
  const first = policy && policy.first_job_profile
  const second = policy && policy.second_job_profile

  const job_profile_supervisor_1 =
    data &&
    data.people_work_placement.company_job_profile.job_profile_supervisor
  const job_profile_supervisor_2 =
    job_profile_supervisor_1 && job_profile_supervisor_1.spv_2
  const job_profile_supervisor_3 =
    job_profile_supervisor_2 && job_profile_supervisor_2.spv_3

  const is_spv_1 =
    job_profile_supervisor_1 &&
    job_profile_supervisor_1.people_work_placements[0]
  const is_spv_2 =
    job_profile_supervisor_2 &&
    job_profile_supervisor_2.people_work_placements[0]
  const is_spv_3 =
    job_profile_supervisor_3 &&
    job_profile_supervisor_3.people_work_placements[0]

  const spvName = (is_spv_1 || is_spv_2 || is_spv_3)?.global_user?.name
  const spvPosition = is_spv_1
    ? job_profile_supervisor_1.title
    : is_spv_2
    ? job_profile_supervisor_2.title
    : is_spv_3
    ? job_profile_supervisor_3.title
    : ''

  const isRejected = data && data.status === 'rejected'
  const bridge = isRejected
    ? [`first_reject`, 'second_reject']
    : [`first_approve`, 'second_approve']
  const fields = data && data[field_from]

  const firstUserAction = (fields && [field_from] &&
    fields[bridge[0]] && [
      ' ' + fields[bridge[0]].user.name,
      ' ' + (fields[bridge[0]].user.title || ''),
    ]) || [undefined, undefined]
  const secondUserAction = (fields &&
    fields[bridge[1]] && [
      ' ' + fields[bridge[1]].user.name,
      ' ' + (fields[bridge[1]].user.title || ''),
    ]) || [undefined, undefined]

  if (status === 'cancelled') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} by{' '}
        <span className="text-secondary">User</span>
      </>
    )
  } else if (status === 'rejected') {
    return (
      <>
        {generateStatus(status, confirm_type, second, true)} by{' '}
        <span className="text-secondary">
          {second
            ? secondUserAction[1] || firstUserAction[0] + firstUserAction[1]
            : confirm_type === '2'
            ? secondUserAction[1]
            : firstUserAction[0] + firstUserAction[1]}
        </span>
      </>
    )
  } else if (status === 'approved') {
    return (
      <>
        <span className="text-success">Approved</span>{' '}
        {confirm_type !== '3' && ' by '}
        <span className="text-secondary">
          {confirm_type === '3'
            ? null
            : second
            ? secondUserAction[1] || firstUserAction[1] || second.title
            : confirm_type === '2'
            ? secondUserAction[1] || first.title
            : (firstUserAction[0] || spvName) +
              ' ' +
              (firstUserAction[1] || spvPosition)}
        </span>
      </>
    )
  } else if (status === 'pending') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} for{' '}
        <span className="text-secondary">
          {confirm_type === '1'
            ? spvName + ' ' + spvPosition
            : first && first.title}
        </span>{' '}
        approval
      </>
    )
  } else if (status === 'confirm1') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} for{' '}
        <span className="text-secondary">{second.title}</span> approval
      </>
    )
  }
}

export const userCanEdit = (type, status, first, second, spv, user_id) => {
  if (status === 'approved') {
    return false
  } else if (status === 'rejected') {
    return false
  } else if (status === 'cancelled') {
    return false
  } else if (type === '1') {
    // ini SPV only
    if (first === null && second === null) {
      if (user_id === spv.people_work_placements[0].global_user.id) {
        if (status === 'pending') {
          return true
        } else {
          return false
        }
      }
      // ini SPV dan CFO
    } else if (first === null && second !== null) {
      if (status === 'pending') {
        if (user_id === spv.people_work_placements[0].global_user.id) {
          return true
        } else {
          return false
        }
      } else if (status === 'confirm1') {
        // cek apakah di position yg di tuju ada orangnya atau tidak, dan
        // cek apakah pwp di second position === user id atau tidak
        if (
          second.people_work_placements.length > 0 &&
          second.people_work_placements
            .map(({global_user}) => global_user.id)
            .includes(user_id)
        ) {
          return true
        } else {
          return false
        }
      } else if (status === 'approved') {
        return false
      }
    }
    // ini CFO only
  } else if (type === '2') {
    if (status === 'pending') {
      // cek apakah di position yg di tuju ada orangnya atau tidak, dan
      // cek apakah pwp di first position === user id atau tidak
      if (
        first.people_work_placements.length > 0 &&
        first.people_work_placements
          .map(({global_user}) => global_user.id)
          .includes(user_id)
      ) {
        return true
      } else {
        return false
      }
    } else if (status === 'confirm1') {
      // cek apakah di position yg di tuju ada orangnya atau tidak, dan
      // cek apakah pwp di second position === user id atau tidak
      if (
        second.people_work_placements.length > 0 &&
        second.people_work_placements
          .map(({global_user}) => global_user.id)
          .includes(user_id)
      ) {
        return true
      } else {
        return false
      }
    } else if (status === 'approved') {
      return false
    }
  }
  return true
}
