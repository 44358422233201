import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {StyledDialog} from '../../../components/modal/ModalStyles'
import {useMediaQuery, useTheme} from '@material-ui/core'
import {COMPANY_NAME, COMPANY_LOGO} from '../../../utils/globals'
import '../../../styles/print.css'

export default function ModalDetailWebFinance({open, onClose, ...child}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledDialog
      classes={{paper: 'w-693-px'}}
      open={open}
      onClose={onClose}
      maxWidth="xl"
      scroll="body"
      fullScreen={fullScreen}
    >
      <div className="dialog-printing">
        <div className="flex flex-col">
          <div
            className={`flex flex-row justify-between ${
              fullScreen ? 'p-4' : 'p-6'
            } items-center`}
          >
            <div className="flex flex-row items-center">
              <div className={`mr-4`}>
                <img alt="logo" src={COMPANY_LOGO} height="auto" width="50px" />
              </div>

              <h2 className="font-semibold">{COMPANY_NAME}</h2>
            </div>

            {fullScreen ? (
              <CloseIcon className="cursor-pointer" onClick={onClose} />
            ) : null}
          </div>

          <div {...child} />
        </div>
      </div>
    </StyledDialog>
  )
}
